import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Typography } from 'components/common/Typography';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';

export const MyAssignments = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const location = useLocation();
  const { pathTo } = useRouterUtils();

  const myAssignmentsQuery = useQuery('GetMyAssignments', {
    params: { organizationId, projectId },
  });

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            My Assignments
          </Typography>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <table className="table-primary mt-8">
          <thead>
            <tr>
              <th>Job</th>
              <th>Role</th>
              <th>Dataitems</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {myAssignmentsQuery.data?.length === 0 && (
              <tr>
                <td colSpan={100} className="p-4 text-center text-slate-500">
                  No Assignments
                </td>
              </tr>
            )}

            {myAssignmentsQuery.data?.map(({ job, mapStateToDataitemCount }) => (
              <tr key={job.id}>
                <td>
                  <Link to={pathTo('JOB', { jobId: job.id })}>{job.name}</Link>
                </td>
                <td>-</td>
                <td>
                  <div className="flex gap-1">
                    {Object.entries(mapStateToDataitemCount).map(([key, value]: any) => (
                      <div key={key}>
                        <div className="inline-flex gap-2 rounded-l border border-r-0 border-slate-200 bg-white px-2 py-0.5 text-slate-600">
                          {key}
                        </div>
                        <div className="inline-flex gap-2 rounded-r border border-slate-200 bg-white px-2 py-0.5 text-slate-500">
                          {value}
                        </div>
                      </div>
                    ))}
                  </div>
                </td>

                <td className="text-right">
                  <Link
                    className="inline-flex items-center gap-1"
                    to={{
                      pathname: pathTo('WORKSPACE', {
                        organizationId,
                        projectId,
                        jobId: job.id,
                        jobDataitemId:
                          localStorage.getItem(`workspace-${projectId}-${job.id}-prev-dataitem-id`) || undefined,
                      }),
                      state: { referrer: location.pathname },
                    }}
                  >
                    <MdOutlineOpenInNew />
                    Workspace
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </LayoutContent>
    </LayoutContentBody>
  );
};
