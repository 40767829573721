import { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { InputText } from '../Forms/InputText/InputText';

interface Props {
  onChange: (query: string) => void;
}

export const SearchInput = ({ onChange }: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const buttonClassNames = 'p-2 hover:text-slate-400';

  return (
    <form
      className="relative overflow-hidden"
      onSubmit={(e) => {
        e.preventDefault();
        onChange(searchQuery);
      }}
    >
      <InputText
        variant="sm"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <div className="absolute right-1 top-1 bottom-1 flex items-center overflow-hidden">
        {searchQuery && (
          <button type="button" onClick={() => setSearchQuery('')} className={buttonClassNames}>
            <IoMdClose size={16} />
          </button>
        )}

        <button type="submit" className={buttonClassNames}>
          <AiOutlineSearch />
        </button>
      </div>
    </form>
  );
};
