import { AnnotationInstanceDO } from 'domain/AnnotationInstanceDO';
import { atom, useSetAtom } from 'jotai';
import { splitAtom } from 'jotai/utils';
import { ObjectKeyframeAnimation } from 'modules/video-editor-2/objects/ObjectKeyframeAnimation';

export const annotationsAtom = atom<AnnotationInstanceDTO[]>([]);
export const annotationAtomsAtom = splitAtom(annotationsAtom);
export const selectedAnnotationInstanceIdAtom = atom<AnnotationInstanceDTO['id'] | null>(null);

export const globalAnnotationInstanceIdAtom = atom<AnnotationInstanceDTO['id'] | null>(null);

export const selectedAnnotationInstanceAtom = atom((get) =>
  get(annotationsAtom).find((e) => e.id === get(selectedAnnotationInstanceIdAtom))
);

export const highlightedAnnotationInstanceIdAtom = atom<AnnotationInstanceDTO['id'] | null>(null);
export const highlightedAnnotationInstanceAtom = atom((get) =>
  get(annotationsAtom).find((e) => e.id === get(highlightedAnnotationInstanceIdAtom))
);

export const mapAtom = atom(null, (get, _set, cb: any) => {
  const items = get(annotationsAtom);
  items.forEach(cb);
});

const setKeyframes = (item: any) => {
  if (!item.editorObject) {
    return;
  }

  const keyframablePropertes = item.annotationClass.properties.filter((e: any) => e.isKeyframable);
  const keyframeAnimationData = Object.fromEntries(
    keyframablePropertes.map((e: any) => [e.key, item.properties[e.key] || []])
  );

  item.editorObject.data('animation', ObjectKeyframeAnimation(item.editorObject, keyframeAnimationData));
};

export const useAnnotationsStore = () => {
  const setAnnnotations = useSetAtom(annotationsAtom);
  const setSelectionId = useSetAtom(selectedAnnotationInstanceIdAtom);
  const setHighlightedId = useSetAtom(highlightedAnnotationInstanceIdAtom);

  const append = (instance: AnnotationInstanceDTO) => {
    setKeyframes(instance); // @todo this is not nice

    setAnnnotations((prev: any) => [...prev, instance]);
  };

  const update = (id: AnnotationInstanceDTO['id'], props: any) => {
    setAnnnotations((annotations) => {
      return annotations.map((e) => {
        if (e.id === id) {
          const instanceDo = AnnotationInstanceDO(e);

          if (props.hasOwnProperty('frame')) {
            const { key, frame, value } = props;
            instanceDo.upsertKeyframe(key, { frame, value });
          } else {
            instanceDo.setValue(props.key, props.value);
          }

          return instanceDo.toDto();
        }

        return e;
      });
    });
  };

  const moveKeyframe = (id: AnnotationInstanceDTO['id'], { key, from, to }: { key: any; from: number; to: number }) => {
    setAnnnotations((annotations) => {
      return annotations.map((e) => {
        if (e.id === id) {
          const instanceDo = AnnotationInstanceDO(e);
          instanceDo.moveKeyframe(key, { from, to });
          return instanceDo.toDto();
        }

        return e;
      });
    });
  };

  const deleteKeyframe = (id: AnnotationInstanceDTO['id'], { key, index }: { key: any; index: number }) => {
    setAnnnotations((annotations) => {
      return annotations.map((e) => {
        if (e.id === id) {
          const instanceDo = AnnotationInstanceDO(e);
          instanceDo.deleteKeyframe(key, index);
          return instanceDo.toDto();
        }

        return e;
      });
    });
  };

  const removeAllKeyframes = (id: AnnotationInstanceDTO['id'], { key }: { key: any }) => {
    setAnnnotations((annotations) => {
      return annotations.map((e) => {
        if (e.id === id) {
          const instanceDo = AnnotationInstanceDO(e);
          instanceDo.removeAllKeyframes(key);
          return instanceDo.toDto();
        }

        return e;
      });
    });
  };

  const removeById = (id: AnnotationInstanceDTO['id']) => {
    setAnnnotations((prev) => prev.filter((e) => e.id !== id));
  };

  const select = (id: AnnotationInstanceDTO['id']) => {
    setSelectionId(id);
  };

  const highlight = (id: AnnotationInstanceDTO['id'] | null) => {
    setHighlightedId(id);
  };

  return {
    append,
    update,
    moveKeyframe,
    deleteKeyframe,
    removeAllKeyframes,
    removeById,
    select,
    highlight,
  };
};
