import { Button } from 'components/common/Button/Button';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { DropdownButtonItem } from 'components/common/DropdownButtonItem/DropdownButtonItem';
import { Tag } from 'components/common/Tag/Tag';
import { mapUserStatusToDisplayName } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { IoTrashBin } from 'react-icons/io5';
import { RiMore2Fill } from 'react-icons/ri';
import { getFormattedDate } from 'utils/dateUtils';

interface UserListingProps {
  users: OrganizationUserDTO[];
  roles: UserRoleDTO[];
}

export const UserListing = ({ users, roles }: UserListingProps) => {
  const { organizationId } = useIntParams<RouteParams.Organization>();
  const { mutate: removeOrganizationUser } = useMutation('RemoveOrganizationUser');
  const { mutateAsync: setUserRole } = useMutation('SetUserRole');

  const handleRoleChange = ({ userId, roleId }: { userId: number; roleId: number }) => {
    setUserRole({
      data: { roleId },
      params: { organizationId, userId },
    });
  };

  return (
    <div>
      <table className="table-primary">
        <thead>
          <tr>
            <th>#</th>
            <th>Email</th>
            <th>Status</th>
            <th>Role</th>
            <th>Created</th>
            <th>Last Login</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map(({ id, email, createdAt, authenticatedAt, status, roleId }, index) => (
            <tr key={id}>
              <td>{index + 1}</td>
              <td>{email}</td>
              <td>
                <Tag>{mapUserStatusToDisplayName[status].toUpperCase()}</Tag>
              </td>
              <td>
                <select
                  value={roleId}
                  onChange={(e) => handleRoleChange({ userId: id, roleId: parseInt(e.target.value, 10) })}
                >
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </td>
              <td>{getFormattedDate(createdAt)}</td>
              <td>{authenticatedAt || '-'}</td>
              <td className="text-right">
                <Dropdown
                  render={() => (
                    <div>
                      <DropdownButtonItem
                        onClick={() =>
                          removeOrganizationUser({
                            params: {
                              organizationId,
                              userId: id,
                            },
                          })
                        }
                      >
                        <IoTrashBin /> Remove
                      </DropdownButtonItem>
                    </div>
                  )}
                >
                  <Button icon={<RiMore2Fill />} variant="icon-rounded" />
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserListing;
