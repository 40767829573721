import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const showSidebarAtom = atomWithStorage('workspace-sidebar-visibility', true);
const showDataitemSidebarAtom = atomWithStorage('workspace-dataitem-sidebar-visibility', true);
const keyboardShortcutsAtom = atomWithStorage('keyboard-shortcuts', {});

const showTimelinePanelAtom = atomWithStorage('workspace-dataitem-video-timline-panel-visibility', true);

const annotationInstancesToPersist = atom<any>({});

const hasUnsavedChangesAtom = atom<boolean>(false);

export const workspaceState = {
  showSidebarAtom,
  showDataitemSidebarAtom,
  keyboardShortcutsAtom,

  // video editor specific
  showTimelinePanelAtom,

  annotationInstancesToPersist,
  hasUnsavedChangesAtom,
};
