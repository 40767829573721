import { Dialog } from 'components/common/Dialog/Dialog';
import { Table } from 'components/common/Table/Table';
import { DataitemPreviewModal } from 'components/Modals/DataitemPreviewModal/DataitemPreviewModal';
import { useDataitemsTable } from 'hooks/react-table/useDataitemsTable';
import useIntParams from 'hooks/useIntParams';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { useState } from 'react';

export const Dataitems = () => {
  const { organizationId, projectId, datasetId } = useIntParams<RouteParams.Dataset>();

  const dataitemsQuery = usePaginatedQuery('GetDataitemsWithPagination', {
    params: { organizationId, projectId, datasetId },
  });

  const [isDataitemPreviewModalOpen, setIsDataitemPreviewModalOpen] = useState(false);
  const [dataitemForPreview, setDataitemForPreview] = useState<DataitemDTO | null>(null);
  const handleOnPreview = (dataitem: DataitemDTO) => {
    setDataitemForPreview(dataitem);
    setIsDataitemPreviewModalOpen(true);
  };

  const { instance } = useDataitemsTable({ rows: dataitemsQuery.data?.results || [], onPreview: handleOnPreview });

  return (
    <>
      <Table instance={instance} pagination={dataitemsQuery.pagination} />
      <Dialog
        render={() => dataitemForPreview && <DataitemPreviewModal dataitem={dataitemForPreview} />}
        isOpen={isDataitemPreviewModalOpen}
        onOpenChange={setIsDataitemPreviewModalOpen}
      />
    </>
  );
};
