import { UiContainer } from '@ory/kratos-client';

interface OryFormUiNodeMessagesProps {
  messages: UiContainer['messages'];
}

export const OryFormUiNodeMessages = ({
  messages,
}: OryFormUiNodeMessagesProps) => {
  if (!messages?.length) {
    return null;
  }

  return (
    <div className="text-xs my-1 text-annotorio-pink">
      {messages.map(({ text }, index) => (
        <div key={index}>{text}</div>
      ))}
    </div>
  );
};
