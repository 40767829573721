import { FloatingPortal, autoUpdate, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';

export const AnnotationGraphViewEdge = ({ containerRef, elA, elB, onRemove }: any) => {
  const [component, setComponent] = useState<any>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState([0, 0]);
  const { strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    if (!elA || !elB) {
      return;
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    const rectA = elA.getBoundingClientRect();
    const rectB = elB.getBoundingClientRect();

    if (rectA.width === 0 || rectB.width === 0) {
      setComponent(null);

      return;
    }

    const x1 = rectA.x + rectA.width - containerRect.x;
    const y1 = rectA.y + rectA.height / 2 - containerRect.y;
    const x2 = rectB.x - containerRect.x;
    const y2 = rectB.y + rectA.height / 2 - containerRect.y;

    setComponent(
      <svg
        className="absolute w-full h-full top-0 left-0 pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
      >
        <line
          id="svg-entity-relation-connector"
          className="pointer-events-auto"
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          strokeWidth={10}
          onContextMenu={(e) => {
            e.preventDefault();
            setContextMenuPosition([e.clientX, e.clientY]);
            setIsOpen(true);
          }}
          ref={refs.setReference}
          {...getReferenceProps()}
        />
        <line
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          strokeWidth={2}
          strokeLinecap="round"
          className={classNames({ 'is-active': isOpen })}
        />
      </svg>
    );
  }, [containerRef, elA, elB, getReferenceProps, isOpen, refs.setReference]);

  return (
    <>
      {component}
      {isOpen && (
        <FloatingPortal>
          <div
            className="text-neutral-200 text-xxs z-10 border border-neutral-800 bg-black"
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: contextMenuPosition[1],
              left: contextMenuPosition[0],
              transform: 'translateX(-50%)',
            }}
            {...getFloatingProps()}
          >
            <button
              className="flex w-full items-center gap-2 bg-black py-1.5 px-4 hover:bg-neutral-800"
              onClick={onRemove}
            >
              <IoCloseSharp /> Remove
            </button>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
