import React, { useRef } from 'react';

type CollapsibleProps = React.PropsWithChildren<{
  open: boolean;
}>;

export const Collapsible = ({ children, open }: CollapsibleProps) => {
  const collapsibleRef = useRef<HTMLDivElement>(null);

  return <>{open ? <div ref={collapsibleRef}>{children}</div> : null}</>;
};
