import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AnnotationGraphViewNode } from './AnnotationsGraphView/AnnotationGraphViewNode';
import { AnnotationGraphViewEdge } from './AnnotationsGraphView/AnnotationGraphViewNodeEdge';
import { annotationInstanceDoAtom } from './WorkspaceDatatitem2';
import { videoEditorState } from './state/videoEditorState';
import { selectedAnnotationInstanceAtom, useAnnotationsStore } from './useAnnotationsStore';

export const AnnotationsGraphView = () => {
  const [tagsRef, setTagsRef] = useState<any>({});
  const selectionRef = useRef<any>(null);
  const containerRef = useRef<any>({});
  const [annotationInstance] = useAtom(selectedAnnotationInstanceAtom);
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);
  const { update } = useAnnotationsStore();

  const setSelection = (key: any) => {
    selectionRef.current = key;
  };

  const handleDocumentMouseUp = useCallback(() => {
    selectionRef.current = null;
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleDocumentMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleDocumentMouseUp);
    };
  }, [handleDocumentMouseUp]);

  const [annotationInstanceDo] = useAtom(annotationInstanceDoAtom);

  if (!annotationInstance || !annotationInstanceDo) {
    return null;
  }

  const annotationClassProperties = Object.fromEntries(
    annotationInstance.annotationClass.properties.map((e) => [e.key, e])
  );

  const props = annotationInstanceDo.getAllPropertyValuesAtKeyframe(currentFrame);

  const truthyProps = Object.fromEntries(Object.entries(props).filter(([_, value]) => value === true));
  const setA = Object.fromEntries(Object.entries(truthyProps).filter(([key]) => key.startsWith('set-a')));
  const setB = Object.fromEntries(Object.entries(truthyProps).filter(([key]) => key.startsWith('set-b')));
  const relations = Object.entries(truthyProps)
    .filter(([key]) => key.startsWith('relation'))
    .map(([key]) => {
      const parts = key.split('_');
      const source = parts[1] + '_' + parts[2];
      const target = parts[3] + '_' + parts[4];
      const indexA = Object.keys(setA).indexOf(source);
      const indexB = Object.keys(setB).indexOf(target);
      return { source: parts[1] + '_' + parts[2], target: parts[3] + '_' + parts[4], indexA, indexB, key };
    });

  const createRelation = (keyA: any, keyB: any) => {
    if (!keyA || !keyB || keyA === keyB) {
      return;
    }

    if (keyB.startsWith('set-a')) {
      [keyA, keyB] = [keyB, keyA];
    }

    const relationKey = ['relation', keyA, keyB].join('_');

    const relationProp = annotationClassProperties[relationKey];

    if (!relationProp) {
      return alert(`error: relation ${relationKey} undefined`);
    }

    update(annotationInstance.id, {
      key: relationKey,
      frame: currentFrame,
      value: true,
    });
  };

  const removeRelation = (key: any) => {
    const prevFramePropertyValue = annotationInstanceDo.getPropertyValueAtKeyframe(key, currentFrame - 1);
    let value: any = false;

    if (prevFramePropertyValue === undefined || prevFramePropertyValue === false) {
      value = null;
    }

    update(annotationInstance.id, {
      key,
      frame: currentFrame,
      value,
    });
  };

  const removeNode = (key: any) => {
    const prevFramePropertyValue = annotationInstanceDo.getPropertyValueAtKeyframe(key, currentFrame - 1);

    let value: any = false;

    if (prevFramePropertyValue === undefined || prevFramePropertyValue === false) {
      value = null;
    }

    update(annotationInstance.id, {
      key,
      frame: currentFrame,
      value,
    });

    relations.filter((e) => e.target === key || e.source === key).forEach((e) => removeRelation(e.key));
  };

  return (
    <div ref={containerRef} className="relative text-xs flex rounded-sm m-2 gap-10">
      {Object.keys(setA).length > 0 && (
        <div className="flex flex-col gap-1.5">
          {Object.keys(setA).map((key: any) => (
            <AnnotationGraphViewNode
              key={key}
              label={annotationClassProperties[key].label}
              onMouseDown={() => setSelection(key)}
              onMouseUp={() => createRelation(selectionRef.current, key)}
              onRemove={() => removeNode(key)}
              ref={(e) => {
                setTagsRef((prev: any) => (prev[key] ? prev : { ...prev, [key]: e }));
              }}
              onUnmount={() => {
                setTagsRef((prev: any) => {
                  const { [key]: _, ...rest } = prev;
                  return rest;
                });
              }}
            />
          ))}
        </div>
      )}

      {Object.keys(setB).length > 0 && (
        <div className="flex flex-col gap-1.5">
          {Object.keys(setB).map((key: any) => (
            <AnnotationGraphViewNode
              key={key}
              label={annotationClassProperties[key].label}
              onMouseDown={() => setSelection(key)}
              onMouseUp={() => createRelation(selectionRef.current, key)}
              onRemove={() => removeNode(key)}
              ref={(e) => {
                setTagsRef((prev: any) => (prev[key] ? prev : { ...prev, [key]: e }));
              }}
              onUnmount={() => {
                setTagsRef((prev: any) => {
                  const { [key]: _, ...rest } = prev;
                  return rest;
                });
              }}
            />
          ))}
        </div>
      )}

      {relations.map((rel: any) => (
        // @ts-ignore
        <AnnotationGraphViewEdge
          key={`${rel.source}-${rel.target}-${rel.indexA}-${rel.indexB}-${currentFrame}`}
          containerRef={containerRef}
          elA={tagsRef[rel.source]}
          elB={tagsRef[rel.target]}
          onRemove={() => removeRelation(rel.key)}
        />
      ))}
    </div>
  );
};
