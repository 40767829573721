import { Box } from 'components/common/Box/Box';
import { Button } from 'components/common/Button/Button';
import { LayoutLoadingSkeleton } from 'components/common/Layout/LayoutLoadingSkeleton';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { PaddleSubscriptionStatus } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { usePaddleJs } from 'hooks/usePaddleJs';
import { useQuery } from 'hooks/useQuery';

// @todo
const mapPlanIdToPaddleCheckoutProductId: any = {
  2: 26734,
  3: 26811,
};

// @todo
export const mapSubscriptionPlanIdToDisplayName: any = {
  '26734': 'PRO',
  '26811': 'ENTERPRISE',
};

export const SubscriptionPlans = () => {
  usePaddleJs();

  const { organizationId } = useIntParams<RouteParams.Organization>();
  const subscriptionPlanQuery = useQuery('GetSubscriptionPlan', { params: { organizationId } });
  const { mutateAsync: updateSubscriptionPlan } = useMutation('UpdateSubscriptionPlan');

  const selectSubscriptionPlan = async (id: number) => {
    if (!currentSubscriptionPlanId) {
      const product = mapPlanIdToPaddleCheckoutProductId[id];

      if (!product) {
        return;
      }

      const passthrough = JSON.stringify({
        organizationId,
      });

      Paddle.Checkout.open({
        product,
        passthrough,
      });
    }

    if (currentSubscriptionPlanId) {
      await updateSubscriptionPlan({
        data: { newPlanId: mapPlanIdToPaddleCheckoutProductId[id] },
        params: { organizationId },
      });
    }
  };

  const currentSubscriptionPlanId = subscriptionPlanQuery.data?.externalPlanId;
  const currentSubscriptionPlandDisplayName = subscriptionPlanQuery.data?.externalPlanId
    ? mapSubscriptionPlanIdToDisplayName[subscriptionPlanQuery.data?.externalPlanId]
    : 'FREE';

  if (subscriptionPlanQuery.isLoading) {
    return <LayoutLoadingSkeleton />;
  }

  if (subscriptionPlanQuery.isError || !subscriptionPlanQuery.data) {
    return <ErrorPage />;
  }

  return (
    <div>
      <div className="space-y-6">
        <Box>
          Current plan{' '}
          <span className="border px-4 py-1 bg-white rounded text-sm font-medium">
            {currentSubscriptionPlandDisplayName}
          </span>
          {subscriptionPlanQuery.data.status === PaddleSubscriptionStatus.PENDING && <div>Pending Activation</div>}
          {subscriptionPlanQuery.data.status === PaddleSubscriptionStatus.ACTIVE && (
            <div>
              <div>
                Next billing date:
                <b>{subscriptionPlanQuery.data?.nextBillDate}</b>
              </div>

              {subscriptionPlanQuery.data?.cancelUrl && (
                <div>
                  <a href={subscriptionPlanQuery.data?.cancelUrl}>Cancel Plan</a>
                </div>
              )}

              {subscriptionPlanQuery.data?.updateUrl && (
                <>
                  <div>Payment method</div>
                  <a href={subscriptionPlanQuery.data?.updateUrl}>Update</a>
                </>
              )}
            </div>
          )}
          {subscriptionPlanQuery.data?.status === PaddleSubscriptionStatus.DELETED && (
            <div>
              <div>
                Status: <b>Cancelled</b>
              </div>
              <div>Active until: {subscriptionPlanQuery.data?.endDate}</div>
            </div>
          )}
          {subscriptionPlanQuery.data?.status === PaddleSubscriptionStatus.PAUSED && (
            <div>
              <div>
                Status: <b>Paused</b>
              </div>
              <div>Active until: {subscriptionPlanQuery.data?.endDate}</div>
            </div>
          )}
        </Box>

        <hr />

        {currentSubscriptionPlanId && (
          <Box>
            <div className="text-xl mb-1">
              <span className="font-semibold">Free</span> Plan
            </div>
            <Button label="Select" onClick={() => selectSubscriptionPlan(1)} />
          </Box>
        )}

        {currentSubscriptionPlanId !== '26734' && (
          <Box>
            <div className="text-xl mb-1">
              <span className="font-semibold">Pro</span> Plan
            </div>
            <Button label="Upgrade" onClick={() => selectSubscriptionPlan(2)} />
          </Box>
        )}

        {currentSubscriptionPlanId !== '26811' && (
          <Box>
            <div className="text-xl mb-1">
              <span className="font-semibold">Enterprise</span> Plan
            </div>
            <Button label="Upgrade" onClick={() => selectSubscriptionPlan(3)} />
          </Box>
        )}
      </div>
    </div>
  );
};
