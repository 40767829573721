import { useQueryClient } from 'react-query';
import { oryKratosService } from './oryKratos.service';

export const useLogoutFlow = () => {
  const queryClient = useQueryClient();

  const logout = async () => {
    const res = await oryKratosService.initLogoutFlow().catch((err) => {
      queryClient.invalidateQueries([{ id: 'IsAuthenticated' }]);
    });

    if (res) {
      const { logout_url } = res.data;

      const searchParams = new URLSearchParams(new URL(logout_url).search);
      const token = searchParams.get('token');

      if (!token) {
        console.log('Logout token not found');
        return;
      }

      await oryKratosService.logout({ token }).then(() => {
        queryClient.invalidateQueries([{ id: 'IsAuthenticated' }]);
      });
    }
  };

  return { logout };
};
