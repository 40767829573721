import { GlobalCompositeOperation, ShapeType } from 'modules/image-annotator/enums';

export const mapItemTypeToRenderer: any = {
  [ShapeType.RECTANGLE]: ({
    item,
    transform,
    context,
    overrides,
  }: {
    item: Rect;
    transform: Transform;
    context: CanvasRenderingContext2D;
    overrides: {};
  }) => {
    const { x, y, width, height, fillStyle, strokeStyle, lineWidth, lineDash } = {
      ...item.props(),
      ...overrides,
    };

    const { x: tx, y: ty, scale } = transform;

    context.fillStyle = fillStyle;
    context.strokeStyle = strokeStyle;
    context.lineWidth = lineWidth;

    if (lineDash.length) {
      context.setLineDash(lineDash);
    }

    context.beginPath();
    context.rect(x * scale + tx, y * scale + ty, width * scale, height * scale);
    context.fill();
    lineWidth !== 0 && context.stroke();
    context.closePath();
  },

  [ShapeType.POLYGON]: ({
    item,
    transform,
    context,
    overrides,
  }: {
    item: Path;
    transform: Transform;
    context: CanvasRenderingContext2D;
    overrides: {};
  }) => {
    const { points, isClosed, fillStyle, strokeStyle, lineWidth } = {
      ...item.props(),
      ...overrides,
    };
    const { x: tx, y: ty, scale } = transform;

    context.fillStyle = fillStyle;
    context.strokeStyle = strokeStyle;
    context.lineWidth = lineWidth;

    context.beginPath();

    points.forEach((point) => {
      const { x, y } = point.props();
      context.lineTo(x * scale + tx, y * scale + ty);
    });

    if (isClosed) {
      context.closePath();
    }

    context.fill();
    lineWidth !== 0 && context.stroke();
  },

  [ShapeType.CIRCLE]: ({
    item,
    transform,
    context,
    overrides,
  }: {
    item: Circle;
    transform: Transform;
    context: CanvasRenderingContext2D;
    overrides: {};
  }) => {
    const { x, y, radius, fillStyle, strokeStyle, lineWidth, renderAs } = {
      ...item.props(),
      ...overrides,
    };
    const { x: tx, y: ty, scale } = transform;

    context.fillStyle = fillStyle;
    context.strokeStyle = strokeStyle;
    context.lineWidth = lineWidth;

    context.beginPath();

    if (renderAs === 'ARC') {
      context.arc(x * scale + tx, y * scale + ty, radius, 0, Math.PI * 2);
    }

    // @todo - This is a very hackish solution to render anchor points as rectangles
    if (renderAs === 'RECT') {
      context.rect(x * scale - radius + tx, y * scale - radius + ty, radius * 2, radius * 2);
    }

    context.fill();
    lineWidth !== 0 && context.stroke();
    context.closePath();
  },

  [ShapeType.ELLIPSE]: ({
    item,
    transform,
    context,
    overrides,
  }: {
    item: Ellipse;
    transform: Transform;
    context: CanvasRenderingContext2D;
    overrides: {};
  }) => {
    const { x, y, radiusX, radiusY, fillStyle, strokeStyle, lineWidth } = {
      ...item.props(),
      ...overrides,
    };
    const { x: tx, y: ty, scale } = transform;

    context.fillStyle = fillStyle;
    context.strokeStyle = strokeStyle;
    context.lineWidth = lineWidth;

    context.beginPath();

    context.ellipse(x * scale + tx, y * scale + ty, radiusX * scale, radiusY * scale, 0, 0, Math.PI * 2);

    context.fill();
    lineWidth !== 0 && context.stroke();
    context.closePath();
  },

  [ShapeType.PIXEL_MASK]: ({
    item,
    transform,
    context,
    overrides,
  }: {
    item: PixelMask;
    transform: Transform;
    context: CanvasRenderingContext2D;
    overrides?: {};
  }) => {
    const { imageSource, x, y, width, height, fillStyle } = {
      ...item.props(),
      ...overrides,
    };
    const { x: tx, y: ty, scale } = transform;

    if (imageSource === null) {
      return;
    }

    if (scale > 1.5) {
      context.imageSmoothingEnabled = false; // todo config?
    }

    context.drawImage(imageSource, x * scale + tx, y * scale + ty, width * scale, height * scale);

    if (fillStyle && fillStyle !== 'transparent') {
      // todo optimize?
      context.globalCompositeOperation = GlobalCompositeOperation.SOURCE_IN;
      context.fillStyle = fillStyle;
      context.fillRect(x * scale + tx, y * scale + ty, width * scale, height * scale);
    }
  },
};
