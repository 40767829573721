import { useBoundingBoxTool } from '../WorkspaceDataitemVideoEditorLogicalComponent/useBoundingBoxTool';
import { useObjectHighlight } from '../WorkspaceDataitemVideoEditorLogicalComponent/useObjectHighlight';
import { useObjectSelection } from '../WorkspaceDataitemVideoEditorLogicalComponent/useObjectSelection';

export const WorkspaceDataitemVideoEditorLogicalComponent = () => {
  useObjectSelection();
  useObjectHighlight();
  useBoundingBoxTool();

  return null;
};
