import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Table } from 'components/common/Table/Table';
import { Typography } from 'components/common/Typography';
import { useJobExportsTable } from 'hooks/react-table/useJobExportsTable';
import useIntParams from 'hooks/useIntParams';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';

export const AllJobExports = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const jobExportHistoriesQuery = usePaginatedQuery('GetJobExportHistoriesWithPagination', {
    params: { organizationId, projectId },
  });

  const { instance: jobExportsTableInstance } = useJobExportsTable({
    rows: jobExportHistoriesQuery.data?.results,
  });

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Job Exports
          </Typography>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <Table instance={jobExportsTableInstance} pagination={jobExportHistoriesQuery.pagination} />
      </LayoutContent>
    </LayoutContentBody>
  );
};
