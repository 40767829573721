import { ErrorType, mapErrorTypeToMessage } from 'shared/errors';

interface Props {
  message?: string | null;
  type?: string | null;
}

const FormError = ({ type, message }: Props) => {
  if (!type && !message) {
    return null;
  }

  return (
    <div className="bg-red-100 p-2 border border-red-200 rounded-sm my-2 text-sm text-red-600">
      {type &&
        (mapErrorTypeToMessage.hasOwnProperty(type)
          ? mapErrorTypeToMessage[type]
          : mapErrorTypeToMessage[ErrorType.GENERIC])}

      {message}
    </div>
  );
};

export default FormError;
