import { Table } from 'components/common/Table/Table';
import { useJobExportsTable } from 'hooks/react-table/useJobExportsTable';
import useIntParams from 'hooks/useIntParams';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';

export const RecentExports = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const { pathTo } = useRouterUtils();

  const jobExportHistoriesQuery = usePaginatedQuery('GetJobExportHistoriesWithPagination', {
    params: { organizationId, projectId },
    query: {
      pageSize: 5,
    },
    // @todo filter for non scheduled
  });

  const { instance: jobExportsTableInstance } = useJobExportsTable({
    rows: jobExportHistoriesQuery.data?.results,
  });

  return (
    <div>
      <div className="mb-2 mt-4 flex items-center justify-between">
        <div className="text-md font-medium text-slate-800">Recent Job Exports</div>

        <Link to={pathTo('ALL_JOB_EXPORTS')} className="text-sm">
          View All
        </Link>
      </div>

      <Table instance={jobExportsTableInstance} />
    </div>
  );
};
