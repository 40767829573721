import { Rect2 } from 'modules/video-editor/pxae/primitives/Rect2';
import { rotate2d } from 'modules/video-editor/utils/2d';

export const TransformBoxResizeAnchors = (
  box: any,
  entity: any,
  { onTransformEnd }: { onTransformEnd: () => void }
) => {
  const anchors = [...Array(4)].map((_, index) =>
    Rect2({
      x: 0,
      y: 0,
      width: 8,
      height: 8,
      fillStyle: '#fcfcfc',
      strokeStyle: '#0d99ff',
      lineWidth: 2,
      data: { draggable: true, selectable: false, index, preserveScaleOnZoom: true },
    })
  );

  function align() {
    const points = box.getPoints();
    anchors.forEach((anchor, i) => {
      const [x, y] = [points[i * 2], points[i * 2 + 1]];
      anchor.translateTo(x, y);
    });
  }

  function show() {
    anchors.forEach((anchor) => anchor.set({ isVisible: true }));
  }

  function hide() {
    anchors.forEach((anchor) => anchor.set({ isVisible: false }));
  }

  function _setup(anchor: any) {
    let [dx1, dy1] = [1, 1]; // @todo initial value

    function getPivotPoint() {
      const i = (anchor.data('index') + 2) % 4;
      const points = box.getPoints();
      const x = points[i * 2];
      const y = points[i * 2 + 1];
      return { x, y };
    }

    function getDistances() {
      const pointA = anchor.getCentroid();
      const pointB = getPivotPoint();
      const angle = -box._getRotation();
      const origin = box.getCentroid();

      const A = rotate2d(pointA, angle, origin);
      const B = rotate2d(pointB, angle, origin);

      return { x: B.x - A.x, y: B.y - A.y };
    }

    anchor.on('dragstart', () => {
      const d = getDistances();
      dx1 = d.x;
      dy1 = d.y;
    });

    anchor.on('drag', () => {
      // @todo subscribe & unsubscribe on dragend?
      const p = getPivotPoint();
      let d = getDistances();

      const sx = d.x / dx1;
      const sy = d.y / dy1;

      entity._scaleLocal({ x: sx, y: sy }, p);

      if (sx < 0 || sy < 0) {
        // @todo optimize
        d = getDistances();
      }

      dx1 = d.x;
      dy1 = d.y;

      align(); // @todo except?
    });

    anchor.on('dragend', () => {
      onTransformEnd();
    });
  }

  anchors.forEach(_setup);

  return { items: anchors, align, show, hide };
};
