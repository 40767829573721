import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { WorkflowTransitionType } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import WorkflowActionListItem from '../WorkflowActionListItem';

interface Props {
  transition: WorkflowTransitionDTO;
}

const TransitionTransitionDetails = ({ transition }: Props) => {
  const { id, label, type, workflowUserAssignActions } = transition;
  const { organizationId, projectId, workflowId } = useIntParams();
  const { mutateAsync: updateWorkflowTransitionNode } = useMutation('UpdateWorkflowTransition');
  const { mutateAsync: removeWorkflowTransitionNode } = useMutation('RemoveWorkflowTransition');

  const handleRemove = async () => {
    await removeWorkflowTransitionNode({ params: { organizationId, projectId, workflowId, workflowTransitionId: id } });
  };

  const handleTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const type = +event.target.value;
    await updateWorkflowTransitionNode({
      data: { ...transition, type },
      params: { organizationId, projectId, workflowId, workflowTransitionId: id },
    });
  };

  return (
    <>
      <div>
        Transition <span className="font-semibold">{label}</span>
      </div>

      <div className="my-2 flex flex-row gap-2 border-t py-2">
        <Dialog render={() => <ConfirmActionModal onConfirm={handleRemove} />}>
          <Button label="Delete" />
        </Dialog>
      </div>

      <div className="my-2 border-t py-2">
        <div>Actions:</div>

        {workflowUserAssignActions.map((item) => (
          <WorkflowActionListItem key={item.id} item={item} />
        ))}
      </div>

      <div className="my-2 border-t py-2">
        <div>Type:</div>

        {/* // @todo */}
        <select className="select" value={type} onChange={handleTypeChange}>
          <option value={WorkflowTransitionType.AUTO}>Auto</option>
          <option value={WorkflowTransitionType.MANUAL}>Manual</option>
        </select>
      </div>
    </>
  );
};

export default TransitionTransitionDetails;
