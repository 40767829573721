import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { selectedAnnotationInstanceAtom } from '../../useAnnotationsStore';
import { WorkspaceDataitemAnnotationInstanceFormControl } from './WorkspaceDataitemAnnotationInstanceFormControl';

export const WorkspaceDataitemAnnotationInstanceForm = () => {
  const [annotationInstance] = useAtom(selectedAnnotationInstanceAtom);

  const [searchValue, setSearchValue] = useState('');

  const items = useMemo(
    () => annotationInstance?.annotationClass.properties.filter((e) => !e.key.startsWith('relation_')) || [],
    [annotationInstance]
  );

  const items2 = useMemo(
    () => items.filter((e) => e.label.toLowerCase().includes(searchValue.toLowerCase())) || [],
    [items, searchValue]
  );

  const delimiterIndex = items2.findIndex((e) => e.key.startsWith('set-b'));

  if (!annotationInstance) {
    return null;
  }

  return (
    <>
      <div className="sticky top-0 bg-neutral-900 border-b border-white/10 py-2 px-2 z-10">
        <input
          type="search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="rounded-sm w-full px-1 py-1 outline-0 bg-neutral-800 text-gray-200"
        />
      </div>

      <div className="py-1.5 px-2">
        {items2.map((annotationClassProperty, i) => (
          <div key={annotationClassProperty.id}>
            {i === delimiterIndex && <hr className="border-white/50 my-2" />}
            <WorkspaceDataitemAnnotationInstanceFormControl
              annotationInstance={annotationInstance}
              annotationClassProperty={annotationClassProperty}
            />
          </div>
        ))}
      </div>
    </>
  );
};
