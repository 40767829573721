import { Button } from 'components/common/Button/Button';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useForm } from 'react-hook-form';

interface Props {
  workflow: WorkflowDTO;
}

interface FormValues {
  name: string;
}

const CreateWorkflowUserGroup = ({ workflow }: Props) => {
  const { organizationId, projectId } = useIntParams();
  const { mutateAsync: createUserGroup } = useMutation('CreateWorkflowUserGroup');

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>();

  const onSubmit = handleSubmit(async ({ name }: FormValues) => {
    await createUserGroup({ data: { name }, params: { organizationId, projectId, workflowId: workflow.id } });
  });

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="flex gap-1">
          <Label htmlFor={'name'}>User Group Name</Label>
          <InputText {...register('name')} id={'name'} placeholder="Name" required />

          <Button type="submit" loading={isSubmitting} label="Create" />
        </div>
      </form>
    </div>
  );
};

export default CreateWorkflowUserGroup;
