import classNames from 'classnames';
import { Button } from 'components/common/Button/Button';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { FormMessage } from 'components/common/FormMessage/FormMessage';
import { isUndefined } from 'lodash';
import { useEffect } from 'react';
import { useForm, FieldError } from 'react-hook-form';
import { IoIosAdd } from 'react-icons/io';

interface Props {
  showIdField: boolean;
  onAppend: ({ value, key }: { value: string; key: string }) => void;
  checkDuplicate: ({ newOptionValue, newOptionKey }: { newOptionValue: string; newOptionKey: string }) => {
    duplicateValue: boolean;
    duplicateKey: boolean;
  };
}

export const AppendPropertyOptionForm = ({ showIdField, onAppend, checkDuplicate }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    setFocus,
    formState: { errors },
  } = useForm<{
    newOptionValue: string;
    newOptionKey: string;
  }>({
    resolver: (values) => {
      const errors = {} as Partial<Record<keyof typeof values, FieldError>>;
      const duplicates = checkDuplicate(values);

      if (duplicates.duplicateValue) {
        errors.newOptionValue = { type: 'duplicate-label', message: 'Duplicate label' };
      }

      if (duplicates.duplicateKey) {
        errors.newOptionKey = { type: 'duplicate-id', message: 'Duplicate key' };
      }

      return {
        values,
        errors,
      };
    },
  });

  const onSubmit = handleSubmit(({ newOptionValue, newOptionKey }) => {
    onAppend({
      value: newOptionValue,
      key: newOptionKey,
    });

    reset();
    setFocus('newOptionValue');
  });

  const watchNewOptionValue = watch('newOptionValue');

  useEffect(() => {
    if (isUndefined(watchNewOptionValue)) {
      return;
    }

    setValue('newOptionKey', watchNewOptionValue.trim().replace(/\s/g, '-').toLowerCase());
  }, [watchNewOptionValue, setValue]);

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-row gap-1">
        <div className="flex w-full flex-row gap-1">
          <div className="grow">
            <Label htmlFor="newOptionValue">Label</Label>
            <InputText
              {...register(`newOptionValue`)}
              id="newOptionValue"
              required
              className={classNames({
                'border-annotorio-pink': errors?.newOptionValue?.message,
              })}
            />
            {errors?.newOptionValue?.message && <FormMessage text={errors.newOptionValue.message} type="error" />}
          </div>

          {showIdField ? (
            <div className="grow">
              <Label htmlFor="newOptionValue">ID</Label>
              <InputText
                {...register('newOptionKey')}
                id="newOptionKey"
                required
                className={classNames({
                  'border-annotorio-pink': errors?.newOptionKey?.message,
                })}
              />
              {errors?.newOptionKey?.message && <FormMessage text={errors.newOptionKey.message} type="error" />}
            </div>
          ) : (
            <input type="hidden" {...register('newOptionKey')} />
          )}

          <Button type="submit" variant="icon-rounded" icon={<IoIosAdd />} />
        </div>
      </div>
    </form>
  );
};
