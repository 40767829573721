import { useParams } from 'react-router-dom';

function useIntParams<T extends UrlParams = UrlParams>() {
  const params = useParams<T>();

  return Object.keys(params).reduce((acc, key: keyof T) => {
    const value = parseInt(params[key], 10);

    if (!isNaN(value)) {
      acc[key] = value;
    }

    return acc;
  }, {} as { [key in keyof T]: number });
}

export default useIntParams;
