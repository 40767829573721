import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';

interface Props {
  workflows: WorkflowDTO[];
}

const WorkflowListing = ({ workflows }: Props) => {
  const { pathTo } = useRouterUtils();

  return (
    <table className="table-primary">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {workflows.map(({ id, name }) => (
          <tr key={id}>
            <td>{id}</td>
            <td>
              <Link to={pathTo('WORKFLOW_STATE_DIAGRAM', { workflowId: id })}>{name}</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default WorkflowListing;
