import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { Label } from 'components/common/Forms/Label/Label';
import { Select } from 'components/common/Forms/Select/Select';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { mapWorkflowStateTypeToDisplayName, WorkflowStateType } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useForm } from 'react-hook-form';
import { MdCheck } from 'react-icons/md';

interface Props {
  state: WorkflowStateDTO;
}

const StateNodeDetails = ({ state: { id, label, type, uiBgColor, isExportable } }: Props) => {
  const { organizationId, projectId, workflowId } = useIntParams();
  const { mutateAsync: updateWorkflowStateNode } = useMutation('UpdateWorkflowState');
  const { mutateAsync: removeWorkflowStateNode } = useMutation('RemoveWorkflowState');

  const handleRemove = async () => {
    await removeWorkflowStateNode({ params: { organizationId, projectId, workflowId, workflowStateId: id } });
  };

  const { register, watch, control, handleSubmit } = useForm({
    defaultValues: {
      type,
      uiBgColor,
      isExportable,
    },
  });
  const onSubmit = handleSubmit(async (data) => {
    await updateWorkflowStateNode({ data, params: { organizationId, projectId, workflowId, workflowStateId: id } });
  });

  const colorOptions = [
    '#374051',
    '#b91c1c',
    '#b5530a',
    '#047857',
    '#1d4ed8',
    '#4338ca',
    '#be185d',
    '#6b7280',
    '#ef4444',
    '#f59e0c',
    '#10b981',
    '#3b82f6',
    '#6366f1',
    '#8b5cf6',
    '#ec4899',
  ];

  return (
    <>
      <div>
        State <span className="font-semibold">{label}</span>
      </div>

      <div>
        <div className="my-2">
          <div>Editor Mode</div>
          <form onSubmit={onSubmit}>
            <div className="my-2">
              <Label htmlFor="type">Node Type</Label>
              <Select
                control={control}
                id="type"
                name="type"
                options={Object.entries(WorkflowStateType).map(([_, value]) => ({
                  value,
                  label: mapWorkflowStateTypeToDisplayName[value],
                }))}
              />
            </div>

            <div className="my-2">
              <div>Color</div>

              <div className="flex flex-wrap gap-1">
                {colorOptions.map((color) => (
                  <label
                    className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-sm text-white"
                    style={{ backgroundColor: color }}
                  >
                    <input type="radio" value={color} className="hidden" {...register('uiBgColor')} />
                    {watch('uiBgColor') === color && <MdCheck />}
                  </label>
                ))}
              </div>
            </div>

            <div className="my-2">
              <Label htmlFor="isExportable">Exportable State</Label>

              <Select
                control={control}
                id="isExportable"
                name="isExportable"
                options={[
                  { value: true, label: 'yes' },
                  { value: false, label: 'no' },
                ]}
              />
            </div>

            <Button type="submit" label="Update" />
          </form>
        </div>
      </div>

      <div className="my-2 flex flex-row gap-2 border-t py-2">
        <Dialog render={() => <ConfirmActionModal onConfirm={handleRemove} />}>
          <Button label="Delete" />
        </Dialog>
      </div>
    </>
  );
};

export default StateNodeDetails;
