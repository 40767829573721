export const dist = ([x1, y1]: any, [x2, y2]: any) => {
  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
};

export const rotate2d = (
  { x, y }: any,
  degree: number,
  pivot = { x: 0, y: 0 }
) => {
  const radAngle = degree * (Math.PI / 180);

  const rx =
    pivot.x +
    (x - pivot.x) * Math.cos(radAngle) -
    (y - pivot.y) * Math.sin(radAngle);
  const ry =
    pivot.y +
    (x - pivot.x) * Math.sin(radAngle) +
    (y - pivot.y) * Math.cos(radAngle);

  return { x: rx, y: ry };
};

// @todo rename or namespace
export const getAABB2d = (points: number[]) => {
  let [xMin, yMin] = [Infinity, Infinity];
  let [xMax, yMax] = [-Infinity, -Infinity];

  for (let i = 0; i < points.length; i += 2) {
    const [x, y] = [points[i], points[i + 1]];
    xMin = Math.min(xMin, x);
    yMin = Math.min(yMin, y);
    xMax = Math.max(xMax, x);
    yMax = Math.max(yMax, y);
  }

  return {
    x: xMin,
    y: yMin,
    width: xMax - xMin,
    height: yMax - yMin,
  };
};

export const _2d = {
  getCentroid: (points: number[]) => {
    let sumX = 0;
    let sumY = 0;

    for (let i = 0; i < points.length; i += 2) {
      const [x, y] = [points[i], points[i + 1]];
      sumX += x;
      sumY += y;
    }

    const x = sumX / (points.length / 2);
    const y = sumY / (points.length / 2);

    return { x, y };
  },
};
