import { AppRoutes } from 'appRoutes';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useOnAfterDatasetImport } from './onAfterDatasetImport';
import { useOnAfterJobExport } from './onAfterJobExport';

const API_URL = globalConfig.env.REACT_APP_API_URL;

export const useServerSentEvents = () => {
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const routeMatch = useRouteMatch<RouteParams.Organization>(AppRoutes.ORGANIZATION);
  const onAfterDatasetImport = useOnAfterDatasetImport();
  const onAfterJobExport = useOnAfterJobExport();

  useEffect(() => {
    setOrganizationId(routeMatch?.params.organizationId ? parseInt(routeMatch.params.organizationId, 10) : null);
  }, [routeMatch]);

  useEffect(() => {
    if (organizationId === null) {
      return;
    }

    const url = `${API_URL}/v2/organizations/${organizationId}/event-stream/`;
    const eventSource = new EventSource(url, { withCredentials: true });

    eventSource.addEventListener('onAfterDatasetImport', (_event) => {
      onAfterDatasetImport.handleEvent();
    });

    eventSource.addEventListener('onAfterJobExport', (_event) => {
      onAfterJobExport.handleEvent();
    });

    eventSource.addEventListener('closedConnection', () => {
      console.log('[SSE] closedConnection');
      eventSource.close();
    });

    eventSource.onerror = (err) => {
      console.error('[SSE] Error', err);
    };

    return () => {
      eventSource.close();
    };
  }, [organizationId, onAfterDatasetImport, onAfterJobExport]);
};
