import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';

export const OrganizationUserInviteModal = () => {
  const { close } = useContext(DialogContext);
  const { organizationId } = useIntParams();
  const { mutateAsync: inviteUsers } = useMutation('InviteUser');
  const { data: roles, isLoading: rolesLoading } = useQuery('GetUserRoles', {
    params: { organizationId },
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{ email: string }>({ defaultValues: { email: '' } });

  const onSubmit = handleSubmit(async (data) => {
    await inviteUsers({
      data,
      params: { organizationId },
    });

    close();
  });

  if (rolesLoading || !roles) {
    return null;
  }

  return (
    <ModalLayout>
      <form onSubmit={onSubmit}>
        <ModalLayoutHeader title="Invite User" />
        <ModalLayoutBody>
          <div className="my-2">
            <Label htmlFor="email">Email</Label>
            <div className="flex">
              <InputText
                {...register('email')}
                id="email"
                type="email"
                placeholder="email@example.com"
                className="grow"
                required
              />
            </div>
          </div>
        </ModalLayoutBody>
        <ModalLayoutFooter>
          <Button loading={isSubmitting} label="Invite" type="submit" />
        </ModalLayoutFooter>
      </form>
    </ModalLayout>
  );
};
