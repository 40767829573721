import { Table } from 'components/common/Table/Table';
import { useDatasetImportsTable } from 'hooks/react-table/useDatasetImportsTable';
import useIntParams from 'hooks/useIntParams';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';

export const DatasetImportHistory = () => {
  const { projectId, organizationId, datasetId } = useIntParams<RouteParams.Dataset>();

  const datasetImportHistoriesQuery = usePaginatedQuery('GetDatasetImportHistoriesWithPagination', {
    params: { organizationId, projectId },
    query: {
      datasetId,
    },
  });

  const { instance } = useDatasetImportsTable({ rows: datasetImportHistoriesQuery.data?.results });

  return <Table instance={instance} pagination={datasetImportHistoriesQuery.pagination} />;
};
