import { Button } from 'components/common/Button/Button';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export const PasswordInput = ({
  register = () => {},
  name,
  showPassword: initialShowPassword = false,
  ...attributes
}: any) => {
  const [showPassword, setShowPassword] = useState(initialShowPassword);
  const type = showPassword ? 'text' : 'password';

  return (
    <div className="relative">
      <InputText type={type} {...register(name)} {...attributes} />

      <div className="absolute right-2 top-0 bottom-0 flex items-center">
        {showPassword ? (
          <Button icon={<AiFillEyeInvisible />} variant="icon-rounded" onClick={() => setShowPassword(false)} />
        ) : (
          <Button
            icon={<AiFillEye />}
            variant="icon-rounded"
            onClick={() => {
              setShowPassword(true);
            }}
          />
        )}
      </div>
    </div>
  );
};
