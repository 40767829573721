import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

interface Props {
  linkTo: string;
  label: string | JSX.Element;
  icon: JSX.Element;
  exact?: boolean;
  className?: string;
}

export const SidebarMenuItem = ({
  linkTo,
  exact = false,
  label,
  icon,
  className,
}: Props) => {
  const menuNavLinkClasses = {
    default:
      'flex w-full items-center rounded my-0.5 px-3 py-2.5 text-slate-600 gap-2 text-sm font-medium hover:bg-[#edf1f4] hover:text-slate-600 border border-transparent',
    active:
      'bg-white hover:bg-white shadow-sm text-blue-700 hover:text-blue-700 border border-slate-100',
  };

  return (
    <NavLink
      to={linkTo}
      exact={exact}
      className={classNames(menuNavLinkClasses.default, className)}
      activeClassName={menuNavLinkClasses.active}
    >
      <div className="flex items-center justify-center" style={{ width: 25 }}>
        {icon}
      </div>

      {label}
    </NavLink>
  );
};
