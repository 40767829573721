import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { OrganizationCreateModal } from 'components/Modals/OrganizationCreateModal/OrganizationCreateModal';

export const NoOrganizations = () => {
  return (
    <LayoutContent>
      <LayoutContentBody>
        <div className="w-full flex justify-center">
          <div className="text-center">
            <div>
              <div className="text-2xl font-medium text-stone-800">You're not a member of any Organizations</div>
              <div>
                <p className="my-5 text-stone-800">Get started by creating a new organization</p>
                <Dialog render={() => <OrganizationCreateModal />}>
                  <Button>Create Organization</Button>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </LayoutContentBody>
    </LayoutContent>
  );
};
