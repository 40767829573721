import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import FormError from 'components/common/FormError';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorType } from 'shared/errors';

interface Props {
  currentName?: string;
  onRename: (value: string) => Promise<unknown>;
}

export const GenericResourceRenameModal = ({ currentName, onRename }: Props) => {
  const { close } = useContext(DialogContext);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const onSubmit = handleSubmit(async ({ name }) => {
    setError(null);
    const res = await onRename(name).catch((error) => error);

    if (res instanceof Error) {
      setError(ErrorType.GENERIC);
      return;
    }

    close();
  });

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Rename" />
      <ModalLayoutBody>
        <form onSubmit={onSubmit}>
          <FormError type={error} />

          <div className="my-2">
            <Label htmlFor={'name'}>Name</Label>
            <InputText
              {...register('name')}
              id={'name'}
              onFocus={(e) => e.target.select()}
              defaultValue={currentName}
              required
            />
          </div>
        </form>
      </ModalLayoutBody>
      <ModalLayoutFooter>
        <Button loading={isSubmitting} onClick={onSubmit}>
          Rename
        </Button>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
