import { api } from 'api/api';
import { useEffect } from 'react';
import { Mutation, useQueryClient } from 'react-query';

export const useOnMutation = <K extends keyof typeof api.mutations>(
  type: K,
  cb: (mutation: Mutation<unknown, unknown, void, unknown>) => void
) => {
  const queryClient = useQueryClient();
  const mutationCache = queryClient.getMutationCache();

  useEffect(() => {
    const unsub = mutationCache.subscribe((mutation) => {
      if (mutation?.meta?.type === type) {
        cb(mutation);
      }
    });

    return () => {
      unsub();
    };
  }, [mutationCache, cb, type]);
};
