import axios, { Canceler } from 'axios';

const API_URL = globalConfig.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const promise = Promise.reject(error);
    callbacks['error'].forEach((cb: any) => cb(error));
    return promise;
  }
);

export const AxiosService = <T>(config: {}): [Promise<T>, Canceler] => {
  const cancelTokenSource = axios.CancelToken.source();

  const response = (async () => {
    const res = await axiosInstance({
      ...config,
      cancelToken: cancelTokenSource.token,
    });

    return res.data;
  })();

  return [response, cancelTokenSource.cancel];
};

const callbacks: any = {};
// @ts-ignore
axiosInstance.on = (type: string, cb: any) => {
  callbacks[type] = callbacks[type] || [];
  callbacks[type].push(cb);
};
// @ts-ignore
axiosInstance.off = (type: string, cb: any) => {
  callbacks[type] = callbacks[type].filter((e: any) => e !== cb);
};
