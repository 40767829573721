import { useContext, useEffect, useState } from 'react';
import { VideoEditorContext } from '../VideoEditorContext';

export const VideoZoomControls = () => {
  const { videoEditor } = useContext(VideoEditorContext); // @todo use atom

  const [zoomPercent, setZoomPercent] = useState(100);

  useEffect(() => {
    if (videoEditor) {
      videoEditor.scene.transform.subscribe(({ scale }: any) => {
        const val = Math.round(scale * 100);
        setZoomPercent(val);
      });
    }
  }, [videoEditor]);

  if (!videoEditor) {
    return null; // @todo
  }

  const { zoomToFit, zoomToCover } = videoEditor.scene;

  const buttonClasses =
    'text-xxs border border-neutral-800 text-gray-400 hover:text-gray-300 hover-border-neutral-700 rounded-sm  px-3';

  return (
    <div className="flex items-center gap-1">
      <div className="jusitfy-between text-xxs mr-3 flex">
        <div>Zoom</div>
        <div className="w-8 text-right">{zoomPercent}%</div>
      </div>
      <button onMouseDown={zoomToFit} tabIndex={-1} onFocus={(e) => e.target.blur()} className={buttonClasses}>
        Fit
      </button>
      <button onMouseDown={zoomToCover} tabIndex={-1} onFocus={(e) => e.target.blur()} className={buttonClasses}>
        100%
      </button>
    </div>
  );
};
