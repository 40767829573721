import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { ConfigureInboundRemoteStorageModal } from 'components/Modals/ConfigureInboundRemoteStorageModal/ConfigureInboundRemoteStorageModal';
import { BsFillCloudPlusFill } from 'react-icons/bs';
import DatasetRemoteStorages from '../DatasetRemoteStorages';

export const DatasetRemoteImportSources = () => {
  return (
    <>
      <Dialog render={() => <ConfigureInboundRemoteStorageModal />}>
        <Button
          icon={<BsFillCloudPlusFill />}
          label="Add Remote Import Source"
        />
      </Dialog>

      <DatasetRemoteStorages />
    </>
  );
};
