import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const RouterError = () => {
  const history = useHistory();

  useEffect(() => {
    alert('Routing Error');
    history.goBack();
  }, [history]);

  return null;
};
