import { Button } from 'components/common/Button/Button';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';

import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

interface WorkflowCopyFormValues {
  name: string;
}

interface Props {
  source?: WorkflowDTO;
  onCancel?: () => void;
  onSuccess?: (workflow: WorkflowDTO) => void;
}

const WorkflowCopy = ({ source, onCancel, onSuccess = () => {} }: Props) => {
  const { organizationId, projectId, workflowId } = useIntParams();
  const { mutateAsync: copyWorkflow } = useMutation('CopyWorkflow');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: `${source?.name} (copy)`,
    },
  });

  useEffect(() => {
    const el = inputRef.current;
    el?.focus();
    el?.select();
  }, []);

  const onSubmit = async ({ name }: WorkflowCopyFormValues) => {
    if (!source) {
      return;
    }

    await copyWorkflow({ data: { name }, params: { organizationId, projectId, workflowId } }).then((data) => {
      onSuccess(data);
    });
  };

  const formControl = register('name');

  return (
    <div>
      <div className="mt-2 mb-4">
        <h1>Copy workflow</h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-2">
          <Label htmlFor="name">Name</Label>

          <InputText
            id="name"
            {...formControl}
            ref={(e) => {
              formControl.ref(e);
              inputRef.current = e;
            }}
            required
          />
        </div>

        <Button type="submit" loading={isSubmitting} label="Copy" />
      </form>
    </div>
  );
};

export default WorkflowCopy;
