import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Typography } from 'components/common/Typography';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { GenericResourceRenameModal } from 'components/Modals/GenericResourceRenameModal/GenericResourceRenameModal';
import { ProjectApiKey } from 'components/ProjectApiKey/ProjectApiKey';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { AiOutlineEdit } from 'react-icons/ai';
import { CgDanger } from 'react-icons/cg';
import { IoMdTrash } from 'react-icons/io';

export const ProjectSettings = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const { mutateAsync: updateProject } = useMutation('UpdateProject');
  const { mutateAsync: removeProject } = useMutation('RemoveProject');
  const projectQuery = useQuery('GetProjectById', {
    params: { organizationId, projectId },
  });

  const { navigateTo } = useRouterUtils();

  async function handleRemoveProject() {
    await removeProject({ params: { organizationId, projectId } });
    navigateTo('ORGANIZATION');
  }

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Project Settings
          </Typography>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <div className="mb-6 ">
          <div className="text-md mb-2 mt-4 font-medium text-slate-800">API Key</div>
          <div className="rounded border border-slate-100 bg-slate-50 px-3 py-3 ">
            <ProjectApiKey />
          </div>
        </div>

        <div className="mb-6">
          <Dialog
            render={() => (
              <GenericResourceRenameModal
                onRename={(name) =>
                  updateProject({
                    data: { name },
                    params: { organizationId, projectId },
                  })
                }
                currentName={projectQuery.data?.name}
              />
            )}
          >
            <Button icon={<AiOutlineEdit />} label="Rename Project" variant="text" />
          </Dialog>
          <hr />
          <div>
            <Dialog
              render={() => (
                <ConfirmActionModal onConfirm={handleRemoveProject}>
                  <div className="mt-2 mb-6 flex flex-row items-center gap-4" style={{ maxWidth: 500 }}>
                    <CgDanger size={60} />
                    <p>
                      Project and all related data (datasets, jobs, exports, etc.) will be <b>permanently</b> deleted
                    </p>
                  </div>
                </ConfirmActionModal>
              )}
            >
              <Button icon={<IoMdTrash />} label="Delete Project" variant="text" />
            </Dialog>
          </div>
        </div>
      </LayoutContent>
    </LayoutContentBody>
  );
};
