import { mapAnnotationClassTypeToDisplayIcon } from 'components/Project/Jobs/Job/JobTasks/JobTasksAnnotationClasses/JobTasksAnnotationClasses';
import { mapAnnotationClassTypeToDisplayName } from 'enums';
import { usePersistedState } from 'hooks/usePersistedState';
import { useAtom } from 'jotai';
import { useRef, useState } from 'react';
import { DraggableCore } from 'react-draggable';
import { WorkspaceDataitemAnnotationInstanceForm } from './WorkspaceDataitem/WorkspaceDataitemAnnotationInstanceForm/WorkspaceDataitemAnnotationInstanceForm';
import { selectedAnnotationInstanceAtom } from './useAnnotationsStore';

interface Props {
  position: number[];
  onClose: () => void;
}
export const WorkspaceDataitemAnnotationInstanceContextMenu = ({ position: initialPosition, onClose }: Props) => {
  const [position, setPosition] = useState(initialPosition);
  const [annotationInstance] = useAtom(selectedAnnotationInstanceAtom);
  const draggableRef = useRef<HTMLDivElement | null>(null);

  const draggableRef2 = useRef<HTMLDivElement | null>(null);
  const [componentHeight, setComponentHeight] = usePersistedState(600, 'annotation-instance-context-menu-height');
  const handleRowHeightChangeOnDrag = (delta: number) => {
    setComponentHeight((prev: any) => prev + delta);
  };

  const handleMouseLeave = () => {
    // onClose();
  };

  if (!annotationInstance) {
    return null;
  }

  return (
    <div
      className="flex flex-col z-100 absolute rounded border border-white/20 bg-black/90 text-xxs text-white overflow-hidden"
      style={{ height: componentHeight, width: 340, left: `${position[0]}px`, top: `${position[1]}px` }}
      onMouseLeave={handleMouseLeave}
    >
      <DraggableCore
        nodeRef={draggableRef}
        offsetParent={document.body}
        onDrag={(_, d) => {
          setPosition((prev) => [Math.max(0, prev[0] + d.deltaX), Math.max(0, prev[1] + d.deltaY)]);
        }}
      >
        <div
          ref={draggableRef}
          className="flex items-center justify-between gap-1 border-b border-neutral-800 px-2 py-1.5 text-neutral-400 "
        >
          <div className="flex items-center gap-1 select-none">
            {mapAnnotationClassTypeToDisplayIcon[annotationInstance.annotationClass.type]}
            {annotationInstance.name || mapAnnotationClassTypeToDisplayName[annotationInstance.annotationClass.type]}
          </div>
          <button onClick={onClose}>&times;</button>
        </div>
      </DraggableCore>
      <div className="text-neutral-400 overflow-y-scroll">
        <WorkspaceDataitemAnnotationInstanceForm />
      </div>

      <DraggableCore
        nodeRef={draggableRef2}
        offsetParent={document.body}
        onDrag={(_, d) => {
          handleRowHeightChangeOnDrag(d.deltaY);
        }}
      >
        <div className="w-full bg-white/20 cursor-ns-resize mt-auto" style={{ height: 5 }} ref={draggableRef2}></div>
      </DraggableCore>
    </div>
  );
};
