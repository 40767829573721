import { Datum, ResponsiveLine, Serie } from '@nivo/line';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

import type { TimeResolution } from './types';

import { AppRouteParams } from 'appRoutes';
import { Button } from 'components/common/Button/Button';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Typography } from 'components/common/Typography';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';

type SelectOption<T> = { label: string; value: T };

const timeResolutionOptions: SelectOption<TimeResolution>[] = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];

export const TimePeriodBreakdown = () => {
  const { jobId, projectId, organizationId } = useIntParams<AppRouteParams['JOB_REPORTS']>();
  const [selectedUsers, setSelectedUsers] = useState<number[]>();
  const [userSelectOptions, setUserSelectOptions] = useState<SelectOption<number[]>[]>([]);
  const [parsedData, setParsedData] = useState<Serie[]>([]);
  const [workflowStateSelectOptions, setWorkflowStateSelectOptions] = useState<SelectOption<string>[]>([]);
  const [workflowStateFilters, setWorkflowStateFilters] = useState<string[]>([]);
  const [timeResolution, setTimeResolution] = useState<TimeResolution>('day');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [reportsQueryEnabled, setReportsQueryEnabled] = useState<boolean>(false);

  const { control, register, handleSubmit } = useForm<{
    users: SelectOption<number[]>;
    timeResolution: SelectOption<TimeResolution>;
    startDate: Date;
    endDate: Date;
  }>();

  const { data: jobWorkflow, isLoading: jobWorkflowLoading } = useQuery('GetJobWorkflow', {
    params: {
      organizationId,
      projectId,
      jobId,
    },
  });
  const { data: jobUsers, isLoading: jobUsersLoading } = useQuery('GetJobUsers', {
    params: {
      organizationId,
      projectId,
      jobId,
    },
  });
  const { data: timeseriesData, isLoading: timeseriesDataLoading } = useQuery(
    'GetJobTimeseriesReport',
    {
      params: {
        organizationId,
        projectId,
        jobId,
      },
      query: {
        timeResolution,
        jobUsers: selectedUsers,
        startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
      },
    },
    {
      enabled: Boolean(jobId && selectedUsers) && reportsQueryEnabled,
    }
  );

  const onSubmit = handleSubmit((data) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSelectedUsers(data.users.value);
      setTimeResolution(data.timeResolution.value);
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      setReportsQueryEnabled(true);
    });
  });

  useEffect(() => {
    if (!timeseriesData) {
      setParsedData([]);
      return;
    }

    const data = timeseriesData.reduce((acc, item) => {
      const { data, time } = item;

      Object.keys(data).forEach((workflowState) => {
        if (workflowStateFilters.length !== 0 && !workflowStateFilters.includes(workflowState)) {
          return;
        }

        const datum = {
          x: time,
          y: data[workflowState],
        };

        if (acc[workflowState]) {
          acc[workflowState].data.push(datum);

          return;
        }

        acc[workflowState] = {
          id: workflowState,
          data: [datum] as Datum[],
        };
      });

      return acc;
    }, {} as Record<string, Serie>);

    setParsedData(Object.values(data));
  }, [timeseriesData, workflowStateFilters]);

  useEffect(() => {
    if (!jobUsers) {
      return;
    }

    const options = jobUsers
      .map((jobUser) => ({
        label: jobUser.user.email,
        value: [jobUser.id],
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    if (options.length === 0) {
      return;
    }

    setUserSelectOptions([{ label: 'All', value: jobUsers.map((user) => user.id) }, ...options]);
    setSelectedUsers(options[0].value);
  }, [jobUsers]);

  useEffect(() => {
    if (!jobWorkflow || workflowStateSelectOptions.length) {
      return;
    }

    const options = jobWorkflow.workflowStates.map((workflowState) => ({
      label: workflowState.label,
      value: workflowState.label,
    }));

    setWorkflowStateSelectOptions(options);
  }, [jobWorkflow, workflowStateSelectOptions]);

  if (timeseriesDataLoading || jobUsersLoading || jobWorkflowLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="mb-4">
        <div className="inline-block">
          <label className="block">Select Workflow States</label>
          <Select
            isMulti
            name="workflow-states-filter"
            options={workflowStateSelectOptions}
            styles={{
              container: (provided) => ({
                ...provided,
                minWidth: 250,
              }),
            }}
            onChange={(selected) => {
              setWorkflowStateFilters(selected.map((option) => option.value));
            }}
          />
        </div>
      </div>
      <form className="mb-4" onSubmit={onSubmit}>
        <div className="mr-4 inline-block">
          <label className="block">User</label>
          <Controller
            control={control}
            name="users"
            render={({ field }) => (
              <Select
                {...field}
                options={userSelectOptions}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    minWidth: 250,
                  }),
                }}
              />
            )}
          />
        </div>
        <div className="mr-4 inline-block">
          <label className="block">Time Resolution</label>
          <Controller
            control={control}
            name="timeResolution"
            render={({ field }) => (
              <Select
                {...field}
                options={timeResolutionOptions}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    minWidth: 250,
                  }),
                }}
              />
            )}
          />
        </div>
        <div className="mr-4 inline-block">
          <label className="block">Start Date</label>
          <InputText {...register('startDate', { valueAsDate: true })} type="date" />
        </div>

        <div className="mr-4 inline-block">
          <label className="block">End Date</label>
          <InputText {...register('endDate', { valueAsDate: true })} type="date" />
        </div>

        <Button type="submit">Apply</Button>
      </form>
      <div className="flex grow flex-col justify-center px-10">
        {parsedData.length > 0 && (
          <div className="h-3/4 w-full">
            <ResponsiveLine
              animate={false}
              data={parsedData}
              curve="linear"
              margin={{ top: 100, right: 120, bottom: 100, left: 120 }}
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 90,
              }}
              enableGridY={false}
              enablePoints={false}
              useMesh={true}
              tooltip={({ point }) => (
                <div className="bg-white p-2 font-bold drop-shadow-lg">
                  {point.data.y} {point.serieId.toString().toLowerCase()} on {point.data.x}
                </div>
              )}
            />
          </div>
        )}
        {parsedData.length === 0 && (
          <Typography component="h1" className="text-center">
            No data available. Try different filters.
          </Typography>
        )}
      </div>
    </>
  );
};
