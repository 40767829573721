import classNames from 'classnames';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { DropdownButtonItem } from 'components/common/DropdownButtonItem/DropdownButtonItem';
import { useLogoutFlow } from 'hooks/auth/useLogoutFlow';
import { useFullScreen } from 'hooks/useFullScreen';
import useIntParams from 'hooks/useIntParams';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useAtom } from 'jotai';
import { AiOutlineUser } from 'react-icons/ai';
import { CgMinimize } from 'react-icons/cg';
import { MdOutlineFullscreen, MdOutlineLogout, MdQueuePlayNext } from 'react-icons/md';
import { VscLayoutSidebarRight, VscLayoutSidebarRightOff } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { workspaceContextStore } from './Workspace2';
import { workspaceState } from './state/workspaceState';
import { useSaveAnnotationInstances } from './useSaveAnnotationInstances';

export const WorkspaceTitlebar = () => {
  const { organizationId, projectId, jobId, jobDataitemId } = useIntParams();
  const { pathTo, navigateTo } = useRouterUtils();
  // const jobDataitemQuery = useQuery('GetJobDataitemById', {
  //   params: { organizationId, projectId, jobId, jobDataitemId },
  // });
  const jobQuery = useQuery('GetJobById', { params: { organizationId, projectId, jobId } });

  const { logout } = useLogoutFlow();
  const { toggleFullScreen, isFullScreen } = useFullScreen();

  const [showDataitemSidebar, setShowDataitemSidebar] = useAtom(workspaceState.showDataitemSidebarAtom, {
    store: workspaceContextStore,
  });
  const { isSaving } = useSaveAnnotationInstances();

  const isJobDataitemLoaded = jobDataitemId !== undefined;

  const jobDataitemsQuery = usePaginatedQuery('GetJobDataitemsWithPagination', {
    params: { organizationId, projectId, jobId },
    query: {
      pageSize: 1,
      after: jobDataitemId,
    },
  });
  const loadNewJobDataitem = () => {
    const jobDataitem = jobDataitemsQuery?.data?.results[0];
    if (!jobDataitem) {
      return;
    }

    navigateTo('WORKSPACE', { organizationId, projectId, jobId, jobDataitemId: jobDataitem.id });
  };

  const hasNextJobDataitem = jobDataitemsQuery?.data?.results?.length !== 0;

  return (
    <div className="text-xxs flex items-center gap-2 border-b border-ws-panel bg-ws-panel px-4 py-1 text-neutral-400 justify-between">
      <div className="flex items-center gap-4">
        <Link
          to={
            projectId
              ? pathTo('PROJECT', { organizationId, projectId })
              : organizationId
              ? pathTo('ORGANIZATION', { organizationId })
              : '/'
          }
        >
          <div className="flex flex-row gap-1.5 items-center text-gray-50">
            <div
              className="rounded-sm text-black flex items-center justify-center bg-white"
              style={{ fontSize: 22, fontWeight: 400, width: 20, height: 20 }}
            >
              <span style={{ marginTop: -3 }}>a</span>
            </div>
            <div className="font-semibold" style={{ fontSize: 20 }}>
              annotorio
            </div>
          </div>
        </Link>
        <div className="border-l border-white/20 pl-4">{jobQuery.data?.name}</div>
      </div>

      {isJobDataitemLoaded && (
        <>
          <div className="ml-auto flex items-center gap-1.5">
            <div className="flex items-center gap-5">
              <button
                onClick={loadNewJobDataitem}
                tabIndex={-1}
                onFocus={(e) => e.target.blur()}
                className={classNames({ 'opacity-25': !hasNextJobDataitem })}
                disabled={!hasNextJobDataitem}
              >
                <MdQueuePlayNext size={22} />
              </button>
            </div>

            {/* <button
              className="flex items-center gap-1 rounded-sm border border-neutral-700 px-3 py-0.5"
              onClick={save}
              disabled={isSaving}
            >
              {isSaving ? (
                <AiOutlineLoading size={16} className="inline-block animate-spin" />
              ) : (
                <AiOutlineCloudUpload className="mr-1" />
              )}{' '}
              Save
            </button> */}

            {/* <div className="flex items-center gap-1 rounded bg-neutral-800 px-3 py-0.5" style={{ fontSize: 10 }}>
          <AiOutlineUser />
          John Doe
        </div> */}

            <div className="border-r h-full border-neutral-700 mx-3">&nbsp;</div>
          </div>
        </>
      )}

      <div className="flex items-center gap-3">
        {isJobDataitemLoaded && (
          <button onMouseDown={() => setShowDataitemSidebar((prev) => !prev)}>
            {showDataitemSidebar ? <VscLayoutSidebarRight size={16} /> : <VscLayoutSidebarRightOff size={16} />}
          </button>
        )}

        <button onClick={toggleFullScreen} tabIndex={-1} onFocus={(e) => e.target.blur()}>
          {isFullScreen ? <CgMinimize size={20} /> : <MdOutlineFullscreen size={22} />}
        </button>

        <Dropdown
          render={() => (
            <div>
              <DropdownButtonItem onClick={logout}>
                <MdOutlineLogout />
                Sign out
              </DropdownButtonItem>
            </div>
          )}
        >
          <button className="border border-neutral-500 rounded-full">
            <AiOutlineUser size={15} />
          </button>
        </Dropdown>
      </div>
    </div>
  );
};
