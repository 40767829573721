import classNames from 'classnames';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { workspaceState } from 'components/Workspace2/state/workspaceState';
import { useAtom } from 'jotai';
import { omit } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { KeyboardShortcut } from './KeyboardShortcut';

const UNBINDABLE_KEY_CODES = ['Escape', 'Backspace']; // @todo

export const KeyboardShortcutItem = ({ label, id }: any) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [keyboardShortcuts, setKeyboardShortcuts] = useAtom(workspaceState.keyboardShortcutsAtom);
  const [val, setVal] = useState<any>((keyboardShortcuts as any)[id]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (UNBINDABLE_KEY_CODES.includes(event.code)) {
      return;
    }

    if (['Alt', 'Meta', 'Control', 'Shift'].includes(event.key)) {
      return;
    }

    const modifierKeys = ['altKey', 'shiftKey', 'metaKey', 'ctrlKey'].filter((key) => (event as any)[key]);

    setVal({
      keyCode: event.code,
      keyLabel: event.code.replace(/Key|Digit/, ''),
      modifierKeys,
      description: label,
    });

    inputRef.current?.blur();
  };

  const remove = () => {
    setVal(null);
  };

  useEffect(() => {
    setKeyboardShortcuts((prev) => {
      return val !== null ? { ...prev, [id]: val } : omit(prev, id);
    });
  }, [val]);

  return (
    <div className="flex items-center justify-between whitespace-nowrap text-xxs">
      <div>{label}</div>

      <div className="flex items-center gap-2">
        <div className="relative w-32 text-xxs">
          <InputText
            ref={inputRef}
            onKeyDown={handleKeyDown}
            className="bg-neutral-100 hover:border-neutral-300 p-1 focus:bg-white"
          />

          <div className="flex items-center justify-center inset-0 absolute pointer-events-none">
            {val && <KeyboardShortcut keyLabel={val.keyLabel} modifierKeys={val.modifierKeys} />}
          </div>
        </div>

        <button
          className={classNames('flex items-center', { 'opacity-20': !val })}
          onClick={remove}
          disabled={val === null}
        >
          <IoMdClose size={14} />
        </button>
      </div>
    </div>
  );
};
