import { EventSystem } from 'modules/video-editor/pxae/events';
import { createStore } from 'zustand/vanilla';

export const Scene = () => {
  const eventSystem = EventSystem();
  const state = {
    viewport: createStore(() => ({ width: 1, height: 1 })),
    world: createStore(() => ({ width: 0, height: 0 })),
    transform: createStore(() => ({ x: 0, y: 0, scale: 1 })),
  };

  function zoomToFit({ allowUpscale = true }) {
    const viewport = state.viewport.getState();
    const world = state.world.getState();
    const sw = viewport.width / world.width;
    const sh = viewport.height / world.height;
    let scale = Math.min(sw, sh);

    if (!allowUpscale && scale > 1) {
      scale = 1;
    }

    const x = (viewport.width - world.width * scale) / 2;
    const y = (viewport.height - world.height * scale) / 2;

    state.transform.setState({ x, y, scale });
  }

  function zoomToCover() {
    const viewport = state.viewport.getState();
    const world = state.world.getState();

    let x = 0;
    if (world.width < viewport.width) {
      x = (viewport.width - world.width) / 2;
    }

    let y = 0;
    if (world.height < viewport.height) {
      y = (viewport.height - world.height) / 2;
    }

    state.transform.setState({ x, y, scale: 1 });
  }

  return {
    ...eventSystem,
    viewport: state.viewport,
    world: state.world,
    globalTransform: state.transform,
    zoomToFit,
    zoomToCover,
  };
};
