import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useState } from 'react';
import CreateWorkflowAction from '../CreateWorkflowAction';
import CreateWorkflowState from '../CreateWorkflowState';
import CreateWorkflowTransition from '../CreateWorkflowTransition';
import StateDiagram from './StateDiagram';
import StateNodeDetails from './StateNodeDetails';
import StateTransitionDetails from './StateTransitionDetails';

const WorkflowDiagramBuilder = () => {
  const { organizationId, projectId, workflowId } = useIntParams<RouteParams.Workflow>();
  const { data: workflow } = useQuery('GetWorkflowById', { params: { organizationId, projectId, workflowId } });
  const { mutateAsync: createWorkflowTransition } = useMutation('CreateWorkflowTransition');
  const { mutateAsync: createWorkflowAction } = useMutation('CreateWorkflowAction');

  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);
  const [selectedEdgeId, setSelectedEdgeId] = useState<number | null>(null);
  const states = workflow?.workflowStates || [];
  const stateTransitions = workflow?.workflowTransitions || [];

  const handleActionCreate = async ({ actionType, transitionId, userGroupId }: any) => {
    await createWorkflowAction({
      data: { actionType, transitionId, userGroupId },
      params: { organizationId, projectId, workflowId },
    });
  };

  const handleStateTransitionCreate = ({
    label,
    sourceId,
    targetId,
    type,
  }: Pick<WorkflowTransitionDTO, 'label' | 'sourceId' | 'targetId' | 'type'>) => {
    createWorkflowTransition({
      data: { label, sourceId, targetId, type },
      params: { organizationId, projectId, workflowId },
    });
  };

  const handleSelectNode = (id: number) => {
    setSelectedNodeId(id);
    setSelectedEdgeId(null);
  };

  const handleSelectEdge = (id: number) => {
    setSelectedNodeId(null);
    setSelectedEdgeId(id);
  };

  const handleDeselect = () => {
    setSelectedNodeId(null);
    setSelectedEdgeId(null);
  };

  if (!workflow) {
    return <div>Loading...</div>;
  }

  const selectedState = states.find(({ id }) => id === selectedNodeId);
  const selectedTransition = stateTransitions.find(({ id }) => id === selectedEdgeId);

  return (
    <>
      <div className="my-1 flex flex-row items-center justify-between">
        <div className="flex flex-row gap-2">
          <Dialog render={() => <CreateWorkflowState workflowId={workflowId} onSuccess={() => {}} />}>
            <Button label="+ State" />
          </Dialog>

          <Dialog render={() => <CreateWorkflowTransition states={states} onCreate={handleStateTransitionCreate} />}>
            <Button label="+ Transition" />
          </Dialog>

          <Dialog render={() => <CreateWorkflowAction workflow={workflow} onCreate={handleActionCreate} />}>
            <Button label="+ Action" />
          </Dialog>
        </div>
      </div>

      <div className="relative my-4">
        <StateDiagram
          states={states}
          transitions={stateTransitions}
          userGroups={workflow.workflowUserGroups!}
          onSelectNode={handleSelectNode}
          onSelectEdge={handleSelectEdge}
        />

        {(selectedState || selectedTransition) && (
          <div className="absolute right-2 top-2 w-64 rounded-sm border bg-white p-4 shadow-sm">
            <Button className="absolute right-2 top-0" onClick={handleDeselect}>
              &times;
            </Button>
            {selectedState && <StateNodeDetails key={selectedState.id} state={selectedState} />}
            {selectedTransition && <StateTransitionDetails transition={selectedTransition} />}
          </div>
        )}
      </div>
    </>
  );
};

export default WorkflowDiagramBuilder;
