import { SubmitSelfServiceLoginFlowWithPasswordMethodBody } from '@ory/kratos-client';
import { FormMessage } from 'components/common/FormMessage/FormMessage';
import { OryFormUi } from 'components/common/OryFormUi/OryFormUi';
import { Typography } from 'components/common/Typography';
import { useLoginFlow } from 'hooks/auth/useLoginFlow';
import { useRouterUtils } from 'hooks/useRouterUtils';
import identity from 'lodash/identity';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

export const Login = () => {
  const { login, flow, error } = useLoginFlow();
  const form = useForm<SubmitSelfServiceLoginFlowWithPasswordMethodBody>();
  const { handleSubmit } = form;

  const { pathTo } = useRouterUtils();

  const onSubmit: SubmitHandler<SubmitSelfServiceLoginFlowWithPasswordMethodBody> = async (data) => {
    return login(data);
  };

  const renderSelfServiceError = () => {
    if (!error) {
      return null;
    }

    if (error.code === 410) {
      const errorMessage = [error.reason || error.message, 'Try refreshing the page.'].filter(identity).join('\n');

      return <FormMessage type="error" text={errorMessage} />;
    }

    return <FormMessage type="error" text={error.reason || error.message} />;
  };

  if (!flow) {
    return null;
  }

  return (
    <div className="w-full max-w-xs">
      <Typography component="h1" className="my-3 text-center">
        Sign In
      </Typography>

      <div>
        {renderSelfServiceError()}

        {(flow.ui.messages || []).map((message) => (
          <FormMessage key={message.id} text={message.text} type={message.type} />
        ))}

        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <OryFormUi ui={flow.ui} />
          </form>
        </FormProvider>

        <div className="mt-4 flex flex-col items-center justify-center gap-2 text-sm">
          <Link to={pathTo('ACCOUNT_RECOVERY')} className="mb-1">
            Forgot password
          </Link>

          <Link to={pathTo('REGISTER')}>Create account</Link>
        </div>
      </div>
    </div>
  );
};
