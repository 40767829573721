import { Collapsible } from 'components/common/Collapsible';
import { Dialog } from 'components/common/Dialog/Dialog';
import { JobCreateModal } from 'components/Modals/JobCreateModal/JobCreateModal';
import { SidebarMenuItem } from 'components/Sidebar/shared/SidebarMenuItem/SidebarMenuItem';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useState } from 'react';
import { BsPlusSquare } from 'react-icons/bs';
import { CgPlayListCheck } from 'react-icons/cg';
import { FaRegCircle } from 'react-icons/fa';
import { MdOutlineExpandLess, MdOutlineExpandMore, MdOutlineOpenInNew } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';

export const SidebarJobsPanel = () => {
  const location = useLocation();
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const { organizationId, projectId } = useIntParams();
  const { pathTo } = useRouterUtils();
  const jobsQuery = useQuery('GetJobs', {
    params: { organizationId, projectId },
  });

  return (
    <>
      <div className="relative">
        <SidebarMenuItem linkTo={pathTo('JOBS')} label="Jobs" icon={<CgPlayListCheck size={22} />} exact />

        <div className="flex items-center absolute top-0 bottom-0 right-0 gap-1">
          <Dialog render={() => <JobCreateModal />}>
            <button className="text-slate-400 hover:text-slate-800 h-full" title="Create Job">
              <BsPlusSquare />
            </button>
          </Dialog>

          <button
            onClick={() => setIsPanelOpen((prev) => !prev)}
            className="flex h-full items-center justify-center px-2 text-slate-400 hover:text-slate-800"
          >
            {isPanelOpen ? <MdOutlineExpandLess size={18} /> : <MdOutlineExpandMore size={18} />}
          </button>
        </div>
      </div>

      <Collapsible open={isPanelOpen}>
        {jobsQuery.data?.length === 0 && <div className="text-xs text-slate-400 py-1 pl-11">No jobs created yet</div>}

        {jobsQuery.data?.map(({ id, name }) => (
          <div key={id} className="relative group">
            <SidebarMenuItem
              linkTo={pathTo('JOB', {
                jobId: id,
              })}
              label={name}
              icon={<FaRegCircle size={8} />}
              className="font-thin"
            />

            <Link
              className="absolute right-0 top-0 bottom-0 flex items-center px-3 invisible group-hover:visible"
              to={{
                pathname: pathTo('WORKSPACE', {
                  organizationId,
                  projectId,
                  jobId: id,
                  jobDataitemId: localStorage.getItem(`workspace-${projectId}-${id}-prev-dataitem-id`) || undefined,
                }),
                state: { referrer: location.pathname },
              }}
            >
              <MdOutlineOpenInNew />
            </Link>
          </div>
        ))}
      </Collapsible>
    </>
  );
};
