import { AppRoutes } from 'appRoutes';
import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { LayoutContentTabs } from 'components/common/Layout/LayoutContentTabs';
import { Typography } from 'components/common/Typography';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { noop } from 'lodash';
import { createContext } from 'react';
import { RiMore2Fill } from 'react-icons/ri';
import { Route, Switch } from 'react-router-dom';
import WorkflowCopy from '../WorkflowCopy/WorkflowCopy';
import WorkflowDiagramBuilder from './WorkflowDiagramBuilder';

import WorkflowUserGroups from './WorkflowUserGroups';

export const WorkflowDetailsContext = createContext<{
  workflow: WorkflowDTO;
}>(null!);

const WorkflowDetails = () => {
  const { workflowId, projectId, organizationId } = useIntParams<RouteParams.Workflow>();
  const { data: workflow } = useQuery('GetWorkflowById', { params: { organizationId, projectId, workflowId } });
  const { mutateAsync: removeWorkflow } = useMutation('RemoveWorkflow');

  const { pathTo, navigateTo } = useRouterUtils();

  const handleRemove = async () => {
    await removeWorkflow({ params: { organizationId, projectId, workflowId } });
    navigateTo('WORKFLOWS');
  };

  if (!workflow) {
    return null;
  }

  return (
    <WorkflowDetailsContext.Provider value={{ workflow }}>
      <LayoutContentBody>
        <LayoutContentHeader>
          <div className="flex items-center">
            <Typography component="h1" className="text-2xl">
              {workflow.name}
            </Typography>
          </div>

          <div>
            <Dropdown
              render={({ close }) => (
                <>
                  <Dialog
                    render={() => (
                      <WorkflowCopy
                        source={workflow}
                        onSuccess={(workflow) => {
                          navigateTo('WORKFLOW', { workflowId: workflow.id });
                        }}
                        onCancel={noop}
                      />
                    )}
                  >
                    <Button onClick={close}>Copy</Button>
                  </Dialog>

                  <Dialog render={() => <ConfirmActionModal onConfirm={handleRemove} />}>
                    <Button onClick={close}>Delete</Button>
                  </Dialog>
                </>
              )}
            >
              <Button variant="outlined" icon={<RiMore2Fill />} />
            </Dropdown>
          </div>
        </LayoutContentHeader>

        <LayoutContentTabs
          items={[
            {
              to: pathTo('WORKFLOW_STATE_DIAGRAM'),
              label: 'State Diagram',
            },
            {
              to: pathTo('WORKFLOW_USER_GROUPS'),
              label: 'User Groups',
            },
          ]}
        />

        <LayoutContent>
          <Switch>
            <Route path={AppRoutes.WORKFLOW_STATE_DIAGRAM} component={WorkflowDiagramBuilder} />

            <Route path={AppRoutes.WORKFLOW_USER_GROUPS} component={WorkflowUserGroups} />
          </Switch>
        </LayoutContent>
      </LayoutContentBody>
    </WorkflowDetailsContext.Provider>
  );
};

export default WorkflowDetails;
