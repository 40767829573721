import { PropsWithChildren } from 'react';

// @todo handle child checkbox, radio, etc

interface Props
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {}

export const Label = ({ children, ...rest }: PropsWithChildren<Props>) => {
  return (
    <label className="inline-block pb-1 font-medium" {...rest}>
      {children}
    </label>
  );
};
