import classNames from 'classnames';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { DropdownButtonItem } from 'components/common/DropdownButtonItem/DropdownButtonItem';
import { AnnotationClassPropertyKey } from 'config';
import { mapAnnotationClassTypeToDisplayName } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useState } from 'react';
import { CgDanger } from 'react-icons/cg';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdAdd, MdAdsClick, MdArrowDropDown, MdOutlineClosedCaptionDisabled } from 'react-icons/md';
import { mapAnnotationClassTypeToDisplayIcon } from '../JobTasksAnnotationClasses/JobTasksAnnotationClasses';
import { JobTasksColumnHeader } from '../shared/JobTasksColumnHeader/JobTasksColumnHeader';
import { BulkCreateAnnotationClassProperties } from './BulkCreateAnnotationClassProperties';
import { CreateTaskDropdown } from './CreateTaskDropdown/CreateTaskDropdown';
import { JobTasksAnnotationClassPropertyList } from './JobTasksAnnotationClassPropertyList/JobTasksAnnotationClassPropertyList';

export const JobTasksAnnotationClass = () => {
  const {
    organizationId,
    projectId,
    jobId,
    annotationClassId,
    annotationClassPropertyId: selectedAnnotationClassPropertyId,
  } = useIntParams();
  const annotationClassesQuery = useQuery('GetAnnotationClasses', {
    params: { organizationId, projectId, jobId },
  });

  const { navigateTo } = useRouterUtils();

  const { mutateAsync: updateAnnotationClass } = useMutation('UpdateAnnotationClass', {
    onSuccess: (data) => {
      const primaryClassificationProperty = data.properties.find(
        (e) => e.key === AnnotationClassPropertyKey.PRIMARY_CLASSIFICATION
      );
      if (primaryClassificationProperty) {
        navigateTo('JOB_TASKS_ANNOTATION_CLASS_PROPERTY', {
          annotationClassPropertyId: primaryClassificationProperty.id,
        });
      }
    },
  });
  const [isConfirmAnnotationClassDisableModalOpen, setIsConfirmAnnotationClassDisableModalOpen] = useState(false);
  const selectedAnnotationClass = annotationClassesQuery.data?.find((e) => e.id === annotationClassId);

  const [showBulkCreate, setShowBulkCreate] = useState(false);

  if (!selectedAnnotationClass) {
    return null;
  }

  return (
    <>
      <div>
        <JobTasksColumnHeader>
          {annotationClassId && (
            <div className="flex items-center gap-2">
              {mapAnnotationClassTypeToDisplayIcon[selectedAnnotationClass.type]}
              <span className="font-medium">{mapAnnotationClassTypeToDisplayName[selectedAnnotationClass.type]}</span>
            </div>
          )}

          <div className="flex items-center space-x-1">
            <Dropdown render={() => <CreateTaskDropdown selectedAnnotationClassId={annotationClassId} />}>
              <button
                disabled={!selectedAnnotationClass.enabled}
                className={classNames(
                  'flex items-center rounded-full border border-slate-200 bg-white px-4 py-1.5 text-xs font-medium text-slate-600 hover:bg-slate-50',
                  {
                    'opacity-30': !selectedAnnotationClass.enabled,
                  }
                )}
              >
                <div className="mr-4 border-r pl-2 pr-4">ADD TASK</div>
                <MdArrowDropDown />
              </button>
            </Dropdown>

            <Dialog
              render={() => (
                <ConfirmActionModal
                  onConfirm={() => {
                    updateAnnotationClass({
                      data: { enabled: false },
                      params: {
                        organizationId,
                        projectId,
                        jobId,
                        annotationClassId: annotationClassId,
                      },
                    });

                    navigateTo('JOB_TASKS_ANNOTATION_CLASS');
                  }}
                >
                  <div className="mt-2 mb-6 flex flex-row items-center gap-4" style={{ maxWidth: 500 }}>
                    <CgDanger size={60} />
                    <p>
                      Warning! Task configuration will be cleared. Any existing annotations related to this task context
                      will be permanently lost.
                    </p>
                  </div>
                </ConfirmActionModal>
              )}
              isOpen={isConfirmAnnotationClassDisableModalOpen}
              onOpenChange={setIsConfirmAnnotationClassDisableModalOpen}
            />
            <Dropdown
              render={() => (
                <>
                  <DropdownButtonItem onClick={() => setShowBulkCreate(true)}>
                    <MdAdd />
                    Bulk Create
                  </DropdownButtonItem>
                  <DropdownButtonItem onClick={() => setIsConfirmAnnotationClassDisableModalOpen(true)}>
                    <MdOutlineClosedCaptionDisabled /> Disable{' '}
                    {mapAnnotationClassTypeToDisplayName[selectedAnnotationClass.type]} Tasks
                  </DropdownButtonItem>
                </>
              )}
            >
              <Button
                disabled={!selectedAnnotationClass.enabled}
                variant="icon-rounded"
                size="sm"
                icon={<IoSettingsOutline />}
                className="py-1.5"
              />
            </Dropdown>
          </div>
        </JobTasksColumnHeader>

        {selectedAnnotationClass.enabled ? (
          selectedAnnotationClass.properties.length === 0 ? (
            <div className="my-6 text-center text-slate-600">No tasks created yet</div>
          ) : (
            <JobTasksAnnotationClassPropertyList
              selectedAnnotationClassId={annotationClassId}
              selectedAnnotationClassPropertyId={selectedAnnotationClassPropertyId}
            />
          )
        ) : (
          <div className="my-6 text-center text-slate-600">
            <Button
              variant="icon-rounded"
              icon={<MdAdsClick />}
              label={`ENABLE ${mapAnnotationClassTypeToDisplayName[selectedAnnotationClass.type]} TASKS`}
              className="m-auto uppercase text-blue-500"
              onClick={() =>
                updateAnnotationClass({
                  data: { enabled: true },
                  params: {
                    organizationId,
                    projectId,
                    jobId,
                    annotationClassId: annotationClassId,
                  },
                })
              }
            />
          </div>
        )}
      </div>

      <Dialog
        render={() => <BulkCreateAnnotationClassProperties />}
        isOpen={showBulkCreate}
        onOpenChange={setShowBulkCreate}
      />
    </>
  );
};
