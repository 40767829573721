import {
  SelfServiceRecoveryFlow,
  SubmitSelfServiceRecoveryFlowWithLinkMethodBody,
} from '@ory/kratos-client';
import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { oryKratosService } from './oryKratos.service';

export const useAccountRecoveryFlow = () => {
  const [flow, setFlow] = useState<SelfServiceRecoveryFlow>();
  const recoveryMutation = useMutation(oryKratosService.accountRecovery);

  const accountRecovery = async (
    data: SubmitSelfServiceRecoveryFlowWithLinkMethodBody
  ) => {
    if (!flow) {
      console.log('Account recovery flow not initialized');
      return;
    }

    try {
      const response = await recoveryMutation.mutateAsync({
        flow: flow.id,
        data,
      });

      setFlow(response.data);

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error as AxiosError<SelfServiceRecoveryFlow>;

        if (response?.data) {
          setFlow(response.data);
        }
      }

      return error;
    }
  };

  useEffect(() => {
    (async () => {
      const res = await oryKratosService.initAccountRecoveryFlow();
      setFlow(res.data);
    })();
  }, []);

  return {
    accountRecovery,
    flow,
  };
};
