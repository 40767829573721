import { mapAnnotationClassTypeToDisplayName } from 'enums';
import { attachEditorObject } from './attachEditorObject';
import { ULID } from './ulid';

interface Props {
  annotationClass: AnnotationClassDTO;
  data?: { [key: string | number]: any };
  name?: string;
}

export const createAnnotationInstance = ({ annotationClass, data, name }: Props) => {
  name = name || mapAnnotationClassTypeToDisplayName[annotationClass.type];

  const instance: AnnotationInstanceDTO = {
    id: ULID(),
    name,
    annotationClass,

    properties: Object.fromEntries(annotationClass.properties.map(({ key }) => [key, data ? data[key] : null])),

    updatedAt: null,

    uiProperties: {
      isVisible: true,
    },
  };

  attachEditorObject(instance);

  return instance;
};
