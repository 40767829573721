import React, { useMemo, useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Select from 'react-select';

import { WorkflowState, JobDataitemDistributionReportFilters } from './types';

import { AppRouteParams } from 'appRoutes';
import { Typography } from 'components/common/Typography';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';

import workflowStateColorMapping from './workflowStateColorMapping';

type ParsedDatum = Record<string, number> & { assigneeEmail: string };
type SelectOption = { label: string; value: number };

export const DataitemDistributionReport = () => {
  const { jobId, projectId, organizationId } = useIntParams<AppRouteParams['JOB_REPORTS']>();
  const [workflowStateFilters, setWorkflowStateFilters] = useState<number[]>([]);
  const [userFilters, setUserFilters] = useState<number[]>([]);
  const [workflowStateSelectOptions, setWorkflowStateSelectOptions] = useState<SelectOption[]>([]);
  const [userSelectOptions, setUserSelectOptions] = useState<SelectOption[]>([]);

  const { data: jobDataitemDistribution } = useQuery('GetJobDataitemDistributionReport', {
    params: {
      organizationId,
      projectId,
      jobId,
    },
    query: {
      users: userFilters,
      workflowStates: workflowStateFilters,
    } as JobDataitemDistributionReportFilters,
  });

  const parsedData = useMemo(() => {
    if (!jobDataitemDistribution) {
      return [];
    }

    const normalizedData = jobDataitemDistribution.reduce((acc, item) => {
      const { assigneeEmail, count, workflowState } = item;

      acc[assigneeEmail] = acc[assigneeEmail] || {
        assigneeEmail: assigneeEmail || 'Unassigned',
      };

      acc[assigneeEmail][workflowState] = count;

      return acc;
    }, {} as Record<string, ParsedDatum>);

    return Object.values(normalizedData);
  }, [jobDataitemDistribution]);

  const relevantWorkflowStates = useMemo(() => {
    if (!jobDataitemDistribution) {
      return {};
    }

    return jobDataitemDistribution.reduce((acc, item) => {
      const { workflowState, workflowStateId } = item;

      acc[workflowState] = workflowStateId;

      return acc;
    }, {} as Record<string, number>);
  }, [jobDataitemDistribution]);

  useEffect(() => {
    // Run only once, when the "whole picture" data is retrieved.
    if (!jobDataitemDistribution || (workflowStateSelectOptions && userSelectOptions)) {
      return;
    }

    const options = jobDataitemDistribution.reduce(
      (acc, item) => {
        const { workflowState, workflowStateId, assigneeEmail, assigneeId } = item;

        acc.workflowStates[workflowState] = workflowStateId;
        acc.users[assigneeEmail] = assigneeId;

        return acc;
      },
      { workflowStates: {}, users: {} } as {
        workflowStates: Record<string, number>;
        users: Record<string, number>;
      }
    );

    if (options.workflowStates.length > 1) {
      setWorkflowStateSelectOptions(
        Object.entries(options.workflowStates).map(([label, value]) => ({
          label,
          value,
        }))
      );
    }

    if (options.users.length > 1) {
      setUserSelectOptions(
        Object.entries(options.users).map(([label, value]) => ({
          label,
          value,
        }))
      );
    }
  }, [jobDataitemDistribution, workflowStateSelectOptions, userSelectOptions]);

  return (
    <>
      <div className="mb-4">
        {userSelectOptions!.length > 1 && (
          <div className="inline-block mr-4">
            <label className="block">Filter Users</label>
            <Select
              isMulti
              name="users"
              options={userSelectOptions}
              styles={{
                container: (provided) => ({
                  ...provided,
                  minWidth: 250,
                }),
              }}
              onChange={(selected) => {
                setUserFilters(selected.map((option) => option.value));
              }}
            />
          </div>
        )}
        {workflowStateSelectOptions!.length > 1 && (
          <div className="inline-block mr-4">
            <label className="block">Select Workflow States</label>
            <Select
              isMulti
              name="workflow-states"
              options={workflowStateSelectOptions}
              styles={{
                container: (provided) => ({
                  ...provided,
                  minWidth: 250,
                }),
              }}
              onChange={(selected) => {
                setWorkflowStateFilters(selected.map((option) => option.value));
              }}
            />
          </div>
        )}
      </div>
      <div className="grow flex flex-col justify-center">
        {parsedData.length > 0 && (
          <div className="w-full self-center" style={{ height: 250 + parsedData.length * 25 }}>
            <div className="flex justify-around mb-10">
              {Object.keys(relevantWorkflowStates).map((state) => (
                <div className="flex items-center" key={state}>
                  <div
                    className="inline-block h-5 w-5 mr-2"
                    style={{
                      backgroundColor: workflowStateColorMapping[state as WorkflowState],
                    }}
                  ></div>
                  <span className="text-xs">{state}</span>
                </div>
              ))}
            </div>

            <ResponsiveBar
              animate={false}
              data={parsedData}
              indexBy="assigneeEmail"
              keys={Object.keys(relevantWorkflowStates)}
              enableGridY={false}
              enableGridX={true}
              margin={{ top: 30, right: 100, bottom: 80, left: 200 }}
              layout="horizontal"
              labelTextColor="inherit:darker(1.4)"
              labelSkipWidth={16}
              labelSkipHeight={16}
              colors={(datum) => workflowStateColorMapping[datum.id as WorkflowState]}
              padding={0.8}
              axisBottom={null}
              axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
              }}
            />
          </div>
        )}

        {parsedData.length === 0 && (
          <Typography component="h1" className="text-center">
            No data available. Try different filters.
          </Typography>
        )}
      </div>
    </>
  );
};
