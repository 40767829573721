import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { useContext } from 'react';
import { IoMdClose } from 'react-icons/io';

interface Props {
  title?: string | JSX.Element;
}

export const ModalLayoutHeader = ({ title }: Props) => {
  const { close } = useContext(DialogContext);

  return (
    <header
      className=" border-slate-100 flex justify-between items-center p-4 border-b"
      style={{ background: 'rgb(248 250 254)' }}
    >
      <div className="flex justify-between items-center gap-2 pt-1">
        <div className="text-xl font-extralight text-stone-800">{title}</div>
      </div>

      <Button onClick={close} icon={<IoMdClose />} variant="icon-rounded" />
    </header>
  );
};
