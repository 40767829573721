import { AnnotationClassType } from 'shared/enums';

export const ShapeType = {
  POINT: 'POINT',
  RECTANGLE: 'RECTANGLE',
  POLYGON: 'POLYGON',
  CIRCLE: 'CIRCLE',
  ELLIPSE: 'ELLIPSE',
  PIXEL_MASK: 'PIXEL_MASK',
};

export const MetaType2 = {
  ANCHOR_POINT: 'ANCHOR_POINT',
};

export const MOUSE_BUTTON = {
  MAIN: 0,
  AUXILIARY: 1,
  SECONDARY: 2,
};

export const ScrollDirection = {
  UP: 'UP',
  DOWN: 'DOWN',
};

export const ToolType = {
  SELECT: 'SELECT',
  BOUNDING_BOX: 'BOUNDING_BOX',
  POLYGON: 'POLYGON',
  POLYLINE: 'POLYLINE',
  SEGMENTATION_BRUSH: 'SEGMENTATION_BRUSH',
  SEGMENTATION_POLYGON: 'SEGMENTATION_POLYGON',
  PATH_NODE_INSERT: 'PATH_NODE_INSERT',
  PATH_NODE_REMOVE: 'PATH_NODE_REMOVE',
  ELLIPSE: 'ELLIPSE',
  FLOOD_FILL: 'FLOOD_FILL',
};

export const mapToolTypeToAnnotationClassType = {
  [ToolType.BOUNDING_BOX]: AnnotationClassType.BOUNDING_BOX,
  [ToolType.POLYGON]: AnnotationClassType.POLYGON,
  [ToolType.SEGMENTATION_BRUSH]: AnnotationClassType.PIXEL_MASK,
  [ToolType.SEGMENTATION_POLYGON]: AnnotationClassType.PIXEL_MASK,
};

export const GlobalCompositeOperation: {
  [key: string]: GlobalCompositeOperation;
} = {
  COPY: 'copy',
  SOURCE_IN: 'source-in',
  SOURCE_OVER: 'source-over',
  DESTINATION_OUT: 'destination-out',
  DESTINATION_OVER: 'destination-over',
};

export const InteractionMode = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
} as const;
