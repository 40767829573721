import { useCallback, useEffect, useState } from 'react';

export const useBoolean = (val: boolean) => {
  const [value, setValue] = useState(val);

  useEffect(() => {
    setValue(val);
  }, [val]);

  return {
    value,
    setValue,
    toggle: useCallback(() => setValue((v) => !v), []),
    setTrue: useCallback(() => setValue(true), []),
    setFalse: useCallback(() => setValue(false), []),
  };
};
