import classNames from 'classnames';

interface Props {
  type: string;
  text: string;
  className?: string;
}

export const FormMessage = ({ type, text, className }: Props) => {
  const isError = type === 'error';
  const isInfo = type === 'info';
  const isDefault = !isError && !isInfo;

  return (
    <div
      className={classNames(
        'my-2 whitespace-pre-line text-sm',
        {
          'text-blue-600': isDefault,
          'text-annotorio-pink': isError,
          'text-green-600': isInfo,
        },
        className
      )}
    >
      {text}
    </div>
  );
};
