import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Typography } from 'components/common/Typography';
import { ProjectCreateModal } from 'components/Modals/ProjectCreateModal/ProjectCreateModal';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';
import { getFormattedDate } from 'utils/dateUtils';

export const Projects = () => {
  const { organizationId } = useIntParams();
  const projectsQuery = useQuery('GetProjects', { params: { organizationId } });
  const { pathTo } = useRouterUtils();

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Projects
          </Typography>
        </div>

        <div>
          <Dialog render={() => <ProjectCreateModal />}>
            <Button>Create Project</Button>
          </Dialog>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <table className="table-primary">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {projectsQuery.data?.length === 0 && (
              <tr>
                <td colSpan={100} className="p-4 text-center text-slate-500">
                  No Projects
                </td>
              </tr>
            )}

            {projectsQuery.data?.map(({ id, name, createdAt }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>
                  <Link to={pathTo('PROJECT', { projectId: id })}>{name}</Link>
                </td>
                <td>{getFormattedDate(createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </LayoutContent>
    </LayoutContentBody>
  );
};
