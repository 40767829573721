import { api } from 'api/api';
import { QueryClient, UseMutationOptions } from 'react-query';

type MutationKeys = keyof typeof api.mutations;

export const ReactQueryMutationOptions = <T extends typeof api.mutations, K extends keyof typeof api.mutations>(
  queryClient: QueryClient
): {
  [key in MutationKeys]: Pick<UseMutationOptions<Awaited<ReturnType<T[K]>>, unknown, any, unknown>, 'onSettled'>;
} => {
  const invalidatAll = () => {
    queryClient.invalidateQueries();
  };

  const invalidateQueries = (queryKeys: unknown[]) => {
    queryKeys.forEach((queryKey) => queryClient.invalidateQueries([queryKey]));
  };

  const removeQueries = (queryKeys: unknown[]) => {
    queryKeys.forEach((queryKey) => queryClient.removeQueries([queryKey]));
  };

  return {
    CreateOrganization: {
      onSettled: (_data, _error, _variables) => {
        invalidateQueries([
          {
            id: 'GetOrganizations',
          },
        ]);
      },
    },

    UpdateOrganiaztion: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetOrganizationById',
            params: { organizationId: variables.params.organizationId },
          },
          {
            id: 'GetOrganizations',
          },
        ]);
      },
    },
    RemoveOrganization: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetOrganizations',
          },
        ]);

        removeQueries([
          {
            params: { organizationId: variables.params.organizationId },
          },
        ]);
      },
    },

    CreateProject: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetProjects',
            params: { organizationId: variables.params.organizationId },
          },
        ]);
      },
    },
    UpdateProject: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetProjectById',
            params: { organizationId: variables.params.organizationId, projectId: variables.params.projectId },
          },
          {
            id: 'GetProjects',
            params: { organizationId: variables.params.organizationId },
          },
        ]);
      },
    },
    RemoveProject: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetProjects',
            params: { organizationId: variables.params.organizationId },
          },
        ]);

        removeQueries([
          {
            params: { projectId: variables.params.projectId },
          },
        ]);
      },
    },

    UpdateAnnotationClass: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetAnnotationClasses',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },

    CreateAnnotationClassProperty: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetAnnotationClasses',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },
    UpdateAnnotationClassProperty: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetAnnotationClasses',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },
    RemoveAnnotationClassProperty: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetAnnotationClasses',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },

    SortAnnotationClassProperties: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetAnnotationClasses',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },

    UpdateAnnotationClassPropertyOptions: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetAnnotationClasses',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },

    BulkRemoveAnnotationInstances: {},
    BulkUpsertAnnotationInstances: {},
    RemoveAnnotationInstance: {},
    UpsertAnnotationInstance: {},

    RemoveDataset: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetDatasets',
            params: { projectId: variables.params.projectId },
          },
        ]);

        removeQueries([
          {
            params: { projectId: variables.params.projectId, datasetId: variables.params.datasetId },
          },
        ]);
      },
    },
    UpdateDataset: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetDatasetById',
            params: { projectId: variables.params.projectId, datasetId: variables.params.datasetId },
          },
          {
            id: 'GetDatasets',
            params: { projectId: variables.params.projectId },
          },
        ]);
      },
    },
    CreateDataset: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetDatasets',
            params: { projectId: variables.params.projectId },
          },
        ]);
      },
    },

    RemoveJobExportSchedule: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJobExportHistories',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },

    ChangeJobWorkflow: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJob',
            params: { jobId: variables.params.jobId },
          },
        ]);

        invalidateQueries([
          {
            id: 'GetJobWorkflow',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },

    CreateJobExport: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJobExportHistories',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },

    CreateJob: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJobs',
            params: { projectId: variables.params.projectId },
          },
        ]);
      },
    },
    UpdateJob: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJobById',
            params: { projectId: variables.params.projectId, jobId: variables.params.jobId },
          },
          {
            id: 'GetJobs',
            params: { projectId: variables.params.projectId },
          },
        ]);
      },
    },
    RemoveJob: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJobs',
            params: { projectId: variables.params.projectId },
          },
        ]);

        removeQueries([
          {
            params: { projectId: variables.params.projectId, jobId: variables.params.jobId },
          },
        ]);
      },
    },

    GenerateProjectApiKey: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetProjectApiKey',
            params: { projectId: variables.params.projectId },
          },
        ]);
      },
    },
    RemoveProjectApiKey: {
      onSettled: (_data, _error, variables) => {
        removeQueries([
          {
            id: 'GetProjectApiKey',
            params: { projectId: variables.params.projectId },
          },
        ]);
      },
    },

    CancelSubscriptionPlan: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetSubscriptionPlan',
            params: { organizationId: variables.params.organizationId },
          },
        ]);
      },
    },
    UpdateSubscriptionPlan: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetSubscriptionPlan',
            params: { organizationId: variables.params.organizationId },
          },
        ]);
      },
    },

    InviteUser: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetOrganizationUsers',
            params: { organizationId: variables.params.organizationId },
          },
        ]);
      },
    },
    RemoveOrganizationUser: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetOrganizationUsers',
            params: { organizationId: variables.params.organizationId },
          },
        ]);
      },
    },

    SetUserPreference: {
      onSettled: (_data, _error, _variables) => {
        invalidateQueries([
          {
            id: 'GetUserPreference',
          },
        ]);
      },
    },

    SetUserRole: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetOrganizationUsers',
            params: { organizationId: variables.params.organizationId },
          },
        ]);
      },
    },

    CreateDatasetRemoteImportSource: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetDatasetRemoteImportSources',
            params: { datasetId: variables.params.datasetId },
          },
        ]);
      },
    },
    EditDatasetRemoteImportSource: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetDatasetRemoteImportSources',
            params: { datasetId: variables.params.datasetId },
          },
        ]);
      },
    },
    RemoveDatasetRemoteImportSource: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetDatasetRemoteImportSources',
            params: { datasetId: variables.params.datasetId },
          },
        ]);
      },
    },

    UpdateDatasetRemoteImportSourceSchedule: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetDatasetRemoteImportSources',
            params: { datasetId: variables.params.datasetId },
          },
        ]);
      },
    },
    RemoveDatasetRemoteImportSourceSchedule: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetDatasetRemoteImportSources',
            params: { datasetId: variables.params.datasetId },
          },
        ]);
      },
    },

    TriggerDatasetRemoteStorageImport: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetDatasetRemoteImportSources',
            params: { datasetId: variables.params.datasetId },
          },
          {
            id: 'GetDatasetImportHistoriesWithPagination',
            params: { datasetId: variables.params.datasetId },
          },
        ]);
      },
    },

    ApplyWorkflowTransitionToJobDataitem: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            params: { jobDataitemId: variables.params.jobDataitemId },
          },
        ]);
      },
    },

    SetBulkJobDataitemAssignee: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            params: { jobId: variables.params.jobId }, // @todo handle pagination
          },
        ]);
      },
    },

    AddJobUsers: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJobUsers',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },
    RemoveJobUser: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJobUsers',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },

    CreateJobExportRemoteStorageTarget: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJobExportRemoteStorageTargets',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },
    EditJobExportRemoteStorageTarget: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJobExportRemoteStorageTargets',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },
    RemoveJobExportRemoteStorageTarget: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetJobExportRemoteStorageTargets',
            params: { jobId: variables.params.jobId },
          },
        ]);
      },
    },

    ConfirmAnnotationClassPropertyOptionDelete: {},

    CreateWorkflow: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetWorkflows',
            params: { organizationId: variables.params.organizationId },
          },
        ]);
      },
    },
    RemoveWorkflow: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetWorkflows',
            params: { organizationId: variables.params.organizationId },
          },
        ]);

        removeQueries([
          {
            params: { workflowId: variables.params.projectId },
          },
        ]);
      },
    },
    UpdateWorkflow: {
      onSettled: (_data, _error, variables) => {
        invalidateQueries([
          {
            id: 'GetWorkflowById',
            params: {
              organizationId: variables.params.organizationId,
              projectId: variables.params.projectId,
              workflowId: variables.params.workflowId,
            },
          },
          {
            id: 'GetWorkflows',
            params: { organizationId: variables.params.organizationId },
          },
        ]);
      },
    },

    CreateWorkflowState: {
      onSettled: () => invalidatAll(),
    },
    RemoveWorkflowState: { onSettled: () => invalidatAll() },
    UpdateWorkflowState: { onSettled: () => invalidatAll() },

    CreateWorkflowTransition: { onSettled: () => invalidatAll() },
    UpdateWorkflowTransition: { onSettled: () => invalidatAll() },
    RemoveWorkflowTransition: { onSettled: () => invalidatAll() },

    CreateWorkflowAction: { onSettled: () => invalidatAll() },
    RemoveWorkflowAction: { onSettled: () => invalidatAll() },

    CreateWorkflowUserGroup: { onSettled: () => invalidatAll() },
    UpdateWorkflowUserGroup: { onSettled: () => invalidatAll() },
    RemoveWorkflowUserGroup: { onSettled: () => invalidatAll() },

    CopyWorkflow: { onSettled: () => invalidatAll() },

    // @todo optimize
    UpdateJobDataitemWorkflowState: { onSettled: () => invalidatAll() },

    CreateBulkAnnotationClassProperty: { onSettled: () => invalidatAll() },
  };
};
