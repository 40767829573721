import { videoTimelineState } from 'components/Workspace2/state/videoTimelineState';
import { Atom, useAtom } from 'jotai';
import { VideoTimelineRowHeadingItem } from './VideoTimelineRowHeadingItem';
import { VideoTimelineRowHeadingTitle } from './VideoTimelineRowHeadingTitle';

interface Props {
  atom: Atom<AnnotationInstanceDTO>;
}

export const VideoTimelineRowHeading = ({ atom }: Props) => {
  const [annotationInstance] = useAtom(atom);
  const [videoTimelineAnnotationInstanceOptions] = useAtom(videoTimelineState.annotationInstanceOptionsAtom);
  // const isDetailedView = videoTimelineAnnotationInstanceOptions[annotationInstance.id]?.isDetailedView;
  const [onlyShowPropertiesWithKeyframeValues] = useAtom(videoTimelineState.onlyShowPropertiesWithKeyframeValues);

  return (
    <div>
      <VideoTimelineRowHeadingTitle annotationInstance={annotationInstance} />

      <div>
        {annotationInstance.annotationClass.properties
          .filter((e) => e.isKeyframable)
          .filter((e) =>
            onlyShowPropertiesWithKeyframeValues
              ? Array.isArray(annotationInstance.properties[e.key]) && annotationInstance.properties[e.key].length !== 0
              : e
          )
          .map((annotationClassProperty) => (
            <VideoTimelineRowHeadingItem
              key={annotationClassProperty.id}
              annotationInstance={annotationInstance}
              annotationClassProperty={annotationClassProperty}
            />
          ))}
      </div>
    </div>
  );
};
