import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { getFormattedDate } from 'utils/dateUtils';

interface Props {
  rows?: DataitemDTO[];
  onPreview: (dataitem: DataitemDTO) => void;
}

export const useDataitemsTable = ({ rows, onPreview }: Props) => {
  const columnHelper = createColumnHelper<DataitemDTO>();

  const columns = [
    columnHelper.accessor('id', {
      header: 'Id',
    }),
    columnHelper.accessor('externalId', {
      id: 'checksum',
      header: 'Name',
      cell: (data) => {
        const externalId = data.getValue();
        const url: any = data.row.original.url;
        return (
          <button className="hover:text-blue-500" onClick={() => onPreview(data.row.original)}>
            {externalId}
          </button>
        );
      },
    }),
    columnHelper.accessor('datasetImportActionId', {
      header: 'Import Id',
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created At',
      cell: (data) => {
        const createdAt: string = data.row.getValue('createdAt');
        return getFormattedDate(createdAt);
      },
    }),
    columnHelper.accessor('updatedAt', {
      header: 'Updated At',
      cell: (data) => {
        const updatedAt: string = data.row.getValue('updatedAt');
        return getFormattedDate(updatedAt);
      },
    }),
  ];

  const instance = useReactTable({
    data: rows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    instance,
  };
};
