import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Tag } from 'components/common/Tag/Tag';
import { Typography } from 'components/common/Typography';
import { ProjectCreateModal } from 'components/Modals/ProjectCreateModal/ProjectCreateModal';
import { mapSubscriptionPlanIdToDisplayName } from 'components/PlansAndBilling/SubscriptionPlans/SubscriptionPlans';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link, useParams } from 'react-router-dom';
import { getFormattedDate } from 'utils/dateUtils';

export const Organization = () => {
  const { organizationId } = useParams<any>();
  const { data: organization } = useQuery('GetOrganizationById', {
    params: { organizationId },
  });
  const { pathTo } = useRouterUtils();

  const projectsQuery = useQuery('GetProjects', { params: { organizationId } });
  const subscriptionPlanQuery = useQuery('GetSubscriptionPlan', { params: { organizationId } });
  const currentSubscriptionPlandDisplayName = subscriptionPlanQuery.data?.externalPlanId
    ? mapSubscriptionPlanIdToDisplayName[subscriptionPlanQuery.data?.externalPlanId]
    : 'FREE';

  if (!organization) {
    return null;
  }

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center gap-2">
          <Typography component="h1" className="text-2xl">
            {organization.name}
          </Typography>

          <Tag>{currentSubscriptionPlandDisplayName}</Tag>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <div className="mb-2 mt-6 flex items-center justify-between">
          <div className="text-md font-medium text-slate-800">Projects</div>

          <div>
            <Dialog render={() => <ProjectCreateModal />}>
              <Button variant="link">+ Create Project</Button>
            </Dialog>
          </div>
        </div>

        <table className="table-primary">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {projectsQuery.data?.length === 0 && (
              <tr>
                <td colSpan={100} className="p-4 text-center text-slate-500">
                  No Projects
                </td>
              </tr>
            )}

            {projectsQuery.data?.map(({ id, name, createdAt }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>
                  <Link to={pathTo('PROJECT', { projectId: id })}>{name}</Link>
                </td>
                <td>{getFormattedDate(createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </LayoutContent>
    </LayoutContentBody>
  );
};
