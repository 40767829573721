import { MOUSE_BUTTON } from 'modules/image-annotator/enums';

export const ViewPan = (view: HTMLElement, transform: any) => {
  let offset = { x: 0, y: 0 };
  let isMouseDown = false;

  const onMouseDown = (event: MouseEvent) => {
    if (!(event.button === MOUSE_BUTTON.AUXILIARY || (event.shiftKey && event.button === MOUSE_BUTTON.MAIN))) {
      return;
    }

    const { pageX, pageY } = event;
    const { x: tx, y: ty } = transform.getState();
    offset = { x: pageX - tx, y: pageY - ty };
    isMouseDown = true;
  };

  const onMouseMove = (event: MouseEvent) => {
    if (!isMouseDown) {
      return;
    }

    const { pageX, pageY } = event;
    const tx = pageX - offset.x;
    const ty = pageY - offset.y;

    transform.setState({ x: tx, y: ty });
  };

  const onMouseUp = () => {
    isMouseDown = false;
  };

  function destroy() {
    view.removeEventListener('mousedown', onMouseDown);
    view.removeEventListener('mousemove', onMouseMove);
    view.removeEventListener('mouseup', onMouseUp);
  }

  function _init() {
    view.addEventListener('mousedown', onMouseDown);
    view.addEventListener('mousemove', onMouseMove);
    view.addEventListener('mouseup', onMouseUp);
  }

  _init();

  return Object.freeze({ destroy });
};
