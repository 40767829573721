import { videoTimelineConfig } from '../VideoTimeline';

interface Props {
  fromIndex: number;
  toIndex: number;
  value?: any;
  color?: string;
}

export const VideoTimelineKeyframeSpan = ({ fromIndex, toIndex, value, color = '#404040' }: Props) => {
  const { FRAME_WIDTH_IN_PX } = videoTimelineConfig;

  return (
    <div
      className="absolute top-0 bottom-0 my-1 rounded border border-black/60"
      style={{
        left: fromIndex * FRAME_WIDTH_IN_PX,
        width: (toIndex - fromIndex) * FRAME_WIDTH_IN_PX,
        background: color,
      }}
    >
      <div className="flex items-center px-4" style={{ fontSize: 9 }}>
        {Array.isArray(value) ? value.join(', ') : value}
      </div>
    </div>
  );
};
