import { forwardRef } from 'react';
import styles from './InputSwitch.module.css';

// @todo disabled state
// @todo hover & active state

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'type'
  > {
  iconLeft?: any; // @todo
  iconRight?: any; // @todo
}

export const InputSwitch = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { iconLeft, iconRight, ...rest } = props;
  const input = (
    // eslint-disable-next-line react/forbid-elements
    <input ref={ref} type="checkbox" className={styles.toggle} {...rest} />
  );

  return (
    <label className={styles.container}>
      {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}
      {iconRight && <div className={styles.iconRight}>{iconRight}</div>}

      {input}
      <div className={styles.inner}></div>
    </label>
  );
});
