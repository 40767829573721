import { Group } from 'modules/video-editor/pxae/Group';
import { Path } from 'modules/video-editor/pxae/primitives/Path';
import { VideoEditorEvents } from '../enums';
import { TransformBoxResizeAnchors } from './TransformBoxResizeAnchors';
import { TransformBoxRotationAnchors } from './TransformBoxRotationAnchors';

// @todo watch visibility

export const TransformBox2 = (item: any) => {
  const id = Math.random(); // @todo
  const box = Path({
    points: [],
    strokeStyle: 'rgba(0, 100, 255, 1)',
    lineWidth: 1,
    fillStyle: 'rgba(0, 0, 0, 0)', // hack for draggability ;)
    data: {
      draggable: true,
      syncDragTarget: item,
      selectable: false,
    },
  });

  const group = Group(); // group on which transform are applied
  group.add(box);
  group.add(item);

  const resizeAnchors = TransformBoxResizeAnchors(box, group, {
    onTransformEnd: () => {
      onTransformEnd();
      alignAnchors();
    },
  });

  const rotationAnchors = TransformBoxRotationAnchors(box, group, {
    onTransformEnd: () => {
      onTransformEnd();
      alignAnchors();
    },
  });

  function show() {
    box.set({ isVisible: true });
    showControls();
  }

  function hide() {
    box.set({ isVisible: false });
    hideControls();
  }

  function update() {
    initTransformBoxPoints(); // @todo
    alignAnchors();
    showControls();
  }

  function onTransform() {
    item.dispatch(VideoEditorEvents.USER_TRANSFORM);
  }

  function onTransformEnd() {
    item.dispatch(VideoEditorEvents.USER_TRANSFORM_END);
  }

  function alignAnchors() {
    resizeAnchors.align();
    rotationAnchors.align();
  }

  function hideControls() {
    resizeAnchors.hide();
    rotationAnchors.hide();
  }

  function showControls() {
    if (item.data()['transform-box-controls'] === false) {
      return;
    }

    resizeAnchors.show();
    rotationAnchors.show();
  }

  function initEvents() {
    box.on('drag', () => {
      hideControls();
      onTransform();
    });

    box.on('dragend', () => {
      alignAnchors();
      showControls();
      onTransformEnd();
    });
  }

  function initTransformBoxPoints() {
    const obb = item.getOBB();
    const a = item._getRotation();
    const path = Path({ points: obb });
    path._rotate(-a, item.getCentroid());
    path._applyTransformMatrix();

    box.resetTransformMatrix();
    box.set({ points: path.getPoints() });
    box._setRotation(a, item.getCentroid());
  }

  function init() {
    initTransformBoxPoints();
    initEvents();
    alignAnchors();

    if (item.data()['transform-box-controls'] === false) {
      hideControls();
    }
  }

  init();

  // return { show, hide, update, items: [box, ...rotationAnchors.items, ...resizeAnchors.items] };
  return { id, show, hide, update, items: [box, ...resizeAnchors.items] };
};
