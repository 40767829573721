import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';

interface Props {
  dataitem: DataitemDTO;
}

export const DataitemPreviewModal = ({ dataitem }: Props) => {
  // const { close } = useContext(DialogContext);

  return (
    <ModalLayout>
      <ModalLayoutHeader title={dataitem.externalId.toString()} />
      <ModalLayoutBody>
        <div style={{ width: 1024 }}>
          <video className="w-full" src={dataitem.url} controls={true} />
        </div>
      </ModalLayoutBody>

      <ModalLayoutFooter></ModalLayoutFooter>
    </ModalLayout>
  );
};
