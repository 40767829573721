import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';

const NoJobs = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const { data: datasets, isLoading } = useQuery('GetDatasets', {
    params: { organizationId, projectId },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!datasets) {
    return <div>Something went wrong</div>;
  }

  return (
    <div className="text-center my-6">
      <div className="text-xl font-semibold">
        {datasets.length === 0
          ? 'There are no datasets to create jobs on'
          : 'There are no jobs available'}
      </div>
      <p className="my-2">
        {datasets.length === 0
          ? 'Get started by creating a dataset first'
          : 'Get started by creating a new job'}
      </p>
    </div>
  );
};

export default NoJobs;
