import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Tag } from 'components/common/Tag/Tag';

interface Props {
  rows?: JobDataitemDTO[];
}

export const useJobDataitemsTable = ({ rows }: Props) => {
  const columnHelper = createColumnHelper<JobDataitemDTO>();
  const columns = [
    columnHelper.accessor('dataitemId', {
      header: 'Dataitem Id',
    }),
    columnHelper.accessor((row) => row.dataitem.externalId, {
      id: 'externalId',
      header: 'Name',
    }),
    columnHelper.accessor((row) => row.workflowState.label, {
      id: 'workflowStateLabel',
      header: 'State',
      cell: (data) => {
        return <Tag>{data.getValue()}</Tag>;
      },
    }),
    columnHelper.accessor((row) => (row.jobUser ? (row.jobUser as any).user?.email : 'Unassigned'), {
      id: 'assignee',
      header: 'Assignee',
    }),
  ];

  const instance = useReactTable({
    data: rows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    instance,
  };
};
