export const AnnotationClassPropertyType = {
  STRING: 1,
  NUMBER: 2,
  ENUM: 3,
  JSON: 4,
  BOOLEAN: 5,
};

export const DatasetStatus = {
  EMPTY: 'EMPTY',
  IMPORTING: 'IMPORTING',
  OK: 'OK',
};

export const AssignmentType = {
  ANNOTATION: 1,
  REVIEW: 2,
};

export const AnnotationClassType = {
  DOCUMENT: 1,
  NAMED_ENTITY_RECOGNITION: 2,
  ENTITY_RELATION: 4,

  BOUNDING_BOX: 5,
  POINT: 6,
  POLYGON: 7,
  PIXEL_MASK: 8,
};

export const DatasetType = {
  IMAGE: 1,
  TEXT: 2,
  VIDEO: 3,
};

export const UserStatus = {
  INVITE_PENDING: 0,
  ACTIVE: 1,
};

export const JobType = {
  TEXT_ANNOTATION: 1,
  IMAGE_ANNOTATION: 2,
  VIDEO_ANNOTATION: 3,
};
