import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Typography } from 'components/common/Typography';
import { DatasetCreateModal } from 'components/Modals/DatasetCreateModal/DatasetCreateModal';
import DatasetListing from './DatasetListing/DatasetListing';

const Datasets = () => {
  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Datasets
          </Typography>
        </div>

        <div>
          <Dialog render={() => <DatasetCreateModal />}>
            <Button>Create Dataset</Button>
          </Dialog>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <DatasetListing />
      </LayoutContent>
    </LayoutContentBody>
  );
};

export default Datasets;
