import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';
import { getFormattedDate } from 'utils/dateUtils';

const DatasetListing = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const { pathTo } = useRouterUtils();

  const { data: datasets, isLoading } = useQuery('GetDatasets', {
    params: { organizationId, projectId },
  });

  if (isLoading || !datasets) {
    return null;
  }

  return (
    <div>
      <table className="table-primary">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Type</th>
            <th>Created</th>
            <th>Updated</th>
          </tr>
        </thead>
        <tbody>
          {datasets.map(({ id, name, type, createdAt, updatedAt }, index) => (
            <tr key={id}>
              <td>{index + 1}</td>
              <td>
                <Link to={pathTo('DATASET', { datasetId: id })}> {name}</Link>
              </td>
              <td>{type}</td>
              <td>{getFormattedDate(createdAt)}</td>
              <td>{getFormattedDate(updatedAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DatasetListing;
