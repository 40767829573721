import { Button } from 'components/common/Button/Button';
import { DropdownContext } from 'components/common/Dropdown/Dropdown';
import { Label } from 'components/common/Forms/Label/Label';
import { Select } from 'components/common/Forms/Select/Select';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { BsFilter } from 'react-icons/bs';

export const UNASSIGNED_USER_ID = 0; // @todo

interface Props {
  filters: { state?: WorkflowStateDTO['id'][]; assignee?: JobUserDTO['id'][] };
  onChange: (filters: { [key: string]: any }) => void;
}

export const JobDataitemFilters = ({ filters, onChange }: Props) => {
  const { organizationId, projectId, jobId } = useIntParams();
  const { handleSubmit, control, reset } = useForm({ defaultValues: filters });
  const { data: jobUsers = [] } = useQuery('GetJobUsers', {
    params: { organizationId, projectId, jobId },
  });
  const { data: workflow } = useQuery('GetJobWorkflow', {
    params: { organizationId, projectId, jobId },
  });

  const { close } = useContext(DropdownContext);

  const onSubmit = handleSubmit((data) => {
    onChange(data);
    close();
  });

  const handleClear = () => {
    reset();
    onChange({
      state: undefined,
      assignee: undefined,
    });
  };

  const assigneeOptions = [
    { value: UNASSIGNED_USER_ID, label: 'Unassigned' },
    ...jobUsers.map(({ id, user: { email } }) => ({
      value: id,
      label: `${id} - ${email}`,
    })),
  ];

  const workflowStateOptions = (workflow?.workflowStates || []).map(({ id, label }) => ({
    value: id,
    label,
  }));

  return (
    <div className="p-4" style={{ minWidth: 300 }}>
      <div className="flex items-center gap-2 mb-3 border-b pb-3 text-sm">
        <BsFilter size={18} /> Filters
      </div>
      <form onSubmit={onSubmit}>
        <div className="mb-2 ">
          <Label>State</Label>
          <Select control={control} name="state" options={workflowStateOptions} isMulti />
        </div>

        <div className="mb-2">
          <Label>Assignee</Label>
          <Select control={control} name="assignee" options={assigneeOptions} isMulti />
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button onClick={handleClear} variant="outlined">
            Clear
          </Button>

          <Button type="submit" data-prevent-dropdown-close onClick={(e) => e.target}>
            Apply
          </Button>
        </div>
      </form>
    </div>
  );
};
