import { IsAny, IfMaybeUndefined, IfVoid } from './tsHelpers';

export interface Action<P = void> {
  type: string;
  payload: P;
}

interface BaseActionCreator<P> {
  type: string;
  match: (action: Action<unknown>) => action is Action<P>;
}

export interface ActionCreatorWithOptionalPayload<P>
  extends BaseActionCreator<P> {
  (payload?: P): Action<P>;
}

export interface ActionCreatorWithoutPayload
  extends BaseActionCreator<undefined> {
  (): Action<undefined>;
}

export interface ActionCreatorWithPayload<P> extends BaseActionCreator<P> {
  (payload: P): Action<P>;
}

export type PayloadActionCreator<P = void> = IsAny<
  P,
  ActionCreatorWithPayload<any>,
  IfVoid<
    P,
    ActionCreatorWithoutPayload,
    // else
    IfMaybeUndefined<
      P,
      ActionCreatorWithOptionalPayload<P>,
      // else
      ActionCreatorWithPayload<P>
    >
  >
>;

function createAction<P = void>(type: string): PayloadActionCreator<P>;
function createAction(type: string): any {
  function actionCreator(...args: any[]) {
    return { type, payload: args[0] };
  }

  actionCreator.toString = () => `${type}`;

  actionCreator.type = type;

  actionCreator.match = (action: Action<unknown>): action is Action =>
    action.type === type;

  return actionCreator;
}

export default createAction;
