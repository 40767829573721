import { Path } from './Path';

// @todo DRY
interface Props {
  id?: any;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  points?: number[];
  fillStyle?: string;
  strokeStyle?: string;
  lineWidth?: number;
  zIndex?: number;
  data?: {};
  keyframes?: number[];
}

const defaultProps = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  fillStyle: '#000',
  lineWidth: 0,
};

export const Rect2 = (props: Props) => {
  const { x, y, width, height, points, ...rest } = { ...defaultProps, ...props };

  const path = Path({
    points: points || [x, y, x + width, y, x + width, y + height, x, y + height],
    isClosed: true,
    ...rest,
  });

  return { ...path };
};
