export function State<T>(initialState: T) {
  let state: T = {
    ...initialState,
  };
  let subs: StateSubscriptionCallback<T>[] = [];

  const get: State<T>['get'] = (key) => {
    if (key !== undefined) {
      // @ts-ignore
      return state[key];
    }

    return Object.freeze(state);
  };

  const set: State<T>['set'] = (args) => {
    let props = args;

    if (typeof args === 'function') {
      // @ts-ignore
      props = args(get());
    }

    const filteredProps = Object.fromEntries(
      Object.entries(props).filter(
        ([key, value]) =>
          // @ts-ignore
          state[key] !== value
      )
    );

    if (Object.keys(filteredProps).length !== 0) {
      state = { ...state, ...filteredProps };
      subs.forEach((cb) => cb(state));
    }

    // force dispatch
    if (typeof args === 'object' && Object.keys(args).length === 0) {
      subs.forEach((cb) => cb(state));
    }
  };

  const subscribe: State<T>['subscribe'] = (cb) => {
    subs.push(cb);

    function unsub() {
      subs = subs.filter((e) => e !== cb);
    }

    return unsub;
  };

  return {
    set,
    get,
    props: get,
    subscribe,
  };
}
