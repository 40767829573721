import {
  SelfServiceFlowExpiredError,
  SelfServiceLoginFlow,
  SubmitSelfServiceLoginFlowWithPasswordMethodBody,
} from '@ory/kratos-client';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { oryKratosService } from './oryKratos.service';

export const useLoginFlow = () => {
  const [flow, setFlow] = useState<SelfServiceLoginFlow>();
  const [error, setError] = useState<SelfServiceFlowExpiredError>();
  const queryClient = useQueryClient();
  const loginMutation = useMutation(oryKratosService.login, {
    onSuccess: () => {
      queryClient.invalidateQueries([{ id: 'IsAuthenticated' }]);
    },
  });

  const login = async (data: SubmitSelfServiceLoginFlowWithPasswordMethodBody) => {
    if (!flow) {
      console.log('Flow not initialized');
      return;
    }

    try {
      const response = await loginMutation.mutateAsync({
        flow: flow.id,
        data,
      });

      return response;
    } catch (error: any) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }

      if (error.response?.data?.ui) {
        setFlow(error.response?.data);
      }

      if (
        error.response?.data?.error?.code === 410 &&
        error.response?.data?.error?.id === 'self_service_flow_expired'
      ) {
        setError(error.response.data.error as SelfServiceFlowExpiredError);
      }

      setError(error.response?.data?.error || error.response?.data);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await oryKratosService.initLoginFlow();
      setFlow(res.data);
    })();
  }, []);

  return {
    login,
    flow,
    error,
  };
};
