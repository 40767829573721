import classNames from 'classnames';
import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { RadioGroup } from 'components/common/Forms/CheckboxGroup/RadioGroup';
import { reactSelectStylesDark } from 'config/reactSelectStyles';
import { usePersistedState } from 'hooks/usePersistedState';
import { useAtom } from 'jotai';
import { CgArrowsExchange, CgCheck } from 'react-icons/cg';
import Select from 'react-select';
import { AnnotationClassPropertyType } from 'shared/enums';
import { FormControlKeyframe } from './FormControlKeyframe';

interface Props {
  annotationInstance: AnnotationInstanceDTO;
  annotationClassProperty: AnnotationClassPropertyDTO;
  value: any;
  onChange: (value: any) => void;
}

const ENUM_DISPLAY_MODE = {
  DEBUG_BOTH: 'DEBUG_BOTH',
  LIST: 'LIST',
  DROPDOWN: 'DROPDOWN',
};

export const WorkspaceDataitemAnnotationInstanceFormControlEnum = ({
  annotationInstance,
  annotationClassProperty,
  value,
  onChange,
}: Props) => {
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);
  const [displayMode, setDisplayMode] = usePersistedState(
    ENUM_DISPLAY_MODE.DROPDOWN,
    `input-${annotationClassProperty.id.toString()}-display-mode`
  );
  const toggleDisplayMode = () => {
    setDisplayMode((prev: any) =>
      prev === ENUM_DISPLAY_MODE.LIST ? ENUM_DISPLAY_MODE.DROPDOWN : ENUM_DISPLAY_MODE.LIST
    );
  };
  const options = annotationClassProperty.propertyOptions.map((e) => ({ value: e.key, label: e.value }));
  const selectedOptions = annotationClassProperty.isMultiChoice
    ? options.filter((e) => (value || []).includes(e.value))
    : options.find((e) => e.value === value);

  const setSelectedOption = (option: any) => {
    if (annotationClassProperty.isMultiChoice) {
      const val = option.map((e: any) => e.value);
      onChange(val);
    } else {
      onChange(option ? option.value : '');
    }
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center pb-1">
        <label className="block">{annotationClassProperty.label}</label>
        <button onClick={toggleDisplayMode}>
          <CgArrowsExchange size={20} />
        </button>
      </div>

      {(displayMode === ENUM_DISPLAY_MODE.DROPDOWN || displayMode === ENUM_DISPLAY_MODE.DEBUG_BOTH) && (
        <div className="flex items-center gap-2">
          <Select
            options={options}
            value={selectedOptions}
            isMulti={annotationClassProperty.isMultiChoice}
            className="w-full"
            onChange={setSelectedOption}
            isClearable={false}
            styles={reactSelectStylesDark}
          />

          <FormControlKeyframe
            annotationClassProperty={annotationClassProperty}
            annotationInstance={annotationInstance}
          />
        </div>
      )}

      {(displayMode === ENUM_DISPLAY_MODE.LIST || displayMode === ENUM_DISPLAY_MODE.DEBUG_BOTH) &&
        annotationClassProperty.type === AnnotationClassPropertyType.ENUM &&
        !annotationClassProperty.isMultiChoice && (
          <RadioGroup
            name={annotationClassProperty.id.toString()}
            options={annotationClassProperty.propertyOptions.map((e) => ({ value: e.key, label: e.value }))}
            value={value}
            onChange={onChange}
          />
        )}

      {(displayMode === ENUM_DISPLAY_MODE.LIST || displayMode === ENUM_DISPLAY_MODE.DEBUG_BOTH) &&
        annotationClassProperty.type === AnnotationClassPropertyType.ENUM &&
        annotationClassProperty.isMultiChoice && (
          <div>
            {annotationClassProperty.propertyOptions
              .map((e) => ({ value: e.key, label: e.value }))
              .map(({ value: optionValue, label }) => (
                <div key={label} className="flex gap-2 items-center justify-between">
                  <label className="input-switch">
                    <input
                      type="checkbox"
                      name={annotationClassProperty.id.toString()}
                      value={optionValue}
                      checked={value.includes(optionValue)}
                      onChange={(e) =>
                        onChange(
                          e.target.checked ? [...value, optionValue] : value.filter((e: any) => e !== optionValue)
                        )
                      }
                    />
                    <div className="toggle">{value && <CgCheck />}</div>
                    <span>{label}</span>
                  </label>

                  <button
                    tabIndex={-1}
                    onFocus={(e) => e.target.blur()}
                    // onMouseDown={toggleKeyframe}
                    className={classNames('rotate-45 border border-gray-600', {
                      'border-white bg-white':
                        annotationInstance.properties[annotationClassProperty.key] &&
                        annotationInstance.properties[annotationClassProperty.key][optionValue]?.find(
                          (e: any) => e.frame === currentFrame
                        ) !== undefined,
                    })}
                    style={{
                      width: 8,
                      height: 8,
                    }}
                  ></button>
                </div>
              ))}
          </div>
        )}

      {/* <select
        value={value || ''}
        className="w-full border border-neutral-700 bg-black"
        onChange={(event) => {
          const value = Array.from(event.target.selectedOptions, (option) => option.value);

          onChange(value);
        }}
        multiple={true}
      >
        <option value=""></option>
        {annotationClassProperty.propertyOptions.map((e) => (
          <option value={e.value}>{e.key}</option>
        ))}
      </select> */}
    </div>
  );
};
