import cronstrue from 'cronstrue';

export function toReadableCronExpression(expression: string) {
  try {
    return cronstrue.toString(expression, {
      verbose: true,
      throwExceptionOnParseError: true,
    });
  } catch (e) {
    return null;
  }
}
