import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { partition } from 'lodash';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { swapArrayElements } from 'utils/arrayUtils';
import { JobTasksAnnotationClassPropertyListItem } from './JobTasksAnnotationClassPropertyListItem/JobTasksAnnotationClassPropertyListItem';

interface Props {
  selectedAnnotationClassId: AnnotationClassDTO['id'];
  selectedAnnotationClassPropertyId: AnnotationClassPropertyDTO['id'] | null;
}

export const JobTasksAnnotationClassPropertyList = ({
  selectedAnnotationClassId,
  selectedAnnotationClassPropertyId,
}: Props) => {
  const { organizationId, projectId, jobId } = useIntParams();

  const annotationClassesQuery = useQuery('GetAnnotationClasses', {
    params: { organizationId, projectId, jobId },
  });

  const { mutateAsync: sortAnnotationClassProperties } = useMutation('SortAnnotationClassProperties');

  const configurableAnnotationClassProperties = annotationClassesQuery.data
    ?.find((e) => e.id === selectedAnnotationClassId)
    ?.properties.filter((e) => e.uiType !== -1);

  const [intrinsicAnnotationClassProperties, userDefinedAnnotationClassProperties] = partition(
    configurableAnnotationClassProperties,
    ({ isIntrinsic }) => isIntrinsic
  );

  const onDragEnd = (result: DropResult) => {
    if (result.source && result.destination && configurableAnnotationClassProperties) {
      const annotationClassPropertyIds = swapArrayElements(
        configurableAnnotationClassProperties,
        result.source.index,
        result.destination.index
      ).map(({ id }) => id);

      sortAnnotationClassProperties({
        data: { annotationClassPropertyIds },
        params: {
          organizationId,
          projectId,
          jobId,
          annotationClassId: selectedAnnotationClassId,
        },
      });
    }
  };

  return (
    <div>
      {intrinsicAnnotationClassProperties.length !== 0 && (
        <div className="py-2">
          {intrinsicAnnotationClassProperties?.map((annotationClassProperty) => (
            <JobTasksAnnotationClassPropertyListItem
              key={annotationClassProperty.id}
              annotationClassProperty={annotationClassProperty}
              isSelected={selectedAnnotationClassPropertyId === annotationClassProperty.id}
              isRepositionable={false}
            />
          ))}
        </div>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {userDefinedAnnotationClassProperties?.map((annotationClassProperty, index) => (
                <Draggable
                  key={annotationClassProperty.id}
                  draggableId={annotationClassProperty.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      key={annotationClassProperty.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="border-t border-slate-100 py-2">
                        <JobTasksAnnotationClassPropertyListItem
                          key={annotationClassProperty.id}
                          annotationClassProperty={annotationClassProperty}
                          isSelected={selectedAnnotationClassPropertyId === annotationClassProperty.id}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
