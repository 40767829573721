import { subscribeWithSelector } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

export const StateManager = <T extends object>(initialState: T) => {
  const store = createStore(subscribeWithSelector(() => initialState));

  return {
    ...store,
    get: store.getState,
    set: store.setState,
  };
};
