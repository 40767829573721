import { AppRoutes } from 'appRoutes';
import { AllDatasetImports } from 'components/AllDatasetImports/AllDatasetImports';
import { AllJobExports } from 'components/AllJobExports/AllJobExports';
import { Header } from 'components/Header';
import { ImportModalContainer } from 'components/ImportModalContainer/ImportModalContainer';
import { MyAssignments } from 'components/MyAssignments/MyAssignments';
import { NoOrganizations } from 'components/NoOrganizations/NoOrganizations';
import { Organization } from 'components/Organization/Organization';
import { OrganizationSettings } from 'components/Organization/OrganizationSettings/OrganizationSettings';
import { OrganizationUsers } from 'components/Organization/OrganizationUsers/OrganizationUsers';
import { Organizations } from 'components/Organizations/Organizations';
import { PlansAndBilling } from 'components/PlansAndBilling/PlansAndBilling';
import Datasets from 'components/Project/Datasets';
import { Dataset } from 'components/Project/Datasets/Dataset/Dataset';
import Jobs from 'components/Project/Jobs';
import Job from 'components/Project/Jobs/Job/Job';
import { ProjectDashboard } from 'components/Project/ProjectDashboard/ProjectDashboard';
import { ProjectSettings } from 'components/Project/ProjectSettings/ProjectSettings';
import Workflows from 'components/Project/Workflows';
import WorkflowDetails from 'components/Project/Workflows/WorkflowDetails';
import { Projects } from 'components/Projects/Projects';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

export const AdministrativeViewLayout = () => {
  const location = useLocation<{ from: string }>();
  const organizationsQuery = useQuery('GetOrganizations', {});
  const userPreferenceQuery = useQuery('GetUserPreference', {});
  const { pathTo } = useRouterUtils();

  const defaultOrganizationId = userPreferenceQuery.data?.defaultOrganizationId;
  const defaultProjectId =
    defaultOrganizationId &&
    userPreferenceQuery.data?.organizations &&
    userPreferenceQuery.data.organizations[defaultOrganizationId]?.defaultProjectId;

  return (
    <>
      <div className="flex">
        <div style={{ width: 300 }}>
          <div className="fixed top-0 left-0 bottom-0 flex flex-col" style={{ width: 300 }}>
            <Sidebar />
          </div>
        </div>

        <div className="flex min-h-screen flex-1 flex-col">
          <Header />

          <Switch>
            <Route path={AppRoutes.JOB} component={Job} />
            <Route path={AppRoutes.JOBS} component={Jobs} exact />

            <Route path={AppRoutes.DATASET} component={Dataset} />
            <Route path={AppRoutes.DATASETS} component={Datasets} exact />

            <Route path={AppRoutes.WORKFLOW} component={WorkflowDetails} />
            <Route path={AppRoutes.WORKFLOWS} component={Workflows} exact />

            <Route path={AppRoutes.PROJECT} component={ProjectDashboard} exact />
            <Route path={AppRoutes.PROJECT_SETTINGS} component={ProjectSettings} />

            <Route path={AppRoutes.ORGANIZATION} component={Organization} exact />

            <Route path={AppRoutes.ALL_JOB_EXPORTS} component={AllJobExports} exact />
            <Route path={AppRoutes.ALL_DATASET_IMPORTS} component={AllDatasetImports} exact />

            <Route path={AppRoutes.ORGANIZATION_USERS} component={OrganizationUsers} />

            <Route path={AppRoutes.ORGANIZATION_PLANS_AND_BILLING} component={PlansAndBilling} />

            <Route path={AppRoutes.ORGANIZATION_SETTINGS} component={OrganizationSettings} />

            <Route path={AppRoutes.ORGANIZATIONS} component={Organizations} exact />

            <Route path={AppRoutes.MY_ASSIGNMENTS} component={MyAssignments} exact />

            <Route path={AppRoutes.PROJECTS} component={Projects} exact />

            <Route path="/" exact>
              {organizationsQuery.data && userPreferenceQuery.data && (
                <>
                  {organizationsQuery.data.length === 0 ? (
                    <NoOrganizations />
                  ) : defaultOrganizationId && defaultProjectId ? (
                    <Redirect
                      to={pathTo('PROJECT', {
                        organizationId: defaultOrganizationId,
                        projectId: defaultProjectId,
                      })}
                    />
                  ) : (
                    <Redirect
                      to={pathTo('ORGANIZATION', {
                        organizationId: organizationsQuery.data[0].id,
                      })}
                    />
                  )}
                </>
              )}
            </Route>

            <Route>
              <Redirect to={location.state?.from || '/'} />
            </Route>
          </Switch>
        </div>
      </div>

      <ImportModalContainer />
    </>
  );
};
