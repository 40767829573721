import Spinner from '../Spinner';
import { LayoutContent } from './LayoutContent';
import { LayoutContentBody } from './LayoutContentBody';
import { LayoutContentHeader } from './LayoutContentHeader';

export const LayoutLoadingSkeleton = () => {
  return (
    <LayoutContentBody>
      <LayoutContentHeader></LayoutContentHeader>

      <LayoutContent>
        <Spinner />
      </LayoutContent>
    </LayoutContentBody>
  );
};
