type AppRoute = string;

export interface AppRouteParams {
  HOME: void;
  LOGIN: void;
  REGISTER: void;
  ACCOUNT_RECOVERY: void;
  ORGANIZATIONS: void;
  ORGANIZATION: Partial<{ organizationId: string }>;
  ORGANIZATION_USERS: Partial<{ organizationId: string }>;
  ORGANIZATION_PLANS_AND_BILLING: Partial<{
    organizationId: string;
  }>;
  BILLING_HISTORY: Partial<{
    organizationId: string;
  }>;
  ORGANIZATION_SETTINGS: Partial<{
    organizationId: string;
  }>;
  PROJECTS: Partial<{
    organizationId: string;
  }>;
  PROJECT: Partial<{
    organizationId: string;
    projectId: string;
  }>;
  PROJECT_SETTINGS: Partial<{
    organizationId: string;
    projectId: string;
  }>;
  MY_ASSIGNMENTS: Partial<{ organizationId: string; projectId: string }>;
  ALL_JOB_EXPORTS: Partial<{ organizationId: string; projectId: string }>;
  ALL_DATASET_IMPORTS: Partial<{ organizationId: string; projectId: string }>;

  JOBS: Partial<{ organizationId: string; projectId: string }>;
  JOB: Partial<{
    organizationId: string;
    projectId: string;
    jobId: string;
  }>;
  JOB_TASKS: Partial<{
    organizationId: string;
    projectId: string;
    jobId: string;
    annotationClassId: string;
  }>;
  JOB_TASKS_ANNOTATION_CLASS: Partial<{
    organizationId: string;
    projectId: string;
    jobId: string;
    annotationClassId: string;
  }>;
  JOB_TASKS_ANNOTATION_CLASS_PROPERTY: Partial<{
    organizationId: string;
    projectId: string;
    jobId: string;
    annotationClassId: string;
    annotationClassPropertyId: string;
  }>;
  JOB_USERS: Partial<{
    organizationId: string;
    projectId: string;
    jobId: string;
  }>;
  JOB_EXPORTS: Partial<{
    organizationId: string;
    projectId: string;
    jobId: string;
  }>;
  JOB_SETTINGS: Partial<{
    organizationId: string;
    projectId: string;
    jobId: string;
  }>;
  JOB_DATAITEMS: Partial<{
    organizationId: string;
    projectId: string;
    jobId: string;
  }>;
  JOB_WORKFLOW: Partial<{
    organizationId: string;
    projectId: string;
    jobId: string;
  }>;
  JOB_REPORTS: {
    organizationId: string;
    projectId: string;
    jobId: string;
  };
  DATASETS: Partial<{
    organizationId: string;
    projectId: string;
  }>;
  DATASET: Partial<{
    organizationId: string;
    projectId: string;
    datasetId: string;
  }>;
  DATASET_REMOTE_IMPORT_SOURCES: Partial<{
    organizationId: string;
    projectId: string;
    datasetId: string;
  }>;
  DATASET_IMPORT_HISTORY: Partial<{
    organizationId: string;
    projectId: string;
    datasetId: string;
  }>;
  DATASET_SETTINGS: Partial<{
    organizationId: string;
    projectId: string;
    datasetId: string;
  }>;
  DATAITEMS: Partial<{
    organizationId: string;
    projectId: string;
    datasetId: string;
  }>;
  WORKFLOW: Partial<{
    organizationId: string;
    projectId: string;
    workflowId: string;
  }>;
  WORKFLOWS: Partial<{
    organizationId: string;
    projectId: string;
  }>;
  WORKFLOW_STATE_DIAGRAM: Partial<{
    organizationId: string;
    projectId: string;
    workflowId: string;
  }>;
  WORKFLOW_USER_GROUPS: Partial<{
    organizationId: string;
    projectId: string;
    workflowId: string;
  }>;

  WORKSPACE: Partial<{
    organizationId: string;
    projectId: string;
    jobId: string;
    jobDataitemId: string;
  }>;

  ROUTE_ERROR: void;
}

interface IAppRoutes {
  HOME: AppRoute;

  LOGIN: AppRoute;
  REGISTER: AppRoute;
  ACCOUNT_RECOVERY: AppRoute;
  ORGANIZATIONS: AppRoute;
  ORGANIZATION: AppRoute;
  ORGANIZATION_USERS: AppRoute;
  ORGANIZATION_PLANS_AND_BILLING: AppRoute;
  BILLING_HISTORY: AppRoute;
  ORGANIZATION_SETTINGS: AppRoute;

  PROJECTS: AppRoute;
  PROJECT: AppRoute;
  PROJECT_SETTINGS: AppRoute;

  MY_ASSIGNMENTS: AppRoute;
  ALL_JOB_EXPORTS: AppRoute;
  ALL_DATASET_IMPORTS: AppRoute;

  JOBS: AppRoute;
  JOB: AppRoute;

  JOB_TASKS: AppRoute;
  JOB_TASKS_ANNOTATION_CLASS: AppRoute;
  JOB_TASKS_ANNOTATION_CLASS_PROPERTY: AppRoute;

  JOB_USERS: AppRoute;
  JOB_EXPORTS: AppRoute;
  JOB_DATAITEMS: AppRoute;
  JOB_WORKFLOW: AppRoute;
  JOB_SETTINGS: AppRoute;
  JOB_REPORTS: AppRoute;

  DATASETS: AppRoute;
  DATASET: AppRoute;

  DATASET_REMOTE_IMPORT_SOURCES: AppRoute;
  DATASET_IMPORT_HISTORY: AppRoute;
  DATASET_SETTINGS: AppRoute;

  DATAITEMS: AppRoute;

  WORKFLOW: AppRoute;
  WORKFLOW_STATE_DIAGRAM: AppRoute;
  WORKFLOW_USER_GROUPS: AppRoute;

  WORKFLOWS: AppRoute;

  WORKSPACE: AppRoute;

  ROUTE_ERROR: AppRoute;
}

export const AppRoutes: IAppRoutes = {
  HOME: '/',

  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  ACCOUNT_RECOVERY: '/auth/account-recovery',

  ORGANIZATIONS: '/organizations',
  ORGANIZATION: `/organizations/:organizationId`,
  ORGANIZATION_USERS: '/organizations/:organizationId/users',
  ORGANIZATION_PLANS_AND_BILLING: '/organizations/:organizationId/plans-and-billing',

  BILLING_HISTORY: '/organizations/:organizationId/plans-and-billing/billing-history',

  ORGANIZATION_SETTINGS: '/organizations/:organizationId/settings',

  PROJECTS: '/organizations/:organizationId/projects',
  PROJECT: '/organizations/:organizationId/projects/:projectId',
  PROJECT_SETTINGS: '/organizations/:organizationId/projects/:projectId/settings',

  MY_ASSIGNMENTS: '/organizations/:organizationId/projects/:projectId/my-assignments',

  ALL_JOB_EXPORTS: '/organizations/:organizationId/projects/:projectId/exports',
  ALL_DATASET_IMPORTS: '/organizations/:organizationId/projects/:projectId/imports',

  JOBS: '/organizations/:organizationId/projects/:projectId/jobs',
  JOB: '/organizations/:organizationId/projects/:projectId/jobs/:jobId',

  JOB_TASKS: '/organizations/:organizationId/projects/:projectId/jobs/:jobId/tasks/:annotationClassId?',
  JOB_TASKS_ANNOTATION_CLASS: '/organizations/:organizationId/projects/:projectId/jobs/:jobId/tasks/:annotationClassId',
  JOB_TASKS_ANNOTATION_CLASS_PROPERTY:
    '/organizations/:organizationId/projects/:projectId/jobs/:jobId/tasks/:annotationClassId/property/:annotationClassPropertyId',

  JOB_USERS: '/organizations/:organizationId/projects/:projectId/jobs/:jobId/users',
  JOB_EXPORTS: '/organizations/:organizationId/projects/:projectId/jobs/:jobId/exports',
  JOB_DATAITEMS: '/organizations/:organizationId/projects/:projectId/jobs/:jobId/dataitems',
  JOB_WORKFLOW: '/organizations/:organizationId/projects/:projectId/jobs/:jobId/workflow',
  JOB_SETTINGS: '/organizations/:organizationId/projects/:projectId/jobs/:jobId/settings',
  JOB_REPORTS: '/organizations/:organizationId/projects/:projectId/jobs/:jobId/reports',

  DATASETS: '/organizations/:organizationId/projects/:projectId/datasets/',
  DATASET: '/organizations/:organizationId/projects/:projectId/datasets/:datasetId',

  DATASET_REMOTE_IMPORT_SOURCES: '/organizations/:organizationId/projects/:projectId/datasets/:datasetId/remotes',
  DATASET_IMPORT_HISTORY: '/organizations/:organizationId/projects/:projectId/datasets/:datasetId/import-history',
  DATASET_SETTINGS: '/organizations/:organizationId/projects/:projectId/datasets/:datasetId/settings',
  DATAITEMS: '/organizations/:organizationId/projects/:projectId/datasets/:datasetId/dataitems',

  WORKFLOW: '/organizations/:organizationId/projects/:projectId/workflows/:workflowId',
  WORKFLOWS: '/organizations/:organizationId/projects/:projectId/workflows',
  WORKFLOW_STATE_DIAGRAM: '/organizations/:organizationId/projects/:projectId/workflows/:workflowId/state-diagram',
  WORKFLOW_USER_GROUPS: '/organizations/:organizationId/projects/:projectId/workflows/:workflowId/user-groups',

  WORKSPACE: '/organizations/:organizationId/projects/:projectId/jobs/:jobId/workspace/:jobDataitemId?',

  ROUTE_ERROR: '/route-error',
};
