import { Box } from 'components/common/Box/Box';
import { Tag } from 'components/common/Tag/Tag';
import { useForm } from 'react-hook-form';
import { BsDownload } from 'react-icons/bs';
import { NavLink, Route, Switch } from 'react-router-dom';
import { Button } from '../common/Button/Button';
import { Checkbox } from '../common/Forms/Checkbox/Checkbox';
import { InputSwitch } from '../common/Forms/InputSwitch/InputSwitch';
import { InputText } from '../common/Forms/InputText/InputText';
import { Radio } from '../common/Forms/Radio/Radio';
import { Select } from '../common/Forms/Select/Select';

export const ComponentLibrary = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm();
  const onSubmit = handleSubmit((data) => {
    console.log(data);
  });

  return (
    <div className="flex flex-row">
      <aside className="bg-white h-screen px-4 py-8 flex flex-col" style={{ width: 280 }}>
        <NavLink to={'/lib/button'}>Button</NavLink>
        <NavLink to={'/lib/input'}>Input</NavLink>
        <NavLink to={'/lib/select'}>Select</NavLink>
        <NavLink to={'/lib/checkbox'}>Checkbox</NavLink>
        <NavLink to={'/lib/radio'}>Radio</NavLink>
        <NavLink to={'/lib/switch'}>Switch</NavLink>

        <hr />

        <NavLink to={'/lib/box'}>Box</NavLink>
        <NavLink to={'/lib/tag'}>Tag</NavLink>
      </aside>
      <main className="flex-1 p-8">
        <Switch>
          <Route path={'/lib/button'} exact>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-2">
                <Button label="Button" />
                <Button label="Button" variant={'outlined'} />
                <Button label="Button" variant={'text'} />
              </div>

              <div className="flex flex-row gap-2">
                <Button label="Button" icon={<BsDownload />} />

                <Button label="Button" variant={'outlined'} icon={<BsDownload />} />

                <Button label="Button" variant={'text'} icon={<BsDownload />} />
              </div>

              <div className="flex flex-row gap-2">
                <Button icon={<BsDownload />} />
                <Button icon={<BsDownload />} variant={'outlined'} />
                <Button icon={<BsDownload />} variant={'text'} />
              </div>

              <div className="flex flex-row gap-2">
                <Button label="Button" size={'sm'} />
                <Button label="Button" variant={'outlined'} size={'sm'} />
                <Button label="Button" variant={'text'} size={'sm'} />
              </div>

              <div className="flex flex-row gap-2">
                <Button label="Button" size={'sm'} icon={<BsDownload />} />

                <Button label="Button" variant={'outlined'} size={'sm'} icon={<BsDownload />} />

                <Button label="Button" variant={'text'} size={'sm'} icon={<BsDownload />} />
              </div>

              <div className="flex flex-row gap-2">
                <Button size={'sm'} icon={<BsDownload />} />
                <Button variant={'outlined'} icon={<BsDownload />} size={'sm'} />
                <Button variant={'text'} icon={<BsDownload />} size={'sm'} />
              </div>

              <div className="flex flex-row gap-2">
                <Button label="Button" variant={'link'} />
              </div>

              <div className="flex flex-row gap-2">
                <Button label="Button" disabled />
                <Button label="Button" variant={'outlined'} disabled />
                <Button label="Button" variant={'text'} disabled />
              </div>

              <div className="flex flex-row gap-2">
                <Button label="Button" loading={true} />
                <Button label="Button" variant={'outlined'} loading={true} />
                <Button label="Button" variant={'text'} loading={true} />
              </div>
            </div>
          </Route>

          <Route path={'/lib/input'} exact>
            <div className="flex gap-1 my-4">
              <InputText placeholder="Placeholder" />
              <Button label="Submit" />
            </div>

            <div className="flex gap-1 my-4">
              <InputText placeholder="Placeholder" variant={'sm'} />
              <Button label="Submit" size={'sm'} />
            </div>
          </Route>

          <Route path={'/lib/select'} exact>
            <div className="flex gap-1 my-4">
              <form onSubmit={onSubmit}>
                <Select
                  name="name"
                  control={control}
                  options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' },
                  ]}
                />
                <Button type="submit" label="Submit" />
              </form>
            </div>
          </Route>

          <Route path={'/lib/checkbox'} exact>
            <Checkbox label="Checkbox" />
          </Route>

          <Route path={'/lib/radio'} exact>
            <Radio label="Radio 1" name="radio" />
            <Radio label="Radio 2" name="radio" />
            <Radio label="Radio 3" name="radio" />
          </Route>

          <Route path={'/lib/switch'} exact>
            <InputSwitch />
          </Route>

          <Route path={'/lib/box'} exact>
            <Box>Box Content</Box>
          </Route>

          <Route path={'/lib/tag'} exact>
            <div className="flex gap-2">
              <Tag>Tag</Tag>
              <Tag variant="success">Tag</Tag>
              <Tag variant="error">Tag</Tag>
            </div>
          </Route>
        </Switch>
      </main>
    </div>
  );
};
