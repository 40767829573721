import classNames from 'classnames';
import { KeyboardShortcut } from 'components/Modals/KeyboardShortcutsModal/KeyboardShortcut';
import { annotationInstanceDoAtom } from 'components/Workspace2/WorkspaceDatatitem2';
import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { workspaceState } from 'components/Workspace2/state/workspaceState';
import { useAnnotationsStore } from 'components/Workspace2/useAnnotationsStore';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { AnnotationClassPropertyType } from 'shared/enums';

interface Props {
  annotationInstance: AnnotationInstanceDTO;
  annotationClassProperty: AnnotationClassPropertyDTO;
}
export const FormControlKeyframe = ({ annotationInstance, annotationClassProperty }: Props) => {
  const { update } = useAnnotationsStore();
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);

  const [annotationInstanceDo] = useAtom(annotationInstanceDoAtom);
  const propertyValue = useMemo(
    () => annotationInstanceDo?.getPropertyValueAtKeyframe(annotationClassProperty.key, currentFrame),
    [annotationClassProperty.key, annotationInstanceDo, currentFrame]
  );

  const isKeyframable = annotationInstanceDo?.isKeyframableProperty(annotationClassProperty.key);
  const hasKeyframe = annotationInstanceDo?.doesPropertyHaveKeyframeAt(annotationClassProperty.key, currentFrame);

  const toggleKeyframe = () => {
    const { id } = annotationInstance;
    const { key } = annotationClassProperty;
    let value = hasKeyframe ? null : propertyValue;

    if (annotationClassProperty.type === AnnotationClassPropertyType.BOOLEAN && propertyValue === undefined) {
      value = false;
    }

    update(id, {
      key,
      frame: currentFrame,
      value,
    });
  };

  const [keyboardShortcuts] = useAtom(workspaceState.keyboardShortcutsAtom);
  const keyboardShortcut = useMemo(
    () => (keyboardShortcuts as any)[`${annotationInstance.annotationClass.id}-${annotationClassProperty.id}`],
    [keyboardShortcuts]
  );

  return (
    <div className="flex items-center gap-2 mr-1">
      {keyboardShortcut && (
        <div className="select-none text-neutral-600 mr-4">
          <KeyboardShortcut keyLabel={keyboardShortcut.keyLabel} modifierKeys={keyboardShortcut.modifierKeys} />
        </div>
      )}

      {isKeyframable && (
        <button
          tabIndex={-1}
          onFocus={(e) => e.target.blur()}
          onMouseDown={toggleKeyframe}
          className={classNames('rotate-45 border border-gray-600', {
            'border-white bg-white': hasKeyframe,
          })}
          style={{
            width: 8,
            height: 8,
          }}
        ></button>
      )}
    </div>
  );
};
