import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { GenericResourceRenameModal } from 'components/Modals/GenericResourceRenameModal/GenericResourceRenameModal';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { AiOutlineEdit } from 'react-icons/ai';
import { CgDanger } from 'react-icons/cg';
import { IoMdTrash } from 'react-icons/io';

export const DatasetSettings = () => {
  const { organizationId, projectId, datasetId } = useIntParams();
  const { mutateAsync: updateDataset } = useMutation('UpdateDataset');
  const { mutateAsync: removeDataset } = useMutation('RemoveDataset');
  const datasetQuery = useQuery('GetDatasetById', {
    params: { organizationId, projectId, datasetId },
  });
  const { navigateTo } = useRouterUtils();

  const handleRemoveDataset = async () => {
    await removeDataset({ params: { organizationId, projectId, datasetId } });
    navigateTo('PROJECT');
  };
  return (
    <div>
      <Dialog
        render={() => (
          <GenericResourceRenameModal
            onRename={(name) =>
              updateDataset({
                data: { name },
                params: { organizationId, projectId, datasetId },
              })
            }
            currentName={datasetQuery.data?.name}
          />
        )}
      >
        <Button icon={<AiOutlineEdit />} label="Rename Dataset" variant="text" />
      </Dialog>

      <div>
        <Dialog
          render={() => (
            <ConfirmActionModal onConfirm={handleRemoveDataset}>
              <div className="mt-2 mb-6 flex flex-row items-center gap-4" style={{ maxWidth: 500 }}>
                <CgDanger size={60} />
                <p>
                  Dataset will be <b>permanently</b> deleted
                </p>
              </div>
            </ConfirmActionModal>
          )}
        >
          <Button icon={<IoMdTrash />} label="Delete Dataset" variant="text" />
        </Dialog>
      </div>
    </div>
  );
};
