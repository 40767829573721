import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { Select } from 'components/common/Forms/Select/Select';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowRightShort } from 'react-icons/bs';

export const JobWorkflowReassignModal = () => {
  const { close } = useContext(DialogContext);
  const { organizationId, projectId, jobId } = useIntParams();
  const { data: job } = useQuery('GetJobById', {
    params: { organizationId, projectId, jobId },
  });
  const { data: workflows, isFetched } = useQuery('GetWorkflows', { params: { organizationId, projectId } });
  const { mutateAsync: changeJobWorkflow } = useMutation('ChangeJobWorkflow');

  const { register, handleSubmit, watch, control } = useForm({
    shouldUnregister: true,
    defaultValues: {
      newWorkflowId: '',
      mapOldToNewState: [],
      mapOldToNewUserGroup: [],
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    await changeJobWorkflow({
      data,
      params: { organizationId, projectId, jobId },
    });
  });

  useEffect(() => {
    if (!isFetched) {
      return;
    }
  }, [isFetched]);

  if (!workflows || !job) {
    return null;
  }

  const watchNewWorkflowId = watch('newWorkflowId');
  const currentWorkflow = workflows.find((item) => item.id === job.workflowId);
  const newWorkflow = workflows.find((item) => item.id === +watchNewWorkflowId);

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Change Workflow" />
      <ModalLayoutBody>
        <form onSubmit={onSubmit}>
          <div className="my-4">
            <label htmlFor="new-workflow" className="my-1 inline-block font-semibold">
              New Workflow
            </label>

            <Select
              control={control}
              id="newWorkflowId"
              name="newWorkflowId"
              options={workflows
                .filter((item) => item.id !== job.workflowId)
                .map(({ id: value, name: label }) => ({ value, label }))}
            />
          </div>

          {newWorkflow && (
            <div className="my-4">
              <hr />
              <div className="my-1 font-semibold">Remap workflow states</div>
              <div className="flex flex-col text-xs">
                <div className="flex flex-row items-center justify-between">
                  <div>Current State</div>
                  <div>New State</div>
                </div>

                {currentWorkflow?.workflowStates?.map(({ id, label }, index) => (
                  <div key={id} className="flex flex-row items-center justify-between">
                    <div className="flex-1 rounded border px-4 py-1 text-center">{label}</div>
                    <BsArrowRightShort size={20} />
                    <div className="flex-1">
                      <input
                        type="hidden"
                        // @ts-ignore
                        {...register(`mapOldToNewState.${index}.sourceId`)}
                        value={id}
                      />

                      {/* defaultValue={
                      newWorkflow?.workflowStates?.find(
                        (item) => item.label === label
                      )?.id || ''
                    } */}
                      <Select
                        control={control}
                        id={`mapOldToNewState.${index}.targetId`}
                        name={`mapOldToNewState.${index}.targetId`}
                        options={newWorkflow?.workflowStates?.map(({ id: value, label }) => ({ value, label }))}
                        required
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="my-4">
                <hr />
                <div className="my-1 font-semibold">Remap user groups</div>
                <div className="flex flex-col text-xs">
                  <div className="flex flex-row items-center justify-between">
                    <div>Current Group</div>
                    <div>New Group</div>
                  </div>
                  {currentWorkflow?.workflowUserGroups?.map(({ id, name }, index) => (
                    <div key={id} className="flex flex-row items-center justify-between">
                      <div className="flex-1 rounded border px-4 py-1 text-center">{name}</div>
                      <BsArrowRightShort size={20} />
                      <div className="flex-1">
                        <input
                          type="hidden"
                          {...register(
                            // @ts-ignore
                            `mapOldToNewUserGroup.${index}.sourceId`
                          )}
                          value={id}
                        />

                        {/* defaultValue={
                            newWorkflow?.workflowUserGroups?.find(
                              (item) => item.name === name
                            )?.id || ''
                        } */}
                        <Select
                          control={control}
                          id={`mapOldToNewUserGroup.${index}.targetId`}
                          name={`mapOldToNewUserGroup.${index}.targetId`}
                          options={newWorkflow?.workflowUserGroups?.map(({ id: value, name: label }) => ({
                            value,
                            label,
                          }))}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </form>
      </ModalLayoutBody>
      <ModalLayoutFooter>
        <Button className="w-full" label="Change Workflow" onClick={onSubmit} />
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
