import useIntParams from 'hooks/useIntParams';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdOutlinePeopleAlt, MdOutlineSpaceDashboard, MdPayment } from 'react-icons/md';
import { SidebarMenuItem } from '../shared/SidebarMenuItem/SidebarMenuItem';
import { SidebarProjectsPanel } from './SidebarProjectsPanel/SidebarProjectsPanel';

export const SidebarOrganizationSlide = () => {
  const { organizationId, projectId } = useIntParams();
  const { pathTo } = useRouterUtils();

  return (
    <>
      <div>
        {organizationId && (
          <div className="mt-3 flex flex-grow flex-col overflow-hidden">
            <SidebarMenuItem
              linkTo={pathTo('ORGANIZATION')}
              icon={<MdOutlineSpaceDashboard size={22} />}
              label="Organization Dashboard"
              exact
            />

            <SidebarMenuItem
              linkTo={pathTo('ORGANIZATION_USERS')}
              icon={<MdOutlinePeopleAlt size={22} />}
              label="Users"
              exact
            />

            <SidebarMenuItem
              linkTo={pathTo('ORGANIZATION_PLANS_AND_BILLING')}
              icon={<MdPayment size={22} />}
              label="Plans & Billing"
            />

            <SidebarMenuItem
              linkTo={pathTo('ORGANIZATION_SETTINGS')}
              icon={<IoSettingsOutline size={22} />}
              label="Settings"
              exact
            />
          </div>
        )}
      </div>

      <div className="my-4 border-b"></div>

      {organizationId && !projectId && <SidebarProjectsPanel />}
    </>
  );
};
