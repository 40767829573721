import { Button } from 'components/common/Button/Button';
import { IoMdResize } from 'react-icons/io';

interface Props {
  onViewportFit?: () => void;
}

const StateDiagramControls = ({ onViewportFit = () => {} }: Props) => {
  return (
    <Button onClick={onViewportFit}>
      <IoMdResize size={16} />
    </Button>
  );
};

export default StateDiagramControls;
