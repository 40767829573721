import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { videoEditorState } from './state/videoEditorState';
import { videoTimelineState } from './state/videoTimelineState';
import { videoTimelineConfig, VideoTimelineState } from './WorkspaceDataitem/VideoTimeline';

interface Props {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

export const VideoTimelineFollowCurrentFrameHelper = ({ scrollContainerRef }: Props) => {
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);
  const [followCurrentFrame] = useAtom(videoTimelineState.followCurrentFrameAtom);
  const [xOffset] = useAtom(VideoTimelineState.xOffsetAtom);

  const prevFrameRef = useRef(0);

  const isFrameInView = (frame: number) => {
    const el = scrollContainerRef.current;
    if (!el) {
      return false;
    }

    const val = frame * videoTimelineConfig.FRAME_WIDTH_IN_PX;
    const lowerBound = xOffset;
    const upperBound = xOffset + el.clientWidth;
    return lowerBound < val && val < upperBound;
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container || !followCurrentFrame) {
      return;
    }

    if (!isFrameInView(currentFrame)) {
      let offset = currentFrame * videoTimelineConfig.FRAME_WIDTH_IN_PX;

      if (currentFrame < prevFrameRef.current) {
        offset -= container.clientWidth;
      }

      container.scrollTo({ left: offset });
    }

    prevFrameRef.current = currentFrame;
  }, [followCurrentFrame, currentFrame]);

  return null;
};
