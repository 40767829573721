import useIntParams from 'hooks/useIntParams';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import { useQuery } from 'hooks/useQuery';
import { useSetAtom } from 'jotai';
import { noop } from 'lodash';
import { AnnotationVideoEditor } from 'modules/video-editor-2/AnnotationVideoEditor';
import { useEffect, useRef, useState } from 'react';
import { videoEditorState } from './state/videoEditorState';

export const useWorkspaceVideoEditor = () => {
  const [initialized, setInitialized] = useState(false);
  const { organizationId, projectId, jobId, jobDataitemId } = useIntParams();
  const videoEditorRef = useRef<any>(null);
  const videoEditorViewContainerRef = useRef<HTMLDivElement>(null);
  const jobDataitemQuery = useQuery('GetJobDataitemById', {
    params: { organizationId, projectId, jobId, jobDataitemId },
  });

  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const setIsError = useSetAtom(videoEditorState.isErrorAtom);
  const setCanPlay = useSetAtom(videoEditorState.canPlayAtom);
  const setFrameCount = useSetAtom(videoEditorState.frameCountAtom);
  const setCurrentFrame = useSetAtom(videoEditorState.currentFrameAtom);
  const setIsPlaying = useSetAtom(videoEditorState.isPlayingAtom);
  const setMediaTime = useSetAtom(videoEditorState.mediaTimeAtom);
  const setDuration = useSetAtom(videoEditorState.durationAtom);
  const setIsFrameSyncError = useSetAtom(videoEditorState.isFrameSyncErrorAtom);
  const setLoadedData = useSetAtom(videoEditorState.loadedDataAtom);

  useEffect(() => {
    if (initialized && videoEditorRef.current) {
      return;
    }

    if (!jobDataitemQuery.data) {
      return;
    }

    const container = videoEditorViewContainerRef.current;
    if (!container) {
      return;
    }

    const { url: src, metadata } = jobDataitemQuery.data?.dataitem;

    if (!metadata || typeof metadata !== 'object') {
      setErrorMessage('Error: invalid video metadata');
      return;
    }

    videoEditorRef.current = AnnotationVideoEditor({
      container,
      src,
      // @ts-ignore
      metadata,
    });

    // @todo refactor?
    videoEditorRef.current.video.store.subscribe(
      ({
        canPlay,
        isError,
        isPlaying,
        currentFrame,
        mediaTime,
        duration,
        frameCount,
        isFrameSyncError,
        loadedData,
      }: any) => {
        setIsError(isError);
        setCanPlay(canPlay);
        setFrameCount(frameCount);
        setCurrentFrame(currentFrame);
        setMediaTime(mediaTime);
        setIsPlaying(isPlaying);
        setDuration(duration);
        setIsFrameSyncError(isFrameSyncError);
        setLoadedData(loadedData);
      }
    );

    setInitialized(true);

    return () => {
      // videoEditorRef.current.destroy();
    };
  }, [jobDataitemQuery.data]);

  // const [annotations] = useAtom(annotationsAtom);

  // useEffect(() => {
  //   // Sync with editor
  //   const editor = videoEditorRef.current;

  //   if (!editor) {
  //     return;
  //   }

  //   // @todo refactor
  //   editor.objects.removeAll();
  //   annotations
  //     .filter((e: any) => (e as any).editorObject)
  //     .map((e) => {
  //       console.log((e as any).editorObject);
  //       editor.objects.add((e as any).editorObject);
  //     });
  // }, [annotations]);

  useKeyboardShortcut('ArrowLeft', videoEditorRef.current?.video.seekToPreviousFrame || noop);
  useKeyboardShortcut('ArrowRight', videoEditorRef.current?.video.seekToNextFrame || noop);

  return {
    errorMessage,
    videoEditorViewContainerRef,
    videoEditorRef,
  };
};
