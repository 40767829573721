import { createStore } from 'zustand/vanilla';

// @todo typing
export const SceneObjectsManager = () => {
  const store = createStore<any>(() => ({ items: [] }));

  function add(item: any) {
    store.setState((prev: any) => {
      if (prev.items.find((e: any) => e.id === item.id)) {
        return prev;
      }

      return { items: [...prev.items, item] };
    });
  }

  function remove(id: any) {
    store.setState((prev: any) => ({ items: prev.items.filter((e: any) => e.id !== id) }));
  }

  function removeAll() {
    store.setState(() => ({ items: [] }));
  }

  function removeByExternalId(id: any) {
    store.setState((prev: any) => ({ items: prev.items.filter((e: any) => e?.data('ref')?.id !== id) }));
  }

  function findById(id: string) {
    const { items } = store.getState();
    const item = items.find((e: any) => e.id === id);
    return item;
  }

  function findByDataAttribute(key: string, value: any) {
    const { items } = store.getState();
    const item = items.find((e: any) => e.data(key) === value);
    return item;
  }

  return Object.freeze({
    store,
    add,
    remove,
    removeAll,
    removeByExternalId,
    findById,
    findByDataAttribute,
  });
};
