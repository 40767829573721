import classNames from 'classnames';
import { mapAnnotationClassTypeToDisplayName } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { AiOutlineFile } from 'react-icons/ai';
import { BiLink } from 'react-icons/bi';
import { BsBoundingBox, BsHexagon, BsTextareaT } from 'react-icons/bs';
import { MdArrowForwardIos } from 'react-icons/md';
import { RiBrushLine } from 'react-icons/ri';
import { AnnotationClassType } from 'shared/enums';
import { JobTasksColumnHeader } from '../shared/JobTasksColumnHeader/JobTasksColumnHeader';

export const mapAnnotationClassTypeToDisplayIcon = {
  [AnnotationClassType.DOCUMENT]: <AiOutlineFile />,
  [AnnotationClassType.BOUNDING_BOX]: <BsBoundingBox />,
  [AnnotationClassType.POLYGON]: <BsHexagon />,
  [AnnotationClassType.NAMED_ENTITY_RECOGNITION]: <BsTextareaT />,
  [AnnotationClassType.ENTITY_RELATION]: <BiLink />,
  [AnnotationClassType.PIXEL_MASK]: <RiBrushLine />,
};

export const JobTasksAnnotationClasses = () => {
  const { annotationClassId } = useIntParams();
  const { navigateTo } = useRouterUtils();
  const { organizationId, projectId, jobId } = useIntParams();

  const annotationClassesQuery = useQuery('GetAnnotationClasses', {
    params: { organizationId, projectId, jobId },
  });

  return (
    <div>
      <JobTasksColumnHeader>Task Context</JobTasksColumnHeader>

      <div className="divide-y divide-slate-100">
        {annotationClassesQuery.data?.map(({ id, type, enabled, properties }) => (
          <div key={id} className="py-2">
            <button
              onClick={() => {
                navigateTo('JOB_TASKS_ANNOTATION_CLASS', {
                  annotationClassId: id,
                });
              }}
              className={classNames('group relative flex w-full items-center rounded px-4 py-3 hover:bg-slate-50', {
                'bg-slate-100 text-black hover:bg-slate-100': annotationClassId === id,
              })}
            >
              <div className={classNames('flex flex-row items-center gap-6 text-left')}>
                <div
                  className={classNames(
                    'inline-flex items-center justify-center rounded-full border border-gray-200 text-lg',
                    {
                      'bg-blue-700 text-white opacity-60': enabled,
                      'bg-white opacity-40': !enabled,
                    }
                  )}
                  style={{ width: 40, height: 40 }}
                >
                  {mapAnnotationClassTypeToDisplayIcon[type]}
                </div>
                <div>
                  <div className="text-sm font-medium">{mapAnnotationClassTypeToDisplayName[type]}</div>
                  <div className="mt-1 text-xs text-slate-500">
                    {properties.filter(({ isIntrinsic }) => !isIntrinsic).length} TASKS
                  </div>
                </div>
              </div>
              <MdArrowForwardIos
                className={classNames('ml-auto text-slate-400', {
                  'invisible group-hover:visible': annotationClassId !== id,
                })}
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
