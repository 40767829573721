import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { Radio } from 'components/common/Forms/Radio/Radio';
import { Select } from 'components/common/Forms/Select/Select';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';

export const AddJobUsersModal = () => {
  const { close } = useContext(DialogContext);
  const { organizationId, projectId, jobId } = useIntParams();
  const usersQuery = useQuery('GetOrganizationUsers', { params: { organizationId } });
  const jobWorkflowQuery = useQuery('GetJobWorkflow', {
    params: { organizationId, projectId, jobId },
  });

  const { mutateAsync: addJobUsers } = useMutation('AddJobUsers');

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<{
    userGroupId: number;
    userIds: number[];
  }>();

  const onSubmit = handleSubmit(async (data) => {
    await addJobUsers({ data, params: { organizationId, projectId, jobId } });
    close();
  });

  if (usersQuery.isLoading) {
    return null;
  }

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Add Users" />
      <ModalLayoutBody>
        <form onSubmit={onSubmit}>
          <fieldset className="my-2">
            <legend className="mb-1 inline-block font-medium">User Group</legend>

            {jobWorkflowQuery.data?.workflowUserGroups.map(({ id, name }) => (
              <Radio key={id} {...register('userGroupId')} value={id} label={name} required />
            ))}
          </fieldset>

          <Select
            control={control}
            options={usersQuery.data?.map(({ id: value, email: label }) => ({
              value,
              label,
            }))}
            id="userIds"
            name="userIds"
            isMulti={true}
            required
          />
        </form>
      </ModalLayoutBody>
      <ModalLayoutFooter>
        <Button loading={isSubmitting} onClick={onSubmit}>
          Add Users
        </Button>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
