export const AuthLayout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div className="h-screen flex flex-row">
      <div className="bg-black dark:bg-white flex flex-col justify-center items-center space-y-3.5 w-2/5">
        <div className="flex flex-row gap-2 items-center text-white">
          <div
            className="rounded-sm text-black w-8 h-8 flex items-center justify-center bg-white"
            style={{ fontSize: 36, fontWeight: 400 }}
          >
            <span style={{ marginTop: -3 }}>a</span>
          </div>
          <div className="font-semibold" style={{ fontSize: 32 }}>
            annotorio
          </div>
        </div>
      </div>

      <div className="bg-white dark:bg-annotorio-black flex justify-center items-center flex-1">
        {children}
      </div>
    </div>
  );
};
