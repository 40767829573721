// @todo outlined does not look good
// @todo color variations (gray)
// @todo primary color
// @todo type safety
// @todo icon size should not have any effect on button size
// @todo should accept classes & styles?
// @todo extract text colors & bg colors
// @todo input border radius to match button border radius
// @todo compatibility with child and label?
// @todo don't allow class overrides?

import classNames from 'classnames';
import Spinner from 'components/common/Spinner';
import { forwardRef, PropsWithChildren } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label?: string;
  variant?: 'contained' | 'outlined' | 'text' | 'link' | 'icon-rounded';
  size?: Component.Size; // @todo
  icon?: any; // @todo
  loading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (props: PropsWithChildren<Props>, ref) => {
    const {
      children,
      label = '',
      variant = 'contained',
      size = 'regular',
      type = 'button',
      loading = false,
      icon,
      className,
      ...rest
    } = props;

    const color = 'blue-600';
    const hoverColor = 'blue-400';

    const baseClasses = `btn`;

    const sizeClasses = {
      sm: `
        text-xs
        ${variant !== 'link' ? 'px-5' : 'p-0'}
        ${variant !== 'link' ? 'py-1' : 'p-0'}`,

      regular: `
        text-sm
        ${variant !== 'link' ? 'px-5' : 'p-0'}
        ${variant !== 'link' ? 'py-1.5' : 'p-0'}`,
    };

    const variantClasses = {
      contained: `
        bg-${color}
        border-${color}
        text-slate-50

        hover:bg-${hoverColor}
        `,

      outlined: `
        bg-white
        border-${color}
        text-${color}

        hover:bg-slate-50
        `,

      text: `
        text-${color}
        border-transparent
        hover:underline

        hover:bg-slate-50`,

      link: `
        p-0
        text-${color}
        border-0
        hover:underline`,

      'icon-rounded': `
        bg-white
        rounded-full

        hover:bg-slate-50
        `,
    };

    return (
      // eslint-disable-next-line react/forbid-elements
      <button
        ref={ref}
        type={type}
        className={classNames(
          className,
          baseClasses,
          sizeClasses[size],
          variantClasses[variant]
        )}
        {...rest}
      >
        {/* // @todo - this is unreadable, clean-up */}
        {children ? (
          <span className={classNames({ 'opacity-25': loading })}>
            {children}
          </span>
        ) : (
          <>
            {icon && (
              <span
                className={classNames({
                  'pr-2.5': label,
                  'opacity-25': loading,
                })}
              >
                {icon}
              </span>
            )}
            <span className={classNames({ 'opacity-25': loading })}>
              {label}
            </span>
          </>
        )}

        {loading && (
          <div className="absolute inset-0 flex items-center">
            <Spinner />
          </div>
        )}
      </button>
    );
  }
);
