import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { current } from 'immer';
import { useAtom } from 'jotai';
import { ToolType } from 'modules/image-annotator/enums';
import { useCallback, useContext, useEffect } from 'react';
import { AnnotationClassType } from 'shared/enums';
import { createAnnotationInstance } from 'utils/createAnnotationInstance';
import { videoEditorState } from '../state/videoEditorState';
import { useAnnotationsStore } from '../useAnnotationsStore';
import { VideoEditorContext } from '../VideoEditorContext';

export const useBoundingBoxTool = () => {
  const { organizationId, projectId, jobId } = useIntParams();
  const [activeTool, setActiveTool] = useAtom(videoEditorState.activeToolAtom);
  const annotationStore = useAnnotationsStore(); // @tood we don't want this here
  const { videoEditor } = useContext(VideoEditorContext); // @todo use atom
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);

  const annotationClassQuery = useQuery('GetAnnotationClasses', {
    params: { organizationId, projectId, jobId },
  });

  const createBoundingBox = useCallback(
    ({ x, y }: any) => {
      const annotationClasses = annotationClassQuery.data;
      if (!annotationClasses) {
        return;
      }

      const boundingBoxClass = annotationClasses.find((e) => e.type === AnnotationClassType.BOUNDING_BOX);

      if (!boundingBoxClass) {
        console.log('no type');
        return;
      }

      const width = 0;
      const height = 0;
      const points = [x, y, x + width, y, x + width, y + height, x, y + height];

      const instance = createAnnotationInstance({
        annotationClass: boundingBoxClass,
        data: {
          points: [{ frame: 1, value: points }],
          transform_matrix: [{ frame: currentFrame, value: [1, 0, 0, 1, 0, 0] }],
          is_visible: [{ frame: currentFrame, value: true }],
        },
      });

      return instance;
    },
    [annotationClassQuery.data]
  );

  useEffect(() => {
    if (!videoEditor || activeTool !== ToolType.BOUNDING_BOX) {
      return;
    }

    const { scene } = videoEditor.objects;

    let mouseDownCoords = { x: 0, y: 0 };
    let instance: any = null;
    let isFirstMoveMove = false;

    const handleMouseDown = (event: any) => {
      instance = createBoundingBox({ ...event.coords });

      let { x, y } = event.coords;

      x = Math.max(x, 0);
      y = Math.max(y, 0);

      mouseDownCoords = { x, y };
      isFirstMoveMove = true;
    };

    const handleMouseMove = (event: any) => {
      if (!instance) {
        return;
      }

      let { x, y } = mouseDownCoords;

      const width = event.coords.x - x;
      const height = event.coords.y - y;

      const points = [x, y, x + width, y, x + width, y + height, x, y + height];

      instance.editorObject.set({ points });

      if (isFirstMoveMove) {
        annotationStore.append(instance);
        annotationStore.select(instance.id);
      }

      isFirstMoveMove = false;
    };

    const handleMouseUp = () => {
      annotationStore.update(instance.id, { key: 'points', frame: 1, value: instance.editorObject.get().points });

      instance = null;

      setActiveTool(ToolType.SELECT);
    };

    scene.on('mousedown', handleMouseDown);
    scene.on('mousemove', handleMouseMove);
    scene.on('mouseup', handleMouseUp);

    return () => {
      scene.off('mousedown', handleMouseDown);
      scene.off('mousemove', handleMouseMove);
      scene.off('mouseup', handleMouseUp);
    };
  }, [videoEditor, createBoundingBox, activeTool]);
};
