import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import FormError from 'components/common/FormError';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { Select } from 'components/common/Forms/Select/Select';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  datasetId?: DatasetDTO['id'];
}

export const JobCreateModal = ({ datasetId }: Props) => {
  const { close } = useContext(DialogContext);
  const { organizationId, projectId } = useIntParams();
  const { data: datasets } = useQuery('GetDatasets', {
    params: { organizationId, projectId },
  });
  const { data: workflows } = useQuery('GetWorkflows', { params: { organizationId, projectId } });
  const { mutateAsync: createJob, isError } = useMutation('CreateJob');
  const { navigateTo } = useRouterUtils();

  const {
    register,
    setFocus,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: '',
      workflowId: '',
      datasetId: datasetId || '',
    },
  });

  useEffect(() => {
    // @todo handle this case better (isFormReady <- datasetQuery.isLoading & workflowsQuery.isLoading; disabled from submit if !isFormReady)
    try {
      setFocus('name');
    } catch (e) {}
  }, [setFocus]);

  const onSubmit = handleSubmit(async ({ name, workflowId, datasetId }) => {
    const job = await createJob({
      data: {
        name,
        workflowId: +workflowId,
        datasetId: +datasetId,
      },
      params: { organizationId, projectId },
    });

    // @todo handle errors

    navigateTo('JOB', { jobId: job.id });
    close();
  });

  if (!datasets || !workflows) {
    return <div>Loading...</div>;
  }

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Create Job" />
      <ModalLayoutBody>
        <form onSubmit={onSubmit}>
          {isError && <FormError message="Something went wrong" />}

          <div className="my-2">
            <Label htmlFor={'name'}>Job Name</Label>
            <InputText {...register('name')} id={'name'} required />
          </div>

          <div className="my-2">
            <label htmlFor="datasetId">Dataset</label>

            <Select
              control={control}
              options={datasets.map(({ id: value, name: label }) => ({
                value,
                label,
              }))}
              id="datasetId"
              name="datasetId"
              disabled={!!datasetId}
              required
            />
          </div>

          <div className="my-2">
            <label htmlFor="workflowId">Workflow</label>

            <Select
              control={control}
              options={workflows.map(({ id: value, name: label }) => ({
                value,
                label,
              }))}
              id="workflowId"
              name="workflowId"
              required
            />
          </div>
        </form>
      </ModalLayoutBody>
      <ModalLayoutFooter>
        <Button label={'Create'} loading={isSubmitting} onClick={onSubmit} />
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
