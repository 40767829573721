import { Dialog } from 'components/common/Dialog/Dialog';
import { ImportModal } from 'components/Modals/ImportModal/ImportModal';
import { useOnMutation } from 'hooks/useOnMutation';
import { useCallback, useState } from 'react';

export const ImportModalContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datasetImportActionId, setDatasetImportActionId] = useState<DatasetImportHistoryEntryDTO['id'] | null>(null);

  useOnMutation(
    'TriggerDatasetRemoteStorageImport',
    useCallback((mutation: any) => {
      if (mutation.state.data?.datasetImportActionId) {
        setDatasetImportActionId(mutation.state.data.datasetImportActionId);
        setIsModalOpen(true);
      }
    }, [])
  );

  return (
    <>
      {datasetImportActionId && (
        <Dialog
          render={() => <ImportModal datasetImportActionId={datasetImportActionId} />}
          isOpen={isModalOpen}
          onOpenChange={setIsModalOpen}
        />
      )}
    </>
  );
};
