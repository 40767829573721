export const RenderLoop = (onRenderCallback: () => void) => {
  let reqAnimFrameId = -1;

  function destroy() {
    cancelAnimationFrame(reqAnimFrameId);
  }

  function _renderLoop() {
    onRenderCallback();
    reqAnimFrameId = requestAnimationFrame(_renderLoop);
  }

  function run() {
    reqAnimFrameId = requestAnimationFrame(_renderLoop);
  }

  return Object.freeze({
    run,
    destroy,
  });
};
