import { GroupBase, StylesConfig } from 'react-select';

const colors = {
  'black-100': 'hsl(0, 0%, 5%)',
  'black-90': 'hsl(0, 0%, 10%)',
  'black-80': 'hsl(0, 0%, 20%)',
  'black-70': 'hsl(0, 0%, 30%)',
  'black-60': 'hsl(0, 0%, 40%)',
  'black-50': 'hsl(0, 0%, 50%)',
  'black-40': 'hsl(0, 0%, 60%)',
  'black-30': 'hsl(0, 0%, 70%)',
  'black-20': 'hsl(0, 0%, 80%)',
  'black-10': 'hsl(0, 0%, 90%)',
};

export const reactSelectStylesDark: StylesConfig<
  {
    value: string;
    label: string;
  },
  boolean,
  GroupBase<{
    value: string;
    label: string;
  }>
> = {
  // clearIndicator: (baseStyles, state) => {
  //   console.log('clearIndicator', baseStyles);
  //   return baseStyles;
  // },
  // container: (baseStyles, state) => ({
  //   ...baseStyles,
  //   label: 'container',
  //   position: 'relative',
  //   boxSizing: 'border-box',
  // }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: colors['black-100'],
    borderColor: colors['black-80'],

    // label: 'control',
    // alignItems: 'center',
    // borderRadius: 4,
    // borderStyle: 'solid',
    // borderWidth: 1,
    // cursor: 'default',
    // display: 'flex',
    // flexWrap: 'wrap',
    // justifyContent: 'space-between',
    // minHeight: 38,
    // outline: '0 !important',
    // position: 'relative',
    // transition: 'all 100ms',
    '&:hover': {
      borderColor: colors['black-70'],
    },
    // boxSizing: 'border-box',
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    color: colors['black-80'],
    // label: 'indicatorContainer',
    // display: 'flex',
    // padding: 8,
    // transition: 'color 150ms',
    // ':hover': {
    //   color: colors['black-60'],
    // },
    // boxSizing: 'border-box',
  }),
  group: (baseStyles, state) => {
    console.log('group', baseStyles);
    return baseStyles;
  },
  groupHeading: (baseStyles, state) => {
    console.log('groupHeading', baseStyles);
    return baseStyles;
  },
  // indicatorsContainer: (baseStyles, state) => ({
  //   ...baseStyles,
  //   alignItems: 'center',
  //   alignSelf: 'stretch',
  //   display: 'flex',
  //   flexShrink: 0,
  //   boxSizing: 'border-box',
  // }),
  indicatorSeparator: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: colors['black-80'],
    // label: 'indicatorSeparator',
    // alignSelf: 'stretch',
    // marginBottom: 8,
    // marginTop: 8,
    // width: 1,
    // boxSizing: 'border-box',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    color: colors['black-80'],
    // margin: 2,
    // paddingBottom: 2,
    // paddingTop: 2,
    // visibility: 'visible',
    // transform: '',
    // flex: '1 1 auto',
    // display: 'inline-grid',
    // gridArea: '1 / 1 / 2 / 3',
    // gridTemplateColumns: '0 min-content',
    // '&:after': {
    //   content: 'attr(data-value) " "',
    //   visibility: 'hidden',
    //   whiteSpace: 'pre',
    //   gridArea: '1 / 2',
    //   font: 'inherit',
    //   minWidth: '2px',
    //   border: 0,
    //   margin: 0,
    //   outline: 0,
    //   padding: 0,
    // },
    // boxSizing: 'border-box',
  }),
  loadingIndicator: (baseStyles, state) => {
    console.log('loadingIndicator', baseStyles);
    return baseStyles;
  },
  loadingMessage: (baseStyles, state) => {
    console.log('loadingMessage', baseStyles);
    return baseStyles;
  },
  menu: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: colors['black-100'],
    // label: 'menu',
    // top: '100%',
    // borderRadius: 4,
    boxShadow: '0 0 0 1px hsla(0, 0%, 100%, 0.1), 0 4px 11px hsla(0, 0%, 10%, 0.1)',
    // marginBottom: 8,
    // marginTop: 8,
    // position: 'absolute',
    // width: '100%',
    // zIndex: 1,
    // boxSizing: 'border-box',
  }),
  // menuList: (baseStyles, state) => ({
  //   ...baseStyles,
  //   maxHeight: 300,
  //   overflowY: 'auto',
  //   paddingBottom: 4,
  //   paddingTop: 4,
  //   position: 'relative',
  //   WebkitOverflowScrolling: 'touch',
  //   boxSizing: 'border-box',
  // }),
  menuPortal: (baseStyles, state) => {
    console.log('menuPortal', baseStyles);
    return baseStyles;
  },
  multiValue: (baseStyles, state) => {
    return {
      ...baseStyles,
      backgroundColor: colors['black-80'],
      // label: 'multiValue',
      // borderRadius: 2,
      // display: 'flex',
      // margin: 2,
      // minWidth: 0,
      // boxSizing: 'border-box',
    };
  },
  multiValueLabel: (baseStyles, state) => {
    return {
      ...baseStyles,
      color: colors['black-10'],
      // borderRadius: 2,
      // fontSize: '85%',
      // overflow: 'hidden',
      // padding: 3,
      // paddingLeft: 6,
      // textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap',
      // boxSizing: 'border-box',
    };
  },
  // multiValueRemove: (baseStyles, state) => {
  //   return {
  //     ...baseStyles,
  //     alignItems: 'center',
  //     borderRadius: 2,
  //     display: 'flex',
  //     paddingLeft: 4,
  //     paddingRight: 4,
  //     ':hover': {
  //       backgroundColor: '#FFBDAD',
  //       color: '#DE350B',
  //     },
  //     boxSizing: 'border-box',
  //   };
  // },
  // noOptionsMessage: (baseStyles, state) => {
  //   return {
  //     ...baseStyles,
  //     color: 'hsl(0, 0%, 60%)',
  //     // padding: '8px 12px',
  //     // textAlign: 'center',
  //     // boxSizing: 'border-box',
  //   };
  // },
  option: (baseStyles, state) => ({
    ...baseStyles,
    '&:hover': {
      background: colors['black-90'],
    },

    // label: 'option',
    // backgroundColor: 'transparent',
    // color: 'inherit',
    // cursor: 'default',
    // display: 'block',
    // fontSize: 'inherit',
    // padding: '8px 12px',
    // width: '100%',
    // userSelect: 'none',
    // WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    // ':active': {
    //   backgroundColor: '#B2D4FF',
    // },
    // boxSizing: 'border-box',
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: colors['black-50'],
    // label: 'placeholder',
    // marginLeft: 2,
    // marginRight: 2,
  }),
  singleValue: (baseStyles, state) => {
    console.log('singleValue', baseStyles);
    return baseStyles;
  },
  // valueContainer: (baseStyles, state) => ({
  //   ...baseStyles,
  //   alignItems: 'center',
  //   display: 'grid',
  //   flex: 1,
  //   flexWrap: 'wrap',
  //   padding: '2px 8px',
  //   WebkitOverflowScrolling: 'touch',
  //   position: 'relative',
  //   overflow: 'hidden',
  //   boxSizing: 'border-box',
  // }),
};
