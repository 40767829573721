// TODO DRY
export const ErrorType = {
  GENERIC: 'GENERIC',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  UPLOAD_FILE_MISSING: 'UPLOAD_FILE_MISSING',
  EMAIL_ALREADY_REGISTERED: 'EMAIL_ALREADY_REGISTERED',
  REMOTE_CONNECTION_REFUSED: 'REMOTE_CONNECTION_REFUSED',
};

export const mapErrorTypeToMessage = {
  [ErrorType.GENERIC]: 'Something went wrong',
  [ErrorType.INVALID_CREDENTIALS]: 'Invalid email or password',
  [ErrorType.UPLOAD_FILE_MISSING]: 'Upload failed',
  [ErrorType.EMAIL_ALREADY_REGISTERED]: 'Email address is already registerd',
  [ErrorType.REMOTE_CONNECTION_REFUSED]: 'Remote connection refused',
};
