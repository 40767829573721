import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Table } from 'components/common/Table/Table';
import { Typography } from 'components/common/Typography';
import { useDatasetImportsTable } from 'hooks/react-table/useDatasetImportsTable';
import useIntParams from 'hooks/useIntParams';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';

export const AllDatasetImports = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const datasetImportHistoriesQuery = usePaginatedQuery('GetDatasetImportHistoriesWithPagination', {
    params: { organizationId, projectId },
  });

  const { instance } = useDatasetImportsTable({ rows: datasetImportHistoriesQuery.data?.results });

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Dataset Imports
          </Typography>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <Table instance={instance} pagination={datasetImportHistoriesQuery.pagination} />
      </LayoutContent>
    </LayoutContentBody>
  );
};
