import { AnnotationClassPropertyType, AnnotationClassType, AssignmentType, JobType } from 'shared/enums';

export const EntityClassTypes = {
  DATA_ITEM: 'DATA_ITEM',
  TEXT_SELECTION: 'TEXT_SELECTION',
  IMAGE_BOUNDING_BOX: 'IMAGE_BOUNDING_BOX',
  IMAGE_POINT: 'IMAGE_POINT',
  IMAGE_POLYGON: 'IMAGE_POLYGON',
  IMAGE_POLYLINE: 'IMAGE_POLYLINE',
  IMAGE_PIXEL_MASK: 'IMAGE_PIXEL_MASK',
};

export const WorkspaceMode = {
  EDITOR: 1,
  REVIEW: 2,
};

export const mapWorkspaceModeToAssignmentType = {
  [AssignmentType.ANNOTATION]: WorkspaceMode.EDITOR,
  [AssignmentType.REVIEW]: WorkspaceMode.REVIEW,
};

export const mapWorkspaceModeToDisplayName = {
  [WorkspaceMode.EDITOR]: 'Editor',
  [WorkspaceMode.REVIEW]: 'Review',
};

export const mapPropertyTypeToDisplayName: any = {
  1: 'Free-form input',
  2: 'Regression',
  3: 'Classification',
};

export const mapAnnotationClassTypeToDisplayName: any = {
  [AnnotationClassType.DOCUMENT]: 'Dataitem',
  [AnnotationClassType.NAMED_ENTITY_RECOGNITION]: 'Named Entity Recognition',
  [AnnotationClassType.ENTITY_RELATION]: 'Relation',
  [AnnotationClassType.BOUNDING_BOX]: 'Bounding Box',
  [AnnotationClassType.POINT]: 'Point',
  [AnnotationClassType.POLYGON]: 'Polygon',
  [AnnotationClassType.PIXEL_MASK]: 'Segmentation',
};

export const mapAnnotationClassTypeToJobTaskDisplayName: any = {
  [AnnotationClassType.DOCUMENT]: 'Dataitem',
  [AnnotationClassType.NAMED_ENTITY_RECOGNITION]: 'Named Entity Recognition',
  [AnnotationClassType.ENTITY_RELATION]: 'Relation',
  [AnnotationClassType.BOUNDING_BOX]: 'Bounding Box',
  [AnnotationClassType.POINT]: 'Point',
  [AnnotationClassType.POLYGON]: 'Polygon',
  [AnnotationClassType.PIXEL_MASK]: 'Segmentation',
};

export const globalAnnotationClassTypes: any = [AnnotationClassType.DOCUMENT];

export const ImageAeSupportedAnnotationClasses = [
  AnnotationClassType.BOUNDING_BOX,
  AnnotationClassType.POLYGON,
  AnnotationClassType.PIXEL_MASK,
  AnnotationClassType.POINT,
];

export const mapEntityClassTypeToStageTool = {
  // TODO Refactoring required
  [EntityClassTypes.IMAGE_BOUNDING_BOX]: 'BOX',
  [EntityClassTypes.IMAGE_POINT]: 'POINT',
  [EntityClassTypes.IMAGE_POLYGON]: 'POLYGON',
  [EntityClassTypes.IMAGE_POLYLINE]: 'POLYLINE',
  [EntityClassTypes.IMAGE_PIXEL_MASK]: 'BRUSH',
};

export const AnnotationInstanceState = {
  UP_TO_DATE: 'UP_TO_DATE',
  TO_CREATE: 'TO_CREATE',
  TO_UPDATE: 'TO_UPDATE',
  TO_REMOVE: 'TO_REMOVE',
};

export const JobStatusEnum = {
  INITIAL: 1,
  RUNNING: 2,
};

export const AnnotationClassPropertyUiType = {
  DROPDOWN: 1,
  LIST: 2,
  NUMBER_INPUT: 3,
  NUMBER_SLIDER: 4,
  SINGLE_LINE_TEXT_INPUT: 5,
  MULTI_LINE_TEXT_INPUT: 6,
};

export const mapAnnotationClassPropertyTypeToUiTypes = {
  [AnnotationClassPropertyType.ENUM]: [AnnotationClassPropertyUiType.DROPDOWN, AnnotationClassPropertyUiType.LIST],
  [AnnotationClassPropertyType.NUMBER]: [
    AnnotationClassPropertyUiType.NUMBER_INPUT,
    AnnotationClassPropertyUiType.NUMBER_SLIDER,
  ],
  [AnnotationClassPropertyType.STRING]: [
    AnnotationClassPropertyUiType.SINGLE_LINE_TEXT_INPUT,
    AnnotationClassPropertyUiType.MULTI_LINE_TEXT_INPUT,
  ],
};

export const mapAnnotationClassPropertyTypeToDisplayName = {
  [AnnotationClassPropertyType.BOOLEAN]: 'Binary Classification',
  [AnnotationClassPropertyType.ENUM]: 'Multiclass Classification',
  [AnnotationClassPropertyType.NUMBER]: 'Numeric Input',
  [AnnotationClassPropertyType.STRING]: 'Free-form Input',
};

export const mapAnnotationClassPropertyUiTypeToDisplayName = {
  [AnnotationClassPropertyUiType.DROPDOWN]: 'Dropdown',
  [AnnotationClassPropertyUiType.LIST]: 'List',
  [AnnotationClassPropertyUiType.NUMBER_INPUT]: 'Input',
  [AnnotationClassPropertyUiType.NUMBER_SLIDER]: 'Slider',
  [AnnotationClassPropertyUiType.SINGLE_LINE_TEXT_INPUT]: 'Single-line',
  [AnnotationClassPropertyUiType.MULTI_LINE_TEXT_INPUT]: 'Multi-Line',
};

export const JobExportStatus = {
  SCHEDULED: -1,
  INITILIAZED: 0,
  RUNNING: 1,
  COMPLETED: 2,
  FAILED: 3,
};

export const mapJobExportStatusToDisplayName = {
  [JobExportStatus.SCHEDULED]: 'Scheduled',
  [JobExportStatus.INITILIAZED]: 'Initialized',
  [JobExportStatus.RUNNING]: 'Exporting',
  [JobExportStatus.COMPLETED]: 'Completed',
  [JobExportStatus.FAILED]: 'Failed',
};

export const JobExportType = {
  INTEGRAL: 1,
  INCREMENTAL: 2,
};

export const mapJobExportTypeToDisplayName = {
  [JobExportType.INTEGRAL]: 'Archive',
  [JobExportType.INCREMENTAL]: 'Flatfile',
};

export const WorkflowTransitionType = {
  MANUAL: 0,
  AUTO: 1,
};

export const mapWorkflowTransitionTypeToDisplayName = {
  [WorkflowTransitionType.MANUAL]: 'Manual',
  [WorkflowTransitionType.AUTO]: 'Auto',
};

export const UserStatus = {
  INVITE_PENDING: 0,
  ACTIVE: 1,
};

export const mapUserStatusToDisplayName = {
  [UserStatus.INVITE_PENDING]: 'Invite pending',
  [UserStatus.ACTIVE]: 'Active',
};

export const SaveStatus = {
  SAVED: 'SAVED',
  UNSAVED: 'UNSAVED',
  PENDING: 'PENDING',
};

export const DatasetImportHistoryStatus = {
  INITIALIZED: 0,
  RUNNING: 1,
  COMPLETED: 2,
  FAILED: 3,
};

export const mapDatasetImportHistoryStatusToDisplayName = {
  [DatasetImportHistoryStatus.INITIALIZED]: 'Pending',
  [DatasetImportHistoryStatus.RUNNING]: 'Importing',
  [DatasetImportHistoryStatus.COMPLETED]: 'Completed',
  [DatasetImportHistoryStatus.FAILED]: 'Failed',
};

export const JobExportFormat = {
  [JobType.IMAGE_ANNOTATION]: {
    DEFAULT: 1,
  },
};

export const mapJobExportFormatToDisplayName = {
  [JobExportFormat[JobType.IMAGE_ANNOTATION].DEFAULT]: 'Default',
};

export const WorkflowStateType = {
  INITIAL: -1,
  INTERMEDIATE: 0,
  FINAL: 1,
};

export const mapWorkflowStateTypeToDisplayName = {
  [WorkflowStateType.INITIAL]: 'Initial',
  [WorkflowStateType.INTERMEDIATE]: 'Intermediate',
  [WorkflowStateType.FINAL]: 'Final',
};

export const ApiCallStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
} as const;
export type ApiCallStatus = typeof ApiCallStatus[keyof typeof ApiCallStatus];

const CustomPaddleSubscriptionStatus = {
  PENDING: 'pending',
};

export const PaddleSubscriptionStatus = {
  ACTIVE: 'active',
  TRIALING: 'trialing',
  PAST_DUE: 'past_due',
  PAUSED: 'paused',
  DELETED: 'deleted',
  ...CustomPaddleSubscriptionStatus,
};

export const DefaultUserRoles = {
  OWNER: 1,
  MANAGER: 2,
  WORKER: 3,
} as const;
export type DefaultUserRoles = typeof DefaultUserRoles[keyof typeof DefaultUserRoles];
