import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
  variant?: 'default' | 'error' | 'success';
}

export const Tag = ({ children, variant = 'default' }: PropsWithChildren<Props>) => {
  const variantClassNames = {
    default: ' text-slate-600 border-slate-200',
    success: 'text-green-600 border-green-200',
    error: 'text-pink-600 border-pink-200',
  };

  return (
    <div
      className={classNames(
        'bg-white border rounded text-xxs px-1.5 py-0.5 inline-flex items-center gap-2 whitespace-nowrap',
        variantClassNames[variant]
      )}
    >
      {children}
    </div>
  );
};
