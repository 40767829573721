import { SubmitSelfServiceRegistrationFlowWithPasswordMethodBody } from '@ory/kratos-client';
import { FormMessage } from 'components/common/FormMessage/FormMessage';
import { OryFormUi } from 'components/common/OryFormUi/OryFormUi';
import { Typography } from 'components/common/Typography';
import { useRegisterFlow } from 'hooks/auth/useRegisterFlow';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useSearchParam } from 'hooks/useSearchParam';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

export const Register = () => {
  const token = useSearchParam('token') || '';
  const { data: user, isLoading } = useQuery('GetUserByInviteToken', {
    params: { token },
  });
  const { pathTo } = useRouterUtils();
  const { register, flow } = useRegisterFlow();
  const form = useForm<any>();

  const { handleSubmit } = form;

  const onSubmit: SubmitHandler<
    SubmitSelfServiceRegistrationFlowWithPasswordMethodBody
  > = async (data) => {
    return register(data);
  };

  useEffect(() => {
    if (user?.email) {
      form.setValue('traits.email', user.email);
    }
  }, [user, form]);

  if (isLoading || !flow) {
    return null;
  }

  return (
    <div className="max-w-xs w-full">
      <Typography component="h1" className="my-6 text-center">
        Sign Up
      </Typography>

      <div>
        {(flow.ui.messages || []).map((message) => (
          <FormMessage
            key={message.id}
            text={message.text}
            type={message.type}
          />
        ))}
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <OryFormUi ui={flow.ui} />
          </form>
        </FormProvider>
        <div className="my-4 text-sm">
          By clicking "Sign Up", I agree to Annotorio's <Link to={''}>TOS</Link>{' '}
          and <Link to={''}>Privacy Policy</Link>.
        </div>
        <div className="my-4 text-sm">
          Already have an account? <Link to={pathTo('LOGIN')}>Log in</Link>
        </div>
      </div>
    </div>
  );
};
