import { Box } from 'components/common/Box/Box';
import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { ConfigureInboundRemoteStorageModal } from 'components/Modals/ConfigureInboundRemoteStorageModal/ConfigureInboundRemoteStorageModal';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { DatasetImportSchedulerModal } from 'components/Modals/DatasetImportSchedulerModal/DatasetImportSchedulerModal';
import dayjs from 'dayjs';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { AiOutlineEdit } from 'react-icons/ai';
import { BsClock, BsPlayFill } from 'react-icons/bs';
import { IoTrashBin } from 'react-icons/io5';
import { toReadableCronExpression } from 'utils/toReadableCron';

interface Props {
  remoteStorageResource: DatasetRemoteStorageResourceDTO;
}

const DatasetRemoteStorageItem = ({ remoteStorageResource }: Props) => {
  const { organizationId, projectId, datasetId } = useIntParams<RouteParams.Dataset>();
  const { mutateAsync: triggerImport } = useMutation('TriggerDatasetRemoteStorageImport');
  const { mutateAsync: removeDatasetRemoteImportSource } = useMutation('RemoveDatasetRemoteImportSource');
  const { id, endpointUrl, bucketName, bucketPrefix, fileRegex, syncDelete, cron, nextRunTime } = remoteStorageResource;

  const isImporting = false; // @todo
  const isScheduledImportActive = cron !== null;

  return (
    <Box className="my-4">
      <div className="flex flex-row items-start justify-between">
        <div>
          <div className="text-sm font-medium">{endpointUrl}</div>
          <div className="mt-1 text-sm text-slate-600">
            {bucketName}/{bucketPrefix}
          </div>

          <div className="mt-1 text-sm text-slate-600">
            {syncDelete && <div>Sync Delete: Enabled</div>}
            {fileRegex && <div>File Regex: {fileRegex}</div>}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Button
            size="sm"
            variant="outlined"
            icon={<BsPlayFill />}
            onClick={() =>
              triggerImport({
                params: {
                  organizationId,
                  projectId,
                  datasetId,
                  remoteStorageResourceId: remoteStorageResource.id,
                },
              })
            }
            loading={isImporting}
            label="Run Import"
          />

          <Dialog render={() => <DatasetImportSchedulerModal remoteStorageResource={remoteStorageResource} />}>
            <Button
              size="sm"
              variant="outlined"
              icon={<BsClock />}
              label={isScheduledImportActive ? 'Update Schedule' : 'Configure Scheduled Imports'}
              loading={isImporting}
            />
          </Dialog>

          <Dialog render={() => <ConfigureInboundRemoteStorageModal remoteStorageResource={remoteStorageResource} />}>
            <Button size="sm" variant="outlined" icon={<AiOutlineEdit />} label="Edit" loading={isImporting} />
          </Dialog>

          <Dialog
            render={() => (
              <ConfirmActionModal
                onConfirm={() =>
                  removeDatasetRemoteImportSource({
                    params: {
                      organizationId,
                      projectId,
                      datasetId,
                      remoteStorageResourceId: id,
                    },
                  })
                }
              />
            )}
          >
            <Button size="sm" variant="outlined" icon={<IoTrashBin />} label="Remove" loading={isImporting} />
          </Dialog>
        </div>
      </div>

      {isScheduledImportActive && (
        <div className="mt-2 border-t pt-2 text-sm">
          <div className="mb-1 flex items-center gap-1">
            <BsClock /> Scheduled imports are active
          </div>
          <div>
            Trigger: <span className="text-sm text-slate-600">{toReadableCronExpression(cron)}</span>
          </div>
          {nextRunTime && (
            <div>
              Next Run Time:{' '}
              <span className="text-sm text-slate-600">
                {dayjs.unix(nextRunTime).format('MMM DD YYYY, HH:mm UTC ZZ')}
              </span>
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export default DatasetRemoteStorageItem;
