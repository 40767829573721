import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { useState } from 'react';

export const VIDEO_PLAYBACK_RATES = [0.1, 0.25, 0.5, 1, 1.5, 1.75, 2];

interface Props {
  videoEditorRef: any;
}

export const VideoPlaybackRate = ({ videoEditorRef }: Props) => {
  const [playbackRate, setPlaybackRate] = useState(1);

  if (!videoEditorRef.current) {
    return null;
  }

  // @todo
  const updatePlaybackRate = (val: number) => {
    videoEditorRef.current.video.setPlaybackRate(val);
    setPlaybackRate(val);
  };

  return (
    <div className="text-xxs flex items-center gap-1 whitespace-nowrap">
      <div className="jusitfy-between mr-1 flex">
        <div>Speed</div>
      </div>

      <div>
        <button
          tabIndex={-1}
          onFocus={(e) => e.target.blur()}
          onMouseDown={() => {
            const val = (VIDEO_PLAYBACK_RATES as any).findLast((e: any) => e < playbackRate);
            if (val !== undefined) {
              updatePlaybackRate(val);
            }
          }}
          className="hover-border-neutral-800 w-5 border border-neutral-800 text-center text-gray-300 hover:text-gray-300"
        >
          -
        </button>
        <Dropdown
          render={() => (
            <div className="text-xxs text-gray-400">
              {VIDEO_PLAYBACK_RATES.map((e) => (
                <button
                  className="block w-full px-4 py-1 text-left hover:bg-neutral-700"
                  onMouseDown={() => updatePlaybackRate(e)}
                >
                  {e}x
                </button>
              ))}
            </div>
          )}
        >
          <button
            tabIndex={-1}
            onFocus={(e) => e.target.blur()}
            className="hover-border-neutral-800 w-10 border-t border-b border-neutral-800 text-center text-gray-300 hover:text-gray-300"
          >
            {playbackRate}x
          </button>
        </Dropdown>

        <button
          tabIndex={-1}
          onFocus={(e) => e.target.blur()}
          onMouseDown={() => {
            const val = VIDEO_PLAYBACK_RATES.find((e) => e > playbackRate);
            if (val !== undefined) {
              updatePlaybackRate(val);
            }
          }}
          className="hover-border-neutral-800 w-5 border border-neutral-800 text-center text-gray-300 hover:text-gray-300"
        >
          +
        </button>
      </div>
    </div>
  );
};
