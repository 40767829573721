import { MoveTool } from './tools/move.tool';
import { ViewPan } from './tools/viewPan';
import { ViewZoom } from './tools/viewZoom';

export function VideoEditorTools(view: any, scene: any) {
  function init() {
    MoveTool(scene);
    ViewZoom(view, scene.globalTransform); // @todo destroy
    ViewPan(view, scene.globalTransform);
  }

  function setActive(type: string) {
    if (type === 'BBOX') {
      // enable guide lines

      scene.on('mousedown', (event: any) => {
        console.log(event);
      });
    }
  }

  return { init, setActive };
}
