import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { GenericResourceRenameModal } from 'components/Modals/GenericResourceRenameModal/GenericResourceRenameModal';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { AiOutlineEdit } from 'react-icons/ai';
import { CgDanger } from 'react-icons/cg';
import { IoMdTrash } from 'react-icons/io';
import { JobWorkflowReassignModal } from '../JobWorkflowReassignModal/JobWorkflowReassignModal';

export const JobSettings = () => {
  const { organizationId, projectId, jobId } = useIntParams();
  const { mutateAsync: updateJob } = useMutation('UpdateJob');
  const { mutateAsync: removeJob } = useMutation('RemoveJob');
  const jobQuery = useQuery('GetJobById', {
    params: { organizationId, projectId, jobId },
  });
  const { navigateTo } = useRouterUtils();

  const handleRemoveJob = async () => {
    await removeJob({ params: { organizationId, projectId, jobId } });
    navigateTo('PROJECT');
  };
  return (
    <div>
      <div>
        <Dialog render={() => <JobWorkflowReassignModal />}>
          <Button icon={<AiOutlineEdit />} label="Change Workflow" variant="text" />
        </Dialog>
      </div>

      <Dialog
        render={() => (
          <GenericResourceRenameModal
            onRename={(name) =>
              updateJob({
                data: { name },
                params: { organizationId, projectId, jobId },
              })
            }
            currentName={jobQuery.data?.name}
          />
        )}
      >
        <Button icon={<AiOutlineEdit />} label="Rename Job" variant="text" />
      </Dialog>

      <div>
        <Dialog
          render={() => (
            <ConfirmActionModal onConfirm={handleRemoveJob}>
              <div className="mt-2 mb-6 flex flex-row items-center gap-4" style={{ maxWidth: 500 }}>
                <CgDanger size={60} />
                <p>
                  Job will be <b>permanently</b> deleted
                </p>
              </div>
            </ConfirmActionModal>
          )}
        >
          <Button icon={<IoMdTrash />} label="Delete Job" variant="text" />
        </Dialog>
      </div>
    </div>
  );
};
