import { AiOutlineLoading } from 'react-icons/ai';

const Spinner = () => {
  return (
    <div className="relative flex-1">
      <div className="absolute inset-0 flex justify-center items-center">
        <AiOutlineLoading size={20} className="animate-spin" />
      </div>
      <div>&nbsp;</div>
    </div>
  );
};

export default Spinner;
