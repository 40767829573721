import { useEffect } from 'react';

export const useDevOpenComponent = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      return;
    }

    const handler = (event: MouseEvent) => {
      if (!event.metaKey) {
        return;
      }

      const h: any = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;
      const p: any = Object.keys(h.$0).find((e) => e.includes('__reactFiber'));
      const file = h?.$0[p]?._debugSource?.fileName;

      if (!file) {
        return;
      }

      // @todo
      const path = file.replace(
        process.env.REACT_APP_PATH_CLIENT_SERVICE_CONTAINER,
        process.env.REACT_APP_PATH_CLIENT_SERVICE_LOCAL
      );

      window.open(`vscode://file/${path}`);
    };

    document.addEventListener('dblclick', handler);

    return () => {
      document.removeEventListener('dblclick', handler);
    };
  }, []);
};
