import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

export const useOnAfterDatasetImport = () => {
  const queryClient = useQueryClient();

  const handleEvent = useCallback(() => {
    // @todo only invalidate specific queries
    queryClient.invalidateQueries();
  }, [queryClient]);

  return {
    handleEvent,
  };
};
