import { atom } from 'jotai';
import { ToolType } from 'modules/image-annotator/enums';

const isErrorAtom = atom(false);
const canPlayAtom = atom(false);
const isPlayingAtom = atom(false);
const frameCountAtom = atom(0);
const mediaTimeAtom = atom(0);
const currentFrameAtom = atom(0);
const durationAtom = atom(0);
const activeToolAtom = atom(ToolType.SELECT);
const isFrameSyncErrorAtom = atom(false);
const loadedDataAtom = atom(false);

export const videoEditorState = {
  isErrorAtom,
  canPlayAtom,
  isPlayingAtom,
  frameCountAtom,
  mediaTimeAtom,
  durationAtom,
  currentFrameAtom,
  activeToolAtom,
  isFrameSyncErrorAtom,
  loadedDataAtom,
};
