import { Button } from 'components/common/Button/Button';
import { PasswordInput } from 'components/common/Input';
import { useBoolean } from 'hooks/useBoolean';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';

export const ProjectApiKey = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const toggleShowKey = useBoolean(false);

  const getProjectApiKeyQuery = useQuery('GetProjectApiKey', {
    params: {
      organizationId,
      projectId,
    },
  });
  const { mutateAsync: createApiKey, isLoading: creatingApiKey } = useMutation('GenerateProjectApiKey');
  const { mutateAsync: removeApiKey, isLoading: removingApiKey } = useMutation('RemoveProjectApiKey');

  const handleCreate = async () => {
    try {
      await createApiKey({ params: { organizationId, projectId } });
    } catch (error) {
      // TODO: handle error
      console.error(error);
    } finally {
      toggleShowKey.setTrue();
    }
  };

  const handleRemove = async () => {
    try {
      await removeApiKey({ params: { organizationId, projectId } });
    } catch (error) {
      // TODO: handle error
      console.error(error);
    }
  };

  if (getProjectApiKeyQuery.isLoading) {
    return null;
  }

  return (
    <div>
      {getProjectApiKeyQuery.data?.apiKey ? (
        <div className="flex flex-row gap-2">
          <div className="flex-1">
            <PasswordInput value={getProjectApiKeyQuery.data?.apiKey} showPassword={toggleShowKey.value} readOnly />
          </div>

          <Button onClick={handleRemove} loading={removingApiKey} variant="outlined">
            Revoke
          </Button>
        </div>
      ) : (
        <div>
          <Button onClick={handleCreate} loading={creatingApiKey} variant="outlined">
            Create API Key
          </Button>
        </div>
      )}
    </div>
  );
};
