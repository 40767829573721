import classNames from 'classnames';

interface Props {
  className?: string;
}

export const ModalLayout = ({ children, className }: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={classNames('relative flex flex-col overflow-hidden rounded-lg bg-white shadow-md', className)}
      style={{ minWidth: 460, maxHeight: '96vh' }}
    >
      {children}
    </div>
  );
};
