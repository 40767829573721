import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import DatasetRemoteStorageItem from './DatasetRemoteStorageItem';

const DatasetRemoteStorages = () => {
  const { organizationId, projectId, datasetId } =
    useIntParams<RouteParams.Dataset>();
  const { data: datasetRemoteStorageResources } = useQuery(
    'GetDatasetRemoteImportSources',
    {
      params: {
        organizationId,
        projectId,
        datasetId,
      },
    }
  );

  if (!datasetRemoteStorageResources) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {datasetRemoteStorageResources.length === 0 ? (
        <div className="my-2">
          There are no remote storage resources configured
        </div>
      ) : (
        <>
          {datasetRemoteStorageResources?.map((item) => (
            <DatasetRemoteStorageItem
              key={item.id}
              remoteStorageResource={item}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default DatasetRemoteStorages;
