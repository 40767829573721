import { forwardRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  createFilter,
  default as ReactSelect,
  default as StateManagedSelect,
  Props as ReactSelectProps,
} from 'react-select';

interface Props extends ReactSelectProps {
  variant?: Component.Size;
  control: Control<any>;
  name: string;
  disabled?: boolean;
  required?: boolean;
}

// @todo fix initialization (with option or with option.value)?
// @todo limit ...rest
// @todo bug: if isMulti & defaultValue -> payload is string instead of []
// @todo defaultValue not working if isMulti?

export const Select = forwardRef<StateManagedSelect, Props>((props: Props, ref) => {
  const { variant, name, control, required, options, disabled, isMulti, isSearchable, isClearable, autoFocus, styles } =
    props;

  //   field: { onChange, onBlur, value, ref },

  const rest = {
    isMulti,
    isDisabled: disabled,
    isClearable,
    autoFocus,
    isSearchable,
    filterOption: createFilter({
      ignoreCase: true,
      ignoreAccents: true,
      matchFrom: 'start',
      trim: true,
    }),
    styles,
  };

  return (
    <Controller
      name={name}
      rules={{ required }}
      control={control}
      render={({ field }) => (
        <ReactSelect
          {...rest}
          {...field}
          options={options}
          value={
            isMulti
              ? (options || []).filter((e: any) => Array.isArray(field.value) && field.value.includes(e.value))
              : field.value !== undefined && options!.find((e: any) => e.value.toString() === field.value.toString())
          }
          onChange={(newValue: any, actionMeta) => {
            field.onChange(isMulti ? newValue.map((e: any) => e.value) : newValue?.value);

            typeof props.onChange === 'function' && props.onChange(newValue, actionMeta);
          }}
          onBlur={(e) => {
            typeof props.onBlur === 'function' && props.onBlur(e);
          }}
          menuPortalTarget={document.querySelector('body')}
        />
      )}
    />
  );
});
