import {
  SelfServiceRegistrationFlow,
  SubmitSelfServiceRegistrationFlowWithPasswordMethodBody,
} from '@ory/kratos-client';
import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { oryKratosService } from './oryKratos.service';

export const useRegisterFlow = () => {
  const [flow, setFlow] = useState<SelfServiceRegistrationFlow>();
  const queryClient = useQueryClient();
  const registerMutation = useMutation(oryKratosService.register, {
    onSuccess: () => {
      queryClient.invalidateQueries([{ id: 'IsAuthenticated' }]);
    },
  });

  const register = async (data: SubmitSelfServiceRegistrationFlowWithPasswordMethodBody) => {
    if (!flow) {
      console.log('Flow not initialized');
      return;
    }

    try {
      const response = await registerMutation.mutateAsync({
        flow: flow.id,
        data,
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error as AxiosError<SelfServiceRegistrationFlow>;

        if (response?.data) {
          setFlow(response.data);
        }
      }

      return error;
    }
  };

  useEffect(() => {
    (async () => {
      const res = await oryKratosService.initRegisterFlow();
      setFlow(res.data);
    })();
  }, []);

  return {
    register,
    flow,
  };
};
