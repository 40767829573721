import startCase from 'lodash/startCase';
import { Fragment } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useLocation, Link } from 'react-router-dom';

type ReplaceableResource = 'organizations' | 'projects' | 'jobs' | 'datasets';

type BreadcrumbsProps = {
  omit?: string[];
  replaceResourceIdWithName?: {
    [resource in ReplaceableResource]?: string;
  };
};

export const Breadcrumbs = ({ omit = [], replaceResourceIdWithName = {} }: BreadcrumbsProps) => {
  const location = useLocation();
  const path = location.pathname.split('/');

  let prevCrumb = '';
  let incrementalUrl = '';
  const breadcrumbs = path
    .filter((item) => item)
    .map((item) => {
      incrementalUrl += `/${item}`;
      const replacement = replaceResourceIdWithName[prevCrumb as ReplaceableResource];
      prevCrumb = item;

      if (replacement) {
        return { displayName: replacement, url: incrementalUrl };
      } else if (!isNaN(parseInt(item, 10))) {
        return { displayName: item, url: incrementalUrl };
      }

      return {
        displayName: startCase(item),
        url: incrementalUrl,
      };
    });

  let cutoffIndex = breadcrumbs.findIndex((b) => b.displayName.toLowerCase() === 'tasks');
  cutoffIndex = cutoffIndex === -1 ? breadcrumbs.length : cutoffIndex;

  return (
    <div className="flex items-center gap-0.5 text-xs text-slate-600">
      {breadcrumbs
        .slice(0, cutoffIndex + 1)
        .filter((crumb) => !omit.includes(crumb.displayName.toLowerCase()))
        .map((crumb, index, array) => (
          <Fragment key={index}>
            <Link to={crumb.url}>{crumb.displayName}</Link>
            {index !== array.length - 1 && <MdKeyboardArrowRight />}
          </Fragment>
        ))}
    </div>
  );
};
