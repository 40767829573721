import { useAtom } from 'jotai';
import { useContext } from 'react';
import { IoMdArrowDropleft, IoMdArrowDropright, IoMdPause, IoMdPlay } from 'react-icons/io';
import { videoEditorState } from './state/videoEditorState';
import { VideoEditorContext } from './VideoEditorContext';

export const VideoPlaybackControls = () => {
  const { videoEditor } = useContext(VideoEditorContext);
  const [isPlaying] = useAtom(videoEditorState.isPlayingAtom);

  if (!videoEditor) {
    return null;
  }

  const { togglePlay, seekToPreviousFrame, seekToNextFrame } = videoEditor.video;

  const buttonProps = {
    tabIndex: -1,
    onFocus: (e: any) => e.target.blur(),
    className: 'text-gray-300 text-lg hover:text-white',
  };

  return (
    <div className="flex items-center gap-6 text-lg">
      {/* <button onMouseDown={skipBackward} {...buttonProps}>
        <IoMdSkipBackward />
      </button> */}
      <button onMouseDown={seekToPreviousFrame} {...buttonProps}>
        <IoMdArrowDropleft size={24} />
      </button>
      <button onClick={togglePlay} {...buttonProps}>
        {isPlaying ? <IoMdPause focusable={false} /> : <IoMdPlay focusable={false} />}
      </button>
      <button onMouseDown={seekToNextFrame} {...buttonProps}>
        <IoMdArrowDropright size={24} />
      </button>
      {/* <button onMouseDown={skipForward} {...buttonProps}>
        <IoMdSkipForward />
      </button> */}
    </div>
  );
};
