import { Ulid } from 'id128';

export const ULID = () => {
  const ulid = Ulid.generate().toRaw();
  const groups = [
    [0, 8],
    [8, 12],
    [12, 16],
    [16, 20],
    [20, 32],
  ];
  return groups
    .map(([i, j]: any) => ulid.substring(i, j))
    .join('-')
    .toLowerCase();
};
