import {
  SubmitSelfServiceSettingsFlowBody,
  SubmitSelfServiceSettingsFlowWithPasswordMethodBody,
  SubmitSelfServiceSettingsFlowWithProfileMethodBody,
} from '@ory/kratos-client';
import { FormMessage } from 'components/common/FormMessage/FormMessage';
import { OryFormUi } from 'components/common/OryFormUi/OryFormUi';
import { Typography } from 'components/common/Typography';
import { useSettingsFlow } from 'hooks/auth/useSettingsFlow';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

type SettingsProps = {
  title?: string;
  showProfile?: boolean;
};

export const Settings = ({
  title = 'Settings',
  showProfile = true,
}: SettingsProps) => {
  const { flow, error, updateSettings } = useSettingsFlow();
  const emailForm =
    useForm<SubmitSelfServiceSettingsFlowWithProfileMethodBody>();
  const { handleSubmit: handleEmailSubmit } = emailForm;
  const passwordForm =
    useForm<SubmitSelfServiceSettingsFlowWithPasswordMethodBody>();
  const { handleSubmit: handlePasswordSubmit } = passwordForm;

  const onSubmit: SubmitHandler<SubmitSelfServiceSettingsFlowBody> = async (
    data
  ) => {
    return updateSettings(data);
  };

  if (!flow) {
    return null;
  }

  return (
    <>
      <div className="max-w-sm">
        <Typography component="h1">{title}</Typography>

        {error?.reason && <FormMessage text={error.reason} type="error" />}

        {!error &&
          (flow.ui.messages || []).map((message) => (
            <FormMessage
              key={message.id}
              text={message.text}
              type={message.type}
            />
          ))}

        {showProfile && (
          <div className="pb-2 mb-4 border-b border-black/10">
            <FormProvider {...emailForm}>
              <form onSubmit={handleEmailSubmit(onSubmit)}>
                <OryFormUi ui={flow.ui} only="profile" />
              </form>
            </FormProvider>
          </div>
        )}

        <div>
          <FormProvider {...passwordForm}>
            <form onSubmit={handlePasswordSubmit(onSubmit)}>
              <OryFormUi ui={flow.ui} only="password" />
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
