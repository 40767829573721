import { createStore } from 'zustand/vanilla';
import { Canvas } from './Canvas';
import { HitTestFn } from './HitTestFn';
import { PointerEventRouter } from './PointerEventRouter';
import { RenderLoop } from './RenderLoop';
import { Scene } from './Scene';
import { SceneObjectsManager } from './SceneObjectsManager';
import { VideoControls } from './VideoControls';
import { VideoEditorTools } from './VideoEditorTools';
import { VideoEditorView } from './VideoEditorView';
import { VideoView } from './VideoView';
import { ObjectFrameSync } from './objectFrameSync';
import { PointerGuideLines } from './tools/helpers/PointerGuideLines';

interface Props {
  container: HTMLElement;
  src: string;
  metadata: {
    frameCount: number;
    pts_times: number[];
  };
}

export interface VideoEditorState {
  canPlay: boolean;
  isLoading: boolean;
  isError: boolean;
  isPlaying: boolean;
  playbackRate: number;
  currentFrame: number;
  currentTime: number;
  frameCount: number;
  isFrameSyncError: boolean;
  zoomValue: number;
  duration: number;
  set: any; // @todo
}

export const AnnotationVideoEditor = ({ container, src, metadata }: Props) => {
  const state = createStore(() => ({ canPlay: false, isLoading: true, isError: false }));
  const scene = Scene();
  const videoEl = VideoView({
    src,
    onCanPlay: () => {
      state.setState({ canPlay: true });
    },
    onLoad: () => {
      state.setState({ isLoading: false });
    },
    onError: (event: any) => {
      console.error('error', event);
      state.setState({ isLoading: false, isError: true });
    },
    onLoadedMetadata: (event) => {
      const { videoWidth: width, videoHeight: height } = event.target as HTMLVideoElement;
      scene.world.setState({ width, height });

      scene.zoomToFit({ allowUpscale: false });
    },
  });

  const videoControls = VideoControls({
    video: videoEl,
    metadata,
  });

  const sceneObjectsManager = SceneObjectsManager();
  const canvas = Canvas({ width: 1, height: 1, hdpi: true });
  const view = VideoEditorView({ video: videoEl, canvas: canvas.view });

  const hitRegionCanvas = Canvas({ width: 1, height: 1, hdpi: false, willReadFrequently: true }); // @todo hdpi?
  const hitTestFn = HitTestFn(hitRegionCanvas, sceneObjectsManager);
  const pointerEventRouter = PointerEventRouter({ view, scene, hitTestFn });

  const {
    store,
    play,
    pause,
    togglePlay,
    seekTo,
    seekToPreviousFrame,
    seekToNextFrame,
    getCurrentFrame,
    setPlaybackRate,
    skipBackward,
    skipForward,
  } = videoControls;
  const { add, remove, removeAll, findById, removeByExternalId } = sceneObjectsManager;

  const objectFrameSync = ObjectFrameSync(videoControls, sceneObjectsManager);
  let containerResizeObserver: ResizeObserver;

  const pointerGuideLines = PointerGuideLines(scene);
  pointerGuideLines.items.forEach((item) => add(item));

  const tools = VideoEditorTools(view, scene);

  const renderLoop = RenderLoop(() => {
    const transform = scene.globalTransform.getState();
    const { items } = sceneObjectsManager.store.getState();
    const renderableItems = items.filter((item: any) => item.isVisible());

    canvas.clear();
    renderableItems.forEach((item: any) => canvas.render(item, transform));

    hitRegionCanvas.clear();
    renderableItems.forEach((item: any) =>
      hitRegionCanvas.render(item, transform, {
        fillStyle: item.data('hitRegionColor'),
        strokeStyle: item.data('hitRegionColor'),
      })
    );
  });

  function destroy() {
    objectFrameSync.destroy();
    pointerEventRouter.destroy();
    renderLoop.destroy();
    container.removeChild(view);
    containerResizeObserver && containerResizeObserver.disconnect();
  }

  function _init() {
    container.appendChild(view);

    renderLoop.run();
    objectFrameSync.init();

    tools.init();

    scene.globalTransform.subscribe(({ x, y, scale }) => {
      videoEl.style.transform = `translateX(${x}px) translateY(${y}px) scale(${scale})`;
    });

    // adjust viewport size based on container
    function resizeObserverHandler() {
      const { width, height } = container.getBoundingClientRect();
      scene.viewport.setState({ width, height });
    }
    const containerResizeObserver = new ResizeObserver(resizeObserverHandler);

    containerResizeObserver.observe(container);
    resizeObserverHandler();

    scene.viewport.subscribe(({ width, height }) => {
      canvas.resize({ width, height });
      hitRegionCanvas.resize({ width, height });
    });

    sceneObjectsManager.store.subscribe(() => {
      objectFrameSync.sync();
    });
  }

  _init();

  return Object.freeze({
    state,
    video: {
      store,
      play,
      pause,
      togglePlay,
      seekTo,
      seekToPreviousFrame,
      seekToNextFrame,
      getCurrentFrame,
      setPlaybackRate,
      skipBackward,
      skipForward,
    },
    scene: {
      world: scene.world,
      viewport: scene.viewport,
      transform: scene.globalTransform,
      zoomToFit: scene.zoomToFit,
      zoomToCover: scene.zoomToCover,
    },
    objects: {
      store: sceneObjectsManager.store,
      scene, // @todo
      add,
      remove,
      findById,
      removeAll,
      removeByExternalId,
    },
    tools: {
      setActive: tools.setActive,
    },
    pointerGuideLines,
    forceSync: objectFrameSync.sync,
    destroy,
  });
};
