import { Button } from 'components/common/Button/Button';
import { Label } from 'components/common/Forms/Label/Label';
import { Select } from 'components/common/Forms/Select/Select';
import { useForm } from 'react-hook-form';

interface Props {
  workflow?: WorkflowDTO;
  onCreate?: (props: any) => void;
}

export const WorkflowActionType = {
  SET_ASSIGNEE: 1,
};

const CreateWorkflowAction = ({ workflow, onCreate = () => {} }: Props) => {
  const { handleSubmit, watch, control } = useForm({
    defaultValues: {
      transitionId: '',
      actionType: '',
      userGroupId: '',
    },
  });

  const onSubmit = (data: any) => {
    onCreate(data);
  };

  if (!workflow) {
    return <div>Loading</div>;
  }

  const transitions = workflow.workflowTransitions || [];
  const userGroups = workflow.workflowUserGroups || [];

  return (
    <div>
      <h2>Create Action</h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-2">
          <Label htmlFor="transitionId">Attach to</Label>

          <Select
            control={control}
            id="transitionId"
            name="transitionId"
            options={transitions.map(({ id: value, label }) => ({
              value,
              label,
            }))}
          />
        </div>

        <div className="my-2">
          <Label htmlFor="actionType">Action</Label>

          <Select
            control={control}
            id="actionType"
            name="actionType"
            options={[{ value: WorkflowActionType.SET_ASSIGNEE, label: 'Set assignee' }]}
          />
        </div>

        {watch('actionType') === WorkflowActionType.SET_ASSIGNEE.toString() && (
          <div className="my-2">
            <Label htmlFor="userGroupId">User bucket</Label>

            <Select
              control={control}
              id="userGroupId"
              name="userGroupId"
              options={userGroups.map(({ id: value, name: label }) => ({
                value,
                label,
              }))}
            />
          </div>
        )}

        <Button type="submit" label="Submit" />
      </form>
    </div>
  );
};

export default CreateWorkflowAction;
