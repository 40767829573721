import { SidebarMenuItem } from 'components/Sidebar/shared/SidebarMenuItem/SidebarMenuItem';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { FaRegCircle } from 'react-icons/fa';
import { MdArrowForwardIos } from 'react-icons/md';

export const SidebarProjectsPanel = () => {
  const { organizationId } = useIntParams();

  const { pathTo } = useRouterUtils();
  const projectsQuery = useQuery('GetProjects', {
    params: { organizationId },
  });

  return (
    <>
      {projectsQuery.data?.map(({ id, name }) => (
        <div key={id} className="relative group">
          <SidebarMenuItem
            linkTo={pathTo('PROJECT', {
              projectId: id,
            })}
            label={name}
            icon={<FaRegCircle size={8} />}
            className="font-thin"
          />

          <div className="absolute right-0 top-0 bottom-0 flex items-center px-3 invisible group-hover:visible pointer-events-none">
            <MdArrowForwardIos size={12} />
          </div>
        </div>
      ))}
    </>
  );
};
