import { Table } from 'components/common/Table/Table';
import { useJobExportsTable } from 'hooks/react-table/useJobExportsTable';
import useIntParams from 'hooks/useIntParams';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';

export const JobOverviewRecentExports = () => {
  const { projectId, organizationId, jobId } = useIntParams();
  const jobExportHistoriesQuery = usePaginatedQuery('GetJobExportHistoriesWithPagination', {
    params: { organizationId, projectId },
    query: {
      jobId,
      pageSize: 5,
    },
  });

  const { instance: jobExportsTableInstance } = useJobExportsTable({
    rows: jobExportHistoriesQuery.data?.results,
  });

  return (
    <div>
      <div className="text-md mb-2 mt-4 font-medium text-slate-800">Recent Exports</div>

      <Table instance={jobExportsTableInstance} />
    </div>
  );
};
