import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { useAtom } from 'jotai';
import { forwardRef } from 'react';
import { videoTimelineConfig } from '../VideoTimeline';

export const VideoTimelinePlayheadBody = forwardRef<HTMLDivElement, {}>((_, ref) => {
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);
  const x = currentFrame * videoTimelineConfig.FRAME_WIDTH_IN_PX + videoTimelineConfig.FRAME_WIDTH_IN_PX / 2 - 1;

  return (
    <div
      ref={ref}
      className="pointer-events-none absolute top-0 bottom-0 z-10 bg-indigo-400"
      style={{ width: 1, transform: `translateX(${x}px)` }}
    ></div>
  );
});
