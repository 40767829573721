import classNames from 'classnames';
import { VideoEditorContext } from 'components/Workspace2/VideoEditorContext';
import { annotationInstanceDoAtom } from 'components/Workspace2/WorkspaceDatatitem2';
import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { videoTimelineState } from 'components/Workspace2/state/videoTimelineState';
import { useAnnotationsStore } from 'components/Workspace2/useAnnotationsStore';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { useAtom } from 'jotai';
import { useContext } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { MdNavigateBefore, MdNavigateNext, MdOutlineMoreVert } from 'react-icons/md';
import { videoTimelineConfig } from '../VideoTimeline';

interface Props {
  annotationInstance: AnnotationInstanceDTO;
  annotationClassProperty: AnnotationClassPropertyDTO;
}
export const VideoTimelineRowHeadingItem = ({ annotationInstance, annotationClassProperty }: Props) => {
  const { videoEditor } = useContext(VideoEditorContext); // @todo
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);
  const [hoveredRow, setHoveredRow] = useAtom(videoTimelineState.hoveredRow);
  const { removeAllKeyframes } = useAnnotationsStore();

  const [annotationInstanceDo] = useAtom(annotationInstanceDoAtom);

  if (!annotationInstanceDo) {
    return null;
  }

  const annotationInstancePropertyDo = annotationInstanceDo.getPropertyDo(annotationClassProperty.key);

  const nextFrame = annotationInstancePropertyDo.getNextFrameRelativeTo(currentFrame);
  const prevFrame = annotationInstancePropertyDo.getPreviousFrameRelativeTo(currentFrame);

  const seekToNextKeyframe = () => {
    nextFrame !== -1 && videoEditor.video?.seekTo(nextFrame);
  };

  const seekToPrevKeyframe = () => {
    prevFrame !== -1 && videoEditor.video?.seekTo(prevFrame);
  };

  const handleMouseEnter = () => {
    setHoveredRow(annotationClassProperty.id);
  };

  const handleMouseLeave = () => {
    setHoveredRow((prev: any) => (prev === annotationClassProperty.id ? null : prev));
  };

  const onRemoveAllKeyframes = () => {
    removeAllKeyframes(annotationInstance.id, { key: annotationClassProperty.key });
  };

  return (
    <div
      className={classNames('text-xxs group flex items-center gap-1 border-b border-ws-panel-200 text-gray-400', {
        'bg-black/10': hoveredRow === annotationClassProperty.id,
      })}
      style={{ height: videoTimelineConfig.ROW_HEIGHT_IN_PX }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="px-3 text-ellipsis overflow-hidden whitespace-nowrap">{annotationClassProperty.label}</div>

      <div className="invisible ml-auto flex items-center gap-2 group-hover:visible">
        <div className="flex items-center">
          <button
            tabIndex={-1}
            onFocus={(e) => e.target.blur()}
            onMouseDown={seekToPrevKeyframe}
            className={classNames('text-gray-400 hover:text-gray-200', {
              'pointer-events-none text-neutral-800': !(prevFrame !== -1),
            })}
          >
            <MdNavigateBefore size={20} />
          </button>
          <button
            tabIndex={-1}
            onFocus={(e) => e.target.blur()}
            onMouseDown={seekToNextKeyframe}
            className={classNames('text-gray-400 hover:text-gray-200', {
              'pointer-events-none text-neutral-800': !(nextFrame !== -1),
            })}
          >
            <MdNavigateNext size={20} />
          </button>

          <Dropdown
            className="text-xxs z-10 border border-neutral-800 text-neutral-200 rounded"
            render={() => (
              <div>
                <button
                  className="flex w-full items-center gap-2 bg-black py-1.5 px-4 hover:bg-neutral-900"
                  onClick={onRemoveAllKeyframes}
                >
                  <IoCloseSharp /> Delete All Keyframes
                </button>
              </div>
            )}
          >
            <button className="ml-2">
              <MdOutlineMoreVert size={16} />
            </button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
