import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';
import { toReadableCronExpression } from 'utils/toReadableCron';

export const ScheduledExports = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const { pathTo } = useRouterUtils();
  const scheduledJobExportConfigs = useQuery('GetAllScheduledJobExportConfigs', {
    params: { organizationId, projectId },
    // @todo filter for cron !== null
  });

  if (scheduledJobExportConfigs.data?.filter(({ cron }) => cron !== null).length === 0) {
    return null;
  }

  return (
    <div>
      <div className="mb-2 mt-4 flex items-center justify-between">
        <div className="text-md font-medium text-slate-800">Scheduled Job Exports</div>
      </div>

      <table className="table-primary">
        <thead>
          <tr>
            <th>Job ID</th>
            <th>Schedule</th>
          </tr>
        </thead>
        <tbody>
          {scheduledJobExportConfigs.data
            ?.filter(({ cron }) => cron !== null)
            .map(({ cron, jobId }) => (
              <tr>
                <td>
                  <Link to={pathTo('JOB', { jobId })}>{jobId}</Link>
                </td>
                <td className="font-medium">{toReadableCronExpression(cron!)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
