import { AnnotationClassPropertyType } from 'shared/enums';

export const AnnotationInstancePropertyDO = (annotationInstanceProperty: any, schema?: AnnotationClassPropertyDTO) => {
  const _isEnumType = () => {
    if (!schema) {
      return false;
    }

    return schema.isKeyframable && schema.type === AnnotationClassPropertyType.ENUM && schema.isMultiChoice;
  };

  let prop: any = annotationInstanceProperty
    ? JSON.parse(JSON.stringify(annotationInstanceProperty))
    : _isEnumType()
    ? {}
    : []; // @todo

  const upsertKeyframe = (frame: number, value: any) => {
    // if (_isEnumType()) {
    //   prop = prop || {};

    //   const propertyOptionKeys = value;

    //   propertyOptionKeys.forEach((propertyOptionKey: any) => {
    //     const subProp = AnnotationInstancePropertyDO(prop[propertyOptionKey]);

    //     if (subProp.getPreviousFrameRelativeTo(frame)?.value !== true) {
    //       subProp.upsertKeyframe(frame, true);
    //     }
    //     prop[propertyOptionKey] = subProp.toDto();
    //   });

    //   // items to remove
    //   const valuesAtKeyframe = getValueAtKeyframe(frame);

    //   const itemsToRemove = valuesAtKeyframe.filter((e: any) => !value.includes(e));

    //   itemsToRemove.forEach((propertyOptionKey: any) => {
    //     const subProp = AnnotationInstancePropertyDO(prop[propertyOptionKey]);
    //     subProp.upsertKeyframe(frame, false);
    //     prop[propertyOptionKey] = subProp.toDto();
    //   });

    //   return;
    // }

    prop = prop || [];
    const foundIndex = prop?.findIndex((e: any) => +e.frame === +frame);

    if (foundIndex !== -1) {
      if (value === null) {
        prop.splice(foundIndex, 1);
      } else {
        prop[foundIndex].value = value;
      }
    } else {
      prop.push({ frame, value });
      prop = prop.sort((a: any, b: any) => a.frame - b.frame);
    }
  };

  const hasKeyframeAt = (frame: number) => {
    // if (_isEnumType()) {
    //   const values = getValueAtKeyframe(frame);
    //   return values.length !== 0;
    // }

    return prop?.find((e: any) => +e.frame === +frame) !== undefined;
  };

  const getValueAtKeyframe = (frame: number) => {
    // if (_isEnumType()) {
    //   const values: any = Object.keys(prop).filter((key) =>
    //     AnnotationInstancePropertyDO(prop[key]).getValueAtKeyframe(frame)
    //   );
    //   return values;
    // }

    if (Array.isArray(prop)) {
      // @ts-ignore
      return prop?.findLast((e: any) => e.frame <= frame)?.value;
    }

    return prop;
  };

  const deleteKeyframe = (frame: number) => {
    // @todo
    // if (_isEnumType()) {
    //   return Object.keys(prop).map((key) => AnnotationInstancePropertyDO(prop[key]).deleteKeyframe(frame));
    // }

    prop = prop.filter((e: any) => +e.frame !== +frame);
  };

  const getNextFrameRelativeTo = (frame: number) => {
    // if (_isEnumType()) {
    //   // @todo optimize
    //   const values = Object.keys(prop)
    //     .map((key) => prop[key])
    //     .sort((a: any, b: any) => a.frame - b.frame);

    //   return values.find((e: any) => e.frame > frame);
    // }

    return prop.find((e: any) => e.frame > frame)?.frame || -1;
  };

  const getPreviousFrameRelativeTo = (frame: number) => {
    // if (_isEnumType()) {
    //   // @todo optimize
    //   const values = Object.keys(prop)
    //     .map((key) => prop[key])
    //     .sort((a: any, b: any) => a.frame - b.frame);

    //   // @ts-ignore
    //   return values?.findLast((e: any) => e.frame > frame);
    // }

    return prop?.findLast((e: any) => e.frame < frame)?.frame || -1;
  };

  const moveKeyframe = (from: number, to: number) => {
    if (!hasKeyframeAt(from)) {
      return;
    }

    const val = getValueAtKeyframe(from);
    deleteKeyframe(from);

    upsertKeyframe(to, val);
  };

  const toDto = () => {
    return JSON.parse(JSON.stringify(prop)); // @todo
  };

  return {
    upsertKeyframe,
    deleteKeyframe,
    hasKeyframeAt,
    getValueAtKeyframe,

    getNextFrameRelativeTo,
    getPreviousFrameRelativeTo,

    moveKeyframe,

    toDto,
  };
};

export const AnnotationInstanceDO = (annotationInstanceDto: AnnotationInstanceDTO) => {
  const instance: AnnotationInstanceDTO = JSON.parse(JSON.stringify(annotationInstanceDto)); // @todo

  const getPropertyDefinition = (propertyKey: any) => {
    return instance.annotationClass.properties.find((e) => e.key === propertyKey);
  };

  const isKeyframableProperty = (propertyKey: any) => {
    return getPropertyDefinition(propertyKey)?.isKeyframable;
  };

  const setValue = (propertyKey: any, value: any) => {
    instance.properties[propertyKey] = value;
  };

  const _getPropertryDo = (propertyKey: any) => {
    return AnnotationInstancePropertyDO(instance.properties[propertyKey], getPropertyDefinition(propertyKey));
  };

  const doesPropertyHaveKeyframeAt = (propertyKey: any, frame: number) => {
    const prop = _getPropertryDo(propertyKey);
    return prop.hasKeyframeAt(frame);
  };

  const getPropertyValueAtKeyframe = (propertyKey: any, frame: number) => {
    const prop = _getPropertryDo(propertyKey);
    return prop?.getValueAtKeyframe(frame);
  };

  const getAllPropertyValuesAtKeyframe = (frame: number) => {
    return Object.fromEntries(
      Object.values(instance.annotationClass.properties).map((prop) => [
        prop.key,
        getPropertyValueAtKeyframe(prop.key, frame),
      ])
    );
  };

  const upsertKeyframe = (propertyKey: any, payload: { frame: number; value: any }) => {
    const propertyDefinition = getPropertyDefinition(propertyKey);
    if (!propertyDefinition?.isKeyframable) {
      return;
    }

    const prop = _getPropertryDo(propertyKey);
    prop.upsertKeyframe(payload.frame, payload.value);
    setValue(propertyKey, prop.toDto());
  };

  const moveKeyframe = (propertyKey: any, { from, to }: { from: number; to: number }) => {
    const propertyDefinition = getPropertyDefinition(propertyKey);
    if (!propertyDefinition?.isKeyframable) {
      return;
    }

    const prop = _getPropertryDo(propertyKey);
    prop.moveKeyframe(from, to);
    setValue(propertyKey, prop.toDto());
  };

  const deleteKeyframe = (propertyKey: any, frame: number) => {
    if (!isKeyframableProperty(propertyKey)) {
      return;
    }

    const prop = _getPropertryDo(propertyKey);
    prop.deleteKeyframe(frame);
    setValue(propertyKey, prop.toDto());
  };

  const removeAllKeyframes = (propertyKey: any) => {
    if (!isKeyframableProperty(propertyKey)) {
      return;
    }

    setValue(propertyKey, []);
  };

  const getPropertyDo = (propertyKey: any) => {
    return AnnotationInstancePropertyDO(instance.properties[propertyKey], getPropertyDefinition(propertyKey));
  };

  const toDto = () => {
    return JSON.parse(JSON.stringify(instance)); // @todo
  };

  return {
    toDto,
    setValue,
    getPropertyValueAtKeyframe,
    getAllPropertyValuesAtKeyframe,
    upsertKeyframe,
    moveKeyframe,
    deleteKeyframe,
    removeAllKeyframes,
    isKeyframableProperty,
    doesPropertyHaveKeyframeAt,
    getPropertyDo,
  };
};
