import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import { useContext } from 'react';
import { KeyboardShortcutItem } from './KeyboardShortcutItem';

interface Props {
  items: { key: string; label: string }[];
}

export const KeyboardShortcutsModal = ({ items = [] }: Props) => {
  const { close } = useContext(DialogContext);

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Keyboard Shortcuts" />

      <ModalLayoutBody>
        {items.map(({ key, label }) => (
          <div key={label} className="p-2 border-b">
            <KeyboardShortcutItem label={label} id={key} />
          </div>
        ))}
      </ModalLayoutBody>

      <ModalLayoutFooter>
        <Button label={'OK'} onClick={close} />
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
