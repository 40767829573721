import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { JobCreateModal } from 'components/Modals/JobCreateModal/JobCreateModal';
import JobListing from 'components/Project/Jobs/JobListing';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';

export const DatasetJobs = () => {
  const { projectId, organizationId, datasetId } = useIntParams();
  const jobsQuery = useQuery('GetJobs', {
    params: { projectId, organizationId },
    query: {
      datasetId,
    },
  });

  return (
    <div>
      <div className="mb-2 mt-4 flex items-center gap-2 justify-between">
        <div className="text-md font-medium text-slate-800">Jobs</div>
        <Dialog render={() => <JobCreateModal datasetId={datasetId} />}>
          <Button size="sm" variant="outlined">
            + Create Job
          </Button>
        </Dialog>
      </div>

      <JobListing jobs={jobsQuery.data || []} />
    </div>
  );
};
