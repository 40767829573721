import { Button } from 'components/common/Button/Button';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useContext } from 'react';
import { WorkflowDetailsContext } from '../WorkflowDetails';
import CreateWorkflowUserGroup from './CreateWorkflowUserGroup';

const WorkflowUserGroups = () => {
  const { organizationId, projectId, workflowId } = useIntParams();
  const { workflow } = useContext(WorkflowDetailsContext);
  const userGroups = workflow.workflowUserGroups || [];
  const { mutateAsync: removeUserGroup } = useMutation('RemoveWorkflowUserGroup');

  const handleRemove = (id: WorkflowUserGroupDTO['id']) => {
    removeUserGroup({ params: { organizationId, projectId, workflowId, workflowUserGroupId: id } });
  };

  return (
    <div>
      <div className="my-4">
        <CreateWorkflowUserGroup workflow={workflow} />
      </div>

      <div className="my-4">
        {userGroups.length === 0 && <div>There are no user groups defined for this workflow</div>}
      </div>

      <div className="my-4">
        {userGroups.map(({ id, name }) => (
          <div key={id} className="my-1 flex justify-between rounded-sm border bg-slate-50 p-2">
            <div>{name}</div>
            <Button onClick={() => handleRemove(id)}>&times;</Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkflowUserGroups;
