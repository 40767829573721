import { State } from 'modules/image-annotator/canvas/State';
import { mapItemTypeToRenderer } from './Context2dRenderer';

interface Props {
  width: number;
  height: number;
  settings?: {
    willReadFrequently?: boolean;
  };
}

export function Canvas({ width, height, settings }: Props): Canvas {
  const state = State<{ width: number; height: number }>({
    width,
    height,
  });

  const canvas = document.createElement('canvas');
  const context = canvas.getContext(
    '2d',
    settings as CanvasRenderingContext2DSettings
  );

  if (context === null) {
    throw new Error('canvas.getContext failed');
  }

  const clear = () => {
    // TODO remove type cast when updated definition (TypeScript/lib.dom.d.ts) will be available
    if (typeof (context as any).reset === 'function') {
      (context as any).reset();
      return;
    }

    const { width, height } = state.get();
    context.resetTransform();
    context.clearRect(0, 0, width, height);
  };

  const render = ({
    item,
    transform,
    overrides = {},
  }: {
    item: Shape<unknown>;
    transform: Transform;
    overrides?: {};
  }) => {
    context?.save();
    mapItemTypeToRenderer[item.type]({ item, transform, overrides, context });
    context?.restore();
  };

  function resize({ width, height }: { width?: number; height?: number }) {
    width !== undefined && canvas.setAttribute('width', width.toString());
    height !== undefined && canvas.setAttribute('height', height.toString());

    const prev = state.get();

    state.set({
      width: width || prev.width,
      height: height || prev.height,
    });
  }

  function _init() {
    resize({ width, height });
  }

  _init();

  return {
    view: canvas,
    context,
    clear,
    render,
    resize,
  };
}
