import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Typography } from 'components/common/Typography';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { GenericResourceRenameModal } from 'components/Modals/GenericResourceRenameModal/GenericResourceRenameModal';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { AiOutlineEdit } from 'react-icons/ai';
import { CgDanger } from 'react-icons/cg';
import { IoMdTrash } from 'react-icons/io';

export const OrganizationSettings = () => {
  const { organizationId } = useIntParams();
  const { mutateAsync: updateOrganization } = useMutation('UpdateOrganiaztion');
  const { mutateAsync: removeOrganization } = useMutation('RemoveOrganization');
  const organizationQuery = useQuery('GetOrganizationById', {
    params: { organizationId },
  });
  const { navigateTo } = useRouterUtils();

  const handleRemoveOrganization = async () => {
    await removeOrganization({ params: { organizationId } });
    navigateTo('HOME');
  };

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Organization Settings
          </Typography>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <Dialog
          render={() => (
            <GenericResourceRenameModal
              onRename={(name) =>
                updateOrganization({
                  data: { name },
                  params: { organizationId },
                })
              }
              currentName={organizationQuery.data?.name}
            />
          )}
        >
          <Button icon={<AiOutlineEdit />} label="Rename Organization" variant="text" />
        </Dialog>

        <div>
          <Dialog
            render={() => (
              <ConfirmActionModal onConfirm={handleRemoveOrganization}>
                <div className="mt-2 mb-6 flex flex-row items-center gap-4" style={{ maxWidth: 500 }}>
                  <CgDanger size={60} />
                  <p>
                    Organization and all related data (projects, datasets, jobs, exports, etc.) will be{' '}
                    <b>permanently</b> deleted
                  </p>
                </div>
              </ConfirmActionModal>
            )}
          >
            <Button icon={<IoMdTrash />} label="Delete Organization" variant="text" />
          </Dialog>
        </div>
      </LayoutContent>
    </LayoutContentBody>
  );
};
