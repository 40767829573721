import { GrNext, GrPrevious } from 'react-icons/gr';

interface Props {
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
  onPrev: () => void;
  onNext: () => void;
}

export const Pagination = ({ isPrevEnabled, isNextEnabled, onPrev, onNext }: Props) => {
  const buttonClassNames = 'bg-white border p-1.5 rounded hover:bg-slate-50 disabled:opacity-30';

  return (
    <div className="flex items-center gap-1 text-sm select-none">
      <button disabled={!isPrevEnabled} onClick={onPrev} className={buttonClassNames}>
        <GrPrevious />
      </button>

      <button disabled={!isNextEnabled} onClick={onNext} className={buttonClassNames}>
        <GrNext />
      </button>
    </div>
  );
};
