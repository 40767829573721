import { useMutation } from 'hooks/useMutation';

interface Props {
  onSuccess?: () => void;
  onSettled?: () => void;
  onError?: () => void;
  onMutate?: () => void;
}

export const useSaveAnnotationInstances = (props?: Props) => {
  const { mutateAsync: bulkUpsertAnnotationInstance, isLoading } = useMutation('BulkUpsertAnnotationInstances', {
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onSettled: props?.onSettled,
    onMutate: props?.onMutate,
  });

  const save = async (
    annotations: AnnotationInstanceDTO[],
    { organizationId, projectId, jobId, jobDataitemId }: any
  ) => {
    await bulkUpsertAnnotationInstance({
      data: { items: annotations as any },
      params: {
        organizationId,
        projectId,
        jobId,
        jobDataitemId,
      },
    }).catch((_err) => {
      alert('Error saving changes');
    });
  };

  return { save, isSaving: isLoading };
};
