import { Box } from 'components/common/Box/Box';
import { Table } from 'components/common/Table/Table';
import { useDatasetImportsTable } from 'hooks/react-table/useDatasetImportsTable';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';
import { getFormattedDate } from 'utils/dateUtils';
import { EmptyDatasetInfoBox } from '../EmptyDatasetInfoBox';
import { DatasetJobs } from './DatasetJobs/DatasetJobs';

export const DatasetOverview = () => {
  const { projectId, organizationId, datasetId } = useIntParams<RouteParams.Dataset>();
  const datasetQuery = useQuery('GetDatasetById', {
    params: { organizationId, projectId, datasetId },
  });
  const { pathTo } = useRouterUtils();

  const datasetImportHistoriesQuery = useQuery('GetDatasetImportHistoriesWithPagination', {
    params: { organizationId, projectId },
    query: {
      datasetId,
      pageSize: 5,
    },
  });

  const { instance: datasetImportsTableInstance } = useDatasetImportsTable({
    rows: datasetImportHistoriesQuery.data?.results,
  });

  return (
    <div>
      {datasetQuery.data?.dataitemCount === 0 && datasetImportHistoriesQuery.data?.results.length === 0 && (
        <div className="mb-6">
          <EmptyDatasetInfoBox />
        </div>
      )}

      <>
        <div className="mb-6 mt-3 flex gap-2">
          <Box className="w-1/3">
            <div className="mb-1 text-xs font-thin uppercase text-slate-500">Dataitems</div>
            <div className="text-lg text-slate-600">{datasetQuery.data?.dataitemCount}</div>
          </Box>

          <Box className="w-1/3">
            <div className="mb-1 text-xs font-thin uppercase text-slate-500">Last Import</div>
            <div className="text-lg text-slate-600">
              {datasetImportHistoriesQuery.data && datasetImportHistoriesQuery.data?.results.length > 0
                ? getFormattedDate(datasetImportHistoriesQuery.data.results[0].updatedAt)
                : '-'}
            </div>
          </Box>

          <Box className="w-1/3">
            <div className="mb-1 text-xs font-thin uppercase text-slate-500">Scheduled Imports</div>
            <div className="text-lg text-slate-600">INACTIVE</div>
          </Box>
        </div>

        <div className="mb-6">
          <div className="mb-2 mt-4 flex items-center gap-2">
            <div className="text-md font-medium text-slate-800">Recent Imports</div>

            <div className="ml-auto justify-self-end">
              <Link to={pathTo('DATASET_IMPORT_HISTORY')} className="text-sm">
                View All
              </Link>
            </div>
          </div>

          <Table instance={datasetImportsTableInstance} />
        </div>

        <div className="mb-6">
          <DatasetJobs />
        </div>
      </>
    </div>
  );
};
