import { AppRoutes } from 'appRoutes';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { LayoutContentTabs } from 'components/common/Layout/LayoutContentTabs';
import { LayoutLoadingSkeleton } from 'components/common/Layout/LayoutLoadingSkeleton';
import { Typography } from 'components/common/Typography';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { JobExport } from '../JobExport/JobExport';
import { JobDataitems } from './JobDataitems/JobDataitems';
import { JobOverview } from './JobOverview/JobOverview';
import { JobReports } from './JobReports/JobReports';
import { JobSettings } from './JobSettings/JobSettings';
import { JobTasks } from './JobTasks/JobTasks';
import { JobUsers } from './JobUsers/JobUsers';

const JobDetails = () => {
  const location = useLocation();
  const { pathTo } = useRouterUtils();
  const { jobId, projectId, organizationId } = useIntParams<RouteParams.Job>();

  const jobQuery = useQuery('GetJobById', {
    params: { organizationId, projectId, jobId },
  });
  const annotationClassesQuery = useQuery('GetAnnotationClasses', {
    params: { organizationId, projectId, jobId },
  });
  const jobUsersQuery = useQuery('GetJobUsers', {
    params: { organizationId, projectId, jobId },
  });

  const workflowQuery = useQuery('GetWorkflowById', {
    params: { organizationId, projectId, workflowId: jobQuery.data?.workflowId! },
  });

  if (jobQuery.isLoading) {
    return <LayoutLoadingSkeleton />;
  }

  if (jobQuery.isError) {
    return <ErrorPage />;
  }

  const userGroups = workflowQuery.data?.workflowUserGroups?.map(({ id }) => id);
  const showUsersTabWarning = userGroups?.some(
    (group) => !jobUsersQuery.data?.some((e) => e.workflowUserGroupId === group)
  );

  const showTasksTabWarnign = !annotationClassesQuery.data?.some(
    ({ enabled, properties }) => enabled && properties.length > 0
  );

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            {jobQuery.data?.name}
          </Typography>
        </div>

        <div className="flex gap-2">
          <Link
            className="flex items-center gap-2 border-r border-t border-b border-slate-200 px-4 py-1.5 text-sm font-medium text-slate-600 first:rounded-l first:border-l last:rounded-r hover:bg-slate-50"
            to={{
              pathname: pathTo('WORKSPACE', {
                organizationId,
                projectId,
                jobId,
                jobDataitemId: localStorage.getItem(`workspace-${projectId}-${jobId}-prev-dataitem-id`) || undefined,
              }),
              state: { referrer: location.pathname },
            }}
          >
            <MdOutlineOpenInNew />
            Workspace
          </Link>
        </div>
      </LayoutContentHeader>

      <LayoutContentTabs
        items={[
          {
            to: pathTo('JOB'),
            label: 'Overview',
          },
          {
            to: pathTo('JOB_TASKS'),
            label: (
              <div className="flex items-center gap-1">
                Tasks {showTasksTabWarnign && <RiErrorWarningLine className="text-amber-400" />}
              </div>
            ),
            exact: false,
          },
          {
            to: pathTo('JOB_USERS'),
            label: (
              <div className="flex items-center gap-1">
                Users {showUsersTabWarning && <RiErrorWarningLine className="text-amber-400" />}
              </div>
            ),
          },
          {
            to: pathTo('JOB_DATAITEMS'),
            label: 'Dataitems',
          },
          // {
          //   to: pathTo('JOB_WORKFLOW'),
          //   label: 'Workflow',
          // },
          {
            to: pathTo('JOB_EXPORTS'),
            label: 'Exports',
          },
          {
            to: pathTo('JOB_REPORTS'),
            label: 'Reports',
          },
          {
            to: pathTo('JOB_SETTINGS'),
            label: 'Settings',
          },
        ]}
      />

      <LayoutContent>
        <Switch>
          <Route path={AppRoutes.JOB} component={JobOverview} exact />
          <Route path={AppRoutes.JOB_TASKS} component={JobTasks} />
          <Route path={AppRoutes.JOB_DATAITEMS} component={JobDataitems} />
          <Route path={AppRoutes.JOB_USERS} component={JobUsers} />
          {/* <Route path={AppRoutes.JOB_WORKFLOW} component={JobWorkflow} /> */}
          <Route path={AppRoutes.JOB_EXPORTS} component={JobExport} />
          <Route path={AppRoutes.JOB_SETTINGS} component={JobSettings} />
          <Route path={AppRoutes.JOB_REPORTS} component={JobReports} />
        </Switch>
      </LayoutContent>
    </LayoutContentBody>
  );
};

export default JobDetails;
