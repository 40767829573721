import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { DropdownButtonItem } from 'components/common/DropdownButtonItem/DropdownButtonItem';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useMemo } from 'react';
import { IoTrashBin } from 'react-icons/io5';
import { RiMore2Fill } from 'react-icons/ri';
import { AddJobUsersModal } from './AddJobUsersModal';

export const JobUsers = () => {
  const { organizationId, projectId, jobId } = useIntParams();
  const jobUsersQuery = useQuery('GetJobUsers', {
    params: { organizationId, projectId, jobId },
  });
  const { mutateAsync: removeJobUser } = useMutation('RemoveJobUser');
  const { data: jobDataitemDistribution } = useQuery('GetJobDataitemDistributionReport', {
    params: {
      organizationId,
      projectId,
      jobId,
    },
  });
  const parsedData = useMemo(() => {
    if (!jobDataitemDistribution) {
      return {};
    }

    const normalizedData = jobDataitemDistribution.reduce((acc, item) => {
      const { assigneeId, count, workflowState } = item;

      if (!assigneeId) {
        return acc;
      }

      acc[assigneeId] = acc[assigneeId] || { assigneeId, data: {} };
      acc[assigneeId].data[workflowState] = count;

      return acc;
    }, {} as Record<string, { data: { [key: string]: number }; assigneeId: number }>);

    return normalizedData;
  }, [jobDataitemDistribution]);

  return (
    <div>
      <div className="my-2 text-right">
        <Dialog render={() => <AddJobUsersModal />}>
          <Button>Add Users</Button>
        </Dialog>
      </div>

      <table className="table-primary">
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Assignment Breakdown</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {jobUsersQuery.data?.length === 0 && (
            <tr>
              <td colSpan={100} className="p-4 text-center text-slate-500">
                No Users
              </td>
            </tr>
          )}

          {jobUsersQuery.data?.map(({ id, user: { id: userId, email }, workflowUserGroupId: group }) => (
            <tr key={id}>
              <td>{email}</td>
              <td>{group}</td>
              <td>
                {parsedData[userId]?.data &&
                  Object.entries(parsedData[userId].data).map(([state, count]) => (
                    <div key={state} className="mr-1 inline-block">
                      <div className="inline-flex gap-2 rounded-l border border-r-0 border-slate-200 bg-white px-2 py-0.5 text-slate-600">
                        {state}
                      </div>
                      <div className="inline-flex gap-2 rounded-r border border-slate-200 bg-white px-2 py-0.5 text-slate-500">
                        {count}
                      </div>
                    </div>
                  ))}
              </td>

              <td className="text-right">
                <Dropdown
                  render={() => (
                    <div>
                      <DropdownButtonItem
                        onClick={() =>
                          removeJobUser({
                            params: {
                              organizationId,
                              projectId,
                              jobId,
                              jobUserId: id,
                            },
                          })
                        }
                      >
                        <IoTrashBin /> Remove
                      </DropdownButtonItem>
                    </div>
                  )}
                >
                  <Button icon={<RiMore2Fill />} variant="icon-rounded" />
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
