import { VideoEditorContext } from 'components/Workspace2/VideoEditorContext';
import { VideoTimelineFollowCurrentFrameHelper } from 'components/Workspace2/VideoTimelineFollowCurrentFrameHelper';
import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { videoTimelineState } from 'components/Workspace2/state/videoTimelineState';
import { annotationAtomsAtom, selectedAnnotationInstanceAtom } from 'components/Workspace2/useAnnotationsStore';
import { Atom, useAtom, useSetAtom } from 'jotai';
import { useContext, useEffect, useRef, useState } from 'react';
import { domUtils } from 'utils/domUtils';
import { VideoTimelineState, videoTimelineConfig } from '../VideoTimeline';
import { VideoTimelineHoveredFrame } from './VideoTimelineHoveredFrame';
import { VideoTimelinePlayheadBody } from './VideoTimelinePlayheadBody';
import { VideoTimelineRow } from './VideoTimelineRow';
import { useVideoTimelineYScrollSync } from './useVideoTimelineYScrollSync';

const VIDEO_TIMELINE_EXTRA_PADDING_RIGHT = 100;

export const VideoTimelineKeyframesColumn = () => {
  const [annotationAtoms] = useAtom(annotationAtomsAtom);
  const [showSelectionOnly] = useAtom(videoTimelineState.showSelectionOnly);
  const [selectedAnnotationInstance] = useAtom(selectedAnnotationInstanceAtom);
  const items = showSelectionOnly
    ? selectedAnnotationInstance !== undefined
      ? [selectedAnnotationInstanceAtom as Atom<AnnotationInstanceDTO>]
      : []
    : annotationAtoms;

  const { videoEditor } = useContext(VideoEditorContext); // @todo use atom
  const [frameCount] = useAtom(videoEditorState.frameCountAtom);
  const setXOffset = useSetAtom(VideoTimelineState.xOffsetAtom);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const playheadRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { FRAME_WIDTH_IN_PX } = videoTimelineConfig;
  const [isScrubbing, setIsScrubbing] = useState(false);
  const seekTo = videoEditor ? videoEditor.video.seekTo : () => {};

  const getFrameIndexFromMouseEvent = (event: React.MouseEvent) => {
    if (!containerRef.current) {
      return -1;
    }

    const clientRect = containerRef.current.getBoundingClientRect();
    const x = Math.min(Math.max(FRAME_WIDTH_IN_PX, event.pageX - clientRect.x));
    const frame = Math.min(Math.max(1, Math.floor(x / FRAME_WIDTH_IN_PX)), frameCount);
    return frame;
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    setIsScrubbing(true);
    const frame = getFrameIndexFromMouseEvent(event);
    seekTo(frame);
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    const frame = getFrameIndexFromMouseEvent(event);

    if (domUtils.isMouseButtonPressed(event.nativeEvent)) {
      seekTo(frame);
    }
  };

  const handleMouseMoveOnDocument = (event: MouseEvent) => {
    const frame = getFrameIndexFromMouseEvent(event as any);
    if (frame !== undefined) {
      seekTo(frame);
    }
  };

  const handleMouseUpOnDocument = () => {
    setIsScrubbing(false);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const xOffset = (event.target as HTMLDivElement).scrollLeft;
    setXOffset(xOffset);
  };

  useEffect(() => {
    if (isScrubbing) {
      document.addEventListener('mousemove', handleMouseMoveOnDocument);
      document.addEventListener('mouseup', handleMouseUpOnDocument);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMoveOnDocument);
      document.removeEventListener('mouseup', handleMouseUpOnDocument);
    };
  }, [isScrubbing, handleMouseMoveOnDocument, handleMouseUpOnDocument]);

  useVideoTimelineYScrollSync(scrollContainerRef);

  return (
    <>
      <VideoTimelineFollowCurrentFrameHelper scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="relative flex-1 overflow-scroll outline-none custom-scrollbar"
        onScroll={handleScroll}
        onKeyDown={(e) => e.preventDefault()}
        tabIndex={-1}
      >
        <div
          className="relative min-h-full min-w-full"
          ref={containerRef}
          style={{ width: (frameCount + 1) * FRAME_WIDTH_IN_PX + VIDEO_TIMELINE_EXTRA_PADDING_RIGHT }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
        >
          <VideoTimelineHoveredFrame parentRef={containerRef} />
          <VideoTimelinePlayheadBody ref={playheadRef} />

          {items.map((atom) => (
            <VideoTimelineRow key={`${atom}`} atom={atom} />
          ))}

          <div
            className="absolute top-0 right-0 h-full bg-black/20"
            style={{ width: VIDEO_TIMELINE_EXTRA_PADDING_RIGHT }}
          ></div>
        </div>
      </div>
    </>
  );
};
