import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { useAtom } from 'jotai';
import { videoTimelineConfig, VideoTimelineState } from '../VideoTimeline';
import { VideoTimelinePlayheadHeader } from './VideoTimelinePlayheadHeader';

// @todo optimize

export const VideoTimelineHeader = () => {
  const [xOffset] = useAtom(VideoTimelineState.xOffsetAtom);
  const { FRAME_WIDTH_IN_PX } = videoTimelineConfig;
  const headingColumnWidth = 320;
  const [frameCount] = useAtom(videoEditorState.frameCountAtom);

  return (
    <div className="flex" style={{ height: 16 }}>
      <div style={{ width: headingColumnWidth }}></div>
      <div className="flex-1 overflow-hidden">
        <div
          className="items-cente relative flex "
          style={{
            width: frameCount * FRAME_WIDTH_IN_PX,
            fontSize: 9,
            marginLeft: -xOffset,
          }}
        >
          {[...Array(frameCount)].map((_, i) => (
            <div key={i} className="border-r border-white/10 text-center" style={{ width: FRAME_WIDTH_IN_PX }}>
              {i !== 0 && i % 5 == 0 ? i : ''}
            </div>
          ))}

          <VideoTimelinePlayheadHeader />
        </div>
      </div>
    </div>
  );
};
