import { DropdownButtonItem } from 'components/common/DropdownButtonItem/DropdownButtonItem';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { AnnotationClassPropertyType } from 'shared/enums';

interface Props {
  selectedAnnotationClassId: number;
}

const DROPDOWN_OPTIONS = [
  {
    label: 'Binary Classification',
    type: AnnotationClassPropertyType.BOOLEAN,
  },
  {
    label: 'Multiclass Classification',
    type: AnnotationClassPropertyType.ENUM,
  },
  {
    label: 'Free-Form Input',
    type: AnnotationClassPropertyType.STRING,
  },
  {
    label: 'Numeric Input',
    type: AnnotationClassPropertyType.NUMBER,
  },
];

export const CreateTaskDropdown = ({ selectedAnnotationClassId }: Props) => {
  const { organizationId, projectId, jobId } = useIntParams();
  const { navigateTo } = useRouterUtils();
  const { mutateAsync: createAnnotationClassProperty } = useMutation('CreateAnnotationClassProperty');

  async function handleCreateAnnotationClassProperty(type: number) {
    const annotationClassProperty = await createAnnotationClassProperty({
      data: { type },
      params: {
        organizationId,
        projectId,
        jobId,
        annotationClassId: selectedAnnotationClassId,
      },
    });
    navigateTo('JOB_TASKS_ANNOTATION_CLASS_PROPERTY', {
      annotationClassPropertyId: annotationClassProperty.id,
    });
  }

  return (
    <div>
      {DROPDOWN_OPTIONS.map(({ label, type }) => (
        <div className="border-b">
          <DropdownButtonItem onClick={() => handleCreateAnnotationClassProperty(type)}>{label}</DropdownButtonItem>
        </div>
      ))}
    </div>
  );
};
