import { Settings } from 'components/OryKratos/Settings';
import { AuthLayout } from './AuthLayout';

export const UpdatePassword = () => {
  return (
    <AuthLayout>
      <Settings title="Update Your Password" showProfile={false} />
    </AuthLayout>
  );
};
