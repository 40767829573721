import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { Breadcrumbs } from './Breadcrumbs';
import { ProfileMenu } from './ProfileMenu';

export const Header = () => {
  const { organizationId, projectId, jobId, datasetId } = useIntParams<any>();

  const { data: organization } = useQuery('GetOrganizationById', {
    params: { organizationId },
  });

  const { data: project } = useQuery('GetProjectById', {
    params: { organizationId, projectId },
  });

  const { data: job } = useQuery('GetJobById', {
    params: { organizationId, projectId, jobId },
  });

  const { data: dataset } = useQuery('GetDatasetById', {
    params: { organizationId, projectId, datasetId },
  });

  return (
    <div
      className="layout-content-padding mb-4 flex w-full items-center justify-between rounded-lg border-b border-slate-100 py-2"
      style={{ background: '#f8fafc26' }}
    >
      <Breadcrumbs
        omit={['organizations']}
        replaceResourceIdWithName={{
          organizations: organization?.name,
          projects: project?.name,
          jobs: job?.name,
          datasets: dataset?.name,
        }}
      />

      <div className="ml-auto flex gap-2">
        {/* <Button variant="icon-rounded" icon={<IoMdNotificationsOutline />} title="Notifications" /> */}

        <ProfileMenu />
      </div>
    </div>
  );
};
