import { head, last } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export const usePagination = () => {
  const [pageCursor, setPageCursor] = useState<{ after?: string; before?: string }>({});
  const [page, setPage] = useState<PaginatedResponse<any> | null>(null);

  return {
    pageCursor,
    hasPrev: useMemo(() => !!page?.pageInfo.hasPrev, [page]),
    hasNext: useMemo(() => !!page?.pageInfo.hasNext, [page]),
    prev: useCallback(() => page?.results.length && setPageCursor({ before: head(page.results)!.id }), [page]),
    next: useCallback(() => page?.results.length && setPageCursor({ after: last(page.results)!.id }), [page]),
    setPage,
  };
};
