import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';
import { Tag } from 'components/common/Tag/Tag';
import { DatasetImportHistoryStatus, mapDatasetImportHistoryStatusToDisplayName } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { isUndefined } from 'lodash';
import { AiOutlineLoading } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { getEllapsedTime, getFormattedDate } from 'utils/dateUtils';
import { useRouterUtils } from '../useRouterUtils';

interface Props {
  rows?: DatasetImportHistoryEntryDTO[];
}

export const useDatasetImportsTable = ({ rows }: Props) => {
  const { pathTo } = useRouterUtils();
  const columnHelper = createColumnHelper<DatasetImportHistoryEntryDTO>();
  const showDatasetColumn = isUndefined(useIntParams().datasetId);

  const columns = [
    columnHelper.accessor('id', {
      header: 'Import Id',
    }),

    columnHelper.accessor('datasetId', {
      header: 'Dataset',
      cell: (data) => {
        const datasetId: DatasetDTO['id'] = data.row.getValue('datasetId');
        return <Link to={pathTo('DATASET', { datasetId })}>{data.row.original?.dataset?.name}</Link>;
      },
    }),
    columnHelper.accessor('createdAt', {
      header: 'Started At',
      cell: (data) => {
        const createdAt: string = data.row.getValue('createdAt');
        return getFormattedDate(createdAt);
      },
    }),
    columnHelper.accessor('finishedAt', {
      header: 'Completed At',
      cell: (data) => {
        const finishedAt: string = data.row.getValue('finishedAt');
        return finishedAt ? getFormattedDate(finishedAt) : '-';
      },
    }),
    columnHelper.display({
      header: 'Duration',
      cell: (data) => {
        const createdAt: string = data.row.getValue('createdAt');
        const finishedAt: string = data.row.getValue('finishedAt');
        return finishedAt ? getEllapsedTime(createdAt, finishedAt) : '-';
      },
    }),
    columnHelper.accessor('insertedCount', {
      header: '# Inserted',
      cell: (data) => {
        const value = data.getValue();
        return (
          <span className={classNames({ 'font-medium': value !== 0, 'text-slate-400': value === 0 })}>{value}</span>
        );
      },
    }),
    columnHelper.accessor('updatedCount', {
      header: '# Updated',
      cell: (data) => {
        const value = data.getValue();
        return (
          <span className={classNames({ 'font-medium': value !== 0, 'text-slate-400': value === 0 })}>{value}</span>
        );
      },
    }),
    columnHelper.accessor('deletedCount', {
      header: '# Deleted',
      cell: (data) => {
        const value = data.getValue();
        return (
          <span className={classNames({ 'font-medium': value !== 0, 'text-slate-400': value === 0 })}>{value}</span>
        );
      },
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (data) => {
        const status = data.getValue();

        return (
          <Tag
            variant={
              (status === DatasetImportHistoryStatus.COMPLETED && 'success') ||
              (status === DatasetImportHistoryStatus.FAILED && 'error') ||
              'default'
            }
          >
            <span className="uppercase">{mapDatasetImportHistoryStatusToDisplayName[status]}</span>
            {status === DatasetImportHistoryStatus.RUNNING && <AiOutlineLoading className="animate-spin" />}
          </Tag>
        );
      },
    }),
  ];

  const instance = useReactTable({
    data: rows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnVisibility: {
        datasetId: showDatasetColumn,
      },
    },
  });

  return {
    instance,
  };
};
