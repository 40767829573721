import {
  FloatingContext,
  ElementProps,
  useFloating,
  offset,
  flip,
  shift,
  useInteractions,
  useClick,
  useDismiss,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';
import classNames from 'classnames';
import { useLatestRef } from 'hooks/useLatestRef';

import React, { cloneElement, createContext, useEffect, useState } from 'react';

interface Props {
  isOpen?: boolean;
  render: (props: { close: () => void }) => React.ReactNode;
  children: JSX.Element;
  className?: string;
}

export const DropdownContext = createContext<{ close: () => void }>(null!);

const useCustomDismiss = (context: FloatingContext): ElementProps => {
  const onOpenChangeRef = useLatestRef(context.onOpenChange);

  return {
    floating: {
      onClick(event: any) {
        // Default behavior: close dropdown on button click

        const el =
          event.target.tagName === 'BUTTON'
            ? event.target
            : event.nativeEvent.path?.find((e: any) => e.tagName === 'BUTTON');

        if (!el || el.dataset.preventDropdownClose) {
          return;
        }

        context.events.emit('dismiss');
        onOpenChangeRef.current(false);
      },
    },
  };
};

export const Dropdown = ({ render, className, isOpen: initialIsOpen = false, children }: Props) => {
  const [isOpen, setIsOpen] = useState(() => initialIsOpen);

  const { x, y, reference, floating, strategy, context, refs, update } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [offset(3), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, {
      outsidePress: true,
    }),
    useCustomDismiss(context),
  ]);

  useEffect(() => {
    if (!isOpen || !refs.reference.current || !refs.floating.current) {
      return;
    }

    return autoUpdate(refs.reference.current, refs.floating.current, update);
  }, [isOpen, refs.reference, refs.floating, update]);

  const containerClasses = classNames(
    'bg-white',
    'dark:bg-[#1A1D2B]',
    'border',
    'border-slate-200',
    'dark:border-slate-800',
    'rounded-md',
    'text-sm',
    'shadow',
    'overflow-hidden'
  );

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref: reference, ...children.props }))}
      <FloatingPortal>
        {isOpen && (
          <div
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              },
            })}
          >
            <DropdownContext.Provider value={{ close: () => setIsOpen(false) }}>
              <div className={className || containerClasses}>
                {render({
                  close: () => setIsOpen(false),
                })}
              </div>
            </DropdownContext.Provider>
          </div>
        )}
      </FloatingPortal>
    </>
  );
};
