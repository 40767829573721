import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';

export const OrganizationListing = () => {
  const { data: organizations } = useQuery('GetOrganizations', {});
  const { pathTo } = useRouterUtils();

  return (
    <table className="table-primary">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
        </tr>
      </thead>

      <tbody>
        {organizations &&
          organizations.map(({ id, name }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>
                <Link to={pathTo('ORGANIZATION', { organizationId: id })}>{name}</Link>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
