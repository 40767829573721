import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';

interface Props {
  scene: any;
}

const SCROLLBAR_SIZE = 6;

function getScrollbarSize({ w, t, scale, v }: any) {
  let f = t > 0 ? w * scale + t : Math.max(w * scale, w * scale + v - (w * scale + t));

  const size = Math.min((v * 100) / f, 100);
  const offset = Math.max((-t * 100) / f, 0);

  return {
    size,
    offset,
  };
}

export const VirtualScrollbars2 = ({ scene }: Props) => {
  const [horizontalScrollbar, setHorizontalScrollbar] = useState({
    size: 0,
    offset: 0,
  });

  const [verticalScrollbar, setVerticalScrollbar] = useState({
    size: 0,
    offset: 0,
  });

  useEffect(() => {
    function update() {
      const world = scene.world.getState();
      const view = scene.viewport.getState();
      const transform = scene.transform.getState();

      {
        const { size, offset } = getScrollbarSize({
          w: world.width,
          t: transform.x,
          scale: transform.scale,
          v: view.width,
        });

        setHorizontalScrollbar({
          size,
          offset,
        });
      }

      {
        const { size, offset } = getScrollbarSize({
          w: world.height,
          t: transform.y,
          scale: transform.scale,
          v: view.height,
        });

        setVerticalScrollbar({
          size,
          offset,
        });
      }
    }

    const unsubs = [scene.world.subscribe(update), scene.viewport.subscribe(update), scene.transform.subscribe(update)];

    return () => {
      unsubs.forEach((unsub) => unsub());
    };
  }, [scene.world, scene.viewport, scene.transform]);

  const scrollTrackClassNames = 'absolute z-10 m-1';
  const scrollThumbClassNames = 'absolute rounded-lg border';
  const scrollThumbStyles = {
    borderColor: 'rgba(255, 255, 255, 0.5)',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  };

  const draggableXRef = useRef<HTMLDivElement | null>(null);
  const draggableYRef = useRef<HTMLDivElement | null>(null);

  const [isDraggedX, setIsDraggedX] = useState(false);
  const [isDraggedY, setIsDraggedY] = useState(false);

  const handleDragX = (_: DraggableEvent, e: DraggableData) => {
    const { x, scale } = scene.transform.getState();
    const { width } = scene.viewport.getState();
    const { width: worldWidth } = scene.world.getState();
    scene.transform.setState({ x: x - e.deltaX * (scale * (worldWidth / width)) });
  };

  const handleDragY = (_: DraggableEvent, e: DraggableData) => {
    const { y, scale } = scene.transform.getState();
    const { height } = scene.viewport.getState();
    const { height: worldHeight } = scene.world.getState();
    scene.transform.setState({ y: y - e.deltaY * (scale * (worldHeight / height)) });
  };

  return (
    <>
      <DraggableCore
        nodeRef={draggableXRef}
        onStart={() => setIsDraggedX(true)}
        onStop={() => setIsDraggedX(false)}
        onDrag={handleDragX}
      >
        <div
          ref={draggableXRef}
          className={classNames(scrollTrackClassNames, 'bottom-0 left-0', {
            hidden: !isDraggedX && horizontalScrollbar.size === 100,
          })}
          style={{
            height: SCROLLBAR_SIZE,
            right: SCROLLBAR_SIZE,
            transform: 'translateZ(0)',
          }}
        >
          <div
            className={classNames(scrollThumbClassNames, 'h-full')}
            style={{
              ...scrollThumbStyles,
              left: horizontalScrollbar.offset + '%',
              width: horizontalScrollbar.size + '%',
            }}
          ></div>
        </div>
      </DraggableCore>

      <DraggableCore
        nodeRef={draggableYRef}
        onStart={() => setIsDraggedY(true)}
        onStop={() => setIsDraggedY(false)}
        onDrag={handleDragY}
      >
        <div
          ref={draggableYRef}
          className={classNames(scrollTrackClassNames, 'top-0 right-0', {
            hidden: !isDraggedY && verticalScrollbar.size === 100,
          })}
          style={{
            width: SCROLLBAR_SIZE,
            bottom: SCROLLBAR_SIZE,
            transform: 'translateZ(0)',
          }}
        >
          <div
            className={classNames(scrollThumbClassNames, 'w-full')}
            style={{
              ...scrollThumbStyles,
              top: verticalScrollbar.offset + '%',
              height: verticalScrollbar.size + '%',
            }}
          ></div>
        </div>
      </DraggableCore>
    </>
  );
};
