import { Box } from 'components/common/Box/Box';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';

export const JobOverviewReport = () => {
  const { organizationId, projectId, jobId } = useIntParams();
  const jobOverviewReportQuery = useQuery('GetJobOverviewReport', { params: { organizationId, projectId, jobId } });
  const totalJobDataitems = jobOverviewReportQuery.data?.reduce((acc, val) => acc + val.count, 0);

  return (
    <Box className="w-full">
      <div className="text-xs font-thin text-slate-800 mb-1 uppercase">DATAITEM STATES OVERVIEW</div>

      <div className="flex-1 mt-4 items-start">
        <div className="flex items-center gap-3 border-b py-1 uppercase text-xxs text-slate-500">
          <div className="w-1/3">Workflow State</div>
          <div className="w-1/3 text-right"># Dataitems</div>
          <div className="w-1/3 text-right">%</div>
        </div>

        {totalJobDataitems &&
          jobOverviewReportQuery.data?.map(({ count, workflowStateId, workflowStateName }) => (
            <div
              key={workflowStateId}
              className="flex items-center gap-3 text-sm border-b border-slate-200 last:border-b-0 py-1"
            >
              <span className="w-1/3 text-left flex items-center gap-2">
                <span>{workflowStateName}</span>
              </span>

              <span className="w-1/3 text-right text-xs">{count}</span>
              <span className="w-1/3 text-right font-medium">{Math.round((count * 100) / totalJobDataitems)}%</span>
            </div>
          ))}
      </div>
    </Box>
  );
};
