interface Props {
  name: string;
  options: { label: string; value: string }[];
  value: string;
  onChange: (val: string) => void;
}

export const RadioGroup = ({ name, options, value, onChange }: Props) => {
  // @todo styling

  return (
    <div>
      {options.map(({ value: optionValue, label }) => (
        <div key={label}>
          <label className="flex items-center gap-1">
            <input
              type="radio"
              name={`${name}-${optionValue}`}
              value={optionValue}
              checked={value === optionValue}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />

            <span>{label}</span>
          </label>
        </div>
      ))}
    </div>
  );
};
