import { PropsWithChildren } from 'react';

interface Props {}

export const ModalLayoutFooter = ({ children }: PropsWithChildren<Props>) => {
  return (
    <footer className=" border-slate-100 flex items-center p-4 border-t justify-end">
      {children}
    </footer>
  );
};
