import axios from 'axios';
import { noop } from 'lodash';

const axiosStorageInstance = axios.create({
  baseURL: globalConfig.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const storageService = {
  upload: ({
    datasetId,
    formData,
    onUploadProgress = noop,
  }: {
    datasetId: DatasetDTO['id'];
    formData: FormData;
    onUploadProgress: any;
  }) =>
    axiosStorageInstance({
      method: 'post',
      url: `/v2/datasets/${datasetId}`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    }),
};
