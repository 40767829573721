import { api } from 'api/api';
import { useQuery } from 'hooks/useQuery';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { usePagination } from './usePagination';

export type PaginatedQuery<K extends keyof typeof api.queries, T extends typeof api.queries> = UseQueryResult<
  Awaited<ReturnType<T[K]>>,
  unknown
>;

export function usePaginatedQuery<K extends keyof typeof api.queries, T extends typeof api.queries>(
  type: K,
  payload: Parameters<T[K]>[0],
  options?: UseQueryOptions<T[K]>
): PaginatedQuery<K, T> & { pagination: ReturnType<typeof usePagination> } {
  const pagination = usePagination();
  const query = useQuery(
    type,
    useMemo(() => _.merge(payload || {}, { query: { ...pagination.pageCursor } }), [pagination.pageCursor, payload]),
    {
      keepPreviousData: true,
      ...options,
    }
  );

  useEffect(() => {
    if (query.data) {
      // @ts-ignore
      pagination.setPage(query.data);
    }
  }, [query.data, pagination]);

  return { ...query, pagination };
}
