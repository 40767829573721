import Spinner from 'components/common/Spinner';
import { useAtom } from 'jotai';
import React from 'react';
import { VirtualScrollbars2 } from './VirtualScrollbars2';
import { videoEditorState } from './state/videoEditorState';

interface Props {
  videoEditorRef: any;
  scene: any;
}

export const VideoEditorView = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [canPlay] = useAtom(videoEditorState.canPlayAtom);
  const [loadedData] = useAtom(videoEditorState.loadedDataAtom);
  const [isError] = useAtom(videoEditorState.isErrorAtom);
  const [isFrameSyncError] = useAtom(videoEditorState.isFrameSyncErrorAtom);

  return (
    <>
      <div className="relative h-full w-full overflow-hidden bg-black" ref={ref}>
        {!canPlay && !isError && !loadedData && (
          <div className="absolute inset-0 flex items-center justify-center text-slate-50">
            <Spinner />
          </div>
        )}

        {isError && (
          <div className="absolute inset-0 z-10 inline flex items-center justify-center text-sm text-neutral-100 opacity-90 bg-black">
            Error loading video
          </div>
        )}

        {isFrameSyncError && (
          <div className="absolute inset-0 z-10 inline flex items-center justify-center text-sm text-neutral-100 opacity-90 bg-black">
            FRAME SYNC ERROR
          </div>
        )}

        {props.scene && <VirtualScrollbars2 scene={props.scene} />}
      </div>
    </>
  );
});
