import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { useEffect, useState } from 'react';
import { videoTimelineConfig } from '../VideoTimeline';
import { useAtom } from 'jotai';

interface Props {
  parentRef: React.RefObject<HTMLDivElement>;
}

export const VideoTimelineHoveredFrame = ({ parentRef }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [hoveredFrame, setHoveredFrame] = useState<number | null>(null);
  const [cursorXPosition, setCursorXPosition] = useState(0);
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);

  const { FRAME_WIDTH_IN_PX } = videoTimelineConfig;

  const handleMouseMove = (event: MouseEvent) => {
    if (!parentRef.current) {
      return;
    }

    // @todo avoid layout trashing
    const clientRect = parentRef.current.getBoundingClientRect();
    const x = Math.min(Math.max(FRAME_WIDTH_IN_PX * 1.5, event.pageX - clientRect.x + parentRef.current.scrollLeft));
    const frame = Math.floor(x / FRAME_WIDTH_IN_PX);

    setIsVisible(true);
    setCursorXPosition(x);
    setHoveredFrame(frame);
  };

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const handleScroll = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    const el = parentRef.current;
    if (!el) {
      return;
    }

    el.addEventListener('mouseenter', handleMouseEnter);
    el.addEventListener('mouseleave', handleMouseLeave);
    el.addEventListener('mousemove', handleMouseMove);
    el.addEventListener('scroll', handleScroll);

    return () => {
      el.removeEventListener('mouseenter', handleMouseEnter);
      el.removeEventListener('mouseleave', handleMouseLeave);
      el.removeEventListener('mousemove', handleMouseMove);
      el.removeEventListener('scroll', handleScroll);
    };
  }, [parentRef.current]);

  if (!isVisible || hoveredFrame === null) {
    return null;
  }

  return (
    <>
      <div
        className="pointer-events-none absolute top-0 bottom-0 z-10"
        style={{
          width: FRAME_WIDTH_IN_PX,
          left: hoveredFrame * FRAME_WIDTH_IN_PX,
          background: 'rgba(255, 255, 255, 0.05)',
        }}
      ></div>

      {currentFrame !== hoveredFrame && (
        <div
          className="pointer-events-none absolute top-0 bottom-0 z-10 bg-gray-500"
          style={{ width: 1.5, left: cursorXPosition }}
        ></div>
      )}
    </>
  );
};
