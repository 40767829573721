import {
  SubmitSelfServiceSettingsFlowBody,
  SubmitSelfServiceSettingsFlowWithProfileMethodBody,
} from '@ory/kratos-client';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import { OryFormUi } from 'components/common/OryFormUi/OryFormUi';
import { useSettingsFlow } from 'hooks/auth/useSettingsFlow';
import { useContext } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

export const ChangeEmailModal = () => {
  const { close } = useContext(DialogContext);
  const { flow, updateSettings } = useSettingsFlow();
  const emailForm =
    useForm<SubmitSelfServiceSettingsFlowWithProfileMethodBody>();
  const { handleSubmit: handleEmailSubmit } = emailForm;

  const onSubmit: SubmitHandler<SubmitSelfServiceSettingsFlowBody> = async (
    data
  ) => {
    return updateSettings(data);
  };

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Change Email"></ModalLayoutHeader>
      <ModalLayoutBody>
        {flow && (
          <FormProvider {...emailForm}>
            <form onSubmit={handleEmailSubmit(onSubmit)}>
              <OryFormUi ui={flow.ui} only="profile" />
            </form>
          </FormProvider>
        )}
      </ModalLayoutBody>
    </ModalLayout>
  );
};
