import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

export const ProjectCreateModal = () => {
  const { close } = useContext(DialogContext);
  const { organizationId } = useIntParams();
  const { mutateAsync: createProject } = useMutation('CreateProject');
  const { navigateTo } = useRouterUtils();
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const onSubmit = handleSubmit(async ({ name }) => {
    const project = await createProject({
      data: { name },
      params: { organizationId },
    });
    // @todo handle errors
    close();
    navigateTo('PROJECT', { projectId: project.id });
  });

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Create Project" />
      <ModalLayoutBody>
        <form onSubmit={onSubmit}>
          <div className="my-2">
            <Label htmlFor={'name'}>Project Name</Label>
            <InputText {...register('name')} id={'name'} required />
          </div>
        </form>
      </ModalLayoutBody>
      <ModalLayoutFooter>
        <Button label={'Create'} loading={isSubmitting} onClick={onSubmit} />
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
