import { Button } from 'components/common/Button/Button';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { Select } from 'components/common/Forms/Select/Select';
import { WorkflowTransitionType } from 'enums';
import { useForm } from 'react-hook-form';

interface Props {
  states: WorkflowStateDTO[];
  onCreate: ({
    label,
    sourceId,
    targetId,
  }: Pick<WorkflowTransitionDTO, 'label' | 'sourceId' | 'targetId' | 'type'>) => void;
}

const CreateWorkflowTransition = ({ states, onCreate }: Props) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      sourceId: '',
      targetId: '',
      label: '',
      type: WorkflowTransitionType.MANUAL,
    },
  });

  const onSubmit = handleSubmit(({ sourceId, targetId, type, label }) => {
    onCreate({
      sourceId: +sourceId,
      targetId: +targetId,
      type,
      label,
    });
  });

  // const isTargetDisabled = watch('sourceId') === '';

  return (
    <div>
      <h2>Create Transition</h2>

      <form onSubmit={onSubmit}>
        <div className="my-2">
          <Label htmlFor="sourceId">Source</Label>
          <Select
            control={control}
            id="sourceId"
            name="sourceId"
            options={states.map(({ id: value, label }) => ({ value, label }))}
          />
        </div>

        <div className="my-2">
          <Label htmlFor="targetId">Target</Label>

          <Select
            control={control}
            id="targetId"
            name="targetId"
            options={states
              .filter(({ id }) => watch('sourceId') !== id.toString())
              .map(({ id: value, label }) => ({ value, label }))}
          />
        </div>

        <div className="my-2">
          <Label htmlFor={'label'}>Label</Label>
          <InputText {...register('label')} />
        </div>

        <div className="my-2">
          <Label htmlFor="type">Type</Label>
          <Select
            control={control}
            id="type"
            name="type"
            options={[
              { value: WorkflowTransitionType.MANUAL, label: 'Manual' },
              { value: WorkflowTransitionType.AUTO, label: 'Automatic' },
            ]}
          />
        </div>

        <Button type="submit" loading={isSubmitting} label="Create" />
      </form>
    </div>
  );
};

export default CreateWorkflowTransition;
