export function HitTestFn(
  hitRegionCanvas: {
    view: HTMLCanvasElement;
    context: CanvasRenderingContext2D | null;
    clear: () => void;
    render: any;
  },
  videoEditorObjectsManager: any
) {
  return ({ x, y }: { x: number; y: number }) => {
    if (!hitRegionCanvas.context) {
      return;
    }

    const imageData = hitRegionCanvas.context.getImageData(x, y, 1, 1);
    const hitRegionColor =
      '#' + [...Array(3)].map((_, index) => imageData.data[index].toString(16).padStart(2, '0')).join('');

    return videoEditorObjectsManager.findByDataAttribute('hitRegionColor', hitRegionColor);
  };
}
