import FormError from 'components/common/FormError';
import { ApiCallStatus } from 'enums';
import React, { useEffect } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import useDatasetUpload from './useDatasetUpload';

type Props = {
  datasetId: number;
  files: File[];
  onDone: () => void;
  onUploadProgress: (progress: number) => void;
};

const DatasetUpload = ({
  datasetId,
  files,
  onDone,
  onUploadProgress,
}: Props) => {
  const { uploadFiles, uploadState } = useDatasetUpload(datasetId, onDone);

  useEffect(() => {
    uploadFiles(files);
  }, [uploadFiles, files]);

  useEffect(() => {
    onUploadProgress(uploadState.uploadProgress);
  }, [uploadState.uploadProgress, onUploadProgress]);

  return (
    <>
      <FormError type={uploadState.error} />

      {uploadState.importStatus === ApiCallStatus.SUCCESS && (
        <div className="text-lg font-semibold">Import Complete!</div>
      )}

      {uploadState.importStatus === ApiCallStatus.LOADING && (
        <div className="text-lg font-semibold flex flex-row items-center gap-2">
          <span>Importing</span>

          <AiOutlineLoading size={20} className="inline-block animate-spin" />

          {uploadState.importProgress !== 0 && (
            <span>{uploadState.importProgress}%</span>
          )}
        </div>
      )}

      {uploadState.uploadStatus === ApiCallStatus.LOADING && (
        <div className="mt-2">
          <div className="text-lg font-semibold">Uploading</div>

          {files.length > 0 && (
            <div className="text-left text-sm">
              <div className="max-h-80">
                {files.map((file: any) => (
                  <div key={file.name} className="w-64 truncate">
                    {file.name}
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* <div className="my-2">{uploadState.uploadProgress}%</div>
          <div className="h-1 relative w-full bg-slate-200 my-2">
            <div
              className="absolute inset-y-0 left-0 bg-blue-600 transition-all"
              style={{ width: `${uploadState.uploadProgress}%` }}></div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default DatasetUpload;
