import { useAtom } from 'jotai';
import { MOUSE_BUTTON } from 'modules/image-annotator/enums';
import { TransformBox2 } from 'modules/video-editor-2/transform-box/TransformBox2';
import { useContext, useEffect, useRef } from 'react';
import {
  globalAnnotationInstanceIdAtom,
  selectedAnnotationInstanceAtom,
  useAnnotationsStore,
} from '../useAnnotationsStore';
import { VideoEditorContext } from '../VideoEditorContext';

export const useObjectSelection = () => {
  const { videoEditor } = useContext(VideoEditorContext); // @todo use atom
  const [selectedAnnotationInstance] = useAtom(selectedAnnotationInstanceAtom);
  const { select } = useAnnotationsStore();
  const prevSelectionRef = useRef<any>(null);
  const [globalAnnotationInstanceId] = useAtom(globalAnnotationInstanceIdAtom);

  // Handle deselect
  useEffect(() => {
    if (!videoEditor) {
      return;
    }

    if (prevSelectionRef.current && prevSelectionRef.current.id !== selectedAnnotationInstance?.id) {
      const editorObject = prevSelectionRef.current.editorObject;

      if (editorObject) {
        const transformBox = editorObject.data('transform-box-ref');
        transformBox.items.map((e: any) => e.id).forEach(videoEditor.objects.remove);
        editorObject.data('transform-box-ref', null);
      }
    }

    prevSelectionRef.current = selectedAnnotationInstance;
  }, [videoEditor, selectedAnnotationInstance]);

  // Handle select
  useEffect(() => {
    if (!videoEditor || !selectedAnnotationInstance) {
      return;
    }

    const editorObject = (selectedAnnotationInstance as any)?.editorObject;

    if (!editorObject) {
      return;
    }

    if (editorObject.data('transform-box-ref')) {
      return;
    }

    const transformBox = TransformBox2(editorObject);
    transformBox.items.forEach(videoEditor.objects.add);
    editorObject.data('transform-box-ref', transformBox);
  }, [videoEditor, selectedAnnotationInstance]);

  // Handle events
  useEffect(() => {
    if (!videoEditor) {
      return;
    }

    // @todo custom events 'select' & 'deselect'
    const handler = (event: any) => {
      if (event.originalEvent.button !== MOUSE_BUTTON.MAIN) {
        return;
      }

      const target = event.target ? event.target.data('externalId') : null;

      if (event.target && event.target.data('selectable') !== true) {
        return;
      }

      select(target ? target : globalAnnotationInstanceId);
    };

    videoEditor.objects.scene.on('mousedown', handler);

    return () => {
      videoEditor.objects.scene.off('mousedown', handler);
    };
  }, [videoEditor, select]);
};
