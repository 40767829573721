import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import FormError from 'components/common/FormError';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { Select } from 'components/common/Forms/Select/Select';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useRouterUtils } from 'hooks/useRouterUtils';

import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorType } from 'shared/errors';

const temporaryDebugConfig = {
  endpointUrl: 'https://s3.eu-central-1.amazonaws.com',
  bucketName: 'annotorio-dev-sync',
  bucketPrefix: 'ds3',
  accessKey: 'AKIAZXKYIH2ET2BKQ55L',
  secretAccessKey: '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',
};

interface Props {
  remoteStorageResource?: RemoteStorageResourceDTO;
}

export const ConfigureJobExportRemoteStorageResourceModal = ({ remoteStorageResource }: Props) => {
  const { organizationId, projectId, jobId } = useIntParams();
  const { close } = useContext(DialogContext);
  const { navigateTo } = useRouterUtils();
  const { mutateAsync: createJobExportRemoteStorageTarget } = useMutation('CreateJobExportRemoteStorageTarget');
  const { mutateAsync: editJobExportRemoteStorageTarget } = useMutation('EditJobExportRemoteStorageTarget');

  const [formError, setFormError] = useState<any>(null);
  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      storageType: 1,
      // endpointUrl: '',
      // bucketName: '',
      // bucketPrefix: '',
      // accessKey: '',
      // secretAccessKey: '',

      ...(remoteStorageResource || temporaryDebugConfig),
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    setFormError(null);

    let res: unknown;

    if (remoteStorageResource === undefined) {
      res = await createJobExportRemoteStorageTarget({
        data,
        params: { organizationId, projectId, jobId },
      }).catch((error) => error);
    } else {
      res = await editJobExportRemoteStorageTarget({
        data: { ...remoteStorageResource, ...data },
        params: { organizationId, projectId, jobId, remoteStorageId: remoteStorageResource!.id },
      }).catch((error) => error);
    }

    if (res instanceof Error) {
      setFormError(ErrorType.GENERIC);
      return;
    }

    close();
  });

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Configure Remote Storage" />

      <ModalLayoutBody>
        <form onSubmit={onSubmit}>
          <FormError type={formError} />

          <div className="my-2">
            <Label htmlFor="storageType">Storage type</Label>

            <Select
              control={control}
              name="storageType"
              id="storageType"
              isMulti={false}
              disabled
              required
              options={[
                {
                  value: 1,
                  label: 'S3 compatible',
                },
              ]}
            ></Select>
          </div>

          <div className="my-2">
            <Label htmlFor="endpointUrl">Endpoint URL</Label>
            <InputText {...register('endpointUrl')} id="endpointUrl" />
          </div>

          <div className="my-2">
            <Label htmlFor="bucketName">Bucket name</Label>
            <InputText {...register('bucketName')} id="bucketName" />
          </div>

          <div className="my-2">
            <Label htmlFor="bucketPrefix">Bucket prefix</Label>
            <InputText {...register('bucketPrefix')} id="bucketPrefix" />
          </div>

          <div className="my-2">
            <Label htmlFor="accessKeyId">Access Key ID</Label>
            <InputText {...register('accessKey')} id="accessKeyId" />
          </div>

          <div className="my-2">
            <Label htmlFor="secretAccessKey">Secret Key</Label>
            <InputText {...register('secretAccessKey')} id="secretAccessKey" />
          </div>

          <div className="my-2 text-right">
            <Button type="submit" label={'Save'} loading={isSubmitting} />
          </div>
        </form>
      </ModalLayoutBody>
    </ModalLayout>
  );
};
