import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import { useMutation } from 'hooks/useMutation';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

export const OrganizationCreateModal = () => {
  const { close } = useContext(DialogContext);
  const { mutateAsync: createOrganization } = useMutation('CreateOrganization');
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isSubmitting },
  } = useForm();
  const { navigateTo } = useRouterUtils();

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const onSubmit = handleSubmit(async ({ name }) => {
    const organization = await createOrganization({
      data: { name },
    });
    close();
    navigateTo('ORGANIZATION', { organizationId: organization.id });
  });

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Create Organization" />

      <ModalLayoutBody>
        <form onSubmit={onSubmit}>
          <div className="my-2">
            <Label htmlFor={'name'}>Organization Name</Label>
            <InputText {...register('name', { required: true })} id={'name'} />
          </div>
        </form>
      </ModalLayoutBody>

      <ModalLayoutFooter>
        <Button label={'Create'} loading={isSubmitting} onClick={onSubmit} />
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
