import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Typography } from 'components/common/Typography';
import { ScheduledExports } from 'components/ScheduledExports/ScheduledExports';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { NoDatasetsInfoBox } from '../Datasets/NoDatasets/NoDatasetsInfoBox';
import { RecentExports } from './RecentExports/RecentExports';
import { RecentImports } from './RecentImports/RecentImports';
import { ScheduledDatasetImports } from './ScheduledDatasetImports/ScheduledDatasetImports';

export const ProjectDashboard = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();

  const datasetsQuery = useQuery('GetDatasets', {
    params: { organizationId, projectId },
  });

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Project Dashboard
          </Typography>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <div className="my-6">{datasetsQuery.data?.length === 0 && <NoDatasetsInfoBox />}</div>

        <div className="mb-8">
          <ScheduledExports />
        </div>

        <div className="mb-8">
          <RecentExports />
        </div>

        <div className="mb-8">
          <ScheduledDatasetImports />
        </div>

        <div className="mb-8">
          <RecentImports />
        </div>
      </LayoutContent>
    </LayoutContentBody>
  );
};
