import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { AnnotationClassType } from 'shared/enums';
import { attachEditorObject } from 'utils/attachEditorObject';
import { createAnnotationInstance } from 'utils/createAnnotationInstance';
import { workspaceDataitemState } from './state/workspaceDataitemState';
import { globalAnnotationInstanceIdAtom, useAnnotationsStore } from './useAnnotationsStore';

export const useInitializeWorkspaceDataitem = () => {
  const [initialized, setInitialized] = useAtom(workspaceDataitemState.isInitializedAtom);
  const { organizationId, projectId, jobId, jobDataitemId } = useIntParams();

  const annotationClassQuery = useQuery('GetAnnotationClasses', {
    params: { organizationId, projectId, jobId },
  });

  const annotationInstancesQuery = useQuery(
    'GetAnnotationInstances',
    {
      params: { organizationId, projectId, jobId, jobDataitemId },
    },
    { refetchOnMount: 'always', cacheTime: 0 }
  );

  const setGlobalAnnotationInstanceId = useSetAtom(globalAnnotationInstanceIdAtom);
  const annotationStore = useAnnotationsStore();

  useEffect(() => {
    const annotationInstances = annotationInstancesQuery.data;
    const annotationClasses = annotationClassQuery.data;

    if (initialized || !annotationInstances || !annotationClasses) {
      return;
    }

    annotationInstances.forEach((item) => {
      attachEditorObject(item); // @todo this is done in createAnnotationInstance too; should be we have one base class for Client Annotation Instances?
      annotationStore.append(item);
    });

    const globalAnnotationInstance = annotationInstances.find(
      (e) => e.annotationClass.type === AnnotationClassType.DOCUMENT
    );
    const globalAnnotationClass = annotationClassQuery.data.find((e) => e.type === AnnotationClassType.DOCUMENT);

    if (globalAnnotationClass && !globalAnnotationInstance) {
      const globalInstance = createAnnotationInstance({
        annotationClass: globalAnnotationClass,
        data: Object.fromEntries(globalAnnotationClass.properties.map((e) => [e.key, null])),
      });

      annotationStore.append(globalInstance);
      annotationStore.select(globalInstance.id);
      setGlobalAnnotationInstanceId(globalInstance.id);
    }

    if (globalAnnotationInstance) {
      annotationStore.select(globalAnnotationInstance.id);
      setGlobalAnnotationInstanceId(globalAnnotationInstance.id);
    }

    setInitialized(true);
  }, [initialized, annotationClassQuery.data, annotationInstancesQuery.data]);
};
