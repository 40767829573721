import { AppRoutes } from 'appRoutes';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AccountRecovery } from './AccountRecovery';
import { AuthLayout } from './AuthLayout';
import { Login } from './Login';
import { Register } from './Register';

export const Auth = () => {
  const location = useLocation<{ from: string }>();

  return (
    <AuthLayout>
      <Switch>
        <Route path={AppRoutes.LOGIN} component={Login} />
        <Route path={AppRoutes.REGISTER} component={Register} />
        <Route path={AppRoutes.ACCOUNT_RECOVERY} component={AccountRecovery} />
        <Route>
          <Redirect
            to={{
              pathname: AppRoutes.LOGIN,
              state: { from: location.state?.from || location.pathname },
            }}
          />
        </Route>
      </Switch>
    </AuthLayout>
  );
};
