import { AppRoutes } from 'appRoutes';
import useIntParams from 'hooks/useIntParams';
import { Route } from 'react-router-dom';
import { JobTasksAnnotationClass } from './JobTasksAnnotationClass/JobTasksAnnotationClass';
import { JobTasksAnnotationClasses } from './JobTasksAnnotationClasses/JobTasksAnnotationClasses';
import { JobTasksAnnotationClassPropertyDetails } from './JobTasksAnnotationClassPropertyDetails/JobTasksAnnotationClassPropertyDetails';

export const JobTasks = () => {
  const { annotationClassId, annotationClassPropertyId } = useIntParams();

  return (
    <div className="my-2 flex h-full flex-1 flex-grow text-sm text-slate-700">
      <div className="w-full border-r pr-6" style={{ maxWidth: 360 }}>
        <JobTasksAnnotationClasses key={annotationClassId} />
      </div>

      <div className="flex w-full">
        <div className="w-1/2 border-r px-6">
          <Route path={[AppRoutes.JOB_TASKS_ANNOTATION_CLASS_PROPERTY, AppRoutes.JOB_TASKS_ANNOTATION_CLASS]}>
            <JobTasksAnnotationClass key={annotationClassId} />
          </Route>
        </div>

        <div className="w-1/2 pl-6">
          <Route path={AppRoutes.JOB_TASKS_ANNOTATION_CLASS_PROPERTY}>
            <JobTasksAnnotationClassPropertyDetails key={annotationClassPropertyId} />
          </Route>
        </div>
      </div>
    </div>
  );
};
