import { EventSystem } from 'modules/video-editor/pxae/events';
import { createStore } from 'zustand/vanilla';

interface Props {
  video: HTMLVideoElement;
  metadata: {
    frameCount: number;
    pts_times: number[];
  };
}

export const VideoControls = ({ video, metadata: { pts_times } }: Props) => {
  const store = createStore(() => ({
    canPlay: false,
    isError: false,
    isPlaying: false,
    currentFrame: 0,
    mediaTime: 0,
    duration: 0,
    playbackRate: 1,
    frameCount: 0,
    isFrameSyncError: false,
    loadedData: false,
  }));
  const eventSystem = EventSystem();
  const { on, dispatch } = eventSystem;
  const mapPtsTimeToIndex = Object.fromEntries(pts_times.map((e, i) => [e, i]));

  function getFramePts(frameIndex: number) {
    // @todo handle out of bounds
    return pts_times[frameIndex - 1];
  }

  function getCurrentFrame() {
    return store.getState().currentFrame;
  }

  function getPtsFrame(pts: number) {
    // @todo optimize
    // const index = pts_times.findIndex((e) => e === pts);
    const index = mapPtsTimeToIndex[pts];
    return index !== undefined ? index + 1 : -1;
  }

  function play() {
    video.play();
  }

  function pause() {
    video.pause();
  }

  function togglePlay() {
    video.paused ? play() : pause();
  }

  let timeoutId: any;

  function seekTo(frame: number) {
    const pts = getFramePts(frame);

    if (pts !== undefined) {
      !video.paused && video.pause();
      video.currentTime = pts;

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (frame !== store.getState().currentFrame) {
          store.setState(() => ({ isFrameSyncError: true }));
          console.error('frame index error', frame);
        }
      }, 500);
    } else {
      console.error('pts unavailable for frame', frame);
    }
  }

  function seekToPreviousFrame() {
    !video.paused && video.pause();
    const { currentFrame } = store.getState();
    seekTo(currentFrame - 1);
  }

  function seekToNextFrame() {
    !video.paused && video.pause();
    // @todo what if this is called in rapid succession
    const { currentFrame } = store.getState();
    seekTo(currentFrame + 1);
  }

  function setPlaybackRate(val: number) {
    video.playbackRate = val;
    store.setState(() => ({ playbackRate: val }));
  }

  function skipBackward() {
    seekTo(1);
  }

  function skipForward() {
    seekTo(pts_times.length);
  }

  function _syncCurrentFrame() {
    const cb: VideoFrameRequestCallback = (_now, metadata) => {
      const frame = getPtsFrame(metadata.mediaTime);

      if (frame !== -1) {
        store.setState(() => ({ currentFrame: frame, mediaTime: metadata.mediaTime }));
        dispatch('frameUpdate', { frame });
      } else {
        store.setState(() => ({ isFrameSyncError: true }));
        console.error('frame index error', frame);
      }

      video.requestVideoFrameCallback(cb);
    };

    video.requestVideoFrameCallback(cb);
  }

  _syncCurrentFrame();

  function updatePlayingState() {
    const isPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
    store.setState(() => ({ isPlaying }));
  }

  function _init() {
    store.setState(() => ({ frameCount: pts_times.length }));

    video.addEventListener('play', updatePlayingState);
    video.addEventListener('pause', updatePlayingState);

    video.addEventListener('loadedmetadata', () => {
      store.setState(() => ({ duration: video.duration }));
    });

    video.addEventListener('loadeddata', () => {
      store.setState(() => ({ loadedData: true }));
    });

    video.addEventListener('canplay', () => {
      store.setState(() => ({ canPlay: true }));
    });

    video.addEventListener('error', (event: any) => {
      const errorMessage = event.target.error.message;
      alert('Video Error:' + errorMessage);
      store.setState(() => ({ isError: true }));
    });
  }

  _init();

  return Object.freeze({
    store,
    events: {
      on,
      dispatch,
    },
    play,
    pause,
    togglePlay,
    seekTo,
    seekToPreviousFrame,
    seekToNextFrame,
    getCurrentFrame,
    setPlaybackRate,
    skipBackward,
    skipForward,
  });
};
