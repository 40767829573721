import { transform2d } from 'modules/video-editor/utils/Transform2d';
import { _2d, getAABB2d } from '../../utils/2d';
import { Matrix2 } from '../../utils/Matrix2';
import { StateManager } from '../StateManager';
import { EventSystem } from '../events';
import { uniqueHexColor } from '../hexColor';

export const Path = (initial: any) => {
  let { id, get, set, subscribe, data: initialData, keyframes: initialKeyframes, ...initialProps } = initial;

  const store = StateManager({
    props: {
      points: [],
      isClosed: true,
      isVisible: true,
      fillStyle: '#eee',
      strokeStyle: 'transparent',
      lineWidth: 0,
      zIndex: 0,
      transform: [1, 0, 0, 1, 0, 0],
      ...initialProps,
    },

    keyframes: initialKeyframes || [],
  });

  const dataValues = StateManager({
    hitRegionColor: '#' + uniqueHexColor(),
    ...initialData,
  });

  get = get || store.getState;
  set = set || store.setState;
  subscribe = subscribe || store.subscribe;

  const setProps = (newProps: any) => {
    const item = { ...get() };
    item.props = { ...item.props, ...newProps };
    set(item);
  };

  const getProps = () => {
    return get().props;
  };

  const getPoints = () => {
    let { points, transform } = get().props;

    const matrix = Matrix2(...transform);
    const newPoints = matrix.applyToArray(points);
    return newPoints as number[];
  };

  const appendPoint = (x: number, y: number) => {
    const { points, transform } = get().props;
    const matrix = Matrix2(...transform);
    const { x: nx, y: ny } = matrix.getInverse().applyToPoint(x, y);
    setProps({ points: [...points, nx, ny] });
  };

  const updatePointByIndex = (index: number, x: number, y: number) => {
    // @todo
    const { points, transform } = get().props;
    const matrix = Matrix2(...transform);
    const { x: nx, y: ny } = matrix.getInverse().applyToPoint(x, y);

    points[index] = nx;
    points[index + 1] = ny;

    set({ points });
  };

  const removePoint = (x: number, y: number) => {
    const { points, transform } = get().props;
    const newPoints = [...points];
    const matrix = Matrix2(...transform);
    let index = -1;

    const { x: nx, y: ny } = matrix.getInverse().applyToPoint(x, y);
    for (let i = 0; i < newPoints.length; i += 2) {
      if (newPoints[i] === nx && newPoints[i + 1] === ny) {
        index = i;
      }
    }

    if (index !== -1) {
      newPoints.splice(index, 2);
      set({ points: [...newPoints] });
    }
  };

  const insertPoint = (index: number, { x, y }: { x: number; y: number }) => {
    const { points, transform } = get().props;
    const matrix = Matrix2(...transform);
    const { x: nx, y: ny } = matrix.getInverse().applyToPoint(x, y);

    const arr = [
      // part of the array before the specified index
      ...points.slice(0, index * 2),
      // inserted item
      nx,
      ny,
      // part of the array after the specified index
      ...points.slice(index * 2),
    ];

    set({ points: [...arr] });
  };

  const updatePoint = (x: number, y: number, nx: number, ny: number) => {
    const { points, transform } = get().props;
    const newPoints = [...points];
    const matrix = Matrix2(...transform);

    const { x: px, y: py } = matrix.getInverse().applyToPoint(x, y);
    const { x: nnx, y: nny } = matrix.getInverse().applyToPoint(nx, ny);

    for (let i = 0; i < points.length; i += 2) {
      if (points[i] === px && points[i + 1] === py) {
        newPoints[i] = nnx;
        newPoints[i + 1] = nny;
      }
    }

    set({ points: newPoints });
  };

  const getAABB = () => {
    const { x, y, width, height } = getAABB2d(getPoints());
    const points = [x, y, x + width, y, x + width, y + height, x, y + height];
    return points;
  };

  const getOBB = () => {
    // @todo optimization required
    const { transform } = get().props;
    const matrix = Matrix2(...transform);
    const path = Path({ points: getPoints() });
    const center = path.getCentroid();
    const angle = matrix.decompose().rotation * (180 / Math.PI);
    path._rotate(-angle, center);
    const newPath = Path({ points: path.getAABB() });
    newPath._rotate(angle, center);

    return newPath.getPoints();
  };

  const getCentroid = () => {
    const points = getPoints(); // @todo?
    return _2d.getCentroid(points);
  };

  const _scaleLocal = ({ x: sx, y: sy }: { x: number; y: number }, center?: { x: number; y: number }) => {
    const { transform } = get().props;
    setProps({
      transform: transform2d.scale(transform, 'local', { x: sx, y: sy }, center),
    });
  };

  const _scaleGlobal = ({ x: sx, y: sy }: { x: number; y: number }, center?: { x: number; y: number }) => {
    const { transform } = get().props;
    setProps({
      transform: transform2d.scale(transform, 'global', { x: sx, y: sy }, center),
    });
  };

  const _rotate = (angle: number, center?: { x: number; y: number }) => {
    const { transform } = get().props;
    setProps({ transform: transform2d.rotate(transform, angle, center) });
  };

  const _setRotation = (angle: number, center?: { x: number; y: number }) => {
    const { transform } = get().props;
    setProps({ transform: transform2d.setRotation(transform, angle, center) });
  };

  const _translateLocal = ({ x, y }: { x: number; y: number }) => {
    const { transform } = get().props;
    setProps({
      transform: transform2d.translate(transform, 'local', { x, y }),
    });
  };

  const _translateGlobal = ({ x, y }: { x: number; y: number }) => {
    const { transform } = get().props;

    setProps({
      transform: transform2d.translate(transform, 'global', { x, y }),
    });
  };

  const moveTo = (x: number, y: number) => {
    const c = getCentroid();
    const tx = x - c.x;
    const ty = y - c.y;

    _translateGlobal({ x: tx, y: ty });
  };

  const translateTo = (x: number, y: number) => {
    const c = getCentroid();
    const tx = x - c.x;
    const ty = y - c.y;

    _translateGlobal({ x: tx, y: ty });
  };

  const _getRotation = () => {
    const { transform } = get().props;
    const matrix = Matrix2(...transform);

    const d = matrix.decompose();
    const deg = d.rotation / (Math.PI / 180);
    return deg;
  };

  const _applyTransformMatrix = () => {
    const { points, transform } = get().props;
    const matrix = Matrix2(...transform);
    const newPoints = matrix.applyToArray(points);
    set({ points: newPoints, transform: [1, 0, 0, 1, 0, 0] });
  };

  const resetTransformMatrix = () => {
    setTransform(1, 0, 0, 1, 0, 0);
  };

  const setTransform = (a: number, b: number, c: number, d: number, e: number, f: number) => {
    setProps({ transform: [a, b, c, d, e, f] });
  };

  const getTransformMatrix = () => {
    const props = getProps();
    return props.transform;
  };

  const getTransform = () => getTransformMatrix();

  const setKeyframes = (keyframes: any) => {
    const item = { ...get() };
    item.keyframes = keyframes;
    set(item);
  };

  const getKeyframes = () => {
    return get().keyframes;
  };

  const upsertKeyframe = (t: number, props: any = {}) => {
    const item = { ...get() };

    const foundIndex = item.keyframes.findIndex((e: any) => +e.frame === +t);

    const newKeyframe = {
      frame: t,
      value: { transform: [...item.props.transform], points: [...item.props.points], ...props }, // @todo
    };

    if (foundIndex === -1) {
      item.keyframes = [...item.keyframes, newKeyframe].sort((a: any, b: any) => a.frame - b.frame);
    } else {
      item.keyframes = item.keyframes.map((e: any, i: any) => (i === foundIndex ? newKeyframe : e));
    }

    set(item);
  };

  const deleteKeyframe = (index: number) => {
    const item = { ...get() };
    item.keyframes = item.keyframes.filter((e: any) => e.frame !== index);
    set(item);
  };

  const isVisible = () => {
    return get().props.isVisible;
  };

  return {
    id: id || Math.random(), // @todo,
    type: 'PATH',
    _uniqueColorId: '#' + uniqueHexColor(),

    ...store.getState(),

    ...EventSystem(),

    set: setProps,
    get: getProps,
    subscribe,

    data: (key?: string, value?: any) => {
      const data = dataValues.getState();

      if (key === undefined) {
        return data;
      }

      if (value !== undefined) {
        dataValues.setState({
          [key]: value,
        });
      }

      return dataValues.getState()[key];
    },

    getPoints,
    appendPoint,
    updatePoint,
    updatePointByIndex,
    insertPoint,
    removePoint,

    getAABB,
    getOBB,

    getCentroid,

    setTransform,
    getTransform, // @todo deprecate
    getTransformMatrix,

    _rotate,
    _scaleLocal,
    _scaleGlobal,
    _translateLocal,
    _translateGlobal,

    _applyTransformMatrix,
    resetTransformMatrix,

    _setRotation,
    _getRotation,

    moveTo, // @todo
    translateTo, // @todo

    setKeyframes,
    getKeyframes,

    upsertKeyframe,
    deleteKeyframe,

    isVisible,
  };
};
