import { _2d } from '../utils/2d';

export const Group = () => {
  let state: any = { entities: [], isVisible: true };

  const set = (props: any) => {
    state = { ...state, ...props };
  };

  const isVisible = () => {
    return state.isVisible;
  };

  const add = (entity: any) => {
    state.entities.push(entity);
  };

  const remove = (entity: any) => {
    state.entities = state.entities.filter((e: any) => e !== entity);
  };

  const removeAll = () => {
    state.entities = [];
  };

  const getAABB = () => {
    // @todo traverse
    const bboxes: any = state.entities.map((entity: any) => entity.getAABB());

    let minX = Infinity;
    let minY = Infinity;
    let maxX = 0;
    let maxY = 0;

    // @todo does not work with rotation
    bboxes.forEach((points: any) => {
      const x = points[0];
      const y = points[1];
      const width = points[2] - x;
      const height = points[5] - y;

      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, width + x);
      maxY = Math.max(maxY, height + y);
    });

    const x = minX;
    const y = minY;
    const width = maxX - minX;
    const height = maxY - minY;

    const points = [x, y, x + width, y, x + width, y + height, x, y + height];
    return points;
  };

  const getOBB = () => {
    // @todo - what is the OBB of a group of objects?

    if (state.entities.length === 1) {
      return state.entities[0].getOBB();
    }

    return getAABB();
  };

  const _getRotation = () => {
    // @todo

    if (state.entities.length === 1) {
      return state.entities[0]._getRotation();
    }

    return 0;
  };

  const _scaleLocal = ({ x, y }: { x: number; y: number }, center?: { x: number; y: number }) => {
    state.entities.forEach((entity: any) => entity._scaleLocal({ x, y }, center));
  };

  const _scaleGlobal = ({ x, y }: { x: number; y: number }, center?: { x: number; y: number }) => {
    state.entities.forEach((entity: any) => entity._scaleGlobal({ x, y }, center));
  };

  const _rotate = (angle: number, center?: { x: number; y: number }) => {
    state.entities.forEach((entity: any) => entity._rotate(angle, center));
  };

  const _setRotation = (angle: number, center?: { x: number; y: number }) => {
    state.entities.forEach((entity: any) => entity._setRotation(angle, center));
  };

  const _translateLocal = ({ x, y }: { x: number; y: number }) => {
    state.entities.forEach((entity: any) => entity._translateLocal({ x, y }));
  };

  const _translateGlobal = ({ x, y }: { x: number; y: number }) => {
    state.entities.forEach((entity: any) => entity._translateGlobal({ x, y }));
  };

  const getCentroid = () => {
    const points = getOBB(); // @todo?
    return _2d.getCentroid(points);
  };

  const dispatchEvent = (type: string, event: any) => {
    state.entities.forEach((entity: any) => entity.dispatchEvent(type, event));
  };

  const dispatch = (type: string, event: any) => {
    state.entities.forEach((entity: any) => entity.dispatch(type, event));
  };

  return {
    type: 'GROUP',
    add,
    remove,
    removeAll,
    get: () => state,
    set,
    getAABB,
    getOBB,

    getCentroid,
    _setRotation,
    _getRotation,

    _rotate,
    _scaleLocal,
    _scaleGlobal,
    _translateLocal,
    _translateGlobal,

    dispatchEvent, // @todo deprecate
    dispatch,

    isVisible,
  };
};
