import {
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { noop } from 'lodash';
import { cloneElement, createContext, useEffect, useState } from 'react';

interface Props {
  isOpen?: boolean;
  onOpenChange?: (val: boolean) => void;
  render: () => React.ReactNode;
  children?: JSX.Element;
}

export const DialogContext = createContext<{ close: () => void }>(null!);

export const Dialog = ({ render, isOpen: initialIsOpen = false, onOpenChange = noop, children }: Props) => {
  const [isOpen, setIsOpen] = useState(() => initialIsOpen);

  const { reference, floating, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context, {
      outsidePress: false,
      referencePress: false,
    }),
  ]);

  useEffect(() => {
    setIsOpen(initialIsOpen);
  }, [initialIsOpen]);

  useEffect(() => {
    onOpenChange(isOpen);
  }, [isOpen, onOpenChange]);

  return (
    <>
      {children ? cloneElement(children, getReferenceProps({ ref: reference, ...children.props })) : null}

      <FloatingPortal>
        {isOpen && (
          <FloatingOverlay
            lockScroll
            style={{
              display: 'grid',
              placeItems: 'center',
              background: 'rgba(0, 0, 0, 0.3)',
            }}
          >
            <DialogContext.Provider value={{ close: () => setIsOpen(false) }}>
              <div
                {...getFloatingProps({
                  ref: floating,
                })}
              >
                {render()}
              </div>
            </DialogContext.Provider>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </>
  );
};
