import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import FormError from 'components/common/FormError';
import { Checkbox } from 'components/common/Forms/Checkbox/Checkbox';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { Radio } from 'components/common/Forms/Radio/Radio';
import { Select } from 'components/common/Forms/Select/Select';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import { JobExportFormat, JobExportType, mapJobExportFormatToDisplayName, mapJobExportTypeToDisplayName } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorType } from 'shared/errors';
import { toReadableCronExpression } from 'utils/toReadableCron';

export const CreateJobExportModal = () => {
  const { close } = useContext(DialogContext);
  const { organizationId, projectId, jobId } = useIntParams();
  const { data: job } = useQuery('GetJobById', {
    params: { organizationId, projectId, jobId },
  });
  const { data: workflow } = useQuery('GetWorkflowById', {
    params: { organizationId, projectId, workflowId: job?.workflowId! },
  });

  const { mutateAsync: createJobExport } = useMutation('CreateJobExport');
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<{
    format: number;
    type: number;
    includeDataitemLinks: boolean;
    hasCron: string;
    cron: string;
    workflowStateIds: number[];
  }>();
  const [error, setError] = useState<string | null>(null);

  const onSubmit = handleSubmit(async (data) => {
    setError(null);

    const res = await createJobExport({
      data,
      params: { organizationId, projectId, jobId },
    }).catch((error) => error);

    if (res instanceof Error) {
      setError(ErrorType.GENERIC);
      return;
    }

    close();
  });

  if (job === undefined || workflow === undefined) {
    return null;
  }

  const exportFormatOptions = Object.values(JobExportFormat[job.type]).map((value) => ({
    label: mapJobExportFormatToDisplayName[value],
    value,
  }));

  const workflowStateOptions = (workflow.workflowStates || [])
    // .filter((e) => e.isExportable)
    .map(({ id, label }) => ({
      value: id,
      label,
    }));

  // @todo
  // const defaultWorkflowStateOptions = (workflow.workflowStates || [])
  //   .filter((e) => e.isExportable)
  //   .map(({ id }) => id);

  const cronWatch = watch('cron');
  const parsedCronExpression = toReadableCronExpression(cronWatch);

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Create Export" />
      <ModalLayoutBody>
        <form onSubmit={onSubmit}>
          <FormError type={error} />

          <div className="my-2">
            <div className="my-1 font-semibold">Format</div>

            <Select name="format" control={control} options={exportFormatOptions} />
          </div>

          <div className="my-2">
            <div className="my-1 font-semibold">States</div>
            <Select name="workflowStateIds" control={control} isMulti={true} options={workflowStateOptions} />
          </div>
          <hr />
          <div className="my-2">
            <div className="my-1 font-semibold">Type</div>

            <Radio
              {...register('type')}
              value={JobExportType.INTEGRAL}
              defaultChecked={true}
              label={mapJobExportTypeToDisplayName[JobExportType.INTEGRAL]}
              required
            />

            <Radio
              {...register('type')}
              value={JobExportType.INCREMENTAL}
              label={mapJobExportTypeToDisplayName[JobExportType.INCREMENTAL]}
              required
            />
          </div>
          <hr />
          <div className="my-3">
            <div className="my-1 font-semibold">Run time</div>

            <Radio {...register('hasCron')} value="false" defaultChecked={true} label="Immediate" required />
            <Radio {...register('hasCron')} value="true" label="Scheduled" required />

            {watch('hasCron') === 'true' && (
              <div className="my-2">
                <Label htmlFor={'cron'}>CRON expression</Label>
                <InputText {...register('cron')} id={'cron'} />

                <div>{parsedCronExpression}</div>
              </div>
            )}
          </div>

          <hr />

          <div className="my-2">
            <Checkbox {...register('includeDataitemLinks')} label="Include dataitem links" />
          </div>
        </form>
      </ModalLayoutBody>
      <ModalLayoutFooter>
        <Button loading={isSubmitting} label="Submit" onClick={onSubmit} />
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
