import { storageService } from 'api/storageService';
import { ApiCallStatus } from 'enums';
import { useCallback, useEffect, useReducer } from 'react';
import createAction from 'utils/createAction';

type State = {
  uploadStatus: ApiCallStatus;
  uploadProgress: number;
  importing: boolean;
  importProgress: number;
  importId: number | null;
  importStatus: ApiCallStatus;
  error: string;
};

const initialState: State = {
  uploadStatus: ApiCallStatus.IDLE,
  uploadProgress: 0,
  importing: false,
  importProgress: 0,
  importId: null,
  importStatus: ApiCallStatus.IDLE,
  error: '',
};

const setUploadStatus = createAction<ApiCallStatus>('set_upload_status');
const setUploadProgress = createAction<number>('set_upload_progress');
const setImportProgress = createAction<number>('set_import_progress');
const setImportId = createAction<number | null>('set_import_id');
const setError = createAction<string>('set_error');
const setImportStatus = createAction<ApiCallStatus>('set_import_status');

const reducer = (state: State, action: any) => {
  if (setUploadStatus.match(action)) {
    return {
      ...state,
      uploadStatus: action.payload,
    };
  }

  if (setUploadProgress.match(action)) {
    return {
      ...state,
      uploadProgress: action.payload,
    };
  }

  if (setImportProgress.match(action)) {
    return {
      ...state,
      importProgress: action.payload,
    };
  }

  if (setImportId.match(action)) {
    return {
      ...state,
      importId: action.payload,
    };
  }

  if (setError.match(action)) {
    return {
      ...state,
      error: action.payload,
    };
  }

  if (setImportStatus.match(action)) {
    return {
      ...state,
      importStatus: action.payload,
    };
  }

  return state;
};

const useDatasetUpload = (datasetId: number, onDone?: () => void) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleUploadProgress = (event: any) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);
    dispatch(setUploadProgress(percentCompleted));
  };

  const uploadFiles = useCallback(
    async (files: File[]) => {
      dispatch(setUploadStatus(ApiCallStatus.LOADING));
      const formData = new FormData();

      for (var i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      let res;

      try {
        res = await storageService.upload({
          datasetId,
          formData,
          onUploadProgress: handleUploadProgress,
        });
      } catch (err) {
        if (err instanceof Error) {
          dispatch(setError(err.message));
        }
      }

      if (res && res.data) {
        if (!res.data?.importId) {
          dispatch(setImportProgress(100));
        } else {
          dispatch(setImportProgress(0));
          dispatch(setImportId(res.data.importId));
        }
      }
    },
    [datasetId]
  );

  useEffect(() => {
    if (state.uploadProgress === 100) {
      dispatch(setImportStatus(ApiCallStatus.LOADING));
      dispatch(setUploadStatus(ApiCallStatus.SUCCESS));
    }
  }, [state.uploadProgress]);

  useEffect(() => {
    if (state.importProgress === 100) {
      dispatch(setImportStatus(ApiCallStatus.SUCCESS));
      dispatch(setImportId(null));
      if (onDone) {
        onDone();
      }
    }
  }, [state.importProgress, onDone]);

  return {
    uploadState: state,
    uploadFiles,
  };
};

export default useDatasetUpload;
