import produce from 'immer';
import { findLast } from 'lodash';
import { interpolate } from 'modules/video-editor/pxae/interpolate';
import { progress } from 'modules/video-editor/pxae/progress';

interface Keyframe {
  frame: number;
  value: any;
}

export const ObjectKeyframeAnimation = (item: any, keyframes: Record<string, Keyframe[]>, initialFrame = 0) => {
  let currentFrame = initialFrame;

  function getFrame() {
    return currentFrame;
  }

  function setFrame(frame: number) {
    currentFrame = frame;

    Object.entries(keyframes).forEach(([key, value]) => {
      if (!Array.isArray(value)) {
        return;
      }

      const keyframeA = findLast(value, (e) => e.frame <= frame);
      const keyframeB = value.find((e) => e.frame > frame) || keyframeA;

      if (key === 'is_visible') {
        item.set({ isVisible: keyframeA !== undefined ? keyframeA.value : false });
      }

      // @todo
      // if (key === 'points' && keyframeA && keyframeB && keyframeA.value && keyframeB.value) {
      //   const pointsA = keyframeA.value;
      //   const pointsB = keyframeB.value;
      //   const newPoints = pointsA.map((e: any, i: any) => {
      //     return interpolate(e, pointsB[i], progress(keyframeA!.frame, keyframeB.frame, frame));
      //   });

      //   item.set({ points: newPoints });
      // }

      if (key === 'transform_matrix' && keyframeA && keyframeB && keyframeA.value && keyframeB.value) {
        const transformA = keyframeA.value;
        const transformB = keyframeB.value;

        const transform = transformA.map((e: any, i: any) => {
          return interpolate(e, transformB[i], progress(keyframeA!.frame, keyframeB.frame, frame));
        });

        item.setTransform(...transform);
      }
    });

    item.dispatch && item.dispatch('keyframe-sync');
  }

  function insert({ key, frame }: { key: string; frame: number }) {
    const value = item.get()[key];

    keyframes = produce(keyframes, (draft) => {
      draft[key] = draft[key] || [];
      const frameIndex = draft[key].findIndex((e: any) => e.frame === frame);

      if (frameIndex !== -1) {
        draft[key][frameIndex].value = value;
      } else {
        draft[key].push({ frame, value });
        draft[key] = draft[key].sort((a: any, b: any) => a.frame - b.frame);
      }
    });
  }

  function _init() {
    setFrame(initialFrame);
  }

  _init();

  return Object.freeze({
    setFrame,
    getFrame,
    insert,
  });
};
