import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { DatasetImportHistoryStatus } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useContext } from 'react';

interface Props {
  datasetImportActionId: DatasetImportHistoryEntryDTO['id'];
}

export const ImportModal = ({ datasetImportActionId }: Props) => {
  const { organizationId, projectId, datasetId } = useIntParams();
  const { close } = useContext(DialogContext);

  const datasetImportHistoriesQuery = useQuery(
    'GetDatasetImportHistoriesWithPagination',
    {
      params: { organizationId, projectId },
      query: {
        datasetId,
        datasetImportActionId,
        pageSize: 100, // @todo
      },
    },
    {
      refetchOnMount: true,
      refetchInterval: 5000,
    }
  );

  const datasetImportAction = datasetImportHistoriesQuery.data?.results.find(({ id }) => id === datasetImportActionId);

  if (!datasetImportAction) {
    return null;
  }

  const { status, insertedCount, updatedCount, deletedCount } = datasetImportAction;
  const count = insertedCount + updatedCount + deletedCount;

  return (
    <ModalLayout>
      <ModalLayoutBody>
        {(status === DatasetImportHistoryStatus.RUNNING || status === DatasetImportHistoryStatus.INITIALIZED) && (
          <div className="my-6">
            <div className="text-slate-500 text-sm">Importing...</div>
            <div className="text-slate-500 text-sm">Processed {count} items</div>

            <div className="relative h-3 w-full rounded-lg bg-slate-200 border my-2 overflow-hidden">
              <div
                className="absolute bg-blue-500 top-0 bottom-0 left-0 w-1/2 animate-pulse rounded"
                style={{ animationName: 'progress', animationDuration: '1s', animationIterationCount: 'infinite' }}
              ></div>
            </div>
          </div>
        )}

        {status === DatasetImportHistoryStatus.COMPLETED && (
          <div>
            <div className="my-3 text-lg font-extralight text-stone-800">Import Completed!</div>

            <div className=" text-sm">
              <div className="flex justify-between border-b border-slate-100 py-1">
                <div>Inserted</div>
                <div>{insertedCount}</div>
              </div>

              <div className="flex justify-between border-b border-slate-100 py-1">
                <div>Updated</div>
                <div>{updatedCount}</div>
              </div>

              <div className="flex justify-between py-1">
                <div>Deleted</div>
                <div>{deletedCount}</div>
              </div>
            </div>
          </div>
        )}

        {status === DatasetImportHistoryStatus.FAILED && 'Import Failed'}
      </ModalLayoutBody>

      <ModalLayoutFooter>
        <Button onClick={close}>
          {status === DatasetImportHistoryStatus.RUNNING || status === DatasetImportHistoryStatus.INITIALIZED
            ? 'Run in Background'
            : 'OK'}
        </Button>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
