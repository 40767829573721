import { forwardRef } from 'react';

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'type'
  > {
  label?: string;
}

export const Radio = forwardRef<HTMLInputElement, Props>(
  (props: Props, ref) => {
    const { label, ...rest } = props;

    // eslint-disable-next-line react/forbid-elements
    const input = <input ref={ref} type="radio" {...rest} />;

    if (label) {
      return (
        <div className="form-radio">
          <label className="inline-flex items-center gap-1">
            {input}
            <span>{label}</span>
          </label>
        </div>
      );
    }

    return input;
  }
);
