import Papa from 'papaparse';
import { useRef } from 'react';

interface Props {
  onParse: (data: Papa.ParseResult<string[]>) => void;
}

export const UploadCSVButton = ({ onParse }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (fileList === null || fileList.length === 0) {
      return;
    }

    const file = fileList[0];

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const csv = event?.target?.result;
      if (!csv) {
        return;
      }

      const data = Papa.parse<string[]>(csv as string, { delimiter: ',' });
      onParse(data);
    });

    reader.readAsText(file);
    formRef.current?.reset();
  };

  return (
    <form ref={formRef}>
      <label htmlFor="file-upload" className="cursor-pointer rounded bg-blue-600 px-5 py-1.5 text-white">
        Upload CSV
      </label>

      <input className="hidden" id="file-upload" type="file" accept=".csv" onChange={handleOnChange} multiple={false} />
    </form>
  );
};
