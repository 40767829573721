import { Button } from 'components/common/Button/Button';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { Select } from 'components/common/Forms/Select/Select';
import { mapWorkflowStateTypeToDisplayName, WorkflowStateType } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  workflowId: WorkflowDTO['id'];
  onSuccess: () => void;
}

interface FormValues {
  label: string;
  type: number;
  isExportable: boolean;
}

const CreateWorkflowState = ({ workflowId, onSuccess }: Props) => {
  const { organizationId, projectId } = useIntParams();
  const { mutateAsync: createWorkflowState } = useMutation('CreateWorkflowState');

  const {
    control,
    register,
    handleSubmit,
    setFocus,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      label: '',
      type: WorkflowStateType.INTERMEDIATE,
      isExportable: false,
    },
  });

  useEffect(() => setFocus('label'), [setFocus]);

  const onSubmit = async (data: FormValues) => {
    await createWorkflowState({ data, params: { organizationId, projectId, workflowId } });
    onSuccess();
  };

  return (
    <div>
      <h2>Create State</h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-2">
          <Label htmlFor={'label'}>Label</Label>
          <InputText {...register('label')} id={'label'} />
        </div>

        <div className="my-2">
          <Label htmlFor="type">Node Type</Label>

          <Select
            control={control}
            id="type"
            name="type"
            options={Object.entries(WorkflowStateType).map(([_, value]) => ({
              value,
              label: mapWorkflowStateTypeToDisplayName[value],
            }))}
          />
        </div>

        <Button type="submit" loading={isSubmitting} label="Create" />
      </form>
    </div>
  );
};

export default CreateWorkflowState;
