import { useEffect, useRef, useState } from 'react';

type Themes = 'light' | 'dark';

export const useTheme = () => {
  const [, forceUpdate] = useState(0);
  const [theme, setTheme] = useState<Themes>(() =>
    document.documentElement.classList.contains('dark') ? 'dark' : 'light'
  );
  const prevThemeRef = useRef<Themes>(theme);

  useEffect(() => {
    document.documentElement.classList.remove(prevThemeRef.current);
    document.documentElement.classList.add(theme);
    localStorage.setItem('theme', theme);
    forceUpdate((n) => n + 1);

    prevThemeRef.current = theme;
  }, [theme]);

  const toggle = () => {
    setTheme((theme) => (theme === 'dark' ? 'light' : 'dark'));
  };

  return {
    theme,
    set: setTheme,
    toggle,
  };
};
