import { CgCheck } from 'react-icons/cg';
import { FormControlKeyframe } from './FormControlKeyframe';

interface Props {
  annotationInstance: AnnotationInstanceDTO;
  annotationClassProperty: AnnotationClassPropertyDTO;
  value: any;
  onChange: (value: any) => void;
}

export const WorkspaceDataitemAnnotationInstanceFormControlBoolean = ({
  annotationInstance,
  annotationClassProperty,
  value,
  onChange,
}: Props) => {
  return (
    <div className="flex items-center justify-between">
      <label className="input-switch">
        <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} />
        <div className="toggle">{value && <CgCheck />}</div>
        <span>{annotationClassProperty.label}</span>
      </label>

      <FormControlKeyframe annotationClassProperty={annotationClassProperty} annotationInstance={annotationInstance} />
    </div>
  );
};
