import { UiNode, UiNodeInputAttributes } from '@ory/kratos-client';
import { Button } from 'components/common/Button/Button';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface OryFormUiNodeProps {
  node: UiNode;
}

export const OryFormUiNode = ({ node }: OryFormUiNodeProps) => {
  const {
    register,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  useEffect(() => {
    if (node.type === 'input') {
      const { name, value, type } = node.attributes as UiNodeInputAttributes;
      type === 'submit' && setValue(name, value);
    }
  }, [node, setValue]);

  if (node.type === 'input') {
    const { type, name, value, required, disabled } =
      node.attributes as UiNodeInputAttributes;

    if (type === 'hidden') {
      return <input type="hidden" {...register(name)} defaultValue={value} />;
    }

    if (type === 'submit' || type === 'button') {
      return (
        <Button
          type={type}
          name={name}
          value={value}
          label={node.meta.label?.text}
          disabled={isSubmitting}
          className="w-full"
        />
      );
    }

    if (type === 'password') {
      return (
        <>
          <Label htmlFor={name}>{node.meta.label?.text}</Label>
          <InputText
            {...register(name)}
            id={name}
            type="password"
            required={required}
            disabled={disabled}
          />

          {/* //error={messages?.length > 0} */}
        </>
      );
    }

    if (
      ['text', 'email', 'number', 'datetime-local', 'url', 'date'].includes(
        type
      )
    ) {
      return (
        <>
          <Label htmlFor={name}>{node.meta.label?.text}</Label>
          <InputText
            {...register(name)}
            id={name}
            required={required}
            disabled={disabled}
          />

          {/* //error={messages?.length > 0} */}
        </>
      );
    }
  }

  console.error('Unhandled OryFormUiNode type', node);

  return null;
};
