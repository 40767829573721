import { AppRoutes } from 'appRoutes';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { LayoutContentTabs } from 'components/common/Layout/LayoutContentTabs';
import { LayoutLoadingSkeleton } from 'components/common/Layout/LayoutLoadingSkeleton';
import { Typography } from 'components/common/Typography';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Route, Switch } from 'react-router-dom';
import { Dataitems } from './Dataitems/Dataitems';
import { DatasetImportDropdown } from './DatasetImportDropdown/DatasetImportDropdown';
import { DatasetImportHistory } from './DatasetImportHistory/DatasetImportHistory';
import { DatasetOverview } from './DatasetOverview/DatasetOverview';
import { DatasetRemoteImportSources } from './DatasetRemoteImportSources/DatasetRemoteImportSources';
import { DatasetSettings } from './DatasetSettings/DatasetSettings';

export const Dataset = () => {
  const { projectId, organizationId, datasetId } = useIntParams<RouteParams.Dataset>();
  const { pathTo } = useRouterUtils();

  const datasetQuery = useQuery('GetDatasetById', {
    params: { organizationId, projectId, datasetId },
  });

  const { data: datasetRemoteStorageResources } = useQuery('GetDatasetRemoteImportSources', {
    params: {
      organizationId,
      projectId,
      datasetId,
    },
  });

  if (datasetQuery.isLoading) {
    return <LayoutLoadingSkeleton />;
  }

  if (datasetQuery.isError || !datasetQuery.data) {
    return <ErrorPage />;
  }

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            {datasetQuery.data.name}
          </Typography>
        </div>

        <div className="flex gap-2">
          <DatasetImportDropdown />
        </div>
      </LayoutContentHeader>

      <LayoutContentTabs
        items={[
          {
            to: pathTo('DATASET'),
            label: 'Overview',
          },
          {
            to: pathTo('DATAITEMS'),
            label: 'Dataitems',
          },
          {
            to: pathTo('DATASET_REMOTE_IMPORT_SOURCES'),
            label: (
              <div className="flex flex-row items-center">
                Remote Import Sources
                <div className="ml-4 px-1.5 text-xs border-slate-200 border bg-white text-slate-400 rounded">
                  {datasetRemoteStorageResources?.length}
                </div>
              </div>
            ),
          },
          {
            to: pathTo('DATASET_IMPORT_HISTORY'),
            label: 'Import History',
          },
          {
            to: pathTo('DATASET_SETTINGS'),
            label: 'Settings',
          },
        ]}
      />

      <LayoutContent>
        <Switch>
          <Route path={AppRoutes.DATASET} component={DatasetOverview} exact />

          <Route path={AppRoutes.DATAITEMS} component={Dataitems} />

          <Route path={AppRoutes.DATASET_REMOTE_IMPORT_SOURCES} component={DatasetRemoteImportSources} />

          <Route path={AppRoutes.DATASET_IMPORT_HISTORY} component={DatasetImportHistory} />

          <Route path={AppRoutes.DATASET_SETTINGS} component={DatasetSettings} />
        </Switch>
      </LayoutContent>
    </LayoutContentBody>
  );
};
