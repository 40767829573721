import useIntParams from 'hooks/useIntParams';
import { useAtom } from 'jotai';
import { cloneDeep, isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { workspaceContextStore } from './Workspace2';
import { workspaceDataitemState } from './state/workspaceDataitemState';
import { workspaceState } from './state/workspaceState';
import { annotationsAtom } from './useAnnotationsStore';
import { useSaveAnnotationInstances } from './useSaveAnnotationInstances';

const AUTO_SAVE_INTERVAL_IN_MS = 1000;

export const WorkspaceDataitemAutoSaveLogicalComponent = () => {
  const { organizationId, projectId, jobId, jobDataitemId } = useIntParams();
  const [annotations] = useAtom(annotationsAtom);
  const [initialized] = useAtom(workspaceDataitemState.isInitializedAtom);
  const prev = useRef<any>(null);
  const [_, setAnnotationInstancesToPersist] = useAtom(workspaceState.annotationInstancesToPersist, {
    store: workspaceContextStore,
  });

  const { save } = useSaveAnnotationInstances({
    onMutate: () => {},
    onSuccess: () => {},
  });

  const timeoutRef = useRef<any>(null);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (prev.current !== initialized) {
      prev.current = initialized;
      return;
    }

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      save(annotations, { organizationId, projectId, jobId, jobDataitemId }).then(() => {
        setAnnotationInstancesToPersist((prev: any) => {
          const { [jobDataitemId]: _, ...rest } = prev;
          return rest;
        });
      });
    }, AUTO_SAVE_INTERVAL_IN_MS);
  }, [initialized, annotations]);

  const prevAnnotationsRef = useRef<any>(null);

  useEffect(() => {
    if (!initialized) {
      return;
    }
    const clonedAnnotations = cloneDeep(annotations);

    if (prevAnnotationsRef.current !== null && !isEqual(clonedAnnotations, prevAnnotationsRef.current)) {
      setAnnotationInstancesToPersist({ [jobDataitemId]: clonedAnnotations });
    }

    prevAnnotationsRef.current = clonedAnnotations;
  }, [initialized, annotations, jobDataitemId, setAnnotationInstancesToPersist]);

  return null;
};
