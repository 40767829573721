export const EventSystem = () => {
  const listeners: any = {};

  const on = (type: any, handler: any) => {
    listeners[type] = listeners[type] || [];
    listeners[type].push(handler);
  };

  const off = (type: any, handler: any) => {
    if (!listeners[type]) {
      return;
    }

    listeners[type] = listeners[type].filter((fn: any) => {
      return fn !== handler;
    });
  };

  const dispatch = (type: string, event: any) => {
    (listeners[type] || []).forEach((cb: any) => cb(event));
  };

  return {
    on,
    off,
    dispatch,
  };
};
