import { useAtom } from 'jotai';
import { formatSeconds } from 'utils/dateUtils';
import { videoEditorState } from '../state/videoEditorState';

export const VideoFrameCount = () => {
  const [frameCount] = useAtom(videoEditorState.frameCountAtom);
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);
  const [mediaTime] = useAtom(videoEditorState.mediaTimeAtom);
  const [duration] = useAtom(videoEditorState.durationAtom);

  return (
    <div className="flex items-center gap-6 jusitfy-between text-sm">
      <div className="text-gray-300">
        <span className="pr-2">Frame</span>
        {currentFrame}
        <span className="text-gray-500">/{frameCount}</span>
      </div>

      <div className="text-gray-300">
        {formatSeconds(mediaTime)}
        <span className="text-gray-500">/{formatSeconds(duration)}</span>
      </div>
    </div>
  );
};
