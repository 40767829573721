import { Dropdown } from 'components/common/Dropdown/Dropdown';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { AiFillCaretDown, AiOutlineBranches } from 'react-icons/ai';

export const WorkspaceDataitemWorkflowStateSelect = () => {
  const { organizationId, projectId, jobId, jobDataitemId } = useIntParams();
  const jobDataitemQuery = useQuery('GetJobDataitemById', {
    params: { organizationId, projectId, jobId, jobDataitemId },
  });
  const jobWorkflowQuery = useQuery('GetJobWorkflow', {
    params: {
      organizationId,
      projectId,
      jobId,
    },
  });
  const updateJobDataitemWorkflowStateMutation = useMutation('UpdateJobDataitemWorkflowState');
  const updateJobDataitemWorkflowState = async (workflowStateId: any) => {
    await updateJobDataitemWorkflowStateMutation.mutateAsync({
      data: {
        workflowStateId,
      },
      params: {
        organizationId,
        projectId,
        jobId,
        jobDataitemId,
      },
    });
  };

  return (
    <div>
      <Dropdown
        className="text-xxs bg-neutral-900 text-gray-400 rounded overflow-hidden border border-neutral-700"
        render={() => (
          <>
            {jobWorkflowQuery.data?.workflowStates
              .filter((e) => e.id !== jobDataitemQuery.data?.workflowStateId)
              .map((e) => (
                <button
                  key={e.id}
                  className="flex w-full items-center justify-center border-b border-neutral-800 py-1 px-12 hover:bg-neutral-800 text-xxs"
                  onClick={() => updateJobDataitemWorkflowState(e.id)}
                >
                  {e.label}
                </button>
              ))}
          </>
        )}
      >
        <button
          className="flex items-center gap-1 rounded-sm border border-neutral-800 px-3 py-0.5 justify-between"
          style={{ fontSize: 11 }}
        >
          <AiOutlineBranches />
          <div className="flex-1 grow text-center px-2">{jobDataitemQuery.data?.workflowState.label}</div>
          <AiFillCaretDown className="ml-auto" />
        </button>
      </Dropdown>
    </div>
  );
};
