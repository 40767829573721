import { Table } from 'components/common/Table/Table';
import { useDatasetImportsTable } from 'hooks/react-table/useDatasetImportsTable';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';

export const RecentImports = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const { pathTo } = useRouterUtils();
  const datasetImportHistoriesQuery = useQuery('GetDatasetImportHistoriesWithPagination', {
    params: { organizationId, projectId },
    query: {
      pageSize: 5,
    },
  });

  const { instance: datasetImportsTableInstance } = useDatasetImportsTable({
    rows: datasetImportHistoriesQuery.data?.results,
  });

  return (
    <div>
      <div className="mb-2 mt-4 flex items-center justify-between">
        <div className="text-md font-medium text-slate-800">Recent Dataset Imports</div>

        <Link to={pathTo('ALL_DATASET_IMPORTS')} className="text-sm">
          View All
        </Link>
      </div>

      <Table instance={datasetImportsTableInstance} />
    </div>
  );
};
