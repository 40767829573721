export const JobReportType = {
  OVERVIEW: 'overview',
  DATAITEM_DISTRIBUTION: 'dataitem-distribution',
  TIME_PERIOD_BREAKDOWN: 'time-period-breakdown',
} as const;
export type JobReportType = typeof JobReportType[keyof typeof JobReportType];

export const WorkflowStates = {
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  PENDING_REVIEW: 'Pending Review',
  REVIEW: 'Review',
  PENDING_QA: 'Pending QA',
  QA: 'QA',
  DONE: 'Done',
  REOPENED: 'Re-Opened',
} as const;
export type WorkflowState = typeof WorkflowStates[keyof typeof WorkflowStates];

export type JobDataitemDistributionReportFilters = {
  users?: number[];
  workflowStates?: number[];
};

export type TimeResolution = 'day' | 'week' | 'month';
