import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Typography } from 'components/common/Typography';
import { JobCreateModal } from 'components/Modals/JobCreateModal/JobCreateModal';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import JobListing from './JobListing';
import NoJobs from './NoJobs';

const Jobs = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const jobsQuery = useQuery('GetJobs', {
    params: { projectId, organizationId },
  });

  const { data: jobs, isLoading } = jobsQuery;

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Jobs
          </Typography>
        </div>
        <div>
          <Dialog render={() => <JobCreateModal />}>
            <Button>Create Job</Button>
          </Dialog>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        {!jobs || jobs.length === 0 ? <NoJobs /> : <JobListing jobs={jobs} />}
      </LayoutContent>
    </LayoutContentBody>
  );
};

export default Jobs;
