import { AdministrativeViewLayout } from 'AdministrativeViewLayout';
import { Playground } from 'Playground';
import { AppRoutes } from 'appRoutes';
import { UpdatePassword } from 'components/Auth/UpdatePassword';
import { ComponentLibrary } from 'components/ComponentLibrary/ComponentLibrary';
import { RouterError } from 'components/RouterError';
import { Workspace2 } from 'components/Workspace2/Workspace2';
import { useDevOpenComponent } from 'hooks/useDevOpenComponent';
import { useHttpInterceptor } from 'hooks/useHttpInterceptor';
import { useQuery } from 'hooks/useQuery';
import { useServerSentEvents } from 'hooks/useServerSentEvents';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Auth } from './components/Auth';
import './styles/app.css';
import './styles/fonts.css';
import './styles/input.css';
import './styles/tailwind.css';

export const App = () => {
  useHttpInterceptor();
  useServerSentEvents();
  useDevOpenComponent();

  const isAuthenticatedQuery = useQuery('IsAuthenticated', {});
  const location = useLocation<{ from: string }>();

  if (isAuthenticatedQuery.isLoading) {
    return null;
  }

  const routes = isAuthenticatedQuery.data?.isAuthenticated ? (
    <Switch>
      <Route path={'/playground'} component={Playground} />

      <Route path={AppRoutes.WORKSPACE} render={(props) => <Workspace2 key={props.match.params.jobId} />} />

      <Route path={[AppRoutes.JOB, AppRoutes.DATASET, AppRoutes.PROJECT, AppRoutes.ORGANIZATION, '/']}>
        <AdministrativeViewLayout />
      </Route>

      <Route path="/update-password" component={UpdatePassword} />
      <Route path={AppRoutes.ROUTE_ERROR} component={RouterError} />

      <Route>
        <Redirect to={location.state?.from || '/'} />
      </Route>
    </Switch>
  ) : (
    <Switch>
      <Route path="/auth" component={Auth} />
      <Route>
        <Redirect
          to={{
            pathname: '/auth',
            state: { from: location.pathname },
          }}
        />
      </Route>
    </Switch>
  );

  return (
    <>
      <div className="bg-white text-slate-800 antialiased">
        <Switch>
          <Route path="/lib" component={ComponentLibrary} />

          {routes}
        </Switch>
      </div>

      <ToastContainer />
    </>
  );
};
