import useIntParams from 'hooks/useIntParams';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { workspaceContextStore } from './Workspace2';
import { workspaceState } from './state/workspaceState';
import { useSaveAnnotationInstances } from './useSaveAnnotationInstances';

// @todo handle offline

export const useAnnotationInstancePersistor = () => {
  const { organizationId, projectId, jobId, jobDataitemId } = useIntParams();
  const prevJobDataitemId = useRef<any>(jobDataitemId);
  const [annotationInstancesToPersist, setAnnotationInstancesToPersist] = useAtom(
    workspaceState.annotationInstancesToPersist,
    {
      store: workspaceContextStore,
    }
  );
  const { save } = useSaveAnnotationInstances();

  const setHasUnsavedChanges = useSetAtom(workspaceState.hasUnsavedChangesAtom, {
    store: workspaceContextStore,
  });

  useEffect(() => {
    if (jobDataitemId === prevJobDataitemId.current) {
      return;
    }

    Object.entries(annotationInstancesToPersist).forEach(async ([key, value]: any) => {
      try {
        await save(value, { organizationId, projectId, jobId, jobDataitemId: key });
        setAnnotationInstancesToPersist((prev: any) => {
          const { [jobDataitemId]: _, ...rest } = prev;
          return rest;
        });
      } catch (err) {
        alert(`Failed to persist data for ${jobDataitemId}`);
      }
    });

    prevJobDataitemId.current = jobDataitemId;
  }, [jobDataitemId]);

  useEffect(() => {
    setHasUnsavedChanges(annotationInstancesToPersist[jobDataitemId] !== undefined);
  }, [annotationInstancesToPersist, jobDataitemId, setHasUnsavedChanges]);
};
