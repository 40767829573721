import classNames from 'classnames';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { JobDataitemFilters } from 'components/common/JobDataitemFilters/JobDataitemFilters';
import useIntParams from 'hooks/useIntParams';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useState } from 'react';
import { AiOutlineFile, AiOutlineFolder } from 'react-icons/ai';
import { BsFilter } from 'react-icons/bs';
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

export const WorkspaceDatasetBrowser = () => {
  const { pathTo } = useRouterUtils();
  const { organizationId, projectId, jobId } = useIntParams();
  const [jobDataitemsQueryParams, setJobDataitemsQueryParams] = useState<{
    [key: string]: any;
  }>({
    searchQuery: undefined,
  });
  const jobDataitemsQuery = usePaginatedQuery('GetJobDataitemsWithPagination', {
    params: { organizationId, projectId, jobId },
    query: {
      ...jobDataitemsQueryParams,
    },
  });

  const pagination = jobDataitemsQuery.pagination;
  const jobDataitems = jobDataitemsQuery?.data?.results || [];

  return (
    <div className="flex flex-col grow overflow-hidden">
      <div className="flex items-center px-3 py-2.5 text-xxs flex items-center gap-1 text-gray-300 font-medium border-ws-panel border-b">
        <AiOutlineFolder size={15} />
        <span className="uppercase">Dataset Browser</span>

        <div className="flex items-center ml-auto">
          <Dropdown
            render={() => (
              <JobDataitemFilters
                filters={jobDataitemsQueryParams}
                onChange={(filters) =>
                  setJobDataitemsQueryParams((prev) => ({
                    ...prev,
                    ...filters,
                  }))
                }
              />
            )}
          >
            <button>
              <BsFilter size={20} />
            </button>
          </Dropdown>
        </div>
      </div>

      <div className="flex flex-1 overflow-hidden">
        <div className="flex flex-col flex-1 text-xxs overflow-hidden text-gray-300">
          <div className="flex justify-between py-1.5 text-gray-500 border-b border-black/10 bg-black/10">
            <div className="px-3 w-2/3">Name</div>
            <div className="px-3 w-1/3 border-l border-neutral-800">State</div>
          </div>

          <div className="flex w-full grow overflow-hidden">
            <div className="overflow-hidden overflow-y-scroll custom-scrollbar invisible-scrollbar bg-black/30 w-full">
              {jobDataitems.map((jobDataitem) => (
                <NavLink
                  key={jobDataitem.id}
                  className="flex justify-between py-1.5 text-gray-400 hover:bg-neutral-600/10"
                  activeClassName="bg-neutral-600/20 text-gray-400"
                  to={{
                    pathname: pathTo('WORKSPACE', {
                      organizationId,
                      projectId,
                      jobId,
                      jobDataitemId: jobDataitem.id,
                    }),
                  }}
                >
                  <div className="flex items-center gap-1 px-3 w-2/3">
                    <AiOutlineFile />
                    {jobDataitem.dataitem.externalId}
                  </div>
                  <div className="px-3 w-1/3">{jobDataitem.workflowState.label}</div>

                  {/* <div className="mt-0.5 flex items-center gap-1 text-gray-500" style={{ fontSize: 10 }}>
              <AiOutlineUser />
              <div>{jobDataitem.jobUser?.user?.email || 'Unassigned'}</div>
            </div> */}
                </NavLink>
              ))}
            </div>
          </div>

          {pagination && (
            <div className="flex items-center gap-1 text-sm select-none w-full bg-black/10 border-ws-panel border-t">
              <button
                disabled={!pagination.hasPrev}
                onClick={pagination.prev}
                className={classNames('flex-1 flex items-center justify-center border-r p-1.5 border-ws-panel', {
                  'hover:bg-black/20 text-neutral-300 ': pagination.hasPrev,
                  'text-neutral-600': !pagination.hasPrev,
                })}
              >
                <MdOutlineNavigateBefore />
              </button>

              <button
                disabled={!pagination.hasNext}
                onClick={pagination.next}
                className={classNames('flex-1 flex items-center justify-center border-r p-1.5 border-ws-panel', {
                  'hover:bg-black/20 text-neutral-300 ': pagination.hasNext,
                  'text-neutral-600': !pagination.hasNext,
                })}
              >
                <MdOutlineNavigateNext />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
