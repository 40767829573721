import { DialogContext } from 'components/common/Dialog/Dialog';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import DatasetUpload from 'components/Project/Datasets/Dataset/DatasetUpload/DatasetUpload';
import useIntParams from 'hooks/useIntParams';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'components/common/Button/Button';
import { useDropzone } from 'react-dropzone';

export const DataitemUploadModal = () => {
  const { datasetId } = useIntParams();
  const { close } = useContext(DialogContext);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  const onUpload = (files: File[]) => {
    const toastId = toast(
      <DatasetUpload
        datasetId={datasetId}
        files={files}
        onDone={async () => {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          toast.done(toastId);
        }}
        onUploadProgress={(progress) => {
          toast.update(toastId, {
            progress: Math.min(progress / 100, 0.999),
          });
        }}
      />,
      {
        autoClose: false,
        position: 'bottom-right',
        progressStyle: {
          background: 'rgba(30, 58, 138)',
        },
      }
    );
    close();
  };

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Upload" />
      <ModalLayoutBody>
        <div
          {...getRootProps({ className: 'dropzone' })}
          className="border-light-blue-500 rounded border-2 border-dashed bg-slate-50 px-80 py-60 text-center"
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop files here</p>
          <p className="my-2 text-slate-400">or</p>

          <Button onClick={open} label="Open File Dialog" />
        </div>

        {acceptedFiles.length > 0 && (
          <div className="my-4 text-left text-sm">
            <div className="my-2">
              {acceptedFiles.length} files selected - {acceptedFiles.reduce((acc, val) => acc + val.size, 0)} bytes
            </div>
            <div style={{ maxHeight: 320, overflow: 'scroll' }}>
              <table className="table-primary">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Size (bytes)</th>
                  </tr>
                </thead>

                <tbody>
                  {acceptedFiles.map((file: any) => (
                    <tr key={file.path}>
                      <td>{file.name}</td>
                      <td>{file.size}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </ModalLayoutBody>

      <ModalLayoutFooter>
        <Button onClick={() => onUpload(acceptedFiles)} label="Upload" />
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
