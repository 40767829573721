function _renderPath(
  context: CanvasRenderingContext2D,
  item: any,
  transform: { x: number; y: number; scale: number },
  overrides = {}
) {
  const { scale: ts, x: tx, y: ty } = transform;
  const { isClosed, lineWidth, fillStyle, strokeStyle }: any = {
    ...item.get(),
    ...overrides,
  };

  const preserveScale = item.data()['preserveScaleOnZoom'];
  let points = item.getPoints();

  context.save();
  context.beginPath();

  if (preserveScale) {
    // @todo optimization required
    item._scaleLocal({ x: 1 / ts, y: 1 / ts }, item.getCentroid());
    points = item.getPoints();
    item._scaleLocal({ x: ts, y: ts }, item.getCentroid());
  }

  for (let i = 0; i < points.length; i += 2) {
    let [x, y] = [points[i] * ts + tx, points[i + 1] * ts + ty];

    if (i === 0) {
      context?.moveTo(x, y);
    } else {
      context?.lineTo(x, y);
    }
  }

  if (fillStyle) {
    context.fillStyle = fillStyle;
  }

  if (strokeStyle && lineWidth !== 0) {
    context.strokeStyle = strokeStyle;
    context.lineWidth = lineWidth;
  }

  if (isClosed) {
    context?.closePath();
  }

  if (strokeStyle && lineWidth !== 0) {
    context?.stroke();
  }

  if (isClosed) {
    context?.fill();
  }

  context.restore();
}

export const CanvasObjectRenderer = (context: CanvasRenderingContext2D | null) => {
  function render(item: any, transform: { x: number; y: number; scale: number }, overrides = {}) {
    if (!context) {
      return;
    }

    if (item.type === 'PATH') {
      _renderPath(context, item, transform, overrides);
    }
  }

  return { render };
};
