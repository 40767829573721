interface Props {
  annotationClassProperty: AnnotationClassPropertyDTO;
  value: any;
  onChange: (value: any) => void;
}

export const WorkspaceDataitemAnnotationInstanceFormControlString = ({
  annotationClassProperty,
  value,
  onChange,
}: Props) => {
  return (
    <div className="w-full">
      <label className="block">{annotationClassProperty.label}</label>
      <input
        className="rounded-sm py-1 px-1 w-full"
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
  // return (
  //   <>
  //     <label
  //       className={classNames('flex items-center gap-1', {
  //         'text-blue-400': value,
  //         'opacity-60 hover:opacity-70': !value,
  //       })}
  //     >
  //       <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} />

  //       {annotationClassProperty.label}
  //     </label>
  //   </>
  // );
};
