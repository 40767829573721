import React, { useState } from 'react';
import Select from 'react-select';

import { JobReportType } from './types';
import { DataitemDistributionReport } from './DataitemDistributionReport';
import { TimePeriodBreakdown } from './TimePeriodBreakdownReport';

const options = [
  {
    label: 'Dataitem Distribution',
    value: JobReportType.DATAITEM_DISTRIBUTION,
  },
  {
    label: 'Time Period Breakdown',
    value: JobReportType.TIME_PERIOD_BREAKDOWN,
  },
];

export const JobReports = () => {
  const [reportType, setReportType] = useState<{
    label: string;
    value: JobReportType;
  }>(options[0]);

  return (
    <div className="h-full flex flex-col">
      <div className="mb-5">
        <label htmlFor="report-type" className="block">
          Type
        </label>
        <Select
          options={options}
          onChange={(selected) => setReportType(selected!)}
          value={reportType}
          className="inline-block"
          styles={{
            container: (provided) => ({
              ...provided,
              minWidth: 250,
            }),
          }}
        />
      </div>
      {reportType.value === JobReportType.DATAITEM_DISTRIBUTION && <DataitemDistributionReport />}
      {reportType.value === JobReportType.TIME_PERIOD_BREAKDOWN && <TimePeriodBreakdown />}
    </div>
  );
};
