import { Box } from 'components/common/Box/Box';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';
import { JobOverviewRecentExports } from './JobOverviewRecentExports/JobOverviewRecentExports';
import { JobOverviewReport } from './JobOverviewReport/JobOverviewReport';

export const JobOverview = () => {
  const { pathTo } = useRouterUtils();
  const { organizationId, projectId, jobId } = useIntParams();
  const jobQuery = useQuery('GetJobById', {
    params: { organizationId, projectId, jobId },
  });

  const datasetQuery = useQuery('GetDatasetById', {
    params: {
      organizationId,
      projectId,
      datasetId: jobQuery.data?.datasetId!,
    },
  });

  return (
    <div>
      <div className="mb-6 flex gap-2 mt-3">
        <Box className="w-1/3">
          <div className="text-xs font-thin text-slate-500 mb-1 uppercase">Dataset</div>
          <div className="text-lg text-slate-600 flex items-center justify-between">
            <Link to={pathTo('DATASET', { datasetId: datasetQuery.data?.id })}>{datasetQuery.data?.name}</Link>
          </div>
        </Box>

        <Box className="w-1/3">
          <div className="text-xs font-thin text-slate-500 mb-1 uppercase">Last Export</div>
          <div className="text-lg text-slate-600">-</div>
        </Box>

        <Box className="w-1/3">
          <div className="text-xs font-thin text-slate-500 mb-1 uppercase">Scheduled Exports</div>
          <div className="text-lg text-slate-600">INACTIVE</div>
        </Box>
      </div>

      <div className="flex gap-2">
        <JobOverviewReport />

        <Box className="w-full"></Box>
      </div>

      <JobOverviewRecentExports />
    </div>
  );
};
