import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { DropdownButtonItem } from 'components/common/DropdownButtonItem/DropdownButtonItem';
import { Checkbox } from 'components/common/Forms/Checkbox/Checkbox';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { Select } from 'components/common/Forms/Select/Select';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { AnnotationClassPropertyUiType, mapAnnotationClassPropertyTypeToDisplayName } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CgDanger } from 'react-icons/cg';
import { IoMdTrash } from 'react-icons/io';
import { RiMore2Fill } from 'react-icons/ri';
import { AnnotationClassPropertyType } from 'shared/enums';
import { JobTasksColumnHeader } from '../shared/JobTasksColumnHeader/JobTasksColumnHeader';
import { AnnotationClassPropertyOptionsModal } from './AnnotationClassPropertyOptionsModal/AnnotationClassPropertyOptionsModal';

const NUMBER_OF_PROPERTY_OPTIONS_TO_DISPLAY = 10;

export const JobTasksAnnotationClassPropertyDetails = () => {
  const { organizationId, projectId, jobId, annotationClassId, annotationClassPropertyId } = useIntParams();

  const annotationClassesQuery = useQuery('GetAnnotationClasses', {
    params: { organizationId, projectId, jobId },
  });

  const { mutateAsync: updateAnnotationClassProperty } = useMutation('UpdateAnnotationClassProperty');
  const { mutateAsync: removeAnnotationClassProperty } = useMutation('RemoveAnnotationClassProperty');

  const selectedAnnotationClass = annotationClassesQuery.data?.find(({ id }) => id === annotationClassId);

  const selectedAnnotationClassProperty = selectedAnnotationClass?.properties.find(
    ({ id }) => id === annotationClassPropertyId
  );

  const { navigateTo } = useRouterUtils();
  const { register, handleSubmit, watch, setValue, control, reset } = useForm({
    defaultValues: selectedAnnotationClassProperty,
  });

  useEffect(() => {
    reset(selectedAnnotationClassProperty);
  }, [reset, selectedAnnotationClassProperty]);

  const onSubmit = handleSubmit((data) => {
    updateAnnotationClassProperty({
      data,
      params: {
        organizationId,
        projectId,
        jobId,
        annotationClassId: annotationClassPropertyId,
        propertyId: annotationClassPropertyId,
      },
    });
  });

  const [isConfirmRemoveModalOpen, setIsConfirmRemoveModalOpen] = useState(false);
  const handleRemoveAnnotationClassProperty = async () => {
    await removeAnnotationClassProperty({
      params: {
        organizationId,
        projectId,
        jobId,
        annotationClassId: annotationClassPropertyId,
        propertyId: annotationClassPropertyId,
      },
    });

    navigateTo('JOB_TASKS_ANNOTATION_CLASS');
  };

  const labelValue = watch('label');

  useEffect(() => {
    if (isUndefined(labelValue)) {
      return;
    }

    if (!selectedAnnotationClassProperty?.key) {
      setValue('key', labelValue.trim().replace(/\s/g, '-').toLowerCase());
    }
  }, [selectedAnnotationClassProperty, labelValue, setValue]);

  const isPrimaryClassification =
    selectedAnnotationClassProperty?.isIntrinsic && selectedAnnotationClassProperty.key === 'className';

  return (
    <div>
      <JobTasksColumnHeader>
        <div>
          <span className="font-medium">
            {selectedAnnotationClassProperty &&
              mapAnnotationClassPropertyTypeToDisplayName[selectedAnnotationClassProperty.type]}
          </span>
        </div>

        <Dialog
          render={() => (
            <ConfirmActionModal onConfirm={() => handleRemoveAnnotationClassProperty()}>
              <div className="mt-2 mb-6 flex flex-row items-center gap-4" style={{ maxWidth: 500 }}>
                <CgDanger size={60} />
                <p>Warning! Any existing annotations related to this task will be permanently lost.</p>
              </div>
            </ConfirmActionModal>
          )}
          isOpen={isConfirmRemoveModalOpen}
          onOpenChange={setIsConfirmRemoveModalOpen}
        />

        <Dropdown
          render={() => (
            <div>
              <DropdownButtonItem
                disabled={selectedAnnotationClassProperty?.isIntrinsic}
                onClick={() => setIsConfirmRemoveModalOpen(true)}
              >
                <IoMdTrash />
                Remove
              </DropdownButtonItem>
            </div>
          )}
        >
          <Button variant="icon-rounded" size="sm" icon={<RiMore2Fill />} className="py-1.5" />
        </Dropdown>
      </JobTasksColumnHeader>

      <form onSubmit={onSubmit} className="mt-4 space-y-6">
        {!isPrimaryClassification && (
          <div className="flex flex-row gap-2">
            <div className="w-64">
              <Label htmlFor="label">Label</Label>
              <InputText
                {...register('label', { onBlur: () => onSubmit() })}
                defaultValue={selectedAnnotationClassProperty?.label}
                id="label"
              />
            </div>

            <div className="w-64">
              <Label htmlFor="id">ID</Label>
              <InputText
                {...register('key', {
                  onBlur: () => onSubmit(),
                })}
                defaultValue={selectedAnnotationClassProperty?.key}
                id="id"
              />
            </div>
          </div>
        )}

        {selectedAnnotationClassProperty?.type === AnnotationClassPropertyType.ENUM && (
          <>
            <div>
              <div className="flex items-center justify-between">
                <Label>Classes/Options</Label>

                <Dialog
                  render={() => (
                    <AnnotationClassPropertyOptionsModal
                      annotationClassId={annotationClassId}
                      annotationClassProperty={selectedAnnotationClassProperty}
                    />
                  )}
                >
                  <Button size="sm" variant="outlined">
                    Configure Options
                  </Button>
                </Dialog>
              </div>

              <div className="-m-1 mt-2">
                {selectedAnnotationClassProperty.propertyOptions
                  .slice(0, NUMBER_OF_PROPERTY_OPTIONS_TO_DISPLAY)
                  .map(({ id, value, meta }) => (
                    <div
                      key={id}
                      id={id}
                      className="m-1 inline-flex items-center gap-2 rounded border border-slate-200 bg-slate-50 px-3 py-0.5"
                    >
                      {meta?.color && <div className="h-2.5 w-2.5 rounded-sm" style={{ background: meta.color }}></div>}
                      {value}
                    </div>
                  ))}

                {selectedAnnotationClassProperty.propertyOptions.length - NUMBER_OF_PROPERTY_OPTIONS_TO_DISPLAY > 0 && (
                  <span>
                    & {selectedAnnotationClassProperty.propertyOptions.length - NUMBER_OF_PROPERTY_OPTIONS_TO_DISPLAY}{' '}
                    more...
                  </span>
                )}
              </div>
            </div>

            {!isPrimaryClassification && (
              <div>
                <Label>Classification Type</Label>
                <Select
                  control={control}
                  name="isMultiChoice"
                  onChange={() => onSubmit()}
                  options={[
                    {
                      value: false,
                      label: 'Single Choice',
                    },
                    {
                      value: true,
                      label: 'Multi Choice',
                    },
                  ]}
                />
              </div>
            )}

            {/* <div className="flex flex-row gap-2">
                <Label>Validation</Label>
                <div>Required</div>
              </div> */}

            {!isPrimaryClassification && (
              <div>
                <Label>Display as</Label>
                <div className="w-full">
                  <Select
                    name="uiType"
                    onChange={() => onSubmit()}
                    control={control}
                    options={[
                      {
                        value: AnnotationClassPropertyUiType.DROPDOWN,
                        label: 'Dropdown',
                      },
                      {
                        value: AnnotationClassPropertyUiType.LIST,
                        label: 'List',
                      },
                    ]}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {selectedAnnotationClassProperty?.type === AnnotationClassPropertyType.STRING && (
          <>
            <div className="flex flex-row gap-2">
              <Label>Validation</Label>
              <div>Required</div>
            </div>

            <div>
              <Label>Display as</Label>
              <div className="w-full">
                <Select
                  name="uiType"
                  control={control}
                  onChange={() => onSubmit()}
                  options={[
                    {
                      value: AnnotationClassPropertyUiType.SINGLE_LINE_TEXT_INPUT,
                      label: 'Single-line input',
                    },
                    {
                      value: AnnotationClassPropertyUiType.MULTI_LINE_TEXT_INPUT,
                      label: 'Multi-line input',
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )}

        {selectedAnnotationClassProperty?.type === AnnotationClassPropertyType.NUMBER && (
          <>
            <div className="flex flex-row gap-2">
              <Label>Validation</Label>
              <div>Required, Min(0), Max(20)</div>
            </div>

            <div>
              <Label>Display as</Label>
              <div className="w-full">
                <Select
                  name="uiType"
                  control={control}
                  onChange={() => onSubmit()}
                  options={[
                    {
                      value: AnnotationClassPropertyUiType.NUMBER_INPUT,
                      label: 'Numeric Input',
                    },
                    // {
                    //   value: AnnotationClassPropertyUiType.NUMBER_SLIDER,
                    //   label: 'Range Slider',
                    // },
                  ]}
                />
              </div>
            </div>
          </>
        )}

        <div className="my-2">
          <Checkbox {...register('isKeyframable')} label="Keyframable" />
        </div>

        <Button type="submit">Save</Button>
      </form>
    </div>
  );
};
