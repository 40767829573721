import { usePersistedState } from 'hooks/usePersistedState';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';
import { workspaceContextStore } from './Workspace2';
import { WorkspaceDataitemAnnotationInstanceFormPanel } from './WorkspaceDataitemAnnotationInstanceFormPanel';
import { WorkspaceDataitemAnnotationsList } from './WorkspaceDataitemAnnotationsList';
import { WorkspacePanel } from './WorkspacePanel';
import { workspaceState } from './state/workspaceState';

export const WorkspaceDataitemSidebar = () => {
  const [showDataitemSidebar] = useAtom(workspaceState.showDataitemSidebarAtom, {
    store: workspaceContextStore,
  });

  const draggableRef = useRef<HTMLDivElement | null>(null);
  const [componentWidth, setComponentWidth] = usePersistedState(320, 'workspace-dataitem-sidebar-width');
  const handleOnDrag = (_: DraggableEvent, { deltaX }: DraggableData) => {
    setComponentWidth((prev: any) => prev - deltaX);
  };

  if (!showDataitemSidebar) {
    return null;
  }

  return (
    <div className="relative flex shrink-0 flex-col" style={{ width: componentWidth }}>
      <DraggableCore nodeRef={draggableRef} offsetParent={document.body} onDrag={handleOnDrag}>
        <div className="absolute h-full top-0 bottom-0 cursor-ew-resize" style={{ width: 5 }} ref={draggableRef}></div>
      </DraggableCore>

      <WorkspacePanel className="shrink-0">
        <WorkspaceDataitemAnnotationsList />
      </WorkspacePanel>

      <WorkspaceDataitemAnnotationInstanceFormPanel />
    </div>
  );
};
