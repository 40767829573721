import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { VideoTimelineState } from '../VideoTimeline';

export const useVideoTimelineYScrollSync = (containerRef: React.RefObject<HTMLDivElement>) => {
  const [yOffset, setYOffset] = useAtom(VideoTimelineState.yOffsetAtom);

  useEffect(() => {
    const el = containerRef.current;
    if (!el) {
      return;
    }

    const handler = () => {
      setYOffset(el.scrollTop);
    };

    el.addEventListener('scroll', handler);

    return () => {
      el.removeEventListener('scroll', handler);
    };
  }, [containerRef.current, setYOffset]);

  useEffect(() => {
    const el = containerRef.current;
    if (!el) {
      return;
    }

    el.scrollTop = yOffset;
  }, [containerRef.current, yOffset]);
};
