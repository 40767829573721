import { annotationInstanceDoAtom } from 'components/Workspace2/WorkspaceDatatitem2';
import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { workspaceState } from 'components/Workspace2/state/workspaceState';
import { useAnnotationsStore } from 'components/Workspace2/useAnnotationsStore';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { AnnotationClassPropertyType } from 'shared/enums';
import { WorkspaceDataitemAnnotationInstanceFormControlBoolean } from './WorkspaceDataitemAnnotationInstanceFormControlBoolean';
import { WorkspaceDataitemAnnotationInstanceFormControlEnum } from './WorkspaceDataitemAnnotationInstanceFormControlEnum';
import { WorkspaceDataitemAnnotationInstanceFormControlString } from './WorkspaceDataitemAnnotationInstanceFormControlString';

interface Props {
  annotationInstance: AnnotationInstanceDTO;
  annotationClassProperty: AnnotationClassPropertyDTO;
}
export const WorkspaceDataitemAnnotationInstanceFormControl = ({
  annotationInstance,
  annotationClassProperty,
}: Props) => {
  const { update } = useAnnotationsStore();
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);

  const [annotationInstanceDo] = useAtom(annotationInstanceDoAtom);
  const propertyValue = useMemo(
    () => annotationInstanceDo?.getPropertyValueAtKeyframe(annotationClassProperty.key, currentFrame),
    [annotationClassProperty.key, annotationInstanceDo, currentFrame]
  );

  const [keyboardShortcuts] = useAtom(workspaceState.keyboardShortcutsAtom);
  const keyboardShortcut = useMemo(
    () => (keyboardShortcuts as any)[`${annotationInstance.annotationClass.id}-${annotationClassProperty.id}`],
    [annotationClassProperty.id, annotationInstance.annotationClass.id, keyboardShortcuts]
  );

  const onChange = useCallback(
    (value: any) => {
      const prevFramePropertyValue = annotationInstanceDo.getPropertyValueAtKeyframe(
        annotationClassProperty.key,
        currentFrame - 1
      );

      if (
        (value === false && (prevFramePropertyValue === undefined || prevFramePropertyValue === false)) ||
        (value === true && prevFramePropertyValue === true)
      ) {
        value = null;
      }

      update(annotationInstance.id, {
        key: annotationClassProperty.key,
        frame: currentFrame,
        value: value,
      });

      // @todo if new value has not effect then remove the keyframe
    },
    [annotationClassProperty.key, annotationInstance.id, annotationInstanceDo, currentFrame, update]
  );

  useEffect(() => {
    if (annotationClassProperty.type !== AnnotationClassPropertyType.BOOLEAN || !keyboardShortcut) {
      return;
    }

    const handler = (event: KeyboardEvent) => {
      if (event.code !== keyboardShortcut.keyCode) {
        return;
      }

      const modifierKeys = ['altKey', 'shiftKey', 'metaKey', 'ctrlKey'].filter((key) => (event as any)[key]);
      const hasAllModifiers = keyboardShortcut.modifierKeys.every((e: any) => modifierKeys.includes(e));

      if (!hasAllModifiers) {
        return;
      }

      event.preventDefault();
      onChange(!propertyValue);
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [annotationClassProperty, onChange, keyboardShortcut, propertyValue]);

  return (
    <div className="flex flex-col my-0.5">
      {annotationClassProperty.type === AnnotationClassPropertyType.BOOLEAN && (
        <WorkspaceDataitemAnnotationInstanceFormControlBoolean
          annotationInstance={annotationInstance}
          annotationClassProperty={annotationClassProperty}
          value={propertyValue}
          onChange={onChange}
        />
      )}

      {(annotationClassProperty.type === AnnotationClassPropertyType.STRING ||
        annotationClassProperty.type === AnnotationClassPropertyType.JSON) && (
        <WorkspaceDataitemAnnotationInstanceFormControlString
          annotationClassProperty={annotationClassProperty}
          value={propertyValue}
          onChange={onChange}
        />
      )}

      {annotationClassProperty.type === AnnotationClassPropertyType.ENUM && (
        <WorkspaceDataitemAnnotationInstanceFormControlEnum
          annotationInstance={annotationInstance}
          annotationClassProperty={annotationClassProperty}
          value={propertyValue}
          onChange={onChange}
        />
      )}
    </div>
  );
};
