import { PrimitiveAtom, useAtom } from 'jotai';
import { VideoEditorEvents } from 'modules/video-editor-2/enums';
import { useContext, useEffect } from 'react';
import { AnnotationClassType } from 'shared/enums';
import { annotationAtomsAtom, useAnnotationsStore } from '../useAnnotationsStore';
import { VideoEditorContext } from '../VideoEditorContext';

const VideoEditorObject = ({ atom }: { atom: PrimitiveAtom<AnnotationInstanceDTO> }) => {
  const [annotationInstance] = useAtom(atom);
  const annotationStore = useAnnotationsStore(); // @tood we don't want this here
  const { videoEditor } = useContext(VideoEditorContext); // @todo use atom

  useEffect(() => {
    if (!videoEditor) {
      return;
    }

    if (
      annotationInstance.annotationClass.type !== AnnotationClassType.BOUNDING_BOX ||
      !(annotationInstance as any).editorObject
    ) {
      return;
    }

    console.log('add', (annotationInstance as any).editorObject);
    videoEditor.objects.add((annotationInstance as any).editorObject);
  }, [videoEditor]);

  useEffect(() => {
    if (!videoEditor) {
      return;
    }

    const editorObject = (annotationInstance as any).editorObject;
    if (annotationInstance.annotationClass.type !== AnnotationClassType.BOUNDING_BOX || !editorObject) {
      return;
    }

    console.log('useEffect');

    const handler = () => {
      const frame = videoEditor.video.getCurrentFrame(); // @todo
      const transform = editorObject.getTransformMatrix();
      annotationStore.update(annotationInstance.id, { key: 'transform_matrix', frame, value: transform });
    };

    editorObject.on(VideoEditorEvents.USER_TRANSFORM_END, handler);

    return () => {
      editorObject.off(VideoEditorEvents.USER_TRANSFORM_END, handler);
    };
  }, [videoEditor, annotationInstance]);

  useEffect(() => {
    console.log('forceSync');
    videoEditor?.forceSync();
  }, [annotationInstance]);

  return null;
};

export const VideoEditorObjectSync = () => {
  const [annotationsAtom] = useAtom(annotationAtomsAtom);

  return (
    <>
      {annotationsAtom.map((atom) => (
        <VideoEditorObject key={`${atom}`} atom={atom} />
      ))}
    </>
  );
};
