import { CanvasObjectRenderer } from './CanvasObjectRenderer';

interface Props {
  width: number;
  height: number;
  hdpi: boolean;
  willReadFrequently?: boolean;
}

export const Canvas = ({ width, height, hdpi = false, willReadFrequently = false }: Props) => {
  const state = { width, height };
  const pixelRatio = hdpi ? window.devicePixelRatio : 1;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d', { willReadFrequently });
  const canvasObjectRenderer = CanvasObjectRenderer(context);

  function clear() {
    if (!context) return;

    const { width, height } = state;
    context.clearRect(0, 0, width, height);

    // @todo transform reset messes up hdpi setting

    // @todo - Remove type cast when updated definition (TypeScript/lib.dom.d.ts) will be available
    // if (typeof (context as any).reset === 'function') {
    //   (context as any).reset();
    //   return;
    // }

    //   context.save();
    //  context.setTransform(1, 0, 0, 1, 0, 0);
    //   context.clearRect(0, 0, canvas.width, canvas.height);
    //   context.restore();
  }

  function resize({ width, height }: { width: number; height: number }) {
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    canvas.width = Math.floor(width * pixelRatio);
    canvas.height = Math.floor(height * pixelRatio);

    if (hdpi) {
      context?.scale(devicePixelRatio, devicePixelRatio);
    }

    state.width = width;
    state.height = height;
  }

  function _init() {
    resize({ width: state.width, height: state.height });
  }

  _init();

  const { render } = canvasObjectRenderer;

  return {
    view: canvas,
    context,
    clear,
    render,
    resize,
  };
};
