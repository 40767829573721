import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Typography } from 'components/common/Typography';

export const ErrorPage = () => {
  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Something went wrong
          </Typography>
        </div>
      </LayoutContentHeader>

      <LayoutContent></LayoutContent>
    </LayoutContentBody>
  );
};
