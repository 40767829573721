import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { LayoutLoadingSkeleton } from 'components/common/Layout/LayoutLoadingSkeleton';
import { Typography } from 'components/common/Typography';
import { OrganizationUserInviteModal } from 'components/Modals/OrganizationUserInviteModal/OrganizationUserInviteModal';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { FiUserPlus } from 'react-icons/fi';
import UserListing from './UserListing/UserListing';

export const OrganizationUsers = () => {
  const { organizationId } = useIntParams<RouteParams.Organization>();

  const { data: users, isLoading } = useQuery('GetOrganizationUsers', {
    params: { organizationId },
  });
  const { data: roles, isLoading: rolesLoading } = useQuery('GetUserRoles', {
    params: { organizationId },
  });

  if (isLoading || rolesLoading || !users || !roles) {
    return <LayoutLoadingSkeleton />;
  }

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Organization Users
          </Typography>
        </div>

        <Dialog render={() => <OrganizationUserInviteModal />}>
          <Button icon={<FiUserPlus />} label="Invite User" />
        </Dialog>
      </LayoutContentHeader>

      <LayoutContent>
        <UserListing users={users} roles={roles} />
      </LayoutContent>
    </LayoutContentBody>
  );
};
