import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import { useQuery } from 'hooks/useQuery';
import { ChangeEmailModal } from '../ChangeEmailModal/ChangeEmailModal';
import { ChangePasswordModal } from '../ChangePasswordModal/ChangePasswordModal';

export const ProfileSettingsModal = () => {
  const whoamiQuery = useQuery('GetWhoAmI', {});
  const email = whoamiQuery.data?.email;

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Profile" />
      <ModalLayoutBody>
        <div className="mb-4">
          <div className="font-semibold mt-2">Email</div>
          <div className="my-1">{email}</div>
          <div>
            <Dialog render={() => <ChangeEmailModal />}>
              <Button variant="link">Change Email</Button>
            </Dialog>
          </div>
        </div>

        <div className="border-b my-3"></div>

        <div className="mb-4">
          <div className="font-semibold mt-2">Password</div>
          <Dialog render={() => <ChangePasswordModal />}>
            <Button variant="link">Change Password</Button>
          </Dialog>
        </div>
      </ModalLayoutBody>
    </ModalLayout>
  );
};
