import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { Select } from 'components/common/Forms/Select/Select';
import { UNASSIGNED_USER_ID } from 'components/common/JobDataitemFilters/JobDataitemFilters';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  jobDataitemIds: JobDataitemDTO['id'][];
  jobDataitemsQueryParams: {
    [key: string]: any;
  };
}

export const JobDataitemAssignModal = ({ jobDataitemIds, jobDataitemsQueryParams }: Props) => {
  const { close } = useContext(DialogContext);
  const { organizationId, projectId, jobId } = useIntParams();
  const jobUsersQuery = useQuery('GetJobUsers', {
    params: { organizationId, projectId, jobId },
  });

  const { mutateAsync: setBulkJobDataitemAssignee } = useMutation('SetBulkJobDataitemAssignee');

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<{ jobUserIds: JobDataitemDTO['id'][] }>();

  const onSubmit = handleSubmit(async ({ jobUserIds }) => {
    await setBulkJobDataitemAssignee({
      data: {
        jobDataitemIds,
        jobUserIds,
      },
      params: { organizationId, projectId, jobId },
      query: jobDataitemsQueryParams,
    });

    close();
  });

  return (
    <ModalLayout>
      <ModalLayoutHeader title={<>Assign {jobDataitemIds.length} Dataitems</>} />
      <ModalLayoutBody>
        <div>Select user(s)</div>

        <form onSubmit={onSubmit}>
          <div className="my-2">
            {jobUsersQuery.data && (
              <Select
                control={control}
                options={[
                  {
                    value: UNASSIGNED_USER_ID,
                    label: 'Unassigned',
                  },
                  ...jobUsersQuery.data?.map(({ id: value, user: { email: label } }) => ({
                    value,
                    label,
                  })),
                ]}
                name="jobUserIds"
                isMulti={true}
                required
              />
            )}
          </div>
        </form>
      </ModalLayoutBody>
      <ModalLayoutFooter>
        <Button loading={isSubmitting} onClick={onSubmit}>
          Assign
        </Button>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
