import classNames from 'classnames';
import { mapAnnotationClassTypeToDisplayIcon } from 'components/Project/Jobs/Job/JobTasks/JobTasksAnnotationClasses/JobTasksAnnotationClasses';
import { videoTimelineState } from 'components/Workspace2/state/videoTimelineState';
import {
  highlightedAnnotationInstanceIdAtom,
  selectedAnnotationInstanceIdAtom,
} from 'components/Workspace2/useAnnotationsStore';
import { mapAnnotationClassTypeToDisplayName } from 'enums';
import { useAtom } from 'jotai';
import { videoTimelineConfig } from '../VideoTimeline';

interface Props {
  annotationInstance: AnnotationInstanceDTO;
}

export const VideoTimelineRowHeadingTitle = ({ annotationInstance }: Props) => {
  const [videoTimelineAnnotationInstanceOptions, setVideoTimelineAnnotationInstanceOptions] = useAtom(
    videoTimelineState.annotationInstanceOptionsAtom
  );
  // const isDetailedView = videoTimelineAnnotationInstanceOptions[annotationInstance.id]?.isDetailedView;
  const [highlightedAnnotationInstanceId] = useAtom(highlightedAnnotationInstanceIdAtom);
  const [selectedAnnotationInstanceId] = useAtom(selectedAnnotationInstanceIdAtom);

  // const toggleDetailedView = () => {
  //   setVideoTimelineAnnotationInstanceOptions((prev: any) => ({
  //     ...prev,
  //     [annotationInstance.id]: {
  //       ...prev[annotationInstance.id],
  //       isDetailedView: !prev[annotationInstance.id]?.isDetailedView,
  //     },
  //   }));
  // };

  return (
    <div
      className={classNames(
        'text-xxs flex items-center gap-1 border-b border-ws-panel-100 bg-neutral-800/30 px-3 hover:bg-black/10',
        {
          'bg-black/10': highlightedAnnotationInstanceId === annotationInstance.id,
          'bg-black/10 text-blue-400': selectedAnnotationInstanceId === annotationInstance.id,
        }
      )}
      style={{ height: videoTimelineConfig.ROW_LVL_1_HEIGHT_IN_PX }}
    >
      {/* <button tabIndex={-1} onMouseDown={toggleDetailedView}>
        {isDetailedView ? <MdArrowDropDown size={20} /> : <MdArrowRight size={20} />}
      </button> */}

      {mapAnnotationClassTypeToDisplayIcon[annotationInstance.annotationClass.type]}
      {annotationInstance.name || mapAnnotationClassTypeToDisplayName[annotationInstance.annotationClass.type]}
    </div>
  );
};
