import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import FormError from 'components/common/FormError';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { Radio } from 'components/common/Forms/Radio/Radio';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatasetType } from 'shared/enums';
import { ErrorType } from 'shared/errors';

type DatasetCreateFormValues = Pick<DatasetDTO, 'name' | 'type'>;

export const DatasetCreateModal = () => {
  const { close } = useContext(DialogContext);
  const { organizationId, projectId } = useIntParams();
  const { navigateTo } = useRouterUtils();
  const { mutateAsync: createDataset } = useMutation('CreateDataset');
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { isSubmitting },
  } = useForm<DatasetCreateFormValues>();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const onSubmit = handleSubmit(async ({ name, type }) => {
    setError(null);

    const res = await createDataset({
      data: { name, type },
      params: { organizationId, projectId },
    }).catch((error) => error);

    if (res instanceof Error) {
      setError(ErrorType.GENERIC);
      return;
    }

    navigateTo('DATASET', { datasetId: res.id });
    close();
  });

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Create Dataset" />

      <ModalLayoutBody>
        <form onSubmit={onSubmit}>
          <FormError type={error} />

          <div className="mt-2 mb-4">
            <Label htmlFor={'name'}>Dataset Name</Label>
            <InputText {...register('name')} id={'name'} required />
          </div>

          <fieldset className="mt-2 mb-4">
            <legend className="pb-1 font-medium">Dataset Type</legend>

            <Radio {...register('type')} value={DatasetType.IMAGE} label="Image" required />
            <Radio {...register('type')} value={DatasetType.VIDEO} label="Video" required />
            <Radio {...register('type')} value={DatasetType.TEXT} label="Text" required />
          </fieldset>
        </form>
      </ModalLayoutBody>

      <ModalLayoutFooter>
        <Button loading={isSubmitting} label="Create" onClick={onSubmit} />
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
