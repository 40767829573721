import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Tag } from 'components/common/Tag/Tag';
import {
  JobExportStatus,
  mapJobExportFormatToDisplayName,
  mapJobExportStatusToDisplayName,
  mapJobExportTypeToDisplayName,
} from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { isUndefined } from 'lodash';
import { AiOutlineLoading } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { getEllapsedTime, getFormattedDate } from 'utils/dateUtils';

interface Props {
  rows?: JobExportHistoryEntryDTO[];
}

export const useJobExportsTable = ({ rows }: Props) => {
  const columnHelper = createColumnHelper<JobExportHistoryEntryDTO>();
  const showJobColumn = isUndefined(useIntParams().jobId);
  const { pathTo } = useRouterUtils();

  const columns = [
    columnHelper.accessor('id', {
      header: 'Export Id',
    }),
    columnHelper.accessor((row) => row.job?.id, {
      id: 'jobId',
      header: 'Job',
      cell: (data) => {
        const jobId = data.row.original?.job?.id;
        const jobName = data.row.original?.job?.name;
        return <Link to={pathTo('JOB', { jobId })}>{jobName}</Link>;
      },
    }),

    columnHelper.accessor('createdAt', {
      header: 'Started At',
      cell: (data) => {
        const createdAt: string = data.row.getValue('createdAt');
        return getFormattedDate(createdAt);
      },
    }),

    columnHelper.accessor('finishedAt', {
      header: 'Completed At',
      cell: (data) => {
        const finishedAt: string = data.row.getValue('finishedAt');
        return finishedAt ? getFormattedDate(finishedAt) : '-';
      },
    }),

    columnHelper.display({
      id: 'duration',
      header: 'Duration',
      cell: (data) => {
        const createdAt: string = data.row.getValue('createdAt');
        const finishedAt: string = data.row.getValue('finishedAt');
        return finishedAt ? getEllapsedTime(createdAt, finishedAt) : '-';
      },
    }),

    columnHelper.accessor((row) => row.jobExportConfig.type, {
      id: 'exportType',
      header: 'Type',
      cell: (data) => {
        return mapJobExportTypeToDisplayName[data.getValue()];
      },
    }),

    columnHelper.accessor((row) => row.jobExportConfig.format, {
      id: 'exportFormat',
      header: 'Type',
      cell: (data) => {
        return mapJobExportFormatToDisplayName[data.getValue()];
      },
    }),

    columnHelper.accessor('itemCount', {
      header: '# Dataitems',
    }),

    columnHelper.accessor('locationUrl', {
      header: 'Export URL',
      cell: (data) => {
        const locationUrl = data.getValue();
        return locationUrl ? <a href={locationUrl}>Download</a> : '-';
      },
    }),

    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      cell: (data) => {
        const status = data.getValue();
        return (
          <Tag
            variant={
              (status === JobExportStatus.COMPLETED && 'success') ||
              (status === JobExportStatus.FAILED && 'error') ||
              'default'
            }
          >
            <span className="uppercase">{mapJobExportStatusToDisplayName[status]}</span>

            {status === JobExportStatus.RUNNING && <AiOutlineLoading className="animate-spin" />}
          </Tag>
        );
      },
    }),
  ];

  const instance = useReactTable({
    data: rows || [],
    columns: [],
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnVisibility: {
        jobId: showJobColumn,
      },
    },
  });

  return {
    instance,
  };
};
