import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { JobExportRemoteStorageItem } from './JobExportRemoteStorageItem/JobExportRemoteStorageItem';

export const JobExportRemoteStorage = () => {
  const { organizationId, jobId, projectId } = useIntParams<RouteParams.Job>();
  const { data: jobExportRemoteStorageResources } = useQuery(
    'GetJobExportRemoteStorageTargets',
    {
      params: { organizationId, projectId, jobId },
    }
  );

  if (!jobExportRemoteStorageResources) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {jobExportRemoteStorageResources?.map((item) => (
        <JobExportRemoteStorageItem
          key={item.id}
          remoteStorageResource={item}
        />
      ))}
    </>
  );
};
