import { throttle } from 'lodash';

const ScrollDirection = {
  UP: 'UP',
  DOWN: 'DOWN',
};

const getScrollDirectionFromEvent = (event: WheelEvent) => {
  return (event.deltaY === 0 ? event.deltaX : event.deltaY) > 0 ? ScrollDirection.UP : ScrollDirection.DOWN;
};

export function ViewZoom(view: HTMLElement, transform: any) {
  function onWheel(event: WheelEvent) {
    event.preventDefault();

    if (event.buttons !== 0) {
      return;
    }

    const { x: tx, y: ty, scale } = transform.getState();

    let { offsetX: x, offsetY: y } = event;
    x = Math.round((x - tx) / scale);
    y = Math.round((y - ty) / scale);

    const newZoomValue = Math.max(
      0.1,
      getScrollDirectionFromEvent(event) === ScrollDirection.UP ? scale - scale * 0.1 : scale + scale * 0.1
    );

    const imageX = x * scale;
    const imageY = y * scale;

    const newX = tx - ((newZoomValue * imageX) / scale - imageX);
    const newY = ty - ((newZoomValue * imageY) / scale - imageY);

    transform.setState({
      x: newX,
      y: newY,
      scale: newZoomValue,
    });
  }

  const throttledOnWheel = throttle(onWheel, 15);

  function destroy() {
    view.removeEventListener('wheel', throttledOnWheel);
  }

  function _init() {
    view.addEventListener('wheel', throttledOnWheel);
  }

  _init();

  return Object.freeze({
    destroy,
  });
}
