import { AppRoutes } from 'appRoutes';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { LayoutContentTabs } from 'components/common/Layout/LayoutContentTabs';
import { Typography } from 'components/common/Typography';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Route, Switch } from 'react-router-dom';
import { BillingHistory } from './BillingHistory/BillingHistory';
import { SubscriptionPlans } from './SubscriptionPlans/SubscriptionPlans';

export const PlansAndBilling = () => {
  const { pathTo } = useRouterUtils();

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Plans & Billing
          </Typography>
        </div>
      </LayoutContentHeader>

      <LayoutContentTabs
        items={[
          {
            to: pathTo('ORGANIZATION_PLANS_AND_BILLING'),
            label: 'Plans',
          },
          {
            to: pathTo('BILLING_HISTORY'),
            label: 'Billing History',
          },
        ]}
      />

      <LayoutContent>
        <Switch>
          <Route path={AppRoutes.ORGANIZATION_PLANS_AND_BILLING} component={SubscriptionPlans} exact />
          <Route path={AppRoutes.BILLING_HISTORY} component={BillingHistory} />
        </Switch>
      </LayoutContent>
    </LayoutContentBody>
  );
};
