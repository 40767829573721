import { useEffect } from 'react';

export const useClassNames = (el: HTMLElement, classes: string[]) => {
  useEffect(() => {
    if (!el) {
      return;
    }

    el.classList.add(...classes);

    return () => {
      el.classList.remove(...classes);
    };
  }, []);
};
