import {
  GenericError,
  JsonError,
  SelfServiceSettingsFlow,
  SubmitSelfServiceSettingsFlowBody,
} from '@ory/kratos-client';
import axios, { AxiosError } from 'axios';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { oryKratosService } from './oryKratos.service';

export const useSettingsFlow = () => {
  const searchParams = useURLSearchParams();
  const [flow, setFlow] = useState<SelfServiceSettingsFlow>();
  const [error, setError] = useState<GenericError>();
  const settingsMutation = useMutation(oryKratosService.settings);

  const updateSettings = async (data: SubmitSelfServiceSettingsFlowBody) => {
    if (!flow) {
      console.log('Settings flow not initialized');
      return;
    }

    try {
      const response = await settingsMutation.mutateAsync({
        flow: flow.id,
        data,
      });

      setFlow(response.data);

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error as AxiosError<
          SelfServiceSettingsFlow | JsonError
        >;

        if (!response) {
          return;
        }

        const { data } = response;

        if ('error' in data) {
          setError(data.error);
        } else {
          setFlow(data);
        }
      }

      return error;
    }
  };

  useEffect(() => {
    (async () => {
      const flowParam = searchParams.get('flow');
      const res = await oryKratosService.initSettingsFlow(flowParam);
      setFlow(res.data);
    })();
  }, [searchParams]);

  return {
    updateSettings,
    flow,
    error,
  };
};
