// @todo input size must match button size (fontSize too)
// @todo error state
// @todo disabled state
// @todo extract text colors & bg colors
// @todo focus shadow
// @todo forwardRef
// @todo common name for size

// input[type='number']::-webkit-inner-spin-button,
// input[type='number']::-webkit-outer-spin-button {
//   opacity: 0;
// }

// @classNames join (no template) and type

import classNames from 'classnames';
import { forwardRef } from 'react';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  variant?: Component.Size;
}

export const InputText = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { variant = 'regular', className, ...rest } = props;

  const sizeClasses: any = {
    sm: `
      text-xs
      p-1
    `,
    regular: `
      text-sm
    `,
  };

  if (props.type === 'hidden') {
    // eslint-disable-next-line react/forbid-elements
    return <input ref={ref} {...rest} />;
  }

  return (
    // eslint-disable-next-line react/forbid-elements
    <input ref={ref} className={classNames('input-text', className, sizeClasses[variant])} {...rest} />
  );
});
