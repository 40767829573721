import {
  arrow,
  FloatingArrow,
  FloatingPortal,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import classNames from 'classnames';
import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { useAtom } from 'jotai';
import { useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { IoCloseSharp } from 'react-icons/io5';
import { videoTimelineConfig } from '../VideoTimeline';

interface Props {
  index: number;
  onRemove?: () => void;
  onMoveTo?: (to: number) => void;
}

export const VideoTimelineKeyframe = ({ index, onMoveTo, onRemove }: Props) => {
  const { FRAME_WIDTH_IN_PX } = videoTimelineConfig;
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom); // @todo optimize

  const handleDragStop = (_event: DraggableEvent, data: DraggableData) => {
    // const targetFrameIndex = index + Math.floor(data.x / FRAME_WIDTH_IN_PX);
    onMoveTo && onMoveTo(currentFrame);
  };

  // const isMovable = typeof onMoveTo === 'function';
  const isMovable = false;
  const isRemovable = typeof onRemove === 'function';

  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(5),
      arrow({
        element: arrowRef,
      }),
    ],
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const element = (
    <div
      className={classNames('group absolute top-0 bottom-0 z-10 flex items-center justify-center', {
        'cursor-ew-resize': isMovable,
      })}
      style={{ width: FRAME_WIDTH_IN_PX, left: index * FRAME_WIDTH_IN_PX }}
    >
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <div
          className={classNames('rotate-45 bg-white/80', {
            'group-hover:bg-yellow-400': isRemovable,
            'opacity-50': !isRemovable,
          })}
          style={{
            width: 7,
            height: 7,
          }}
        ></div>
      </div>

      {typeof onRemove === 'function' && isOpen && (
        <FloatingPortal>
          <div
            className="text-neutral-200 text-xxs z-10 border border-neutral-800"
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            <FloatingArrow ref={arrowRef} context={context} />
            <button
              className="flex w-full items-center gap-2 bg-black py-1.5 px-4 hover:bg-neutral-900"
              onClick={onRemove}
            >
              <IoCloseSharp /> Delete Keyframe
            </button>
          </div>
        </FloatingPortal>
      )}
    </div>
  );

  return isMovable ? (
    <Draggable axis="x" onStop={handleDragStop} key={index}>
      {element}
    </Draggable>
  ) : (
    element
  );
};
