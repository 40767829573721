import {
  SelfServiceLoginFlow,
  SelfServiceLogoutUrl,
  SelfServiceRecoveryFlow,
  SelfServiceRegistrationFlow,
  SelfServiceSettingsFlow,
  SubmitSelfServiceLoginFlowWithPasswordMethodBody,
  SubmitSelfServiceRecoveryFlowWithLinkMethodBody,
  SubmitSelfServiceRegistrationFlowWithPasswordMethodBody,
  SubmitSelfServiceSettingsFlowBody,
  SuccessfulSelfServiceLoginWithoutBrowser,
  SuccessfulSelfServiceRegistrationWithoutBrowser,
} from '@ory/kratos-client';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: globalConfig.env.REACT_APP_AUTH_API_URL,
  withCredentials: true,
});

export const oryKratosService = {
  initLoginFlow: async () => {
    const res = await axiosInstance.get<SelfServiceLoginFlow>('/self-service/login/browser');
    return res;
  },

  login: async ({ flow, data }: { flow: string; data: SubmitSelfServiceLoginFlowWithPasswordMethodBody }) => {
    const res = await axiosInstance.post<SuccessfulSelfServiceLoginWithoutBrowser>(`/self-service/login`, data, {
      params: {
        flow,
      },
    });
    return res;
  },

  initRegisterFlow: async () => {
    const res = await axiosInstance.get<SelfServiceRegistrationFlow>('/self-service/registration/browser');
    return res;
  },

  register: async ({ flow, data }: { flow: string; data: SubmitSelfServiceRegistrationFlowWithPasswordMethodBody }) => {
    const res = await axiosInstance.post<SuccessfulSelfServiceRegistrationWithoutBrowser>(
      `/self-service/registration`,
      data,
      {
        params: {
          flow,
        },
      }
    );
    return res;
  },

  initLogoutFlow: async () => {
    const res = await axiosInstance.get<SelfServiceLogoutUrl>('/self-service/logout/browser');
    return res;
  },

  logout: async ({ token }: { token: string }) => {
    const res = await axiosInstance.get<void>('/self-service/logout', {
      params: { token },
    });
    return res;
  },

  initAccountRecoveryFlow: async () => {
    const res = await axiosInstance.get<SelfServiceRecoveryFlow>('/self-service/recovery/browser');
    return res;
  },

  accountRecovery: async ({ flow, data }: { flow: string; data: SubmitSelfServiceRecoveryFlowWithLinkMethodBody }) => {
    const res = await axiosInstance.post<SelfServiceRecoveryFlow>(`/self-service/recovery`, data, {
      params: {
        flow,
      },
    });
    return res;
  },

  initSettingsFlow: async (flow: string | null) => {
    if (flow) {
      return axiosInstance.get<SelfServiceSettingsFlow>(`/self-service/settings/flows?id=${flow}`);
    } else {
      return axiosInstance.get<SelfServiceSettingsFlow>(`/self-service/settings/browser`);
    }
  },

  settings: async ({ flow, data }: { flow: string; data: SubmitSelfServiceSettingsFlowBody }) => {
    const res = await axiosInstance.post<SelfServiceSettingsFlow>(`/self-service/settings`, data, {
      params: {
        flow,
      },
    });
    return res;
  },
};
