import classNames from 'classnames';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useAtom } from 'jotai';
import { AiOutlineFile } from 'react-icons/ai';
import { workspaceContextStore } from './Workspace2';
import { WorkspaceDataitemWorkflowStateSelect } from './WorkspaceDataitemWorkflowStateSelect';
import { workspaceState } from './state/workspaceState';

export const WorkspaceDataitemTitlebar = () => {
  const { organizationId, projectId, jobId, jobDataitemId } = useIntParams();
  const jobDataitemQuery = useQuery('GetJobDataitemById', {
    params: { organizationId, projectId, jobId, jobDataitemId },
  });

  const [hasUnsavedChanges] = useAtom(workspaceState.hasUnsavedChangesAtom, {
    store: workspaceContextStore,
  });

  return (
    <div className="flex items-center justify-between bg-ws-panel px-1.5 py-1 text-xxs gap-1 text-gray-300 font-medium border-ws-panel border-b">
      <div className="flex items-center gap-1.5">
        <div className="flex gap-1 items-center text-neutral-400">
          <AiOutlineFile />
          <span>{jobDataitemQuery.data?.dataitem.externalId}</span>
        </div>
        <div
          style={{ width: 6, height: 6 }}
          className={classNames('rounded-full', {
            'bg-red-500': hasUnsavedChanges,
            'bg-green-500': !hasUnsavedChanges,
          })}
        ></div>
      </div>

      <div>
        <WorkspaceDataitemWorkflowStateSelect />
      </div>
    </div>
  );
};
