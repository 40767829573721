import { usePersistedState } from 'hooks/usePersistedState';
import { atom } from 'jotai';
import { useRef } from 'react';
import { DraggableCore } from 'react-draggable';
import { VideoTimelineHeader } from './VideoTimeline/VideoTimelineHeader';
import { VideoTimelineHeadingsColumn } from './VideoTimeline/VideoTimelineHeadingsColumn';
import { VideoTimelineKeyframesColumn } from './VideoTimeline/VideoTimelineKeyframesColumn';
import { VideoTimelineOptions } from './VideoTimeline/VideoTimelineOptions';

export const videoTimelineConfig = {
  FRAME_WIDTH_IN_PX: 14,
  ROW_LVL_1_HEIGHT_IN_PX: 30,
  ROW_HEIGHT_IN_PX: 24,
};

export const VideoTimelineState = {
  xOffsetAtom: atom(0),
  yOffsetAtom: atom(0),
};

export const VideoTimeline = () => {
  const draggableRef = useRef<HTMLDivElement | null>(null);
  const [componentHeight, setComponentHeight] = usePersistedState(300, 'video-timeline-height');
  const handleRowHeightChangeOnDrag = (delta: number) => {
    setComponentHeight((prev: any) => prev - delta);
  };

  return (
    <div
      className="flex w-full select-none flex-col bg-ws-panel-body text-xs text-gray-300"
      onContextMenu={(e) => e.preventDefault()}
    >
      <DraggableCore
        nodeRef={draggableRef}
        offsetParent={document.body}
        onDrag={(_, d) => {
          handleRowHeightChangeOnDrag(d.deltaY);
        }}
      >
        <div className="w-full cursor-ns-resize" style={{ height: 5 }} ref={draggableRef}></div>
      </DraggableCore>

      <div className="flex items-center border-b border-neutral-800 bg-ws-panel px-3 pt-1 pb-2">
        <VideoTimelineOptions />
      </div>
      <VideoTimelineHeader />
      <div className="flex overflow-hidden" style={{ height: componentHeight }}>
        <VideoTimelineHeadingsColumn />
        <VideoTimelineKeyframesColumn />
      </div>
    </div>
  );
};
