import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { VideoEditorContext } from 'components/Workspace2/VideoEditorContext';
import { useContext, useState, useEffect } from 'react';
import { videoTimelineConfig } from '../VideoTimeline';
import { useAtom } from 'jotai';

export const VideoTimelinePlayheadHeader = () => {
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);

  return (
    <div
      className="flex items-center absolute bg-indigo-600 rounded-b py-0.5 px-1 text-gray-200 z-20 h-full border border-indigo-500"
      style={{
        fontSize: 10,
        left: currentFrame * videoTimelineConfig.FRAME_WIDTH_IN_PX + videoTimelineConfig.FRAME_WIDTH_IN_PX / 2 - 1,
        transform: `translateX(-50%)`,
      }}
    >
      {currentFrame}
    </div>
  );
};
