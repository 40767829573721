import { WorkflowStates } from './types';

// TODO: Replace with ui_bg_color in workflow_states table
const workflowStateColorMapping = {
  [WorkflowStates.OPEN]: '#64B5F6',
  [WorkflowStates.IN_PROGRESS]: '#FFD54F',
  [WorkflowStates.PENDING_REVIEW]: '#FF8A65',
  [WorkflowStates.REVIEW]: '#FF7043',
  [WorkflowStates.PENDING_QA]: '#AB47BC',
  [WorkflowStates.QA]: '#9C27B0',
  [WorkflowStates.DONE]: '#66BB6A',
  [WorkflowStates.REOPENED]: '#EF5350',
};

export default workflowStateColorMapping;
