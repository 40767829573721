import classNames from 'classnames';
import { useAtom } from 'jotai';
import { Fragment } from 'react';
import { AnnotationClassPropertyType } from 'shared/enums';
import { videoEditorState } from '../state/videoEditorState';
import { videoTimelineState } from '../state/videoTimelineState';
import { useAnnotationsStore } from '../useAnnotationsStore';
import { videoTimelineConfig } from './VideoTimeline';
import { VideoTimelineKeyframe } from './VideoTimeline/VideoTimelineKeyframe';
import { VideoTimelineKeyframeSpan } from './VideoTimeline/VideoTimelineKeyframeSpan';

interface Props {
  annotationClassProperty: AnnotationClassPropertyDTO;
  annotationInstance: AnnotationInstanceDTO;
}

export const VideoTimelineRowItem = ({ annotationClassProperty, annotationInstance }: Props) => {
  const [frameCount] = useAtom(videoEditorState.frameCountAtom);
  const { deleteKeyframe, moveKeyframe } = useAnnotationsStore();
  const [hoveredRow, setHoveredRow] = useAtom(videoTimelineState.hoveredRow);
  const handleMouseEnter = () => {
    setHoveredRow(annotationClassProperty.id);
  };
  const handleMouseLeave = () => {
    setHoveredRow((prev: any) => (prev === annotationClassProperty.id ? null : prev));
  };

  return (
    <div
      key={annotationClassProperty.id}
      className={classNames('relative border-b border-ws-panel-100', {
        'bg-black/10': hoveredRow === annotationClassProperty.id,
      })}
      style={{ height: videoTimelineConfig.ROW_HEIGHT_IN_PX }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {(annotationInstance.properties[annotationClassProperty.key] || []).map(
        ({ frame, value }: any, index: number) => (
          <Fragment key={`${index}`}>
            <VideoTimelineKeyframe
              index={frame}
              onMoveTo={(to) => {
                moveKeyframe(annotationInstance.id, { key: annotationClassProperty.key, from: frame, to });
              }}
              onRemove={() => {
                deleteKeyframe(annotationInstance.id, { key: annotationClassProperty.key, index: frame });
              }}
            />

            {annotationClassProperty.type === AnnotationClassPropertyType.BOOLEAN && value.toString() === 'true' && (
              <VideoTimelineKeyframeSpan
                fromIndex={frame}
                toIndex={annotationInstance.properties[annotationClassProperty.key][index + 1]?.frame || frameCount + 1} // @todo +1
              />
            )}

            {annotationClassProperty.type === AnnotationClassPropertyType.ENUM && value.toString() !== '' && (
              <VideoTimelineKeyframeSpan
                fromIndex={frame}
                toIndex={annotationInstance.properties[annotationClassProperty.key][index + 1]?.frame || frameCount + 1}
                value={value}
              />
            )}
          </Fragment>
        )
      )}
    </div>
  );
};
