const hexColorUids: { [key: string]: boolean } = {};

export function uniqueHexColor() {
  let id;

  do {
    id = Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0");
  } while (hexColorUids[id]);

  hexColorUids[id] = true;

  return id;
}
