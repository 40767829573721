import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Typography } from 'components/common/Typography';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { noop } from 'lodash';
import WorkflowCreate from './WorkflowCreate';
import WorkflowListing from './WorkflowListing';

const Workflows = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const { data: workflows } = useQuery('GetWorkflows', { params: { organizationId, projectId } });
  const { navigateTo } = useRouterUtils();

  if (!workflows) {
    return null;
  }

  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Workflows
          </Typography>
        </div>

        <div>
          <Dialog
            render={() => (
              <WorkflowCreate
                projectId={projectId}
                organizationId={organizationId}
                onCreate={({ id }) => {
                  navigateTo('WORKFLOW', { workflowId: id });
                }}
                onCancel={noop}
              />
            )}
          >
            <Button>Create Workflow</Button>
          </Dialog>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <WorkflowListing workflows={workflows} />
      </LayoutContent>
    </LayoutContentBody>
  );
};

export default Workflows;
