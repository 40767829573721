import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { DropdownButtonItem } from 'components/common/DropdownButtonItem/DropdownButtonItem';
import { ConfigureInboundRemoteStorageModal } from 'components/Modals/ConfigureInboundRemoteStorageModal/ConfigureInboundRemoteStorageModal';
import { DataitemUploadModal } from 'components/Modals/DataitemUploadModal/DataitemUploadModal';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useState } from 'react';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { BsFillCloudPlusFill, BsPlayFill } from 'react-icons/bs';
import { MdArrowDropDown, MdOutlineComputer } from 'react-icons/md';

export const DatasetImportDropdown = () => {
  const { projectId, organizationId, datasetId } = useIntParams<RouteParams.Dataset>();
  const [isDataitemUploadModalOpen, setIsDataitemUploadModalOpen] = useState(false);
  const [isConfigureInboundRemoteStorageModalOpen, setIsConfigureInboundRemoteStorageModalOpen] = useState(false);
  const { mutateAsync: triggerImport } = useMutation('TriggerDatasetRemoteStorageImport');
  const { data: datasetRemoteStorageResources } = useQuery('GetDatasetRemoteImportSources', {
    params: {
      organizationId,
      projectId,
      datasetId,
    },
  });

  const dropdown = (
    <div>
      <div>
        <DropdownButtonItem onClick={() => setIsDataitemUploadModalOpen(true)}>
          <MdOutlineComputer /> Upload from Local Machine
        </DropdownButtonItem>
      </div>

      <div className="border-t border-b">
        <DropdownButtonItem onClick={() => setIsConfigureInboundRemoteStorageModalOpen(true)}>
          <BsFillCloudPlusFill />
          Add Remote Import Source
        </DropdownButtonItem>
      </div>

      {datasetRemoteStorageResources?.length !== 0 && (
        <div className="px-4 py-4 pb-2 text-xs text-slate-500">REMOTE IMPORT SOURCES</div>
      )}

      {datasetRemoteStorageResources?.map(({ id, endpointUrl, bucketName, bucketPrefix }) => (
        <div
          className="group flex w-full items-center gap-2 border-t px-4 py-2 text-left text-slate-600 hover:bg-slate-50"
          key={id}
        >
          <AiOutlineCloudDownload />
          <div>
            <div>{endpointUrl} </div>
            <div className="mt-1 text-xs text-slate-500">
              {bucketName}/{bucketPrefix}
            </div>
          </div>

          <Button
            className="invisible group-hover:visible"
            variant="icon-rounded"
            icon={<BsPlayFill />}
            title="Run Import"
            onClick={() =>
              triggerImport({
                params: {
                  organizationId,
                  projectId,
                  datasetId,
                  remoteStorageResourceId: id,
                },
              })
            }
          />
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Dialog
        render={() => <DataitemUploadModal />}
        isOpen={isDataitemUploadModalOpen}
        onOpenChange={setIsDataitemUploadModalOpen}
      />

      <Dialog
        render={() => <ConfigureInboundRemoteStorageModal />}
        isOpen={isConfigureInboundRemoteStorageModalOpen}
        onOpenChange={setIsConfigureInboundRemoteStorageModalOpen}
      />

      <Dropdown render={() => dropdown}>
        <button className="flex items-center border-r border-t border-b border-slate-200 px-4 py-1.5 text-sm font-medium text-slate-600 first:rounded-l first:border-l last:rounded-r hover:bg-slate-50">
          <div className="mr-4 border-r pl-2 pr-4">Import</div>
          <MdArrowDropDown />
        </button>
      </Dropdown>
    </>
  );
};
