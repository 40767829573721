import dayjs from 'dayjs';

export const getFormattedDate = (dateString: string | Date) => {
  const date = dayjs(dateString);
  return date.format('MMM DD YYYY, HH:mm UTC ZZ');
};

export const getEllapsedTime = (from: string, to: string) => {
  // TODO Use Day.js
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const d = toDate.getTime() - fromDate.getTime();

  const seconds = Math.floor((d / 1000) % 60);
  const minutes = Math.floor((d / (1000 * 60)) % 60);
  const hours = Math.floor((d / (1000 * 60 * 60)) % 24);

  return (
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes) +
    ':' +
    (seconds < 10 ? '0' + seconds : seconds)
  );
};

export const formatSeconds = (seconds: number) => {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = Math.floor(seconds % 60);

  return (
    String(hours).padStart(2, '0') +
    ':' +
    String(minutes).padStart(2, '0') +
    ':' +
    String(remainingSeconds).padStart(2, '0')
  );
};
