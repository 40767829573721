import classNames from 'classnames';
import { useClassNames } from 'hooks/useClassNames';
import useIntParams from 'hooks/useIntParams';
import { createStore, Provider, useAtom } from 'jotai';
import { useEffect } from 'react';
import { AiOutlineFolder } from 'react-icons/ai';
import { BiTimeFive } from 'react-icons/bi';
import { workspaceState } from './state/workspaceState';
import { useAnnotationInstancePersistor } from './useAnnotationInstancePersistor';
import { WorkspaceDatatitem2 } from './WorkspaceDatatitem2';
import { WorkspaceSidebarContainer } from './WorkspaceSidebarContainer';
import { WorkspaceTitlebar } from './WorkspaceTitlebar';

export const workspaceContextStore = createStore();
export const workspaceDataitemContextStore = createStore();

export const Workspace2 = () => {
  const { projectId, jobId, jobDataitemId } = useIntParams();
  const [showSiderbar, setShowSidebar] = useAtom(workspaceState.showSidebarAtom, { store: workspaceContextStore });
  const [showTimelinePanel, setShowTimelinePanel] = useAtom(workspaceState.showTimelinePanelAtom, {
    store: workspaceDataitemContextStore,
  });

  useClassNames(document.body, ['overflow-hidden']);

  useAnnotationInstancePersistor();

  useEffect(() => {
    if (jobDataitemId !== undefined) {
      localStorage.setItem(`workspace-${projectId}-${jobId}-prev-dataitem-id`, jobDataitemId.toString());
    }
  }, [jobDataitemId]);

  return (
    <div className="flex flex-col h-screen overflow-hidden" style={{ background: '#08080f' }}>
      <WorkspaceTitlebar />

      <div className="flex grow overflow-hidden m-0.5">
        <div className="flex">
          <div className="flex flex-col m-0.5 border border-ws-panel rounded overflow-hidden bg-ws-panel">
            <button
              onClick={() => setShowSidebar((prev) => !prev)}
              className={classNames('rounded-sm p-2', {
                'text-neutral-600': !showSiderbar,
                'bg-neutral-800 text-neutral-300': showSiderbar,
              })}
            >
              <AiOutlineFolder size={22} />
            </button>

            <button
              title="Toggle Timeline"
              onClick={() => setShowTimelinePanel((prev: any) => !prev)}
              tabIndex={-1}
              onFocus={(e) => e.target.blur()}
              className={classNames('rounded-sm p-2 mt-auto', {
                'text-neutral-600': !showTimelinePanel,
                'bg-neutral-800 text-neutral-300': showTimelinePanel,
              })}
            >
              <BiTimeFive size={22} />
            </button>
          </div>

          <WorkspaceSidebarContainer />
        </div>

        {jobDataitemId && (
          <div className="flex flex-1 flex-col overflow-hidden" key={`${jobDataitemId}`}>
            <Provider>
              <WorkspaceDatatitem2 />
            </Provider>
          </div>
        )}
      </div>
    </div>
  );
};
