interface Props {
  src: string;
  onCanPlay: () => void;
  onLoad: (event: Event) => void;
  onError: (event: any) => void;
  onLoadedMetadata: (event: Event) => void;
}

export const VideoView = ({ src, onCanPlay, onLoad, onError, onLoadedMetadata }: Props) => {
  const videoEl = document.createElement('video');
  videoEl.onerror = onError;
  videoEl.onload = onLoad;
  videoEl.oncanplay = onCanPlay;
  videoEl.onloadedmetadata = onLoadedMetadata;
  videoEl.controls = false;
  videoEl.preload = 'auto';

  const srcUrl = new URL(src);
  srcUrl.searchParams.set('t', Date.now().toString());

  videoEl.src = srcUrl.toString();

  videoEl.style.width = 'auto';
  videoEl.style.maxWidth = 'none';

  return videoEl;
};
