import classNames from 'classnames';

interface Props {
  isOpen?: boolean;
  className?: string;
}

export const WorkspacePanel = ({ className, children, isOpen = true }: React.PropsWithChildren<Props>) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={classNames('flex m-0.5 border border-ws-panel rounded overflow-hidden', className)}>{children}</div>
  );
};
