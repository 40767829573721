import { Button } from 'components/common/Button/Button';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface WorkflowCreateFormValues {
  name: string;
}

interface Props {
  projectId: number;
  organizationId: OrganizationDTO['id'];
  onCancel?: () => void;
  onCreate?: (workflow: WorkflowDTO) => void;
}

const WorkflowCreate = ({ onCancel, onCreate, organizationId }: Props) => {
  const { projectId } = useIntParams();
  const { mutateAsync: createWorkflow, data: workflow, isSuccess } = useMutation('CreateWorkflow');

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<WorkflowCreateFormValues>();

  const onSubmit = handleSubmit(async ({ name }) => {
    await createWorkflow({
      data: { name },
      params: { organizationId, projectId },
    });
  });

  useEffect(() => {
    if (onCreate && isSuccess && workflow) {
      onCreate(workflow);
    }
  }, [isSuccess, workflow, onCreate]);

  return (
    <div>
      <div className="mt-2 mb-4">
        <h1>Create workflow</h1>
      </div>

      <form onSubmit={onSubmit}>
        <div className="my-2">
          <Label htmlFor="name">Name</Label>
          <InputText id="name" {...register('name')} required />
        </div>

        <Button type="submit" loading={isSubmitting} label="Create" />
      </form>
    </div>
  );
};

export default WorkflowCreate;
