import { Rect2 } from 'modules/video-editor/pxae/primitives/Rect2';

export const TransformBoxRotationAnchors = (
  box: any,
  entity: any,
  { onTransformEnd }: { onTransformEnd: () => void }
) => {
  const anchors = [...Array(4)].map((_, index) =>
    Rect2({
      x: 0,
      y: 0,
      width: 20,
      height: 20,
      fillStyle: 'green',
      data: { draggable: true, selectable: false, index, preserveScaleOnZoom: true },
    })
  );

  function align() {
    const points = box.getPoints();
    anchors.forEach((anchor, i) => {
      const [x, y] = [points[i * 2], points[i * 2 + 1]];
      anchor.translateTo(x, y);
    });
  }

  function show() {
    anchors.forEach((anchor) => anchor.set({ isVisible: true }));
  }

  function hide() {
    anchors.forEach((anchor) => anchor.set({ isVisible: false }));
  }

  function _setup(anchor: any) {
    let pointA: any = null;

    anchor.on('dragstart', () => {
      pointA = anchor.getCentroid();
    });

    anchor.on('drag', () => {
      const c = box.getCentroid();
      const b = anchor.getCentroid();

      {
        const x1 = c.x - pointA.x;
        const y1 = c.y - pointA.y;

        const x2 = c.x - b.x;
        const y2 = c.y - b.y;

        const a = Math.atan2(x1 * y2 - y1 * x2, x1 * x2 + y1 * y2);
        const a2 = a / (Math.PI / 180);

        entity._rotate(a2, c);
        // anchor1._rotate(a2, c);

        pointA = anchor.getCentroid();
      }
    });

    anchor.on('dragend', () => {
      onTransformEnd();
    });
  }

  anchors.forEach(_setup);

  return {
    items: anchors,
    show,
    hide,
    align,
  };
};
