import { Path } from 'modules/video-editor/pxae/primitives/Path';

const style = {
  lineWidth: 1,
  fillStyle: 'transparent',
  strokeStyle: 'rgba(255, 255, 255, 0.3)',
};

const Line = () => {
  return Path({
    points: [0, 0, 0, 0],
    isClosed: false,
    ...style,
  });
};

export const PointerGuideLines = (scene: any) => {
  const vLine = Line();
  const hLine = Line();

  const onMouseMove = (event: any) => {
    const { x, y } = event.coords;
    const { width, height } = scene.world.getState();

    const cx = Math.min(Math.max(0, x), width);
    const cy = Math.min(Math.max(0, y), height);

    vLine.set({ points: [cx, 0, cx, height] });
    hLine.set({ points: [0, cy, width, cy] });
  };

  const onMouseLeave = () => {
    const options = { strokeStyle: 'transparent' };
    vLine.set(options);
    hLine.set(options);
  };

  const onMouseEnter = () => {
    const options = { strokeStyle: style.strokeStyle };
    vLine.set(options);
    hLine.set(options);
  };

  const addEventListeners = () => {
    scene.on('mousemove', onMouseMove);
    scene.on('mouseleave', onMouseLeave);
    scene.on('mouseenter', onMouseEnter);
  };

  function show() {
    vLine.set({ isVisible: true });
    hLine.set({ isVisible: true });
  }

  function hide() {
    vLine.set({ isVisible: false });
    hLine.set({ isVisible: false });
  }

  function _init() {
    hide();
    addEventListeners();
  }

  _init();

  return { show, hide, items: [vLine, hLine] };
};
