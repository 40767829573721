import { BsAlt, BsShift, BsCommand } from 'react-icons/bs';
import { MdOutlineKeyboardArrowUp } from 'react-icons/md';

const mapModifierKeyToDisplayIcon: any = {
  altKey: <BsAlt />,
  shiftKey: <BsShift />,
  metaKey: <BsCommand />,
  ctrlKey: <MdOutlineKeyboardArrowUp />,
};

export const KeyboardShortcut = ({ keyLabel, modifierKeys = [] }: { keyLabel: string; modifierKeys?: string[] }) => {
  return (
    <div className="flex items-center gap-1 whitespace-nowrap">
      {modifierKeys.map((e) => (
        <div key={e}>{mapModifierKeyToDisplayIcon[e]}</div>
      ))}

      <div>{keyLabel}</div>
    </div>
  );
};
