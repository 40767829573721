interface Props {
  video: HTMLVideoElement;
  canvas: HTMLCanvasElement;
}

export const VideoEditorView = ({ video, canvas }: Props) => {
  const container = document.createElement('div');

  container.style.position = 'relative';
  container.style.userSelect = 'none';
  container.style.overflow = 'hidden';
  container.style.height = '100%';

  video.style.transformOrigin = '0 0';

  canvas.style.position = 'absolute';
  canvas.style.top = '0';
  canvas.style.bottom = '0';

  container.appendChild(video);
  container.appendChild(canvas);

  return container;
};
