import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';
import { getFormattedDate } from 'utils/dateUtils';

interface JobListingProps {
  jobs: GetJobsResponse; // @todo
}

const JobListing = ({ jobs }: JobListingProps) => {
  const { pathTo } = useRouterUtils();

  return (
    <div>
      <table className="table-primary">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Created</th>
          </tr>
        </thead>

        <tbody>
          {jobs.length === 0 && (
            <tr>
              <td colSpan={100} className="p-4 text-center text-slate-500">
                No Results
              </td>
            </tr>
          )}

          {jobs.map(({ id, name, createdAt }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>
                <Link to={pathTo('JOB', { jobId: id })}> {name}</Link>
              </td>
              <td>{getFormattedDate(createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JobListing;
