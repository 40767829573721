import classNames from 'classnames';
import { useAtom } from 'jotai';
import { VideoPlaybackControls } from './VideoPlaybackControls';
import { VideoPlaybackRate } from './VideoPlaybackRate';
import { VideoFrameCount } from './WorkspaceDataitem/VideoFrameCount';
import { VideoSeekbar } from './WorkspaceDataitem/VideoSeekbar';
import { VideoZoomControls } from './WorkspaceDataitem/VideoZoomControls';
import { videoEditorState } from './state/videoEditorState';

interface Props {
  videoEditorRef: any;
}

export const WorkspaceVideoDataitemControls = ({ videoEditorRef }: Props) => {
  const [canPlay] = useAtom(videoEditorState.canPlayAtom);

  return (
    <div className={classNames({ 'pointer-events-none opacity-50': !canPlay })}>
      <div
        className="flex justify-end py-1.5 px-2 text-neutral-400 border-ws-panel border-t"
        style={{ background: '#111' }}
      >
        <VideoZoomControls />
      </div>

      <div className="bg-ws-panel-body">
        <div className="border-t border-b border-neutral-800">
          <VideoSeekbar />
        </div>
        <div className="flex items-center justify-between border-t border-neutral-800 px-3 py-2 text-neutral-300">
          <div className="flex-1">
            <VideoPlaybackRate videoEditorRef={videoEditorRef} />
          </div>
          <div className="flex flex-1 justify-center">
            <VideoPlaybackControls />
          </div>
          <div className="flex flex-1 justify-end">
            <VideoFrameCount />
          </div>
        </div>
      </div>
    </div>
  );
};
