import { AxiosInstance } from 'axios';
export const HttpMethod = {
  GET: 'GET',
  HEAD: 'HEAD',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  CONNECT: 'CONNECT',
  OPTIONS: 'OPTIONS',
  TRACE: 'TRACE',
} as const;
export const initApi = (axios: AxiosInstance) => ({
queries: {
    CreateServiceUsageBillingCharge: ({
      query = {},
      signal,
      
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<CreateServiceUsageBillingChargeResponse>({
          method: HttpMethod.GET,
          url: ` /organizations/${organizationId}/webhooks/create-service-usage-billing-charge`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetAnnotationClasses: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetAnnotationClassesResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/annotation-classes`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetAnnotationInstances: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId,jobDataitemId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,jobDataitemId: string | number
      }
    }) =>
        axios.request<GetAnnotationInstancesResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/dataitems/${jobDataitemId}/annotation-instances`,
          params: query,
          signal,
          
        }).then((res) => res.data),IsAuthenticated: ({
      query = {},
      signal,
      
      
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      
    }) =>
        axios.request<IsAuthenticatedResponse>({
          method: HttpMethod.GET,
          url: `/authenticated`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetDatasetById: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,datasetId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number
      }
    }) =>
        axios.request<GetDatasetByIdResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetDatasets: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GetDatasetsResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets`,
          params: query,
          signal,
          
        }).then((res) => res.data),EventStream: ({
      query = {},
      signal,
      
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<EventStreamResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/event-stream`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobExportHistoriesWithPagination: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GetJobExportHistoriesWithPaginationResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/job-export-history`,
          params: query,
          signal,
          
        }).then((res) => res.data),Healthcheck: ({
      query = {},
      signal,
      
      
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      
    }) =>
        axios.request<HealthcheckResponse>({
          method: HttpMethod.GET,
          url: `/healthcheck`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetDatasetImportHistoriesWithPagination: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GetDatasetImportHistoriesWithPaginationResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/dataset-import-history`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobById: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetJobByIdResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobDataitemDistributionReport: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetJobDataitemDistributionReportResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/dataitem-distribution-report`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobOverviewReport: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetJobOverviewReportResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/overview-report`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobPermissions: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetJobPermissionsResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/permissions`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobs: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GetJobsResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobTimeseriesReport: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetJobTimeseriesReportResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/timeseries-report`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobWorkflow: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetJobWorkflowResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/workflow`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetOrganizationById: ({
      query = {},
      signal,
      
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<GetOrganizationByIdResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetOrganizations: ({
      query = {},
      signal,
      
      
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      
    }) =>
        axios.request<GetOrganizationsResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetProjectApiKey: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GetProjectApiKeyResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/api-key`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetProjectById: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GetProjectByIdResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetProjects: ({
      query = {},
      signal,
      
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<GetProjectsResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetAllDatasetRemoteImportSources: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GetAllDatasetRemoteImportSourcesResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/dataset-remote-storages`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetAllScheduledJobExportConfigs: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GetAllScheduledJobExportConfigsResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/job-export-configs`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetSubscriptionPlan: ({
      query = {},
      signal,
      
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<GetSubscriptionPlanResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/subscription-plan`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetMyAssignments: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GetMyAssignmentsResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/assignments`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetOrganizationUsers: ({
      query = {},
      signal,
      
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<GetOrganizationUsersResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/users`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetUserByInviteToken: ({
      query = {},
      signal,
      
      params:  {
        token
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        token: string | number
      }
    }) =>
        axios.request<GetUserByInviteTokenResponse>({
          method: HttpMethod.GET,
          url: `/v2/invites/${token}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetUserPreference: ({
      query = {},
      signal,
      
      
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      
    }) =>
        axios.request<GetUserPreferenceResponse>({
          method: HttpMethod.GET,
          url: `/preferences`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetUserRoles: ({
      query = {},
      signal,
      
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<GetUserRolesResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/user-roles`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetWhoAmI: ({
      query = {},
      signal,
      
      
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      
    }) =>
        axios.request<GetWhoAmIResponse>({
          method: HttpMethod.GET,
          url: `/v2/whoami`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetWorkflowById: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,workflowId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number
      }
    }) =>
        axios.request<GetWorkflowByIdResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetWorkflows: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GetWorkflowsResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetDataitemsWithPagination: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,datasetId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number
      }
    }) =>
        axios.request<GetDataitemsWithPaginationResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/dataitems`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetDatasetRemoteImportSources: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,datasetId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number
      }
    }) =>
        axios.request<GetDatasetRemoteImportSourcesResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/remote-storages`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobDataitemAssignments: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetJobDataitemAssignmentsResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/assignments`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobDataitemById: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId,jobDataitemId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,jobDataitemId: string | number
      }
    }) =>
        axios.request<GetJobDataitemByIdResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/dataitems/${jobDataitemId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobDataitemsWithPagination: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetJobDataitemsWithPaginationResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/dataitems`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetRecentJobDataitemsWithPagination: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetRecentJobDataitemsWithPaginationResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/recent-dataitems`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobUsers: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetJobUsersResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/users`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetJobExportRemoteStorageTargets: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<GetJobExportRemoteStorageTargetsResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/remote-storages`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetWorkflowStateById: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,workflowId,workflowStateId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number,workflowStateId: string | number
      }
    }) =>
        axios.request<GetWorkflowStateByIdResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/states/${workflowStateId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetWorkflowTransitionById: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,workflowId,workflowTransitionId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number,workflowTransitionId: string | number
      }
    }) =>
        axios.request<GetWorkflowTransitionByIdResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/transitions/${workflowTransitionId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),GetWorkflowUserGroupById: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,workflowId,workflowUserGroupId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number,workflowUserGroupId: string | number
      }
    }) =>
        axios.request<GetWorkflowUserGroupByIdResponse>({
          method: HttpMethod.GET,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/roles/${workflowUserGroupId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data)
   },
mutations: {
    ConfirmAnnotationClassPropertyOptionDelete: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,annotationClassId,propertyId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: ConfirmAnnotationClassPropertyOptionDeleteRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,annotationClassId: string | number,propertyId: string | number
      }
    }) =>
        axios.request<ConfirmAnnotationClassPropertyOptionDeleteResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/annotation-classes/${annotationClassId}/properties/${propertyId}/confirm-options-update`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateAnnotationClassProperty: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,annotationClassId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateAnnotationClassPropertyRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,annotationClassId: string | number
      }
    }) =>
        axios.request<CreateAnnotationClassPropertyResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/annotation-classes/${annotationClassId}/properties`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateBulkAnnotationClassProperty: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,annotationClassId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateBulkAnnotationClassPropertyRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,annotationClassId: string | number
      }
    }) =>
        axios.request<CreateBulkAnnotationClassPropertyResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/annotation-classes/${annotationClassId}/bulk-properties`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveAnnotationClassProperty: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId,annotationClassId,propertyId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,annotationClassId: string | number,propertyId: string | number
      }
    }) =>
        axios.request<RemoveAnnotationClassPropertyResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/annotation-classes/${annotationClassId}/properties/${propertyId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),SortAnnotationClassProperties: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,annotationClassId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: SortAnnotationClassPropertiesRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,annotationClassId: string | number
      }
    }) =>
        axios.request<SortAnnotationClassPropertiesResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/annotation-classes/${annotationClassId}/sort-properties`,
          params: query,
          signal,
          data
        }).then((res) => res.data),UpdateAnnotationClass: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,annotationClassId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateAnnotationClassRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,annotationClassId: string | number
      }
    }) =>
        axios.request<UpdateAnnotationClassResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/annotation-classes/${annotationClassId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),UpdateAnnotationClassProperty: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,annotationClassId,propertyId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateAnnotationClassPropertyRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,annotationClassId: string | number,propertyId: string | number
      }
    }) =>
        axios.request<UpdateAnnotationClassPropertyResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/annotation-classes/${annotationClassId}/properties/${propertyId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),UpdateAnnotationClassPropertyOptions: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,annotationClassId,propertyId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateAnnotationClassPropertyOptionsRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,annotationClassId: string | number,propertyId: string | number
      }
    }) =>
        axios.request<UpdateAnnotationClassPropertyOptionsResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/annotation-classes/${annotationClassId}/properties/${propertyId}/options`,
          params: query,
          signal,
          data
        }).then((res) => res.data),BulkRemoveAnnotationInstances: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,jobDataitemId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: BulkRemoveAnnotationInstancesRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,jobDataitemId: string | number
      }
    }) =>
        axios.request<BulkRemoveAnnotationInstancesResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/dataitems/${jobDataitemId}/annotation-instances/bulk-delete`,
          params: query,
          signal,
          data
        }).then((res) => res.data),BulkUpsertAnnotationInstances: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,jobDataitemId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: BulkUpsertAnnotationInstancesRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,jobDataitemId: string | number
      }
    }) =>
        axios.request<BulkUpsertAnnotationInstancesResponse>({
          method: HttpMethod.PATCH,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/dataitems/${jobDataitemId}/annotation-instances`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveAnnotationInstance: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId,jobDataitemId,annotationInstanceId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,jobDataitemId: string | number,annotationInstanceId: string | number
      }
    }) =>
        axios.request<RemoveAnnotationInstanceResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/dataitems/${jobDataitemId}/annotation-instances/${annotationInstanceId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpsertAnnotationInstance: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,jobDataitemId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpsertAnnotationInstanceRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,jobDataitemId: string | number
      }
    }) =>
        axios.request<UpsertAnnotationInstanceResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/dataitems/${jobDataitemId}/annotation-instances`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateDataset: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateDatasetRequest,
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<CreateDatasetResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveDataset: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,datasetId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number
      }
    }) =>
        axios.request<RemoveDatasetResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpdateDataset: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,datasetId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateDatasetRequest,
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number
      }
    }) =>
        axios.request<UpdateDatasetResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),ChangeJobWorkflow: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: ChangeJobWorkflowRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<ChangeJobWorkflowResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/workflow`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateJob: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateJobRequest,
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<CreateJobResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveJob: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<RemoveJobResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpdateJob: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateJobRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<UpdateJobResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateOrganization: ({
      query = {},
      signal,
      data,
      
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateOrganizationRequest,
      
    }) =>
        axios.request<CreateOrganizationResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveOrganization: ({
      query = {},
      signal,
      
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<RemoveOrganizationResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpdateOrganiaztion: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateOrganiaztionRequest,
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<UpdateOrganiaztionResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateProject: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateProjectRequest,
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<CreateProjectResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects`,
          params: query,
          signal,
          data
        }).then((res) => res.data),GenerateProjectApiKey: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<GenerateProjectApiKeyResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/api-key`,
          params: query,
          signal,
          
        }).then((res) => res.data),RemoveProject: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<RemoveProjectResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),RemoveProjectApiKey: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<RemoveProjectApiKeyResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/api-key`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpdateProject: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateProjectRequest,
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<UpdateProjectResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CancelSubscriptionPlan: ({
      query = {},
      signal,
      
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<CancelSubscriptionPlanResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/subscription-plan`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpdateSubscriptionPlan: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateSubscriptionPlanRequest,
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<UpdateSubscriptionPlanResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/subscription-plan`,
          params: query,
          signal,
          data
        }).then((res) => res.data),InviteUser: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: InviteUserRequest,
      params: {
        organizationId: string | number
      }
    }) =>
        axios.request<InviteUserResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/users`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveOrganizationUser: ({
      query = {},
      signal,
      
      params:  {
        organizationId,userId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,userId: string | number
      }
    }) =>
        axios.request<RemoveOrganizationUserResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/users/${userId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),SetUserPreference: ({
      query = {},
      signal,
      data,
      
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: SetUserPreferenceRequest,
      
    }) =>
        axios.request<SetUserPreferenceResponse>({
          method: HttpMethod.POST,
          url: `/preferences`,
          params: query,
          signal,
          data
        }).then((res) => res.data),SetUserRole: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,userId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: SetUserRoleRequest,
      params: {
        organizationId: string | number,userId: string | number
      }
    }) =>
        axios.request<SetUserRoleResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/users/${userId}/role`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CopyWorkflow: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,workflowId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CopyWorkflowRequest,
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number
      }
    }) =>
        axios.request<CopyWorkflowResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/copy`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateWorkflow: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateWorkflowRequest,
      params: {
        organizationId: string | number,projectId: string | number
      }
    }) =>
        axios.request<CreateWorkflowResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveWorkflow: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,workflowId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number
      }
    }) =>
        axios.request<RemoveWorkflowResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpdateWorkflow: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,workflowId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateWorkflowRequest,
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number
      }
    }) =>
        axios.request<UpdateWorkflowResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateDatasetRemoteImportSource: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,datasetId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateDatasetRemoteImportSourceRequest,
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number
      }
    }) =>
        axios.request<CreateDatasetRemoteImportSourceResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/remote-storages`,
          params: query,
          signal,
          data
        }).then((res) => res.data),EditDatasetRemoteImportSource: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,datasetId,remoteStorageId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: EditDatasetRemoteImportSourceRequest,
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number,remoteStorageId: string | number
      }
    }) =>
        axios.request<EditDatasetRemoteImportSourceResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/remote-storages/${remoteStorageId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveDatasetRemoteImportSource: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,datasetId,remoteStorageResourceId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number,remoteStorageResourceId: string | number
      }
    }) =>
        axios.request<RemoveDatasetRemoteImportSourceResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/remote-storages/${remoteStorageResourceId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),RemoveDatasetRemoteImportSourceSchedule: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,datasetId,remoteStorageResourceId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number,remoteStorageResourceId: string | number
      }
    }) =>
        axios.request<RemoveDatasetRemoteImportSourceScheduleResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/remote-storages/${remoteStorageResourceId}/schedule`,
          params: query,
          signal,
          
        }).then((res) => res.data),TriggerDatasetRemoteStorageImport: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,datasetId,remoteStorageResourceId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number,remoteStorageResourceId: string | number
      }
    }) =>
        axios.request<TriggerDatasetRemoteStorageImportResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/remote-storages/${remoteStorageResourceId}/import`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpdateDatasetRemoteImportSourceSchedule: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,datasetId,remoteStorageResourceId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateDatasetRemoteImportSourceScheduleRequest,
      params: {
        organizationId: string | number,projectId: string | number,datasetId: string | number,remoteStorageResourceId: string | number
      }
    }) =>
        axios.request<UpdateDatasetRemoteImportSourceScheduleResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/datasets/${datasetId}/remote-storages/${remoteStorageResourceId}/schedule`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateJobExport: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateJobExportRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<CreateJobExportResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/exports`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveJobExportSchedule: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId,exportId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,exportId: string | number
      }
    }) =>
        axios.request<RemoveJobExportScheduleResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/exports/${exportId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),ApplyWorkflowTransitionToJobDataitem: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,jobDataitemId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: ApplyWorkflowTransitionToJobDataitemRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,jobDataitemId: string | number
      }
    }) =>
        axios.request<ApplyWorkflowTransitionToJobDataitemResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/dataitems/${jobDataitemId}/apply-transition`,
          params: query,
          signal,
          data
        }).then((res) => res.data),SetBulkJobDataitemAssignee: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: SetBulkJobDataitemAssigneeRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<SetBulkJobDataitemAssigneeResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/assign-dataitems`,
          params: query,
          signal,
          data
        }).then((res) => res.data),UpdateJobDataitemWorkflowState: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,jobDataitemId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateJobDataitemWorkflowStateRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,jobDataitemId: string | number
      }
    }) =>
        axios.request<UpdateJobDataitemWorkflowStateResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/dataitems/${jobDataitemId}/workflow-state`,
          params: query,
          signal,
          data
        }).then((res) => res.data),AddJobUsers: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: AddJobUsersRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<AddJobUsersResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/users`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveJobUser: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId,jobUserId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,jobUserId: string | number
      }
    }) =>
        axios.request<RemoveJobUserResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/users/${jobUserId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),CreateJobExportRemoteStorageTarget: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateJobExportRemoteStorageTargetRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number
      }
    }) =>
        axios.request<CreateJobExportRemoteStorageTargetResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/remote-storages`,
          params: query,
          signal,
          data
        }).then((res) => res.data),EditJobExportRemoteStorageTarget: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,jobId,remoteStorageId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: EditJobExportRemoteStorageTargetRequest,
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,remoteStorageId: string | number
      }
    }) =>
        axios.request<EditJobExportRemoteStorageTargetResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/remote-storages/${remoteStorageId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveJobExportRemoteStorageTarget: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,jobId,remoteStorageResourceId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,jobId: string | number,remoteStorageResourceId: string | number
      }
    }) =>
        axios.request<RemoveJobExportRemoteStorageTargetResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/jobs/${jobId}/remote-storages/${remoteStorageResourceId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),CreateWorkflowAction: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,workflowId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateWorkflowActionRequest,
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number
      }
    }) =>
        axios.request<CreateWorkflowActionResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/actions`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveWorkflowAction: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,workflowId,workflowActionId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number,workflowActionId: string | number
      }
    }) =>
        axios.request<RemoveWorkflowActionResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/actions/${workflowActionId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),CreateWorkflowState: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,workflowId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateWorkflowStateRequest,
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number
      }
    }) =>
        axios.request<CreateWorkflowStateResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/states`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveWorkflowState: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,workflowId,workflowStateId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number,workflowStateId: string | number
      }
    }) =>
        axios.request<RemoveWorkflowStateResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/states/${workflowStateId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpdateWorkflowState: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,workflowId,workflowStateId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateWorkflowStateRequest,
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number,workflowStateId: string | number
      }
    }) =>
        axios.request<UpdateWorkflowStateResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/states/${workflowStateId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateWorkflowTransition: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,workflowId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateWorkflowTransitionRequest,
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number
      }
    }) =>
        axios.request<CreateWorkflowTransitionResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/transitions`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveWorkflowTransition: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,workflowId,workflowTransitionId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number,workflowTransitionId: string | number
      }
    }) =>
        axios.request<RemoveWorkflowTransitionResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/transitions/${workflowTransitionId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpdateWorkflowTransition: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,workflowId,workflowTransitionId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateWorkflowTransitionRequest,
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number,workflowTransitionId: string | number
      }
    }) =>
        axios.request<UpdateWorkflowTransitionResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/transitions/${workflowTransitionId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data),CreateWorkflowUserGroup: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,workflowId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: CreateWorkflowUserGroupRequest,
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number
      }
    }) =>
        axios.request<CreateWorkflowUserGroupResponse>({
          method: HttpMethod.POST,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/roles`,
          params: query,
          signal,
          data
        }).then((res) => res.data),RemoveWorkflowUserGroup: ({
      query = {},
      signal,
      
      params:  {
        organizationId,projectId,workflowId,workflowUserGroupId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number,workflowUserGroupId: string | number
      }
    }) =>
        axios.request<RemoveWorkflowUserGroupResponse>({
          method: HttpMethod.DELETE,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/roles/${workflowUserGroupId}/`,
          params: query,
          signal,
          
        }).then((res) => res.data),UpdateWorkflowUserGroup: ({
      query = {},
      signal,
      data,
      params:  {
        organizationId,projectId,workflowId,workflowUserGroupId
      }
    } : {
      query?: { [key: string]: any },
      signal?: any,
      data: UpdateWorkflowUserGroupRequest,
      params: {
        organizationId: string | number,projectId: string | number,workflowId: string | number,workflowUserGroupId: string | number
      }
    }) =>
        axios.request<UpdateWorkflowUserGroupResponse>({
          method: HttpMethod.PUT,
          url: `/v2/organizations/${organizationId}/projects/${projectId}/workflows/${workflowId}/roles/${workflowUserGroupId}/`,
          params: query,
          signal,
          data
        }).then((res) => res.data)
  }
})