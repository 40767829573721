import { workspaceDataitemContextStore } from 'components/Workspace2/Workspace2';
import { videoTimelineState } from 'components/Workspace2/state/videoTimelineState';
import { workspaceState } from 'components/Workspace2/state/workspaceState';
import { useAtom, useSetAtom } from 'jotai';
import { BiTimeFive } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';

export const VideoTimelineOptions = () => {
  const [followCurrentFrame, setFollowCurrentFrame] = useAtom(videoTimelineState.followCurrentFrameAtom);
  const [showSelectionOnly, setShowSelectionOnly] = useAtom(videoTimelineState.showSelectionOnly);
  const [onlyShowPropertiesWithKeyframeValues, setOnlyShowPropertiesWithKeyframeValues] = useAtom(
    videoTimelineState.onlyShowPropertiesWithKeyframeValues
  );
  const setShowTimelinePanel = useSetAtom(workspaceState.showTimelinePanelAtom, {
    store: workspaceDataitemContextStore,
  });
  return (
    <div className="flex flex-1 items-center">
      <div className="text-xxs flex items-center gap-1 text-gray-300 uppercase font-medium">
        <BiTimeFive />
        <div>Video Timeline</div>
      </div>

      <div className="ml-auto flex items-center gap-2">
        <label className="text-xxs flex items-center gap-1.5 text-gray-400">
          <input
            type="checkbox"
            checked={onlyShowPropertiesWithKeyframeValues}
            onChange={(event) => setOnlyShowPropertiesWithKeyframeValues(event.target.checked)}
          />
          Only show items with keyframes
        </label>

        {/* <label className="text-xxs flex items-center gap-1.5 text-gray-400">
          <input
            type="checkbox"
            checked={showSelectionOnly}
            onChange={(event) => setShowSelectionOnly(event.target.checked)}
          />
          Show Selection Only
        </label> */}

        <label className="text-xxs flex items-center gap-1.5 text-gray-400">
          <input
            type="checkbox"
            checked={followCurrentFrame}
            onChange={(event) => setFollowCurrentFrame(event.target.checked)}
          />
          Follow Current Frame
        </label>

        <div className="border-r border-neutral-800 mx-1">&nbsp;</div>

        <button onClick={() => setShowTimelinePanel(false)}>
          <IoMdClose size={11} />
        </button>
      </div>
    </div>
  );
};
