import { GlobalCompositeOperation } from 'modules/image-annotator/enums';
import { Canvas } from '../canvas/helpers/Canvas';

const canvas = Canvas({ width: 1, height: 1 });
const { context } = canvas;

context.globalCompositeOperation = GlobalCompositeOperation.COPY;

export function colorStringToRgbaArray(color: string) {
  context.fillStyle = color;
  context.fillRect(0, 0, 1, 1);

  return Array.from(context.getImageData(0, 0, 1, 1).data);
}
