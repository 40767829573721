import { flexRender, Table as ReactTable } from '@tanstack/react-table';
import classNames from 'classnames';
import { usePagination } from 'hooks/usePagination';
import { useEffect, useState } from 'react';
import { MdOutlineFilterList } from 'react-icons/md';
import { Button } from '../Button/Button';
import { Dropdown } from '../Dropdown/Dropdown';
import { Checkbox } from '../Forms/Checkbox/Checkbox';
import { Pagination } from '../Pagination/Pagination';
import { SearchInput } from '../SearchInput/SearchInput';

interface Props {
  instance: ReactTable<any>;
  pagination?: ReturnType<typeof usePagination>;
  onSearch?: (query: string | undefined) => void;
  onRowSelectionUpdate?: (selectedRowIds: unknown[]) => void;
  rowSelect?: boolean;
  selectedRowActions?: JSX.Element;
  filtersComponent?: JSX.Element;
}

export const Table = ({
  instance,
  pagination,
  rowSelect = false,
  onSearch,
  onRowSelectionUpdate,
  selectedRowActions,
  filtersComponent,
}: Props) => {
  const [rowSelections, setRowSelections] = useState<{ [key: string | number]: boolean }>({});
  const [areAllRowsSelected, setAreAllRowsSelected] = useState(false);
  const rows = instance.getRowModel().rows;

  useEffect(() => {
    if (!onRowSelectionUpdate) {
      return;
    }

    onRowSelectionUpdate(Object.keys(rowSelections));
  }, [rowSelections, onRowSelectionUpdate]);

  const handleSelectRow = (id: any, value: boolean) => {
    setRowSelections((prev) =>
      Object.fromEntries(
        Object.entries({
          ...prev,
          [id]: value,
        }).filter(([_, value]) => value)
      )
    );
  };

  const handleSelectAllRows = () => {
    setRowSelections(
      areAllRowsSelected ? {} : Object.fromEntries(instance.getRowModel().rows.map((e) => [e.original.id, true]))
    );
  };

  useEffect(() => {
    setAreAllRowsSelected(Object.keys(rowSelections).length === instance.getRowModel().rows.length);
  }, [instance, rowSelections]);

  useEffect(() => {
    setRowSelections({});
  }, [rows]);

  const rowSelectionsCount = Object.keys(rowSelections).length;

  return (
    <div className="mb-4 overflow-hidden text-xs">
      {(rowSelect || onSearch || filtersComponent || pagination) && (
        <div
          className="flex items-center justify-between rounded-t border border-b-0 border-slate-200 p-1.5"
          style={{ background: '#fcfcfc' }}
        >
          <div className="px-2">
            {rowSelect && (
              <div className="flex items-center gap-4">
                <div className={classNames({ 'text-slate-400': rowSelectionsCount === 0 })}>
                  <span className="font-medium">{rowSelectionsCount}</span> selections
                </div>

                {selectedRowActions}
              </div>
            )}
          </div>

          <div className="flex items-center gap-4">
            {onSearch && (
              <>
                <div className=" w-72">
                  <SearchInput onChange={onSearch} />
                </div>
                <div className="h-5 border-r border-slate-200"></div>
              </>
            )}

            {filtersComponent && (
              <>
                <Dropdown render={() => filtersComponent}>
                  <Button icon={<MdOutlineFilterList />} variant="icon-rounded" size="sm" label="Filters" />
                </Dropdown>
                <div className="h-5 border-r border-slate-200"></div>
              </>
            )}

            {pagination && (
              <Pagination
                isPrevEnabled={pagination.hasPrev}
                isNextEnabled={pagination.hasNext}
                onPrev={pagination.prev}
                onNext={pagination.next}
              />
            )}
          </div>
        </div>
      )}

      <table className="table-primary">
        <thead>
          {instance.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {rowSelect && (
                <th>
                  <div className="flex items-center justify-center">
                    <Checkbox onChange={handleSelectAllRows} checked={areAllRowsSelected} />
                  </div>
                </th>
              )}
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody style={{ background: '#fefefe' }}>
          {instance.getRowModel().rows.length === 0 && (
            <tr>
              <td colSpan={100} className="p-4 text-center">
                No Results
              </td>
            </tr>
          )}

          {instance.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {rowSelect && (
                <td>
                  <div className="flex items-center justify-center">
                    <Checkbox
                      onChange={(e) => {
                        handleSelectRow(row.original.id, e.target.checked);
                      }}
                      checked={rowSelections[row.original.id] === true}
                    />
                  </div>
                </td>
              )}

              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}> {flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {pagination && (
        <div
          className="flex justify-end rounded-b border border-t border-slate-200 p-1.5"
          style={{ background: '#fcfcfc' }}
        >
          <Pagination
            isPrevEnabled={pagination.hasPrev}
            isNextEnabled={pagination.hasNext}
            onPrev={pagination.prev}
            onNext={pagination.next}
          />
        </div>
      )}
    </div>
  );
};
