import { Button } from 'components/common/Button/Button';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';

import { useContext } from 'react';
import { WorkflowDetailsContext } from '../../WorkflowDetails';

interface Props {
  item: WorkflowUserAssignActionDTO;
}

const WorkflowActionListItem = ({ item: { id, workflowUserGroupId } }: Props) => {
  const {
    workflow: { workflowUserGroups },
  } = useContext(WorkflowDetailsContext);
  const { organizationId, projectId, workflowId } = useIntParams();
  const { mutateAsync: removeWorkflowAction } = useMutation('RemoveWorkflowAction');

  return (
    <div>
      Set assignee:{' '}
      <span className="font-semibold">
        {(workflowUserGroups || []).find((e) => e.id === workflowUserGroupId)?.name}
      </span>
      <Button
        onClick={() =>
          removeWorkflowAction({ params: { organizationId, projectId, workflowId, workflowActionId: id } })
        }
      >
        &times;
      </Button>
    </div>
  );
};

export default WorkflowActionListItem;
