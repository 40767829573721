import { MOUSE_BUTTON } from 'modules/image-annotator/enums';
import { CgLayoutGrid } from 'react-icons/cg';

interface Props {
  view: HTMLElement;
  scene: any;
  hitTestFn: ({ x, y }: { x: number; y: number }) => any;
}

const POINTER_EVENTS = ['click', 'mousedown', 'mouseup', 'mousemove', 'mouseenter', 'mouseleave'];

export const PointerEventRouter = ({ view, scene, hitTestFn }: Props) => {
  let hoverTarget: any = null;
  let dragTarget: any = null;

  function _toViewCoordinates({ x, y }: { x: number; y: number }) {
    const { x: tx, y: ty, scale } = scene.globalTransform.getState();
    x = (x - tx) / scale;
    y = (y - ty) / scale;
    return { x, y };
  }

  function _getMouseCoordinatesFromEvent(event: MouseEvent) {
    const viewClientRect = view.getBoundingClientRect(); // @todo optimize!
    const x = event.pageX - viewClientRect.x;
    const y = event.pageY - viewClientRect.y;
    return { x, y };
  }

  function _pointerEventHandler(event: any) {
    const mouseCoords = _getMouseCoordinatesFromEvent(event);
    const point = _toViewCoordinates(mouseCoords);
    const target = hitTestFn(mouseCoords);

    const syntheticEvent = {
      target,
      originalEvent: event,
      coords: point,
    };

    if (target && target.hasOwnProperty('dispatch')) {
      target.dispatch(event.type, syntheticEvent);
    }

    scene.dispatch(event.type, syntheticEvent);

    // handle mouseenter & mouseleave
    if (event.type === 'mousemove' && hoverTarget !== null && target !== hoverTarget && dragTarget !== hoverTarget) {
      const eventData = {
        ...syntheticEvent,
        relatedTarget: target,
        target: hoverTarget,
      };

      hoverTarget.dispatch('mouseleave', eventData);
      scene.dispatch('mouseleave', eventData);

      hoverTarget = null;
    }

    if (event.type === 'mousemove' && hoverTarget === null && target !== undefined) {
      hoverTarget = target;
      const eventData = {
        ...syntheticEvent,
        relatedTarget: target,
        target: hoverTarget,
      };
      hoverTarget.dispatch('mouseenter', eventData);
      scene.dispatch('mouseenter', eventData);
    }

    // handle drag events
    if (event.type === 'mousemove' && dragTarget !== null && event.button === MOUSE_BUTTON.MAIN) {
      dragTarget.dispatch('drag', {
        ...syntheticEvent,
        target: dragTarget,
      });
    }

    if (
      target !== undefined &&
      event.type === 'mousedown' &&
      dragTarget === null &&
      event.button === MOUSE_BUTTON.MAIN
    ) {
      dragTarget = target;
      dragTarget.dispatch('dragstart', syntheticEvent);
    }

    if (event.type === 'mouseup' && dragTarget !== null && event.button === MOUSE_BUTTON.MAIN) {
      dragTarget.dispatch('dragend', {
        ...syntheticEvent,
        target: dragTarget,
      });

      dragTarget = null;
    }
  }

  function destroy() {
    POINTER_EVENTS.forEach((eventName) => view.removeEventListener(eventName, _pointerEventHandler));
  }

  function _init() {
    POINTER_EVENTS.forEach((eventName) => view.addEventListener(eventName, _pointerEventHandler));
  }

  _init();

  return {
    destroy,
  };
};
