import { usePersistedState } from 'hooks/usePersistedState';
import { useAtom } from 'jotai';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { WorkspaceDataitemAnnotationInstance } from './WorkspaceDataitemAnnotationInstance';
import { annotationAtomsAtom } from './useAnnotationsStore';

export const WorkspaceDataitemAnnotationsList = () => {
  const [isPanelExpanded, setIsPanelExpanded] = usePersistedState(
    false,
    'workspace-dataitem-annotations-list-is-panel-expanded'
  );
  const [annotationAtoms] = useAtom(annotationAtomsAtom);

  return (
    <div className="w-full bg-ws-panel">
      <div className="flex items-center px-3 py-2.5 text-xxs gap-1 text-gray-300 font-medium border-ws-panel border-b uppercase justify-between">
        <div>Annotation Instances</div>
        <button onClick={() => setIsPanelExpanded((p: any) => !p)}>
          {isPanelExpanded ? <MdExpandLess size={18} /> : <MdExpandMore size={18} />}
        </button>
      </div>

      {isPanelExpanded && (
        <div className="bg-ws-panel-body">
          {annotationAtoms.map((atom) => (
            <WorkspaceDataitemAnnotationInstance key={`${atom}`} atom={atom} />
          ))}
        </div>
      )}
    </div>
  );
};
