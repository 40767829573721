import { videoEditorState } from 'components/Workspace2/state/videoEditorState';
import { videoTimelineState } from 'components/Workspace2/state/videoTimelineState';
import { Atom, useAtom } from 'jotai';
import { useMemo } from 'react';
import { videoTimelineConfig } from '../VideoTimeline';
import { VideoTimelineRowItem } from '../VideoTimelineRowItem';
import { VideoTimelineKeyframe } from './VideoTimelineKeyframe';
import { VideoTimelineKeyframeSpan } from './VideoTimelineKeyframeSpan';

interface Props {
  atom: Atom<AnnotationInstanceDTO>;
}

export const VideoTimelineRow = ({ atom }: Props) => {
  const [annotationInstance] = useAtom(atom);
  const [videoTimelineAnnotationInstanceOptions] = useAtom(videoTimelineState.annotationInstanceOptionsAtom);
  // const isDetailedView = videoTimelineAnnotationInstanceOptions[annotationInstance.id]?.isDetailedView;
  const [frameCount] = useAtom(videoEditorState.frameCountAtom);
  const [onlyShowPropertiesWithKeyframeValues] = useAtom(videoTimelineState.onlyShowPropertiesWithKeyframeValues);

  const allKeyframes = useMemo(
    () => [
      ...new Set(
        Object.entries(annotationInstance.properties)
          .filter(([_, val]) => Array.isArray(val))
          .map(([_, val]) => val.map((e: any) => e.frame))
          .flat()
      ),
    ],
    [annotationInstance]
  );

  const isVisibleKeyframes = annotationInstance.properties.is_visible || [];

  return (
    <div className="relative">
      <div
        className="relative border-b border-ws-panel-100 bg-neutral-800/30"
        style={{ height: videoTimelineConfig.ROW_LVL_1_HEIGHT_IN_PX }}
      >
        {allKeyframes.map((frame) => (
          <VideoTimelineKeyframe key={frame} index={frame} />
        ))}

        {isVisibleKeyframes.length !== 0 &&
          isVisibleKeyframes.map(
            ({ frame, value }: any, index: number) =>
              value && (
                <VideoTimelineKeyframeSpan
                  key={index} // @todo
                  fromIndex={frame}
                  toIndex={isVisibleKeyframes[index + 1]?.frame || frameCount + 1}
                  // color={getAnnotationInstanceColor(annotationInstance)}
                />
              )
          )}
      </div>

      <div>
        {annotationInstance.annotationClass.properties
          .filter((e) => e.isKeyframable)
          .filter((e) =>
            onlyShowPropertiesWithKeyframeValues
              ? Array.isArray(annotationInstance.properties[e.key]) && annotationInstance.properties[e.key].length !== 0
              : e
          )
          .map((annotationClassProperty) => (
            <VideoTimelineRowItem
              key={annotationClassProperty.id}
              annotationClassProperty={annotationClassProperty}
              annotationInstance={annotationInstance}
            />
          ))}
      </div>
    </div>
  );
};
