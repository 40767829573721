import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { Label } from 'components/common/Forms/Label/Label';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { toReadableCronExpression } from 'utils/toReadableCron';

interface Props {
  remoteStorageResource: DatasetRemoteStorageResourceDTO;
}

export const DatasetImportSchedulerModal = ({ remoteStorageResource }: Props) => {
  const { close } = useContext(DialogContext);
  const { organizationId, projectId, datasetId } = useIntParams();

  const updateDatasetRemoteStorageResourceMutation = useMutation('UpdateDatasetRemoteImportSourceSchedule');
  const removeDatasetRemoteImportSourceScheduleMutation = useMutation('RemoveDatasetRemoteImportSourceSchedule', {
    onSuccess: () => {
      close();
    },
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      cron: remoteStorageResource.cron || '',
    },
  });

  const onSubmit = handleSubmit(async ({ cron }) => {
    await updateDatasetRemoteStorageResourceMutation.mutateAsync({
      data: {
        cron,
      },
      params: {
        organizationId,
        projectId,
        datasetId,
        remoteStorageResourceId: remoteStorageResource.id,
      },
    });

    close();
  });

  const cron = watch('cron');
  const hasActiveSchedule = !!remoteStorageResource.cron;
  const disableActions =
    isSubmitting ||
    updateDatasetRemoteStorageResourceMutation.isLoading ||
    removeDatasetRemoteImportSourceScheduleMutation.isLoading;

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Configure Scheduled Import" />

      <ModalLayoutBody>
        <form onSubmit={onSubmit}>
          <div className="my-2">
            <Label htmlFor={'cron'}>CRON Expression</Label>
            <InputText {...register('cron')} id={'cron'} />
          </div>

          {cron ? toReadableCronExpression(cron) : ''}
        </form>
      </ModalLayoutBody>

      <ModalLayoutFooter>
        {hasActiveSchedule && (
          <Button
            label={'Remove Schedule'}
            loading={disableActions}
            onClick={() =>
              removeDatasetRemoteImportSourceScheduleMutation.mutate({
                params: {
                  organizationId,
                  projectId,
                  datasetId,
                  remoteStorageResourceId: remoteStorageResource.id,
                },
              })
            }
          />
        )}

        <Button
          label={hasActiveSchedule ? 'Update' : 'Create'}
          type="submit"
          loading={disableActions}
          onClick={onSubmit}
        />
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
