import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { ConfigureInboundRemoteStorageModal } from 'components/Modals/ConfigureInboundRemoteStorageModal/ConfigureInboundRemoteStorageModal';
import { DataitemUploadModal } from 'components/Modals/DataitemUploadModal/DataitemUploadModal';
import { BsFillCloudPlusFill } from 'react-icons/bs';
import { MdOutlineComputer } from 'react-icons/md';

export const EmptyDatasetInfoBox = () => {
  return (
    <div className="rounded border border-slate-100 bg-slate-50 p-6 text-slate-600">
      <div>
        <div className="mb-4 flex flex-row items-center gap-2 border-b border-slate-200 pb-4 text-xl">
          Import Options
        </div>

        <ul className="my-4 ml-6 list-disc">
          <li className="my-6">
            <div className="mb-2">
              <div>Import from cloud storage</div>
            </div>

            <Dialog render={() => <ConfigureInboundRemoteStorageModal />}>
              <Button icon={<BsFillCloudPlusFill />} label="Configure Cloud Import" variant="outlined" />
            </Dialog>
          </li>
          <li className="my-6">
            <div className="mb-2">Upload files from your local machine using your browser</div>

            <Dialog render={() => <DataitemUploadModal />}>
              <Button icon={<MdOutlineComputer />} label="Upload from Local Machine" variant="outlined" />
            </Dialog>
          </li>
          <li className="my-6">
            <div className="mb-2">
              Upload files from your local machine using the CLI (recommended for large datasets)
            </div>

            {/* @todo include instructional text */}
          </li>
        </ul>
      </div>
    </div>
  );
};
