import { Box } from 'components/common/Box/Box';
import { Button } from 'components/common/Button/Button';
import { Table } from 'components/common/Table/Table';
import { JobExportStatus } from 'enums';
import { useJobExportsTable } from 'hooks/react-table/useJobExportsTable';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { BsClock } from 'react-icons/bs';
import { IoTrashBin } from 'react-icons/io5';
import { toReadableCronExpression } from 'utils/toReadableCron';

const JobExportsList = () => {
  const { organizationId, projectId, jobId } = useIntParams<RouteParams.Job>();
  const jobExportHistoriesQuery = usePaginatedQuery('GetJobExportHistoriesWithPagination', {
    params: { organizationId, projectId },
    query: {
      jobId,
    },
  });
  const { mutate: removeJobExportSchedule } = useMutation('RemoveJobExportSchedule');

  const { instance: jobExportsTableInstance } = useJobExportsTable({
    rows: jobExportHistoriesQuery.data?.results,
  });

  if (jobExportHistoriesQuery.isLoading) {
    return <div>Loading</div>;
  }

  return (
    <div>
      {jobExportHistoriesQuery.data?.results
        .filter((item) => item.status === JobExportStatus.SCHEDULED)
        .map(({ id, locationUrl, jobExportConfig: { format, type, cron } }) => (
          <Box key={id} className="text-sm">
            <div className="flex justify-between">
              <div>
                <div className="mb-1 flex items-center gap-1">
                  <BsClock /> Scheduled Export ID {id}
                </div>

                {cron && (
                  <div>
                    Trigger: <span className="text-sm text-slate-600">{toReadableCronExpression(cron)}</span>
                  </div>
                )}

                {/* {nextRunTime && (
                  <div>
                    Next Run Time:{' '}
                    <span className="text-sm text-slate-600">
                      {dayjs.unix(nextRunTime).format('MMM DD YYYY, HH:mm UTC ZZ')}
                    </span>
                  </div>
                )} */}

                {locationUrl && <a href={locationUrl}>Download</a>}
              </div>
              <div>
                <Button
                  size="sm"
                  variant="outlined"
                  icon={<IoTrashBin />}
                  label="Remove"
                  onClick={() =>
                    removeJobExportSchedule({
                      params: {
                        organizationId,
                        projectId,
                        jobId,
                        exportId: id,
                      },
                    })
                  }
                />
              </div>
            </div>
          </Box>
        ))}

      <div className="my-4">
        <Table instance={jobExportsTableInstance} pagination={jobExportHistoriesQuery.pagination} />
      </div>
    </div>
  );
};

export default JobExportsList;
