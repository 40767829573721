import classNames from 'classnames';
import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { FormMessage } from 'components/common/FormMessage/FormMessage';
import { Checkbox } from 'components/common/Forms/Checkbox/Checkbox';
import { InputText } from 'components/common/Forms/InputText/InputText';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { AnnotationClassPropertyKey } from 'config';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import get from 'lodash/get';

import { ParseResult } from 'papaparse';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useFieldArray, useForm } from 'react-hook-form';
import { IoIosClose } from 'react-icons/io';
import { MdDragIndicator } from 'react-icons/md';
import { toast } from 'react-toastify';
import { ULID } from 'utils/ulid';
import { UploadCSVButton } from '../UploadCSVButton/UploadCSVButton';
import { AppendPropertyOptionForm } from './AppendPropertyOptionForm/AppendPropertyOptionForm';

const validateCsvDuplication = (
  csv: string[][],
  seen: {
    labels: Record<string, boolean>;
    ids: Record<string, boolean>;
  } = {
    labels: {},
    ids: {},
  }
) => {
  // e.g duplicateLocationMap.labels['dacia'] = [1, 55, 188];
  const duplicateLocationMap = { labels: {}, ids: {} } as {
    labels: Record<string, number[]>;
    ids: Record<string, number[]>;
  };

  csv.forEach(([label, id], index) => {
    if (!label || !id) {
      return;
    }

    if (seen.labels[label]) {
      duplicateLocationMap.labels[label] = [...(duplicateLocationMap.labels[label] || []), index];
    } else {
      seen.labels[label] = true;
    }

    if (seen.ids[id]) {
      duplicateLocationMap.ids[id] = [...(duplicateLocationMap.ids[id] || []), index];
    } else {
      seen.ids[id] = true;
    }
  });

  const hasDuplicates =
    Object.keys(duplicateLocationMap.labels).length > 0 || Object.keys(duplicateLocationMap.ids).length > 0;

  return {
    hasDuplicates,
    duplicateLocationMap: hasDuplicates ? duplicateLocationMap : null,
  };
};

const renderDuplicateToastErrorContent = (
  duplicateLocationMap: {
    labels: Record<string, number[]>;
    ids: Record<string, number[]>;
  },
  labelSectionTitle: string,
  idSectionTitle: string
) => {
  const duplicateLabelsEl = Object.keys(duplicateLocationMap.labels).length ? (
    <>
      <div>{labelSectionTitle}</div>
      {Object.entries(duplicateLocationMap.labels).map(([label, locations]) => (
        <div key={label}>
          Label <span className="font-bold">{label}</span> at lines:&nbsp;
          <span>{locations.map((location) => `${location + 1}`).join(', ')}</span>
        </div>
      ))}
    </>
  ) : null;

  const duplicateIdsEl = Object.keys(duplicateLocationMap.ids).length ? (
    <>
      <div>{idSectionTitle}</div>
      {Object.entries(duplicateLocationMap.ids).map(([id, locations]) => (
        <div key={id}>
          ID <span className="font-bold">{id}</span> at lines:&nbsp;
          <span>{locations.map((location) => `${location + 1}`).join(', ')}</span>
        </div>
      ))}
    </>
  ) : null;

  return (
    <>
      {duplicateLabelsEl}
      <br />
      {duplicateIdsEl}
    </>
  );
};

interface Props {
  annotationClassId: AnnotationClassDTO['id'];
  annotationClassProperty: AnnotationClassPropertyDTO;
}

export const AnnotationClassPropertyOptionsModal = ({ annotationClassId, annotationClassProperty }: Props) => {
  const isPrimaryClassificationProperty =
    annotationClassProperty.key === AnnotationClassPropertyKey.PRIMARY_CLASSIFICATION;

  const { organizationId, projectId, jobId } = useIntParams();
  const [showIdField, setShowIdField] = useState(false);
  const [skipCsvHeader, setSkipCsvHeader] = useState(false);
  const [globalFormError, setGlobalFormError] = useState('');
  const [numberOfAffectedJobDataitems, setNumberOfAffectedJobDataitems] = useState<number>();
  const [showDeletionConfirmationModal, setShowDeletionConfirmationModal] = useState(false);

  const { mutateAsync: updateAnnotationClassPropertyOptions } = useMutation('UpdateAnnotationClassPropertyOptions');
  const { mutateAsync: confirmAnnotationClassPropertyOptionDelete } = useMutation(
    'ConfirmAnnotationClassPropertyOptionDelete'
  );

  const {
    register,
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<{
    options: {
      id: string;
      value: string;
      key: string;
      meta?: { color: string };
    }[];
  }>({
    defaultValues: {
      options: annotationClassProperty.propertyOptions.map(({ id, key, value }) => ({ id, key, value })),
    },
  });

  const updatePropertyOptions = async () => {
    const data = getValues();

    try {
      await updateAnnotationClassPropertyOptions({
        data,
        params: {
          organizationId,
          projectId,
          jobId,
          annotationClassId,
          propertyId: annotationClassProperty.id,
        },
      });
    } catch (error: any) {
      const duplicates = get(error, 'response.data.details.duplicates') as
        | { labels: Record<number, boolean>; ids: Record<number, boolean> }
        | undefined;

      if (duplicates) {
        Object.keys(duplicates.labels).forEach((labelIndex) => {
          setError(`options.${+labelIndex}.value`, {
            type: 'manual',
            message: 'Duplicate label',
          });
        });

        Object.keys(duplicates.ids).forEach((id) => {
          setError(`options.${+id}.key`, {
            type: 'manual',
            message: 'Duplicate key',
          });
        });

        setGlobalFormError(error.response?.data?.error);
      }
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { affectedJobDataitems } = await confirmAnnotationClassPropertyOptionDelete({
        data,
        params: {
          organizationId,
          projectId,
          jobId,
          annotationClassId,
          propertyId: annotationClassProperty.id,
        },
      });

      if (affectedJobDataitems > 0) {
        setNumberOfAffectedJobDataitems(affectedJobDataitems);
        setShowDeletionConfirmationModal(true);
      } else {
        updatePropertyOptions();
      }
    } catch (error: any) {
      setGlobalFormError(error.response?.data?.error || error);
    }
  });

  const {
    fields,
    append: appendField,
    remove: removeField,
    swap,
  } = useFieldArray({
    control,
    name: 'options',
  });

  const onDragEnd = (result: DropResult) => {
    if (result.source && result.destination) {
      swap(result.source.index, result.destination.index);
    }
  };

  const validateCsvInlineDuplication = (data: string[][]) => {
    const { hasDuplicates, duplicateLocationMap } = validateCsvDuplication(data);

    return {
      hasInlineDuplicates: hasDuplicates,
      inlineDuplicateLocationMap: duplicateLocationMap,
    };
  };

  const validateCsvDuplicationAgainstForm = (data: string[][]) => {
    const seen = fields.reduce(
      (acc, field) => {
        acc.labels[field.value] = true;
        acc.ids[field.key] = true;
        return acc;
      },
      { labels: {}, ids: {} } as {
        labels: Record<string, boolean>;
        ids: Record<string, boolean>;
      }
    );

    const { hasDuplicates, duplicateLocationMap } = validateCsvDuplication(data, seen);
    return {
      hasFormDuplicates: hasDuplicates,
      formDuplicateLocationMap: duplicateLocationMap,
    };
  };

  const isCSVValid = (data: string[][]) => {
    if (data[0].length !== 2) {
      toast.error('CSV must have exactly two comma-separated columns, label and id');
      return false;
    }

    const { hasInlineDuplicates, inlineDuplicateLocationMap } = validateCsvInlineDuplication(data);
    if (hasInlineDuplicates && inlineDuplicateLocationMap) {
      toast.error(
        renderDuplicateToastErrorContent(
          inlineDuplicateLocationMap,
          'Duplicate labels found in CSV file:',
          'Duplicate IDs found in CSV file:'
        )
      );
      return false;
    }

    const { hasFormDuplicates, formDuplicateLocationMap } = validateCsvDuplicationAgainstForm(data);
    if (hasFormDuplicates && formDuplicateLocationMap) {
      toast.error(
        renderDuplicateToastErrorContent(
          formDuplicateLocationMap,
          'Duplicate labels found between form values and CSV file:',
          'Duplicate IDs found between form values and CSV file:'
        )
      );
      return false;
    }

    return true;
  };

  const handleCSVUpload = ({ data, errors }: ParseResult<string[]>) => {
    if (errors.length) {
      toast.error('Unable to parse CSV', { theme: 'colored' });
      return;
    }

    const workingCopy = data.slice();

    if (!isCSVValid(workingCopy)) {
      return;
    }

    if (skipCsvHeader) {
      workingCopy.shift();
    }

    // @todo verify for correct format
    appendField(
      workingCopy
        // Omit empty lines (generally last line in a file which can be empty)
        .filter(([label, id]) => label && id)
        .map(([label, id]: any) => {
          return {
            id: ULID(),
            value: label.trim(),
            key: id.trim(),
          };
        })
    );
  };

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Configure Options" />
      <ModalLayoutBody>
        <div className="flex items-center">
          <UploadCSVButton onParse={handleCSVUpload} />
          <Checkbox
            label="Skip headers"
            checked={skipCsvHeader}
            onChange={() => setSkipCsvHeader((prev) => !prev)}
            className="ml-2"
          />
        </div>

        <hr className="my-4" />

        <Checkbox label="Show ID fields" checked={showIdField} onChange={() => setShowIdField((prev) => !prev)} />

        <hr className="my-4" />

        <AppendPropertyOptionForm
          showIdField={showIdField}
          onAppend={(data) => appendField({ id: ULID(), ...data }, { shouldFocus: false })}
          checkDuplicate={(newOption) => {
            const result = {
              duplicateKey: false,
              duplicateValue: false,
            };

            for (const option of fields) {
              if (result.duplicateKey && result.duplicateValue) {
                break;
              }

              if (option.value === newOption.newOptionValue) {
                result.duplicateValue = true;
              }

              if (option.key === newOption.newOptionKey) {
                result.duplicateKey = true;
              }
            }

            return result;
          }}
        />

        <hr className="my-4" />

        {fields.length === 0 && <div className="text-center text-slate-500">No Options</div>}
        <form onSubmit={onSubmit}>
          {globalFormError && <FormMessage text={globalFormError} type="error" />}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable key={field.id} draggableId={field.id.toString()} index={index}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div key={field.id} className="my-1 flex flex-row gap-1">
                            <div className="flex items-center text-slate-400">
                              <MdDragIndicator />
                            </div>

                            {isPrimaryClassificationProperty && (
                              <input {...register(`options.${index}.meta.color`)} type="color" />
                            )}

                            <InputText
                              {...register(`options.${index}.value`)}
                              className={classNames({
                                'border-annotorio-pink': errors.options?.[index]?.value?.message,
                              })}
                            />

                            <InputText
                              {...register(`options.${index}.key`)}
                              type={showIdField ? 'text' : 'hidden'}
                              className={classNames({
                                'border-annotorio-pink': errors.options?.[index]?.value?.message,
                              })}
                            />

                            <Button variant="icon-rounded" icon={<IoIosClose />} onClick={() => removeField(index)} />

                            <input type="hidden" {...register(`options.${index}.id`)} />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </form>
      </ModalLayoutBody>

      <ModalLayoutFooter>
        <Button loading={isSubmitting} onClick={onSubmit}>
          Save
        </Button>
      </ModalLayoutFooter>

      <Dialog
        render={() => (
          <ConfirmActionModal onConfirm={updatePropertyOptions}>
            <div className="mt-2 mb-6 flex flex-row items-center gap-4" style={{ maxWidth: 500 }}>
              <p>
                This will affect {numberOfAffectedJobDataitems} data items. The class options will be removed from the
                items' annotations.
              </p>
            </div>
          </ConfirmActionModal>
        )}
        isOpen={showDeletionConfirmationModal}
        onOpenChange={setShowDeletionConfirmationModal}
      />
    </ModalLayout>
  );
};
