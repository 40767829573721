import { KeyboardShortcutsModal } from 'components/Modals/KeyboardShortcutsModal/KeyboardShortcutsModal';
import { mapAnnotationClassTypeToDisplayIcon } from 'components/Project/Jobs/Job/JobTasks/JobTasksAnnotationClasses/JobTasksAnnotationClasses';
import { Dialog } from 'components/common/Dialog/Dialog';
import { mapAnnotationClassTypeToDisplayName } from 'enums';
import { useAtom } from 'jotai';
import { BsKeyboard } from 'react-icons/bs';
import { AnnotationClassPropertyType } from 'shared/enums';
import { WorkspaceDataitemAnnotationInstanceForm } from './WorkspaceDataitem/WorkspaceDataitemAnnotationInstanceForm/WorkspaceDataitemAnnotationInstanceForm';
import { WorkspacePanel } from './WorkspacePanel';
import { selectedAnnotationInstanceAtom } from './useAnnotationsStore';

export const WorkspaceDataitemAnnotationInstanceFormPanel = () => {
  const [annotationInstance] = useAtom(selectedAnnotationInstanceAtom);

  if (!annotationInstance) {
    return null;
  }

  const keyboardShortcutItems = annotationInstance.annotationClass.properties
    .filter((e) => e.type === AnnotationClassPropertyType.BOOLEAN)
    .map((e) => ({ key: `${annotationInstance.annotationClass.id}-${e.id.toString()}`, label: `Toggle "${e.label}"` }));

  return (
    <WorkspacePanel className="grow">
      <div className="flex flex-col w-full bg-ws-panel text-xs text-neutral-400">
        <div className="flex items-center px-3 py-2.5 text-xxs flex items-center gap-1 text-gray-300 font-medium border-ws-panel border-b uppercase justify-between">
          <div className="flex items-center gap-1">
            {mapAnnotationClassTypeToDisplayIcon[annotationInstance.annotationClass.type]}
            {annotationInstance.name || mapAnnotationClassTypeToDisplayName[annotationInstance.annotationClass.type]}
          </div>

          <Dialog render={() => <KeyboardShortcutsModal items={keyboardShortcutItems} />}>
            <button>
              <BsKeyboard />
            </button>
          </Dialog>
        </div>

        <div className="flex grow text-xxs overflow-hidden overflow-y-scroll bg-ws-panel-body invisible-scrollbar">
          <div className="w-full">
            <WorkspaceDataitemAnnotationInstanceForm />
          </div>
        </div>
      </div>
    </WorkspacePanel>
  );
};
