import { api } from 'api/api';
import { isUndefined } from 'lodash';
import { useQuery as useReactQuery, UseQueryOptions, UseQueryResult } from 'react-query';

export function useQuery<K extends keyof typeof api.queries, T extends typeof api.queries>(
  type: K,
  payload: Parameters<T[K]>[0],
  options?: UseQueryOptions<T[K]>
): UseQueryResult<Awaited<ReturnType<T[K]>>, unknown> {
  const queryKey = [
    {
      id: type,
      params: (payload as any).params,
      query: (payload as any).query,
    },
  ];

  const reactQueryOptions = options || {
    enabled: 'params' in payload ? Object.values(payload.params).filter((e) => isUndefined(e)).length === 0 : true,
  };

  // @ts-ignore
  return useReactQuery(queryKey, ({ signal }) => api.queries[type]({ ...payload, signal }), reactQueryOptions);
}
