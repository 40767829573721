import { UiContainer } from '@ory/kratos-client';
import { OryFormUiNode } from './OryFormUiNode';
import { OryFormUiNodeMessages } from './OryFormUiNodeMessages';

interface OryFormUiProps {
  ui: UiContainer;
  only?: string;
}

export const OryFormUi = ({ ui, only }: OryFormUiProps) => {
  const nodes = ui.nodes.filter(
    ({ group }) => !only || group === 'default' || group === only
  );

  return (
    <div className="space-y-3">
      {nodes.map((node, index) => (
        <div key={index}>
          <OryFormUiNode node={node} />
          <OryFormUiNodeMessages messages={node.messages} />
        </div>
      ))}
    </div>
  );
};
