import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { ConfigureJobExportRemoteStorageResourceModal } from './ConfigureJobExportRemoteStorageResourceModal/ConfigureJobExportRemoteStorageResourceModal';
import { CreateJobExportModal } from './CreateJobExportModal/CreateJobExportModal';
import { JobExportRemoteStorage } from './JobExportRemoteStorage/JobExportRemoteStorage';
import JobExportsList from './JobExportsList';

export const JobExport = () => {
  const { organizationId, jobId, projectId } = useIntParams<RouteParams.Job>();
  const { data: jobExportRemoteStorageResources } = useQuery('GetJobExportRemoteStorageTargets', {
    params: { organizationId, projectId, jobId },
  });

  return (
    <div>
      <div className="mb-6">
        <div className="mb-2 mt-4 flex items-center justify-between">
          <div className="flex ml-auto items-center space-x-2">
            <Dialog render={() => <ConfigureJobExportRemoteStorageResourceModal />}>
              <Button
                label="Configure remote storage"
                icon={<AiOutlineCloudUpload />}
                disabled={jobExportRemoteStorageResources?.length !== 0}
              />
            </Dialog>
            <Dialog render={() => <CreateJobExportModal />}>
              <Button>+ Create Export</Button>
            </Dialog>
          </div>
        </div>

        <JobExportRemoteStorage />

        <JobExportsList />
      </div>
    </div>
  );
};
