import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { ConfirmActionModal } from 'components/Modals/ConfirmActionModal/ConfirmActionModal';
import { ConfigureJobExportRemoteStorageResourceModal } from 'components/Project/Jobs/JobExport/ConfigureJobExportRemoteStorageResourceModal/ConfigureJobExportRemoteStorageResourceModal';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { AiOutlineEdit } from 'react-icons/ai';
import { IoTrashBin } from 'react-icons/io5';

interface Props {
  remoteStorageResource: RemoteStorageResourceDTO;
}

export const JobExportRemoteStorageItem = ({ remoteStorageResource }: Props) => {
  const { organizationId, projectId, jobId } = useIntParams();
  const { id, endpointUrl, bucketName, bucketPrefix } = remoteStorageResource;
  const { mutateAsync: removeJobExportRemoteStorageTarget } = useMutation('RemoveJobExportRemoteStorageTarget');

  return (
    <div>
      <div className="my-2 rounded border border-slate-100 bg-slate-50 p-4 text-slate-800">
        <div className="flex flex-row items-start justify-between">
          <div>
            <div className="mb-1 text-sm uppercase text-slate-400">Remote Storage</div>
            <div className="text-sm font-medium">{endpointUrl}</div>
            <div className="mt-1 text-sm text-slate-600">
              {bucketName}/{bucketPrefix}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Dialog
              render={() => (
                <ConfigureJobExportRemoteStorageResourceModal remoteStorageResource={remoteStorageResource} />
              )}
            >
              <Button size="sm" variant="outlined" icon={<AiOutlineEdit />} label="Edit" />
            </Dialog>

            <Dialog
              render={() => (
                <ConfirmActionModal
                  onConfirm={() =>
                    removeJobExportRemoteStorageTarget({
                      params: {
                        organizationId,
                        projectId,
                        jobId,
                        remoteStorageResourceId: id,
                      },
                    })
                  }
                />
              )}
            >
              <Button size="sm" variant="outlined" icon={<IoTrashBin />} label="Remove" />
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};
