import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export const Box = ({ children, className }: PropsWithChildren<Props>) => {
  return (
    <div
      className={classNames(
        'rounded p-4 bg-slate-50 border border-slate-100',
        className
      )}
    >
      {children}
    </div>
  );
};
