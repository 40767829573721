import { FloatingPortal } from '@floating-ui/react';
import { AnnotationInstanceDO } from 'domain/AnnotationInstanceDO';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { AnnotationsGraphView } from './AnnotationsGraphView';
import { VideoEditorContext } from './VideoEditorContext';
import { VideoEditorView } from './VideoEditorView';
import { workspaceDataitemContextStore } from './Workspace2';
import { VideoEditorObjectSync } from './WorkspaceDataitem/VideoEditorObjectSync';
import { VideoTimeline } from './WorkspaceDataitem/VideoTimeline';
import { WorkspaceDataitemVideoEditorLogicalComponent } from './WorkspaceDataitem/WorkspaceDataitemVideoEditorLogicalComponent';
import { WorkspaceDataitemAnnotationInstanceContextMenu } from './WorkspaceDataitemAnnotationInstanceContextMenu';
import { WorkspaceDataitemAutoSaveLogicalComponent } from './WorkspaceDataitemAutoSaveLogicalComponent';
import { WorkspaceDataitemSidebar } from './WorkspaceDataitemSiderbar';
import { WorkspaceDataitemTitlebar } from './WorkspaceDataitemTitlebar';
import { WorkspacePanel } from './WorkspacePanel';
import { WorkspaceVideoDataitemControls } from './WorkspaceVideoDataitemControls';
import { workspaceState } from './state/workspaceState';
import { selectedAnnotationInstanceAtom } from './useAnnotationsStore';
import { useInitializeWorkspaceDataitem } from './useInitializeWorkspaceDataitem';
import { useWorkspaceVideoEditor } from './useWorkspaceVideoEditor';

export const annotationInstanceDoAtom = atom<any>(null);

export const WorkspaceDatatitem2 = () => {
  useInitializeWorkspaceDataitem();

  const { videoEditorRef, videoEditorViewContainerRef, errorMessage } = useWorkspaceVideoEditor();

  const [showTimelinePanel] = useAtom(workspaceState.showTimelinePanelAtom, {
    store: workspaceDataitemContextStore,
  });

  const [contextMenuState, setContextMenuState] = useState({
    position: [0, 0],
    isOpen: false,
  });

  const handleContextMenu = (e: any) => {
    e.preventDefault();
    setContextMenuState((prev) => ({ ...prev, isOpen: true, position: [e.clientX - 100, e.clientY - 20] }));
  };

  const videoEditorContextValue = {
    videoEditor: videoEditorRef.current,
  };

  const [annotationInstance] = useAtom(selectedAnnotationInstanceAtom);
  const setAnnotationInstanceDoAtom = useSetAtom(annotationInstanceDoAtom);

  useEffect(() => {
    setAnnotationInstanceDoAtom(annotationInstance ? AnnotationInstanceDO(annotationInstance) : null);
  }, [annotationInstance, setAnnotationInstanceDoAtom]);

  return (
    <VideoEditorContext.Provider value={videoEditorContextValue}>
      <div className="flex grow overflow-hidden">
        <WorkspacePanel className="grow overflow-hidden">
          <div className="flex flex-1 grow flex-col overflow-hidden">
            <WorkspaceDataitemTitlebar />

            <div className="relative flex h-full w-full flex-row overflow-hidden">
              {/* <WorkspaceVideoEditorToolbox /> */}

              <div
                className="relative flex h-full w-full flex-row overflow-hidden bg-black"
                onContextMenu={handleContextMenu}
              >
                {errorMessage && (
                  <div className="absolute inset-0 z-10 inline flex items-center justify-center text-sm text-neutral-100">
                    {errorMessage}
                  </div>
                )}
                <VideoEditorView
                  ref={videoEditorViewContainerRef}
                  videoEditorRef={videoEditorRef}
                  scene={videoEditorRef.current?.scene}
                />
              </div>

              <div className="absolute left-0 top-0 inline-flex">
                <AnnotationsGraphView />
              </div>
            </div>

            <WorkspaceVideoDataitemControls videoEditorRef={videoEditorRef} />

            <WorkspacePanel isOpen={showTimelinePanel} className="shrink-0 overflow-hidden">
              <VideoTimeline />
            </WorkspacePanel>
          </div>
        </WorkspacePanel>

        <WorkspaceDataitemSidebar />
      </div>

      {contextMenuState.isOpen && (
        <FloatingPortal>
          <WorkspaceDataitemAnnotationInstanceContextMenu
            position={contextMenuState.position}
            onClose={() => setContextMenuState((prev) => ({ ...prev, isOpen: false }))}
          />
        </FloatingPortal>
      )}

      <WorkspaceDataitemVideoEditorLogicalComponent />
      <WorkspaceDataitemAutoSaveLogicalComponent />
      <VideoEditorObjectSync />
    </VideoEditorContext.Provider>
  );
};
