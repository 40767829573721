import classNames from 'classnames';
import { mapAnnotationClassTypeToDisplayIcon } from 'components/Project/Jobs/Job/JobTasks/JobTasksAnnotationClasses/JobTasksAnnotationClasses';
import { mapAnnotationClassTypeToDisplayName } from 'enums';
import { Atom, useAtom } from 'jotai';
import {
  highlightedAnnotationInstanceIdAtom,
  selectedAnnotationInstanceIdAtom,
  useAnnotationsStore,
} from './useAnnotationsStore';
interface Props {
  atom: Atom<AnnotationInstanceDTO>;
}

export const WorkspaceDataitemAnnotationInstance = ({ atom }: Props) => {
  const [annotationInstance] = useAtom(atom);
  const { removeById, select, highlight } = useAnnotationsStore();
  const [selectedAnnotationInstanceId] = useAtom(selectedAnnotationInstanceIdAtom);
  const [highlightedAnnotationInstanceId] = useAtom(highlightedAnnotationInstanceIdAtom);
  const isSelected = selectedAnnotationInstanceId === annotationInstance.id;
  const isHighlighted = highlightedAnnotationInstanceId === annotationInstance.id;

  return (
    <div className="flex flex-row items-center justify-between text-xs text-neutral-400">
      <button
        style={{ fontSize: 11 }}
        className={classNames('text-xxs 0 flex w-full items-center gap-2 py-1.5 px-2', {
          'bg-blue-600 text-neutral-100': isSelected,
          'hover:bg-neutral-800 hover:text-neutral-200': !isSelected,
          'bg-neutral-800': isHighlighted,
        })}
        onMouseDown={() => select(annotationInstance.id)}
        onMouseEnter={() => highlight(annotationInstance.id)}
        onMouseLeave={() => highlight(null)}
      >
        {mapAnnotationClassTypeToDisplayIcon[annotationInstance.annotationClass.type]}
        {annotationInstance.name || mapAnnotationClassTypeToDisplayName[annotationInstance.annotationClass.type]}
      </button>

      {/* <button onClick={() => removeById(annotationInstance.id)}>&times;</button> */}
    </div>
  );
};
