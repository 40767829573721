import dayjs from 'dayjs';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';
import { toReadableCronExpression } from 'utils/toReadableCron';

export const ScheduledDatasetImports = () => {
  const { projectId, organizationId } = useIntParams<RouteParams.Project>();
  const { pathTo } = useRouterUtils();
  const datasetRemoteImportSources = useQuery('GetAllDatasetRemoteImportSources', {
    params: { organizationId, projectId },
  });

  if (datasetRemoteImportSources.data?.filter(({ cron }) => cron !== null).length === 0) {
    return null;
  }

  return (
    <div>
      <div className="mb-2 mt-4 flex items-center justify-between">
        <div className="text-md font-medium text-slate-800">Scheduled Dataset Imports</div>
      </div>

      <table className="table-primary">
        <thead>
          <tr>
            <th>Dataset</th>
            <th>Schedule</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {datasetRemoteImportSources.data
            ?.filter(({ cron }) => cron !== null)
            .map(({ id, datasetId, datasetName, cron, nextRunTime, endpointUrl, bucketName, bucketPrefix }) => (
              <tr key={id}>
                <td>
                  <Link to={pathTo('DATASET', { datasetId })}>{datasetName}</Link>
                </td>
                <td>
                  {cron && (
                    <div>
                      <span className="text-slate-500">Trigger:</span> {toReadableCronExpression(cron)}
                    </div>
                  )}
                  {nextRunTime && (
                    <div>
                      <span className="text-slate-500">Next Run Time:</span>{' '}
                      {dayjs.unix(nextRunTime).format('MMM DD YYYY, HH:mm UTC ZZ')}
                    </div>
                  )}
                </td>
                <td>
                  <div>
                    <div className="text-xs">{endpointUrl}</div>
                    <div className="text-slate-500 text-xs">
                      {bucketName}/{bucketPrefix}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
