export function ObjectFrameSync(videoControls: any, videoEditorObjectsManager: any) {
  function sync() {
    const { items } = videoEditorObjectsManager.store.getState();
    const frame = videoControls.getCurrentFrame();

    items.forEach((item: any) => {
      const animation = item.data('animation');
      animation && animation.setFrame(frame);
    });
  }

  function destroy() {
    // videoControls.events.off('frameUpdate', sync);
  }

  function init() {
    // @todo listen to native event instead?
    videoControls.events.on('frameUpdate', sync);
    sync();
  }

  return Object.freeze({
    init,
    destroy,
    sync,
  });
}
