import { Typography } from 'components/common/Typography';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  items: {
    to: string;
    label: ReactElement | string;
    exact?: boolean;
  }[];
}

export const LayoutContentTabs = ({
  items,
}: React.PropsWithChildren<Props>) => {
  return (
    <div
      className="layout-content-padding mt-4 font-medium "
      style={{ fontSize: 15 }}
    >
      <div className="flex items-center">
        {items.map(({ to, label, exact = true }, index) => (
          <NavLink
            key={index}
            to={to}
            exact={exact}
            className="text-slate-700 hover:text-slate-700 text-sm inline-flex px-8 py-2 hover:bg-slate-50 border-slate-200 border-r border-t border-b first:border-l first:rounded-l last:rounded-r"
            activeClassName="bg-blue-500 hover:bg-blue-500 text-slate-50 hover:text-slate-50 focus:text-slate-50"
          >
            <Typography>{label}</Typography>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
