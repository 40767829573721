import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import { PropsWithChildren, useContext } from 'react';

interface Props {
  onConfirm: () => void;
}

export const ConfirmActionModal = ({ children, onConfirm }: PropsWithChildren<Props>) => {
  const { close } = useContext(DialogContext);

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Confirm Action" />
      <ModalLayoutBody>{children}</ModalLayoutBody>
      <ModalLayoutFooter>
        <div className="flex gap-1">
          <Button className="w-1/2" onClick={close} variant="outlined">
            Cancel
          </Button>

          <Button
            className="w-1/2"
            onClick={() => {
              onConfirm();
              close();
            }}
          >
            Confirm
          </Button>
        </div>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
