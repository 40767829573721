import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const DropdownButtonItem = ({ children, className, ...rest }: PropsWithChildren<Props>) => {
  const menuItemClasses = classNames(
    'flex items-center gap-2 w-full text-left px-4 py-2 text-slate-600 hover:bg-slate-100 disabled:pointer-events-none',
    className
  );

  return (
    <button className={menuItemClasses} {...rest}>
      {children}
    </button>
  );
};
