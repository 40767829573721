import { Button } from 'components/common/Button/Button';
import { DialogContext } from 'components/common/Dialog/Dialog';
import { ModalLayout } from 'components/common/ModalLayout/ModalLayout';
import { ModalLayoutBody } from 'components/common/ModalLayout/ModalLayoutBody';
import { ModalLayoutFooter } from 'components/common/ModalLayout/ModalLayoutFooter';
import { ModalLayoutHeader } from 'components/common/ModalLayout/ModalLayoutHeader';
import useIntParams from 'hooks/useIntParams';
import { useMutation } from 'hooks/useMutation';
import { useContext, useRef } from 'react';

export const BulkCreateAnnotationClassProperties = () => {
  const { organizationId, projectId, jobId, annotationClassId } = useIntParams();
  const { close } = useContext(DialogContext);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { mutateAsync: createBulkAnnotationClassProperty } = useMutation('CreateBulkAnnotationClassProperty');

  const handleCreate = async () => {
    const payload = textareaRef.current?.value;

    if (!payload) {
      return;
    }

    let isValidJson = true;
    let parsedPayload = null;

    try {
      parsedPayload = JSON.parse(payload);
    } catch (e) {
      isValidJson = false;
    }

    if (!isValidJson || !Array.isArray(parsedPayload)) {
      console.log(parsedPayload);
      alert('Invalid JSON');
      return;
    }

    const res = await createBulkAnnotationClassProperty({
      params: {
        organizationId,
        projectId,
        jobId,
        annotationClassId: annotationClassId,
      },

      data: { items: payload } as any,
    });

    if (res) {
      close();
    }
  };

  return (
    <ModalLayout>
      <ModalLayoutHeader title="Bulk Create" />
      <ModalLayoutBody>
        <textarea ref={textareaRef} className="outline-0 p-2 text-xs border w-full resize-none" rows={16}></textarea>
      </ModalLayoutBody>
      <ModalLayoutFooter>
        <div className="flex gap-1">
          <Button className="w-1/2" onClick={close} variant="outlined">
            Cancel
          </Button>

          <Button
            className="w-1/2"
            onClick={() => {
              handleCreate();
            }}
          >
            Create
          </Button>
        </div>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};
