import cytoscape from 'cytoscape';
import { useEffect, useRef, useState } from 'react';
import StateDiagramControls from './StateDiagramControls';

interface Props {
  states: WorkflowStateDTO[];
  transitions: WorkflowTransitionDTO[];
  userGroups: WorkflowUserGroupDTO[];
  onSelectNode: (id: number) => void;
  onSelectEdge: (id: number) => void;
}

const StateDiagram = ({ states, transitions, userGroups, onSelectNode, onSelectEdge }: Props) => {
  const [context, setContext] = useState<cytoscape.Core>();
  const containeRef = useRef(null);

  useEffect(() => {
    const container = containeRef.current;
    if (container === null) {
      return;
    }

    const elements = [
      {
        data: {
          id: 'initial-node',
          label: '',
        },
        style: {
          shape: 'ellipse',
          width: 24,
          height: 24,
          'background-color': '#999',
          color: '#222',
        },
      },

      ...states.map(({ id, label, uiBgColor }) => ({
        data: { id: id.toString(), label },
        style: {
          backgroundColor: uiBgColor,
        },
      })),
      ...transitions.map(({ id, label, sourceId, targetId, workflowUserAssignActions }) => ({
        data: {
          edgeId: id,
          label: (() => {
            if (workflowUserAssignActions && workflowUserAssignActions.length) {
              return `${label}\nAction: set assignee ${
                userGroups.find((e) => e.id === workflowUserAssignActions[0].workflowUserGroupId)?.name
              }`;
            }

            return label;
          })(),
          source: sourceId === null ? 'initial-node' : sourceId.toString(),
          target: targetId.toString(),
        },
      })),
    ];

    const cy = cytoscape({
      container,
      elements,
      style: [
        {
          selector: 'node',
          style: {
            'font-size': 12,
            'background-color': '#444',
            color: '#fff',
            label: 'data(label)',
            shape: 'round-rectangle',
            width: 100,
            height: 24,
            'text-halign': 'center',
            'text-valign': 'center',
          },
        },

        {
          selector: 'edge',
          style: {
            width: 1,
            'font-size': 12,
            'line-color': '#ccc',
            'target-arrow-color': '#ccc',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',

            color: '#fff',
            'text-background-opacity': 1,
            'text-background-color': '#ff9f09',
            'text-background-shape': 'roundrectangle',
            'text-border-color': '#ff9f09',
            'text-border-width': 10,
            'text-border-opacity': 1,
            'text-wrap': 'wrap',
            label: 'data(label)',
          },
        },
      ],

      layout: { name: 'grid' },

      maxZoom: 1,
      minZoom: 1,
    });

    cy.on('tap', 'node', (event) => {
      const nodeId = +event.target.id();
      if (nodeId) {
        onSelectNode(nodeId);
      }
    });

    cy.on('tap', 'edge', (event) => {
      const { edgeId } = event.target.data();
      if (edgeId) {
        onSelectEdge(edgeId);
      }
    });

    setContext(cy);
  }, [containeRef, states, transitions]);

  return (
    <div className="relative rounded-sm border bg-slate-50">
      <div ref={containeRef} style={{ width: '100%', height: 520 }}></div>
      <div className="absolute left-1 top-1 z-10">
        <StateDiagramControls onViewportFit={() => context?.reset()} />
      </div>
    </div>
  );
};

export default StateDiagram;
