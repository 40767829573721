export const insert = (arr: any[], index: number, items: any[]) => {
  return [...arr.slice(0, index), ...items, ...arr.slice(index + items.length)];
};

export const swapArrayElements = (
  arr: any[],
  index1: number,
  index2: number
) => {
  const newArr = [...arr];
  const [removed] = newArr.splice(index1, 1);
  newArr.splice(index2, 0, removed);
  return newArr;
};
