import { api } from 'api/api';
import { useMutation } from 'hooks/useMutation';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useQueryClient } from 'react-query';

export const useSwitchOrganization = () => {
  const { navigateTo } = useRouterUtils();
  const queryClient = useQueryClient();
  const { mutateAsync: setUserPreference } = useMutation('SetUserPreference');

  const fn = async (id: OrganizationDTO['id']) => {
    const res = await setUserPreference({ data: { defaultOrganizationId: id } });

    if (res.organizations && res.organizations[id.toString()]?.defaultProjectId) {
      navigateTo('PROJECT', { organizationId: id, projectId: res.organizations[id.toString()].defaultProjectId });
    } else {
      const projects = await queryClient.fetchQuery(['projects', id], () =>
        api.queries.GetProjects({ params: { organizationId: id } })
      );
      if (projects.length !== 0) {
        setUserPreference({ data: { organizations: { [id]: { defaultProjectId: projects[0].id } } } });
        navigateTo('PROJECT', { organizationId: id, projectId: projects[0].id });
      }
    }
  };

  return [fn];
};
