import { Box } from 'components/common/Box/Box';
import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { DatasetCreateModal } from 'components/Modals/DatasetCreateModal/DatasetCreateModal';
import { FiInfo } from 'react-icons/fi';

export const NoDatasetsInfoBox = () => {
  return (
    <Box>
      <div className="flex flex-row gap-5 items-center">
        <div className="text-blue-500">
          <FiInfo size={32} />
        </div>
        <div>
          <div className="text-lg font-medium">No Datasets</div>
        </div>

        <div className="ml-auto">
          <Dialog render={() => <DatasetCreateModal />}>
            <Button>Create Dataset</Button>
          </Dialog>
        </div>
      </div>
    </Box>
  );
};
