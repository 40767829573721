import type { PolymorphicComponentProps } from 'types/PolymorphicComponentProps';

type TypographyProps<C extends React.ElementType> = PolymorphicComponentProps<C>;

export const Typography = <C extends React.ElementType = 'span'>(props: TypographyProps<C>) => {
  const { children, className, component, ...rest } = props;

  const TypographyRoot = component || 'span';

  return (
    <TypographyRoot className={className} {...rest}>
      {children}
    </TypographyRoot>
  );
};
