import { useState, useRef, useEffect } from 'react';

// @todo https://github.com/donavon/use-persisted-state/blob/develop/src/usePersistedState.js

export const usePersistedState = (defaultValue: any, key: string) => {
  const [value, setValue] = useState(defaultValue);
  const keyRef = useRef(key);

  useEffect(() => {
    try {
      const storedValue = localStorage.getItem(key);

      if (storedValue !== null) {
        setValue(JSON.parse(storedValue).value);
      } else {
        localStorage.setItem(key, JSON.stringify({ value: defaultValue }));
      }
    } catch {
      setValue(defaultValue);
    }
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem(keyRef.current, JSON.stringify({ value }));
    } catch {}
  }, [value]);

  useEffect(() => {
    const last = keyRef.current;

    if (key !== last) {
      try {
        localStorage.setItem(key, JSON.stringify({ value }));
        keyRef.current = key;
        localStorage.removeItem(last);
      } catch {}
    }
  }, [key]);

  return [value, setValue];
};
