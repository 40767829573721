import { usePersistedState } from 'hooks/usePersistedState';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';
import { workspaceContextStore } from './Workspace2';
import { WorkspaceDatasetBrowser } from './WorkspaceDatasetBrowser';
import { WorkspacePanel } from './WorkspacePanel';
import { workspaceState } from './state/workspaceState';
import { AiOutlineFolder } from 'react-icons/ai';

export const WorkspaceSidebarContainer = () => {
  const [showSiderbar, setShowSidebar] = useAtom(workspaceState.showSidebarAtom, { store: workspaceContextStore });

  const draggableRef = useRef<HTMLDivElement | null>(null);
  const [componentWidth, setComponentWidth] = usePersistedState(340, 'workspace-sidebar-width');
  const handleOnDrag = (_: DraggableEvent, { deltaX }: DraggableData) => {
    setComponentWidth((prev: any) => prev + deltaX);
  };

  if (!showSiderbar) {
    return null;
  }

  return (
    <WorkspacePanel>
      <div className="flex flex-col overflow-hidden shrink-0 relative bg-ws-panel" style={{ width: componentWidth }}>
        <DraggableCore nodeRef={draggableRef} offsetParent={document.body} onDrag={handleOnDrag}>
          <div
            className="absolute h-full top-0 bottom-0 right-0 cursor-ew-resize"
            style={{ width: 5 }}
            ref={draggableRef}
          ></div>
        </DraggableCore>

        <WorkspaceDatasetBrowser />
      </div>
    </WorkspacePanel>
  );
};
