import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { LayoutContent } from 'components/common/Layout/LayoutContent';
import { LayoutContentBody } from 'components/common/Layout/LayoutContentBody';
import { LayoutContentHeader } from 'components/common/Layout/LayoutContentHeader';
import { Typography } from 'components/common/Typography';
import { OrganizationCreateModal } from 'components/Modals/OrganizationCreateModal/OrganizationCreateModal';
import { OrganizationListing } from './OrganizationListing/OrganizationListing';

export const Organizations = () => {
  return (
    <LayoutContentBody>
      <LayoutContentHeader>
        <div className="flex items-center">
          <Typography component="h1" className="text-2xl">
            Organizations
          </Typography>
        </div>

        <div>
          <Dialog render={() => <OrganizationCreateModal />}>
            <Button>Create Organization</Button>
          </Dialog>
        </div>
      </LayoutContentHeader>

      <LayoutContent>
        <OrganizationListing />
      </LayoutContent>
    </LayoutContentBody>
  );
};
