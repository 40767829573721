import { AppRoutes } from 'appRoutes';
import classNames from 'classnames';
import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { DropdownButtonItem } from 'components/common/DropdownButtonItem/DropdownButtonItem';
import { OrganizationCreateModal } from 'components/Modals/OrganizationCreateModal/OrganizationCreateModal';
import { ProfileSettingsModal } from 'components/Modals/ProfileSettingsModal/ProfileSettingsModal';
import { ProjectCreateModal } from 'components/Modals/ProjectCreateModal/ProjectCreateModal';
import { useLogoutFlow } from 'hooks/auth/useLogoutFlow';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useTheme } from 'hooks/useTheme';
import { useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BiCheck } from 'react-icons/bi';
import { BsPlusSquare } from 'react-icons/bs';
import { FaUserCog } from 'react-icons/fa';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineLogout } from 'react-icons/md';
import { RiStackFill } from 'react-icons/ri';
import { useRouteMatch } from 'react-router-dom';
import { useSwitchOrganization } from './useSwitchOrganization';

export const ProfileMenu = () => {
  const whoamiQuery = useQuery('GetWhoAmI', {});
  const { logout } = useLogoutFlow();
  const { data: organizations } = useQuery('GetOrganizations', {});
  const { pathTo, navigateTo } = useRouterUtils();
  const { theme, toggle: toggleTheme } = useTheme();

  const [isProfileSettingsModalOpen, setIsProfileSettingsModalOpen] = useState(false);
  const [isOrganizationCreateModalOpen, setIsOrganizationCreateModalOpen] = useState(false);
  const [isProjectCreateModalOpen, setIsProjectCreateModalOpen] = useState(false);
  /**
   * The "Route" component only matches "/" here, so it'll never know about params
   * and therefore useParams.
   *
   * The Route of AdministrativeViewLayout (parent of Header) COULD be made to know
   * about the URLs of its children, with an array of all of its child URLs
   * However, that's a lot more work, so here we are
   */
  const matchOrganization = useRouteMatch<RouteParams.Organization>(AppRoutes.ORGANIZATION);
  const matchProject = useRouteMatch<RouteParams.Project>(AppRoutes.PROJECT);

  const organizationId = matchOrganization?.params.organizationId
    ? parseInt(matchOrganization.params.organizationId, 10)
    : -1;
  const projectId = matchProject?.params.projectId ? parseInt(matchProject.params.projectId, 10) : -1;

  const projectsQuery = useQuery(
    'GetProjects',
    {
      params: { organizationId },
    },
    { enabled: organizationId !== -1 }
  );

  const [handleSwitchOrganization] = useSwitchOrganization();

  const { mutateAsync: setUserPreference } = useMutation('SetUserPreference');
  const handleSwitchProject = (id: ProjectDTO['id']) => {
    if (organizationId === -1) {
      return;
    }
    setUserPreference({ data: { organizations: { [organizationId.toString()]: { defaultProjectId: id } } } });
    navigateTo('PROJECT', { projectId: id });
  };

  const dropdown = (
    <div className="flex flex-col" style={{ minWidth: 250 }}>
      <div className="px-4 py-3 font-medium text-slate-700 border-b bg-slate-50">{whoamiQuery.data?.email}</div>

      <div>
        <DropdownButtonItem onClick={() => setIsProfileSettingsModalOpen(true)}>
          <FaUserCog />
          Profile Settings
        </DropdownButtonItem>
      </div>

      <div className="border-t border-b">
        <div className="text-xs text-slate-500 px-4 py-2 pb-2">ORGANIZATIONS</div>
        {organizations?.map(({ id, name }) => (
          <div className="relative" key={id}>
            <DropdownButtonItem
              onClick={() => handleSwitchOrganization(id)}
              disabled={organizationId === id}
              className={classNames({ 'font-medium text-slate-700': organizationId === id })}
              data-prevent-dropdown-close
            >
              {organizationId === id ? <BiCheck /> : <RiStackFill className="invisible" />}
              <span>{name}</span>
            </DropdownButtonItem>

            {organizationId === id && (
              <button
                onClick={() => navigateTo('ORGANIZATION', { organizationId: id })}
                className="absolute right-0 top-0 bottom-0 flex items-center px-4 hover:text-blue-500"
              >
                <IoSettingsOutline />
              </button>
            )}
          </div>
        ))}

        <div>
          <DropdownButtonItem onClick={() => setIsOrganizationCreateModalOpen(true)}>
            <BsPlusSquare />
            Create an Organization
          </DropdownButtonItem>
        </div>
      </div>

      <div className="border-b">
        <div className="text-xs text-slate-500 px-4 py-2 pb-2">PROJECTS</div>
        {projectsQuery.data?.map(({ id, name }) => (
          <div key={id}>
            <DropdownButtonItem
              onClick={() => handleSwitchProject(id)}
              disabled={projectId === id}
              className={classNames({ 'font-medium text-slate-700': projectId === id })}
            >
              {projectId === id ? <BiCheck /> : <RiStackFill className="invisible" />}
              {name}
            </DropdownButtonItem>
          </div>
        ))}

        <div>
          <DropdownButtonItem onClick={() => setIsProjectCreateModalOpen(true)}>
            <BsPlusSquare />
            Create a Project
          </DropdownButtonItem>
        </div>
      </div>

      {/* <div className="px-4 py-2 flex items-center justify-between">
        <div className="text-xs text-slate-500">CHANGE THEME</div>

        <InputSwitch
          checked={theme === 'dark'}
          onChange={toggleTheme}
          iconLeft={<MdOutlineWbSunny />}
          iconRight={<MdNightlightRound />}
        />
      </div> */}

      <div className="border-t">
        <DropdownButtonItem onClick={logout}>
          <MdOutlineLogout />
          Sign out
        </DropdownButtonItem>
      </div>
    </div>
  );

  return (
    <>
      <Dialog
        render={() => <ProfileSettingsModal />}
        isOpen={isProfileSettingsModalOpen}
        onOpenChange={setIsProfileSettingsModalOpen}
      />

      <Dialog
        render={() => <OrganizationCreateModal />}
        isOpen={isOrganizationCreateModalOpen}
        onOpenChange={setIsOrganizationCreateModalOpen}
      />

      <Dialog
        render={() => <ProjectCreateModal />}
        isOpen={isProjectCreateModalOpen}
        onOpenChange={setIsProjectCreateModalOpen}
      />

      <Dropdown render={() => dropdown}>
        <Button variant="icon-rounded" icon={<AiOutlineUser />} title={whoamiQuery.data?.email} />
      </Dropdown>
    </>
  );
};
