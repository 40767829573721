import { MOUSE_BUTTON } from 'modules/image-annotator/enums';
import { VideoEditorEvents } from '../enums';

export const MoveTool = (scene: any) => {
  let dragTarget: any = null;
  let offset = [0, 0]; // @todo

  function onMouseDown(event: any) {
    if (!event.target || event.originalEvent.button !== MOUSE_BUTTON.MAIN) {
      return;
    }

    if (event.target.data && !event.target.data().draggable) {
      return;
    }

    dragTarget = event.target;
    offset = [event.coords.x, event.coords.y];
  }

  function onMouseMove(event: any) {
    // @todo debounce
    if (dragTarget === null) {
      return;
    }

    const { x, y } = event.coords;

    const aabb = dragTarget.getAABB();
    const bbox = { x: aabb[0], y: aabb[1], width: aabb[2] - aabb[0], height: aabb[5] - aabb[1] };
    const world = scene.world.getState();

    const tx = Math.min(Math.max(x - offset[0], -bbox.x), world.width - (bbox.x + bbox.width));
    const ty = Math.min(Math.max(y - offset[1], -bbox.y), world.height - (bbox.y + bbox.height));

    dragTarget._translateGlobal({ x: tx, y: ty });

    if (dragTarget.data('syncDragTarget')) {
      dragTarget.data('syncDragTarget')._translateGlobal({ x: tx, y: ty });
    }

    offset[0] = event.coords.x;
    offset[1] = event.coords.y;
  }

  function onMouseUp() {
    if (!dragTarget) {
      return;
    }

    dragTarget.dispatch(VideoEditorEvents.USER_TRANSFORM_END);
    dragTarget = null;
  }

  function _init() {
    scene.on('mousedown', onMouseDown);
    scene.on('mousemove', onMouseMove);
    scene.on('mouseup', onMouseUp);
  }

  _init();
};
