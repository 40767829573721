import useIntParams from 'hooks/useIntParams';
import { Logo } from './Logo/Logo';
import { SidebarOrganizationSlide } from './SidebarOrganizationSlide/SidebarOrganizationSlide';
import { SidebarProjectSlide } from './SidebarProjectSlide/SidebarProjectSlide';

export const Sidebar = () => {
  const { organizationId, projectId } = useIntParams();

  return (
    <div className="flex flex-1 flex-col overflow-hidden border-r border-slate-100 bg-slate-50">
      <div className="p-5">
        <Logo />
      </div>

      {organizationId && (
        <>
          <div
            className="flex flex-row flex-grow transition-transform duration-200 transform-gpu overflow-hidden"
            style={{
              width: '200%',
              // @ts-ignore
              '--tw-translate-x': projectId ? '-50%' : 0,
            }}
          >
            <div className="flex-1 w-full px-4 overflow-y-scroll overscroll-y-contain invisible-scrollbar">
              <SidebarOrganizationSlide />
            </div>

            <div className="flex-1 w-full px-4 overflow-y-scroll overscroll-y-contain invisible-scrollbar">
              <SidebarProjectSlide />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
