import useIntParams from 'hooks/useIntParams';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { Link } from 'react-router-dom';

export const Logo = () => {
  const { organizationId, projectId } = useIntParams();
  const { pathTo } = useRouterUtils();

  return (
    <Link
      to={
        projectId
          ? pathTo('PROJECT', { organizationId, projectId })
          : organizationId
          ? pathTo('ORGANIZATION', { organizationId })
          : '/'
      }
    >
      <div className="flex flex-row gap-2 items-center text-slate-900">
        <div
          className="rounded-sm text-slate-50 w-8 h-8 flex items-center justify-center bg-black"
          style={{ fontSize: 30, fontWeight: 400 }}
        >
          <span style={{ marginTop: -3 }}>a</span>
        </div>
        <div className="font-semibold" style={{ fontSize: 26 }}>
          annotorio
        </div>
      </div>
    </Link>
  );
};
