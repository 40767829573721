import { Button } from 'components/common/Button/Button';
import { Dialog } from 'components/common/Dialog/Dialog';
import { JobDataitemFilters } from 'components/common/JobDataitemFilters/JobDataitemFilters';
import { Table } from 'components/common/Table/Table';
import { useJobDataitemsTable } from 'hooks/react-table/useJobDataitemsTable';
import useIntParams from 'hooks/useIntParams';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { useCallback, useState } from 'react';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { IoGitBranchOutline } from 'react-icons/io5';
import { JobDataitemAssignModal } from './JobDataitemAssignModal/JobDataitemAssignModal';

export const JobDataitems = () => {
  const { organizationId, projectId, jobId } = useIntParams();

  const [jobDataitemSelections, setJobDataitemSelections] = useState<JobDataitemDTO['id'][]>([]);
  const [jobDataitemsQueryParams, setJobDataitemsQueryParams] = useState<{
    [key: string]: any;
  }>({
    searchQuery: undefined,
  });
  const jobDataitemsQuery = usePaginatedQuery('GetJobDataitemsWithPagination', {
    params: { organizationId, projectId, jobId },
    query: {
      ...jobDataitemsQueryParams,
    },
  });

  const { instance } = useJobDataitemsTable({ rows: jobDataitemsQuery.data?.results });

  return (
    <>
      <Table
        instance={instance}
        rowSelect={true}
        onRowSelectionUpdate={useCallback((selections: any) => setJobDataitemSelections(selections), [])}
        pagination={jobDataitemsQuery.pagination}
        onSearch={(searchQuery) => setJobDataitemsQueryParams((prev) => ({ ...prev, searchQuery }))}
        filtersComponent={
          <JobDataitemFilters
            filters={jobDataitemsQueryParams}
            onChange={(filters) =>
              setJobDataitemsQueryParams((prev) => ({
                ...prev,
                ...filters,
              }))
            }
          />
        }
        selectedRowActions={
          <div className="flex items-center gap-1">
            <Dialog
              render={() => (
                <JobDataitemAssignModal
                  jobDataitemsQueryParams={jobDataitemsQueryParams}
                  jobDataitemIds={Array.from(jobDataitemSelections)}
                />
              )}
            >
              <Button
                icon={<AiOutlineUsergroupAdd />}
                label="Assign"
                variant="icon-rounded"
                size="sm"
                disabled={jobDataitemSelections.length === 0}
              />
            </Dialog>

            <Button
              icon={<IoGitBranchOutline />}
              label="State Update"
              variant="icon-rounded"
              size="sm"
              disabled={jobDataitemSelections.length === 0}
            />
          </div>
        }
      />
    </>
  );
};
