import { axiosInstance } from 'http/axios';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

export const useHttpInterceptor = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const handler = (err: any) => {
      if (err?.response?.status === 401) {
        queryClient.invalidateQueries([{ id: 'IsAuthenticated' }]);
      }
    };

    // @ts-ignore
    axiosInstance.on('error', handler);

    return () => {
      // @ts-ignore
      axiosInstance.off('error', handler);
    };
  }, [queryClient]);
};
