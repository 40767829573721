import { videoTimelineState } from 'components/Workspace2/state/videoTimelineState';
import { annotationAtomsAtom, selectedAnnotationInstanceAtom } from 'components/Workspace2/useAnnotationsStore';
import { Atom, useAtom } from 'jotai';
import { useRef } from 'react';
import { VideoTimelineRowHeading } from './VideoTimelineRowHeading';
import { useVideoTimelineYScrollSync } from './useVideoTimelineYScrollSync';

const headingColumnWidth = 320;

export const VideoTimelineHeadingsColumn = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [annotationAtoms] = useAtom(annotationAtomsAtom);
  const [showSelectionOnly] = useAtom(videoTimelineState.showSelectionOnly);
  const [selectedAnnotationInstance] = useAtom(selectedAnnotationInstanceAtom);
  const items = showSelectionOnly
    ? selectedAnnotationInstance !== undefined
      ? [selectedAnnotationInstanceAtom as Atom<AnnotationInstanceDTO>]
      : []
    : annotationAtoms;

  useVideoTimelineYScrollSync(containerRef);

  return (
    <div
      ref={containerRef}
      className="invisible-scrollbar overflow-scroll"
      style={{ width: headingColumnWidth, marginBottom: 16 }}
    >
      {items.map((atom) => (
        <VideoTimelineRowHeading key={`${atom}`} atom={atom} />
      ))}
    </div>
  );
};
