import { api } from 'api/api';
import { ReactQueryMutationOptions } from 'config/reactQueryMutationOptions';
import {
  useMutation as useReactQueryMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from 'react-query';

export function useMutation<T extends typeof api.mutations, K extends keyof typeof api.mutations>(
  type: K,
  options?: UseMutationOptions<Awaited<ReturnType<T[K]>>>
): UseMutationResult<Awaited<ReturnType<T[K]>>, unknown, Parameters<T[K]>[0], unknown> {
  const queryClient = useQueryClient();
  const reactQueryMutationOptions = ReactQueryMutationOptions<T, K>(queryClient)[type];

  const extendedOptions = {
    ...options,
    onSettled(data: Awaited<ReturnType<T[K]>> | undefined, error: unknown, variables: any, context: unknown) {
      reactQueryMutationOptions?.onSettled && reactQueryMutationOptions.onSettled(data, error, variables, context);
      options?.onSettled && options.onSettled(data, error, variables, context);
    },
    meta: {
      type,
    },
  };

  return useReactQueryMutation(
    // @ts-ignore
    (payload: Parameters<T[K]>[0]) =>
      // @ts-ignore
      api.mutations[type](payload),
    extendedOptions
  );
}
