import { SubmitSelfServiceRecoveryFlowWithLinkMethodBody } from '@ory/kratos-client';
import { FormMessage } from 'components/common/FormMessage/FormMessage';
import { OryFormUi } from 'components/common/OryFormUi/OryFormUi';
import { Typography } from 'components/common/Typography';
import { useAccountRecoveryFlow } from 'hooks/auth/useAccountRecoveryFlow';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

export const AccountRecovery = () => {
  const { flow, accountRecovery } = useAccountRecoveryFlow();

  const form = useForm<SubmitSelfServiceRecoveryFlowWithLinkMethodBody>();
  const { handleSubmit } = form;

  const onSubmit: SubmitHandler<
    SubmitSelfServiceRecoveryFlowWithLinkMethodBody
  > = async (data) => {
    return accountRecovery(data);
  };

  if (!flow) {
    return null;
  }

  return (
    <div className="max-w-xs w-full">
      <Typography component="h1" className="my-3 text-center">
        Account Recovery
      </Typography>

      <div>
        {(flow.ui.messages || []).map((message) => (
          <FormMessage
            key={message.id}
            text={message.text}
            type={message.type}
          />
        ))}

        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <OryFormUi ui={flow.ui} />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
