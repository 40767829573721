import { useEffect } from 'react';
import { PaddleSubscriptionStatus } from './../enums';
import { useMutation } from './useMutation';

export const usePaddleJs = () => {
  const { mutateAsync: updateSubscriptionPlan } = useMutation('UpdateSubscriptionPlan');

  useEffect(() => {
    const existingScript = document.getElementById('paddle-js');

    if (existingScript) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.id = 'paddle-js';
    script.onload = () => {
      if (globalConfig.env.PADDLE_SANDBOX === 'true') {
        Paddle.Environment.set('sandbox');
      }

      Paddle.Setup({
        vendor: Number(globalConfig.env.PADDLE_VENDOR_ID),
        eventCallback: (data) => {
          if (data.event === 'Checkout.Complete') {
            const { organizationId } = JSON.parse(data.eventData.checkout.passthrough);

            updateSubscriptionPlan({
              data: {
                state: PaddleSubscriptionStatus.PENDING,
                planId: data.eventData.product.id,
              },
              params: {
                organizationId,
              },
            });
          }
        },
      });
    };

    document.body.appendChild(script);
  }, []);
};
