import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const annotationInstanceOptionsAtom = atom<any>({});
const followCurrentFrameAtom = atomWithStorage('video-timeline-follow-current-frame', true);
const showSelectionOnly = atomWithStorage('video-timeline-show-selection-only', false);
const onlyShowPropertiesWithKeyframeValues = atomWithStorage(
  'video-timeline-only-show-properties-with-keyframe-values',
  true
);
const hoveredRow = atom<any>(null);

export const videoTimelineState = {
  showSelectionOnly,
  followCurrentFrameAtom,
  annotationInstanceOptionsAtom,
  hoveredRow,
  onlyShowPropertiesWithKeyframeValues,
};
