import { FloatingPortal, autoUpdate, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { forwardRef, useEffect, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';

export const AnnotationGraphViewNode = forwardRef(
  ({ label, onMouseDown, onMouseUp, onRemove, onUnmount }: any, ref: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState([0, 0]);
    const { strategy, refs, context } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      whileElementsMounted: autoUpdate,
      strategy: 'fixed',
    });
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    useEffect(() => {
      return onUnmount;
    }, []);

    return (
      <>
        <div ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
          <div
            className="flex font-medium items-center justify-center select-none text-xxs items-center px-6 py-1 rounded shadow-md border border-white/20 z-10 cursor-pointer bg-black/70  hover:bg-black/80"
            style={{ color: 'rgb(4 255 252)', textShadow: '#000 1px 1px 2px' }}
            onContextMenu={(e) => {
              e.preventDefault();
              setContextMenuPosition([e.clientX, e.clientY]);
              setIsOpen(true);
            }}
            ref={refs.setReference}
            {...getReferenceProps()}
          >
            {label}
          </div>
        </div>

        {isOpen && (
          <FloatingPortal>
            <div
              className="text-neutral-200 text-xxs z-10 border border-neutral-800 bg-black"
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: contextMenuPosition[1],
                left: contextMenuPosition[0],
                transform: 'translateX(-50%)',
              }}
              {...getFloatingProps()}
            >
              <button
                className="flex w-full items-center gap-2 bg-black py-1.5 px-4 hover:bg-neutral-800"
                onClick={onRemove}
              >
                <IoCloseSharp /> Remove
              </button>
            </div>
          </FloatingPortal>
        )}
      </>
    );
  },
);
