import { forwardRef } from 'react';
import classNames from 'classnames';

interface Props
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type'> {
  label?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { label, className, ...rest } = props;

  // eslint-disable-next-line react/forbid-elements
  const input = <input ref={ref} type="checkbox" {...rest} />;

  if (label) {
    return (
      <div className={classNames('form-checkbox', className)}>
        <label className="inline-flex items-center gap-1">
          {input}
          <span>{label}</span>
        </label>
      </div>
    );
  }

  return input;
});
