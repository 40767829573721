import { rotate2d } from './2d';
import { Matrix2 } from './Matrix2';

const translate = (transform: number[], type: 'local' | 'global', { x, y }: { x: number; y: number }) => {
  const matrix = Matrix2(...transform);
  const t = matrix;
  const d = matrix.decompose();

  if (type === 'local') {
    t.translate(x / d.scale[0], y / d.scale[1]);
  }

  if (type === 'global') {
    const p = rotate2d({ x, y }, -d.rotation / (Math.PI / 180));
    t.translate(p.x / d.scale[0], p.y / d.scale[1]);
  }

  return t.get();
};

const scale = (
  transform: number[],
  type: 'local' | 'global',
  { x: sx, y: sy }: { x: number; y: number },
  center?: { x: number; y: number }
) => {
  const matrix = Matrix2(...transform);
  const t = matrix;
  const d = matrix.decompose();

  center = center || { x: 0, y: 0 };

  if (type === 'local') {
    const { x, y } = rotate2d({ x: d.translation[0], y: d.translation[1] }, -d.rotation / (Math.PI / 180));

    const p = rotate2d(center, -d.rotation / (Math.PI / 180));

    t.scale(1 / d.scale[0], 1 / d.scale[1]);
    t.translate(p.x - x, p.y - y);
    t.scale(sx, sy);
    t.translate(-(p.x - x), -(p.y - y));
    t.scale(d.scale[0], d.scale[1]);
  }

  if (type === 'global') {
    t.translate(center.x, center.y);
    t.rotate(-d.rotation);
    t.scale(sx, sy);
    t.rotate(d.rotation);
    t.translate(-center.x, -center.y);
  }

  return t.get();
};

const rotate = (transform: number[], angle: number, center?: { x: number; y: number }) => {
  center = center || { x: 0, y: 0 };

  const matrix = Matrix2(...transform);
  const t = matrix;
  const d = matrix.decompose();

  const { x, y } = rotate2d({ x: d.translation[0], y: d.translation[1] }, -d.rotation / (Math.PI / 180));

  const p = rotate2d(center, -d.rotation / (Math.PI / 180));

  t.scale(1 / d.scale[0], 1 / d.scale[1]);
  t.translate(p.x - x, p.y - y);
  t.rotateDeg(angle);
  t.translate(-(p.x - x), -(p.y - y));
  t.scale(d.scale[0], d.scale[1]);

  return t.get();
};

const setRotation = (transform: number[], angle: number, center?: { x: number; y: number }) => {
  center = center || { x: 0, y: 0 };

  const matrix = Matrix2(...transform);
  const t = matrix;
  const d = matrix.decompose();

  const { x, y } = rotate2d({ x: d.translation[0], y: d.translation[1] }, -d.rotation / (Math.PI / 180));

  const p = rotate2d(center, -d.rotation / (Math.PI / 180));

  t.scale(1 / d.scale[0], 1 / d.scale[1]);
  t.translate(p.x - x, p.y - y);
  t.rotateDeg(-(d.rotation / (Math.PI / 180)) + angle);
  t.translate(-(p.x - x), -(p.y - y));
  t.scale(d.scale[0], d.scale[1]);

  return t.get();
};

export const transform2d = {
  rotate,
  setRotation,
  translate,
  scale,
};
