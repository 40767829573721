import { useAtom } from 'jotai';
import { useContext, useEffect, useRef } from 'react';
import { highlightedAnnotationInstanceAtom, useAnnotationsStore } from '../useAnnotationsStore';
import { VideoEditorContext } from '../VideoEditorContext';

export const useObjectHighlight = () => {
  const [highlightedAnnotationInstance] = useAtom(highlightedAnnotationInstanceAtom);
  const { videoEditor } = useContext(VideoEditorContext); // @todo use atom
  const { highlight } = useAnnotationsStore();
  const prevRef = useRef<any>(null);

  // on unhighlight
  useEffect(() => {
    if (!videoEditor) {
      return;
    }

    if (prevRef.current && prevRef.current.id !== highlightedAnnotationInstance?.id) {
      const editorObject = prevRef.current.editorObject;

      if (editorObject && editorObject.data('isHighlighted')) {
        const initialStyles = editorObject.data('initial-styles');
        editorObject.set(initialStyles);
        editorObject.data('isHighlighted', false);
      }
    }

    if (prevRef.current?.id !== highlightedAnnotationInstance?.id) {
      prevRef.current = highlightedAnnotationInstance;
    }
  }, [videoEditor, highlightedAnnotationInstance]);

  // on highlight
  useEffect(() => {
    if (!videoEditor) {
      return;
    }

    if (!highlightedAnnotationInstance) {
      return;
    }

    const editorObject = (highlightedAnnotationInstance as any).editorObject;

    if (!editorObject || editorObject.data('isHighlighted')) {
      return;
    }

    const { strokeStyle } = editorObject.get();
    editorObject.data('isHighlighted', true);
    editorObject.data('initial-styles', { strokeStyle });
    editorObject.set({ strokeStyle: 'blue' });
  }, [videoEditor, highlightedAnnotationInstance]);

  useEffect(() => {
    if (!videoEditor) {
      return;
    }

    const { scene } = videoEditor.objects;

    const handlerMouseEnter = (event: any) => {
      const target = event.target ? event.target.data('externalId') : null;
      highlight(target);
    };

    const handleMouseLeave = (event: any) => {
      highlight(null);
    };

    scene.on('mouseenter', handlerMouseEnter);
    scene.on('mouseleave', handleMouseLeave);

    return () => {
      scene.off('mouseenter', handlerMouseEnter);
      scene.off('mouseleave', handleMouseLeave);
    };
  }, [videoEditor, highlight]);
};
