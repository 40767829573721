import classNames from 'classnames';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { AiOutlineExport } from 'react-icons/ai';

import { IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { RiTodoLine } from 'react-icons/ri';
import { SidebarMenuItem } from '../shared/SidebarMenuItem/SidebarMenuItem';
import { SidebarDatasetsPanel } from './SidebarDatasetsPanel/SidebarDatasetsPanel';
import { SidebarJobsPanel } from './SidebarJobsPanel/SidebarJobsPanel';

export const SidebarProjectSlide = () => {
  const { organizationId, projectId } = useIntParams();
  const { pathTo } = useRouterUtils();

  const myAssignmentsQuery = useQuery('GetMyAssignments', {
    params: { organizationId, projectId },
  });

  const totalAssignmentsCount = myAssignmentsQuery.data
    ?.map((e) => Object.values(e.mapStateToDataitemCount).reduce((acc, val) => acc + val, 0))
    .reduce((acc, val) => acc + val, 0);

  if (!projectId) {
    return null;
  }

  return (
    <>
      <div className="flex flex-grow flex-col overflow-hidden">
        <SidebarMenuItem
          linkTo={pathTo('PROJECT')}
          icon={<MdOutlineSpaceDashboard size={22} />}
          label="Project Dashboard"
          exact
        />

        <SidebarMenuItem
          linkTo={pathTo('MY_ASSIGNMENTS')}
          icon={<RiTodoLine size={22} />}
          label={
            <div className="flex w-full items-center justify-between">
              <div>My Assignments</div>
              <div
                className={classNames('text-xxs rounded border px-2 py-0.5 font-medium shadow-sm', {
                  'border-pink-200 bg-white text-pink-500  ': totalAssignmentsCount !== 0,
                })}
              >
                {totalAssignmentsCount}
              </div>
            </div>
          }
        />

        <SidebarMenuItem linkTo={pathTo('ALL_JOB_EXPORTS')} icon={<AiOutlineExport size={22} />} label="Job Exports" />

        <SidebarMenuItem
          linkTo={pathTo('PROJECT_SETTINGS')}
          icon={<IoSettingsOutline size={22} />}
          label="Settings"
          exact
        />

        <div className="my-4 border-b"></div>

        <div className="flex flex-1 flex-grow flex-col overflow-hidden">
          <div className="flex flex-col overflow-hidden">
            <SidebarJobsPanel />
          </div>

          <div className="my-3 border-b"></div>

          <div className="flex flex-col overflow-hidden">
            <SidebarDatasetsPanel />
          </div>
        </div>
      </div>
    </>
  );
};
