import { AppRouteParams, AppRoutes } from 'appRoutes';
import { useCallback } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

export const useRouterUtils = () => {
  const history = useHistory();
  const locationParams = useParams();

  const pathTo = useCallback(
    function <T extends keyof typeof AppRoutes>(
      routeName: T,
      params?: { [param in keyof AppRouteParams[T]]: string | number }
    ) {
      try {
        return generatePath(AppRoutes[routeName] as string, {
          ...locationParams,
          ...(params || {}),
        });
      } catch (err) {
        console.error(err);
        return AppRoutes.ROUTE_ERROR;
      }
    },
    [locationParams]
  );

  const navigateTo = useCallback(
    function nav<T extends keyof typeof AppRoutes>(
      routeName: T,
      params?: { [param in keyof AppRouteParams[T]]: string | number }
    ) {
      const path = pathTo(routeName, params);
      history.push(path);
    },
    [history, pathTo]
  );

  return {
    pathTo,
    navigateTo,
  };
};
