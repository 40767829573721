import axios from 'axios';
import { QueryClient } from 'react-query';

const REQUEST_RETRY_COUNT = 3;
const NO_RETRY_HTTP_STATUS_CODES = [400, 401, 403, 404];
const STALE_TIME_IN_MS = 1000 * 60;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME_IN_MS,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: (failureCount, error) => {
        if (axios.isAxiosError(error) && error.response && NO_RETRY_HTTP_STATUS_CODES.includes(error.response.status)) {
          return false;
        }

        return failureCount <= REQUEST_RETRY_COUNT;
      },
    },
  },
});
