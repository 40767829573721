import { useAtom } from 'jotai';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { VideoEditorContext } from '../VideoEditorContext';
import { videoEditorState } from '../state/videoEditorState';

// @todo optimization required
export function VideoSeekbar() {
  const { videoEditor } = useContext(VideoEditorContext);
  const [frameCount] = useAtom(videoEditorState.frameCountAtom);
  const [currentFrame] = useAtom(videoEditorState.currentFrameAtom);
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentFrameCandidate, setCurrentFrameCandidate] = useState(0);
  const [currentFrameCandidatePosition, setCurrentFrameCandidatePosition] = useState(0);
  const [isScrubbing, setIsScrubbing] = useState(false);
  const [showFrameTooltip, setShowFrameTooltip] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const seekTo = videoEditor ? videoEditor.video.seekTo : () => {};

  const getFrameIndexFromMouseEvent = (event: React.MouseEvent) => {
    if (!containerRef.current) {
      return;
    }

    // @todo avoid layout trashing
    const clientRect = containerRef.current.getBoundingClientRect();
    const x = event.pageX - clientRect.x;
    const frame = Math.min(Math.max(1, Math.round((x * frameCount) / clientRect.width)), frameCount);
    return frame;
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    const frame = getFrameIndexFromMouseEvent(event);
    if (frame !== undefined) {
      setCurrentFrameCandidate(frame);

      if (!containerRef.current) {
        return;
      }
      const clientRect = containerRef.current.getBoundingClientRect();
      const x = event.pageX - clientRect.x;
      setCurrentFrameCandidatePosition(Math.min(Math.max(x, 0), clientRect.width));
    }
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    setIsScrubbing(true);

    const frame = getFrameIndexFromMouseEvent(event);
    if (frame !== undefined) {
      seekTo(frame);
    }
  };

  const handleMouseMoveOnDocument = (event: MouseEvent) => {
    const frame = getFrameIndexFromMouseEvent(event as any);
    if (frame !== undefined) {
      seekTo(frame);
    }

    if (!containerRef.current) {
      return;
    }
    const clientRect = containerRef.current.getBoundingClientRect();
    const x = event.pageX - clientRect.x;
    setCurrentFrameCandidatePosition(Math.min(Math.max(x, 0), clientRect.width));
  };

  const handleMouseUpOnDocument = () => {
    setIsScrubbing(false);

    if (!isHovered) {
      setShowFrameTooltip(false);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    setShowFrameTooltip(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (!isScrubbing) {
      setShowFrameTooltip(false);
    }
  };

  useEffect(() => {
    if (isScrubbing) {
      document.addEventListener('mousemove', handleMouseMoveOnDocument);
      document.addEventListener('mouseup', handleMouseUpOnDocument);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMoveOnDocument);
      document.removeEventListener('mouseup', handleMouseUpOnDocument);
    };
  }, [isScrubbing, handleMouseMoveOnDocument, handleMouseUpOnDocument]);

  const frameIndex = isScrubbing ? currentFrame : currentFrameCandidate;

  return (
    <div className="relative my-3 mx-3 w-auto z-100" style={{ height: 10 }}>
      <div
        ref={containerRef}
        className="relative h-full w-full cursor-pointer select-none rounded-sm bg-neutral-800"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
      >
        {frameCount && (
          <div
            className="pointer-events-none absolute left-0 top-0 z-20 h-full bg-blue-500"
            style={{ width: ((currentFrame - 1) * 100) / (frameCount - 1) + '%' }}
          ></div>
        )}

        {showFrameTooltip && (
          <>
            <div
              className="pointer-events-none absolute left-0 top-0 z-10 h-full bg-white/20"
              style={{ width: currentFrameCandidatePosition + 'px' }}
            ></div>
            <div
              className="absolute mb-1 rounded-sm bg-neutral-700 p-1 text-gray-300 z-100"
              style={{
                fontSize: 10,
                transform: 'translateX(-50%)',
                bottom: '100%',
                left: currentFrameCandidatePosition + 'px',
              }}
            >
              {frameIndex}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
