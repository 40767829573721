import { useEffect } from 'react';

export const useKeyboardShortcut = (eventCode: KeyboardEvent['code'], callback: () => void) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.code === eventCode) {
        callback();
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [callback]);
};
