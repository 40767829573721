import classNames from 'classnames';
import { mapAnnotationClassPropertyTypeToDisplayName } from 'enums';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { MdArrowForwardIos, MdDragIndicator } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';
import { AnnotationClassPropertyType } from 'shared/enums';

interface Props {
  annotationClassProperty: AnnotationClassPropertyDTO;
  isSelected: boolean;
  isRepositionable?: boolean;
}

export const JobTasksAnnotationClassPropertyListItem = ({
  annotationClassProperty,
  isSelected,
  isRepositionable = true,
}: Props) => {
  const { navigateTo } = useRouterUtils();
  const { id, label, type } = annotationClassProperty;

  const isValidConfiguration =
    annotationClassProperty.type === AnnotationClassPropertyType.ENUM
      ? annotationClassProperty.propertyOptions.length > 0
      : true;

  return (
    <button
      className={classNames('group rounded w-full hover:bg-slate-50', {
        'bg-slate-100 hover:bg-slate-100 text-black': isSelected,
      })}
      onClick={() => {
        navigateTo('JOB_TASKS_ANNOTATION_CLASS_PROPERTY', {
          annotationClassPropertyId: id,
        });
      }}
    >
      <div className="flex gap-4 items-center py-3 px-3">
        <div className="text-slate-500">
          {isRepositionable && <MdDragIndicator />}
        </div>
        <div className="text-left ">
          <div className="mb-1 flex items-center gap-1">
            <div className="uppercase text-xs text-slate-500">
              {mapAnnotationClassPropertyTypeToDisplayName[type]}
            </div>
            {!isValidConfiguration && (
              <RiErrorWarningLine className="text-amber-400" />
            )}
          </div>

          {label ? (
            <div className="text-black text-sm">{label}</div>
          ) : (
            <div className="text-slate-300 text-sm">Unlabeled</div>
          )}
        </div>

        <MdArrowForwardIos
          className={classNames('ml-auto text-slate-400', {
            'invisible group-hover:visible': !isSelected,
          })}
        />
      </div>
    </button>
  );
};
