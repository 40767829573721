import { Collapsible } from 'components/common/Collapsible';
import { Dialog } from 'components/common/Dialog/Dialog';
import { DatasetCreateModal } from 'components/Modals/DatasetCreateModal/DatasetCreateModal';
import { SidebarMenuItem } from 'components/Sidebar/shared/SidebarMenuItem/SidebarMenuItem';
import { DatasetImportHistoryStatus } from 'enums';
import useIntParams from 'hooks/useIntParams';
import { useQuery } from 'hooks/useQuery';
import { useRouterUtils } from 'hooks/useRouterUtils';
import { useState } from 'react';
import { BsPlusSquare } from 'react-icons/bs';
import { CgSpinner } from 'react-icons/cg';
import { FaRegCircle } from 'react-icons/fa';
import { FiHardDrive } from 'react-icons/fi';
import { MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';

export const SidebarDatasetsPanel = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const { organizationId, projectId } = useIntParams();
  const { pathTo } = useRouterUtils();
  const datasetsQuery = useQuery('GetDatasets', {
    params: { organizationId, projectId },
  });

  const datasetImportHistoriesQuery = useQuery('GetDatasetImportHistoriesWithPagination', {
    params: { organizationId, projectId },
    query: {
      status: DatasetImportHistoryStatus.RUNNING,
    },
  });

  const isImporting = (id: DatasetDTO['id']) =>
    datasetImportHistoriesQuery.data?.results.find(
      ({ datasetId, status }) => datasetId === id && status === DatasetImportHistoryStatus.RUNNING
    );

  return (
    <>
      <div className="relative">
        <SidebarMenuItem linkTo={pathTo('DATASETS')} label="Datasets" icon={<FiHardDrive size={22} />} exact />

        <div className="flex items-center absolute top-0 bottom-0 right-0 gap-1">
          <Dialog render={() => <DatasetCreateModal />}>
            <button className="text-slate-400 hover:text-slate-800 h-full" title="Create Dataset">
              <BsPlusSquare />
            </button>
          </Dialog>

          <button
            onClick={() => setIsPanelOpen((prev) => !prev)}
            className="flex h-full items-center justify-center px-2 text-slate-400 hover:text-slate-800"
          >
            {isPanelOpen ? <MdOutlineExpandLess size={18} /> : <MdOutlineExpandMore size={18} />}
          </button>
        </div>
      </div>

      <Collapsible open={isPanelOpen}>
        {datasetsQuery.data?.length === 0 && (
          <div className="text-xs text-slate-400 py-1 pl-11">No datasets created yet</div>
        )}

        {datasetsQuery.data?.map(({ id, name }) => (
          <SidebarMenuItem
            key={id}
            linkTo={pathTo('DATASET', {
              datasetId: id,
            })}
            label={
              <div className="flex items-center justify-between w-full">
                <div>{name}</div>
                <div>{isImporting(id) ? <CgSpinner className="text-blue-700 animate-spin text-base" /> : null}</div>
              </div>
            }
            icon={<FaRegCircle size={8} />}
            className="font-thin"
          />
        ))}
      </Collapsible>
    </>
  );
};
