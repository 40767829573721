import { colorStringToRgbaArray } from 'modules/image-annotator/utils/colorStringToRgbaArray';
import { Rect2 } from 'modules/video-editor/pxae/primitives/Rect2';
import { AnnotationClassType } from 'shared/enums';

const FILL_STYLE_OPACITY = 0.15;
const STROKE_STYLE_OPACITY = 0.8;

const DEFAULT_COLOR_DATA = {
  fillStyle: `rgba(0, 0, 0, ${FILL_STYLE_OPACITY})`,
  strokeStyle: `rgba(0, 0, 0, ${STROKE_STYLE_OPACITY})`,
};

export const getColorData = (annotationInstance?: AnnotationInstanceDTO) => {
  if (!annotationInstance || !annotationInstance.properties.className) {
    return DEFAULT_COLOR_DATA;
  }

  const { className } = annotationInstance.properties;

  const propertyOption = annotationInstance.annotationClass.properties
    .find((e) => e.key === 'className')
    ?.propertyOptions.find((e: any) => e.value === className);

  if (!propertyOption?.meta?.color) {
    return DEFAULT_COLOR_DATA;
  }

  const { color } = propertyOption.meta;
  const [r, g, b] = colorStringToRgbaArray(color || '');
  const fillStyle = `rgba(${r}, ${g}, ${b}, ${FILL_STYLE_OPACITY})`;
  const strokeStyle = `rgba(${r}, ${g}, ${b}, ${STROKE_STYLE_OPACITY})`;

  return {
    fillStyle,
    strokeStyle,
  };
};

export const attachEditorObject = (annotationInstance: AnnotationInstanceDTO) => {
  if (annotationInstance.annotationClass.type !== AnnotationClassType.BOUNDING_BOX) {
    return;
  }

  const editorObject = Rect2({
    ...getColorData(annotationInstance),
    points: annotationInstance.properties.points[0].value,
    lineWidth: 1,
    data: {
      externalId: annotationInstance.id,
      selectable: true,
      draggable: true,
    },
  });

  (annotationInstance as any).editorObject = editorObject;
};
